import './style.scss'

import React, { Component } from 'react'
import Product from '../../../models/Product'
import AddOn from './AddOn'

type Props = {
  addOns: Product[],
  selectedAddOns: Product[],
  fixedAddOns: Product[],
  onChange: Function,
  color: String
}

export default class AddOnGrid extends Component<Props, State> {
  render() {
    const { addOns } = this.props

    if (addOns.length === 0) {
      return <div style={{ textAlign: 'center' }}>No add-ons for the selected service.</div>
    }

    return (
      <div className="AddOnGrid">
        {addOns.map((addOn, i) => (
          <AddOn key={i} {...this.props} addOn={addOn} />
        ))}
      </div>
    )
  }
}
