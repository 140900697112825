import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Form from '../../shared/Form/Form'
import Field from '../../shared/Field/Field'
import SaveButton from '../../shared/SaveButton/SaveButton'
import {
  saveCorporateAccount,
  deleteCorporateAccount
} from '../../../store/actions/corporateAccounts'
import { handleError } from '../../../util/error-util'
import { toast } from '../../../store/actions/app'
import DeleteButton from '../../shared/DeleteButton/DeleteButton'
import confirm from '../../shared/confirm/confirm'
import ActionBar from '../../shared/ActionBar/ActionBar'
import history from '../../../history'
import { CorporateAccount, User } from '../../../models'
import { loadEmployees } from '../../../store/actions/employees'

type Props = {
  corporateAccount?: CorporateAccount,
  employees: User[],
  urlPrefix: string,
  onSave: Function
}

type State = {
  corporateAccount: ?CorporateAccount,
  logoFile: File,
  saving: boolean
}

export class CorporateAccountForm extends Component<Props, State> {
  state = {
    corporateAccount: null,
    logoFile: null,
    saving: false
  }

  static defaultProps = {
    onSave: _.noop
  }

  componentDidMount() {
    const { employees, urlPrefix } = this.props
    this.setCorporateAccount()

    if (!employees.length) {
      loadEmployees(urlPrefix)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.corporateAccount !== prevProps.corporateAccount) {
      this.setCorporateAccount()
    }
  }

  setCorporateAccount = () => {
    this.setState({ corporateAccount: new CorporateAccount(this.props.corporateAccount).asForm() })
  }

  onChange = (prop: string, value: any) => {
    const nextState = { ...this.state }
    const corporateAccount = _.cloneDeep(this.state.corporateAccount)
    nextState.corporateAccount = corporateAccount

    if (prop === 'logo') {
      if (typeof value === 'object') {
        nextState.logoFile = value
        corporateAccount.logo = value.name
      }
    } else {
      _.set(corporateAccount, prop, value)
    }

    this.setState(nextState)
  }

  onSubmit = async () => {
    try {
      const { urlPrefix, onSave } = this.props
      const { corporateAccount, logoFile } = this.state
      this.setState({ saving: true })

      const formData = new FormData()
      for (const [k, v] of Object.entries(corporateAccount)) {
        switch (k) {
          case 'logo':
            if (logoFile) {
              formData.append(k, logoFile)
            }
            break
          case 'shifts':
          case 'technicians':
          case 'products':
            formData.append(k, JSON.stringify(v))
            break
          default:
            if (v != null) {
              formData.append(k, v)
            }
        }
      }

      toast('Saving...')
      const result = await saveCorporateAccount(formData, urlPrefix)
      toast('Saved')

      this.setState({ saving: false })
      onSave(result)
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  onDeleteClick = async () => {
    try {
      if (!(await confirm())) {
        return
      }

      const { urlPrefix } = this.props
      const { corporateAccount } = this.state

      this.setState({ saving: true })

      toast('Deleting...')
      await deleteCorporateAccount(corporateAccount, urlPrefix)
      toast('Deleted')

      history.goToListView()
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  render() {
    const { employees } = this.props
    const { logoFile, saving } = this.state
    const corporateAccount: CorporateAccount = this.state.corporateAccount

    if (!corporateAccount) {
      return null
    }

    return (
      <div className="CorporateAccountForm">
        <Form onSubmit={this.onSubmit}>
          <Field
            type="text"
            label="Name:"
            value={corporateAccount.company_name}
            onChange={e => this.onChange('company_name', e.target.value)}
          />
          <Field
            type="text"
            label="Address:"
            value={corporateAccount.address}
            onChange={e => this.onChange('address', e.target.value)}
          />
          <Field
            type="text"
            label="URL:"
            value={corporateAccount.url}
            onChange={e => this.onChange('url', e.target.value)}
          />
          <Field
            type="file"
            label="Logo:"
            value={[corporateAccount.logo, logoFile]}
            onChange={e => this.onChange('logo', e.target.files[0])}
          />
          <Field
            type="date"
            label="Date:"
            value={corporateAccount.date}
            onChange={e => this.onChange('date', e.target.value)}
          />

          {corporateAccount.shifts.map((shift, i) => (
            <div key={i} className="shift">
              <h3>Shift {i + 1}</h3>
              <Field
                type="time"
                label="Start time:"
                value={shift.start_time}
                onChange={e => this.onChange(`shifts[${i}].start_time`, e.target.value)}
              />
              <Field
                type="time"
                label="End time:"
                value={shift.end_time}
                onChange={e => this.onChange(`shifts[${i}].end_time`, e.target.value)}
              />
              <Field
                type="checkboxgroup"
                label="Techs:"
                value={shift.technicians}
                options={employees.map(u => ({ value: u.id, label: u.toString() }))}
                onChange={e => this.onChange(`shifts[${i}].technicians`, e.target.value)}
              />
            </div>
          ))}
          <ActionBar>
            <SaveButton onClick={this.onSubmit} disabled={saving} />
            {corporateAccount.id ? (
              <DeleteButton onClick={this.onDeleteClick} disabled={saving} />
            ) : null}
          </ActionBar>
        </Form>
      </div>
    )
  }
}

const mapState = state => ({
  employees: state.employees
})

export default connect(mapState)(CorporateAccountForm)
