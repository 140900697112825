import './style.scss'

import React, { Component } from 'react'
import _debug from 'debug'
import _ from 'lodash'
import moment from 'moment'
import { formatMoney } from 'accounting'
import {
  scheduleAppointment,
  checkAvailability,
  deleteEvent,
  sendOnCompleteNotification,
  captureCharge,
  loadCharge,
  updateEvent,
  cancelEvent
} from '../../../store/actions/events'
import { getErrorInfo, handleError } from '../../../util/error-util'
import { toast } from '../../../store/actions/app'
import { getFullName } from '../../../util/string-util'
import { toTimeString, toDateString } from '../../../util/date-util'
import { Event, Charge, Product, User, Car, Address, CorporateAccount } from '../../../models'
import Plan from '../../../models/Plan'
import { Form, Field, Button, SaveButton, DeleteButton, ActionBar, confirm } from '../../shared'
import { searchCars } from '../../../store/actions/cars'
import { searchAddresses } from '../../../store/actions/addresses'
import { connect } from 'react-redux'
import { ChargeFormV1, ChargeFormV2 } from './ChargeForm'
import CancelAppointmentModal from './CancelAppointmentModal'

const debug = _debug('carwash:components:WashEventForm')

type Props = {
  event?: Event,
  employees: User[],
  customers: User[],
  products: Product[],
  corporateAccounts: CorporateAccount[],
  urlPrefix: string,
  onSave: Function,
  onClose: Function
}

type State = {
  event: Event,
  charge: Charge,
  cars: Car[],
  addresses: Address[],

  product_id: Number,
  add_on_ids: Number[],
  car_id: Number,
  address_id: Number,
  discount_code: String,
  notify_customer: Boolean,
  charge_card: Boolean,
  custom_amount: Number,

  useCustomAmount: Boolean,
  saving: boolean,
  isAvailable: boolean,
  selectedAddOnIds: Number[],

  showCancelModal: Boolean
}

export class WashEventForm extends Component<Props, State> {
  state = {
    event: null,
    charge: null,
    cars: [],
    addresses: [],

    product_id: null,
    add_on_ids: [],
    car_id: null,
    address_id: null,
    discount_code: '',
    notify_customer: true,
    charge_card: true,
    custom_amount: null,

    useCustomAmount: false,
    saving: false,
    isAvailable: true,
    selectedAddOnIds: [],

    showCancelModal: false
  }

  static defaultProps = {
    onSave: _.noop,
    customers: [],
    employees: []
  }

  componentDidMount() {
    this.setEvent()
  }

  componentDidUpdate(prevProps) {
    if (this.props.event !== prevProps.event) {
      this.setEvent()
    }
  }

  /**
   * Clones props.event for form editing.
   */
  setEvent = () => {
    let event = new Event(this.props.event)

    if (!event.address) {
      this.maybeSetAddress(event)
    }

    if (event.start) {
      event.date = toDateString(event.start)
      event.startTime = toTimeString(event.start)
    }
    if (event.end) {
      event.endTime = toTimeString(event.end)
    }

    const nextState = { event }
    if (event.products) {
      const washProduct = event.products.find(p => Product.isWash(p.type))
      const addOnProducts = event.products.filter(p => !Product.isWash(p.type))
      nextState.product_id = washProduct ? washProduct.id : null
      nextState.add_on_ids = addOnProducts.map(p => p.id)
    }

    this.setState(nextState, () => {
      this.loadCharge(event)
      this.checkAvailability(Event.build(event))
    })
  }

  maybeSetAddress = (event: Object) => {
    const { customers } = this.props
    if (!event.customer_id) {
      return
    }

    const customer = customers.find(c => c.id === event.customer_id)
    if (!(customer && customer.default_address)) {
      return
    }

    event.address = customer.default_address.street
    event.description = customer.default_address.extra_info
  }

  loadCharge = async (event: Event) => {
    try {
      if (!(event && event.id)) {
        return
      }

      const charge: Charge = await loadCharge(event)
      if (charge) {
        this.setState({ charge })
      }
    } catch (err) {
      console.error(err)
    }
  }

  loadCars = async (customer_id: Number) => {
    try {
      const { urlPrefix } = this.props
      const cars = await searchCars(urlPrefix, { user_id: customer_id })
      this.setState({ cars })
    } catch (err) {
      console.error(err)
    }
  }

  loadAddresses = async (customer_id: Number) => {
    try {
      const { urlPrefix } = this.props
      const addresses = await searchAddresses(urlPrefix, { user_id: customer_id })
      this.setState({ addresses })
    } catch (err) {
      console.error(err)
    }
  }

  checkAvailability = _.debounce(async (event: Event) => {
    try {
      if (!event.employee_id) {
        this.setState({ isAvailable: true })
        return
      }

      const isAvailable = await checkAvailability(event)
      this.setState({ isAvailable })
    } catch (err) {
      debug('[checkAvailability] error: ', err)
    }
  }, 500)

  onEventChange = (prop: string, value: any) => {
    const { event } = this.state
    event[prop] = value

    switch (prop) {
      case 'customer_id':
        this.setState({ car_id: null, address_id: null })
        this.loadCars(value)
        this.loadAddresses(value)
        break
      default:
      // no op
    }

    this.forceUpdate()
  }

  onChange = (prop: string, value: any) => {
    const nextState = { [prop]: value }

    switch (prop) {
      case 'product_id':
        nextState.add_on_ids = []
        break
      default:
      // no op
    }

    this.setState(nextState)
  }

  onSubmit = async () => {
    try {
      const { urlPrefix, onSave } = this.props
      const {
        product_id,
        add_on_ids,
        car_id,
        address_id,
        discount_code,
        notify_customer,
        charge_card,
        custom_amount
      } = this.state

      const event = Event.build(this.state.event)
      this.setState({ saving: true })
      let result

      if (event.id) {
        if (notify_customer) {
          event.send_email = notify_customer
        }
        result = await updateEvent(event, urlPrefix)
      } else {
        const data = {
          event,
          product_id,
          add_on_ids,
          car_id,
          address_id,
          discount_code,
          notify_customer,
          charge_card,
          custom_amount: custom_amount ? Number(custom_amount) * 100 : null
        }

        result = await scheduleAppointment(data, urlPrefix)
      }

      toast('Saved')

      this.setState({ saving: false })
      onSave(result)
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  onDeleteClick = async () => {
    try {
      if (!(await confirm())) {
        return
      }

      const { onClose, urlPrefix } = this.props
      const { event } = this.state

      this.setState({ saving: true })

      toast('Deleting...')
      await deleteEvent(event, urlPrefix)
      toast('Deleted')

      onClose()
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  onCancelClick = async (customCancelAmount: Number) => {
    try {
      const { urlPrefix } = this.props
      const { event, charge } = this.state

      let data = {}
      if (Number.isInteger(customCancelAmount) && customCancelAmount >= 0 && charge) {
        if (charge.payment_api === 2) {
          data.refund_amount = customCancelAmount
        } else {
          data.cancel_fee = customCancelAmount
        }
      }

      this.setState({ saving: true })

      toast('Cancelling...')
      const res = await cancelEvent(event, urlPrefix, data)
      let toastMsg = 'Cancelled'
      if (res.msg) {
        toastMsg += `.\n\nCustomer message: ${res.msg}`
      }
      toast(toastMsg)

      this.setState({ saving: false, showCancelModal: false })
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  onSendCompleteNotificationClick = async (force: Boolean = false) => {
    try {
      let confirmInfo
      if (force) {
        confirmInfo = {
          message: 'Notification has already been sent. Send another?'
        }
      }

      if (!(await confirm(confirmInfo))) {
        return
      }

      const { urlPrefix } = this.props
      let { event } = this.state

      this.setState({ saving: true })

      toast('Sending...')
      await sendOnCompleteNotification(event, force, urlPrefix)
      toast('Sent')

      this.setState({ saving: false })
    } catch (err) {
      this.setState({ saving: false })
      toast('')
      const info = getErrorInfo(err)
      if (!force && info) {
        if (info.code === 'NotificationAlreadySentError') {
          await this.onSendCompleteNotificationClick(true)
        }
      } else {
        handleError(err, true)
      }
    }
  }

  onCaptureClick = async () => {
    try {
      if (!(await confirm())) {
        return
      }

      const { urlPrefix } = this.props
      const { event } = this.state

      this.setState({ saving: true })

      toast('Charging...')
      const charge = await captureCharge(event, urlPrefix)
      toast('Charged')

      this.setState({ charge, saving: false })
    } catch (err) {
      this.setState({ saving: false })
      handleError(err, true)
    }
  }

  isValid = () => {
    const { event } = this.state
    if (!event) {
      return false
    }

    const e = Event.build(event)
    if (!e.allDay) {
      if (e.start >= e.end) {
        return false
      }
    }

    return e.start && (e.end || e.allDay) && e.employee_id && e.customer_id
  }

  onAddOnChange = (plan: Plan) => {
    let selectedAddOnIds = [...this.state.selectedAddOnIds]
    if (selectedAddOnIds.includes(plan.id)) {
      selectedAddOnIds = selectedAddOnIds.filter(id => id !== plan.id)
    } else {
      selectedAddOnIds.push(plan.id)
    }

    this.setState({ selectedAddOnIds })
  }

  didStartTimeChange = () => {
    const { event: oldEvent } = this.props
    const { event } = this.state
    if (!event.id) {
      return true
    }

    try {
      const newEvent = Event.build(event)
      const newTimestamp = new Date(newEvent.start).getTime()
      const oldTimestamp = new Date(oldEvent.start).getTime()
      return newEvent.id && newTimestamp !== oldTimestamp
    } catch (err) {
      return true
    }
  }

  renderUnavailableMessage = () => {
    return null
    // const { employees } = this.props
    // const { event, isAvailable } = this.state

    // if (isAvailable) {
    //   return null
    // }

    // const emp = employees.find(e => e.id === event.employee_id)
    // if (!emp) {
    //   return null
    // }

    // let unavialableMessage = ''
    // if (emp.id === getUser().id) {
    //   unavialableMessage = 'Heads up! You have another appointment scheduled at that time.'
    // } else {
    //   unavialableMessage = `Heads up! ${emp.first_name} has another appointment scheduled at that time.`
    // }

    // return <span className="unavialable-message">{unavialableMessage}</span>
  }

  calculateAddonTotal = () => {
    const { products } = this.props
    const { selectedAddOnIds } = this.state
    const addOns = products.filter(p => p.type === 'ADD_ON')

    let total = 0

    selectedAddOnIds.forEach(id => {
      const product = addOns.find(p => p.id === id)
      total += product.amount
    })

    total /= 100

    return formatMoney(total)
  }

  getPrice = () => {
    const { products } = this.props
    const { product_id, add_on_ids } = this.state

    const ids = [product_id, ...add_on_ids]
    const selectedProducts = products.filter(p => ids.includes(p.id))
    let price = 0
    for (const p of selectedProducts) {
      price += p.amount
    }

    return formatMoney(price / 100)
  }

  render() {
    const { employees, customers, products, corporateAccounts } = this.props
    const {
      cars,
      addresses,

      product_id,
      add_on_ids,
      car_id,
      address_id,
      // discount_code,
      notify_customer,
      charge_card,
      custom_amount,

      useCustomAmount,
      selectedAddOnIds,
      saving,

      showCancelModal
    } = this.state

    const event: Event = this.state.event
    const charge: Charge = this.state.charge

    if (!event) {
      return null
    }

    // const products = event.products || []
    const washProducts = _.sortBy(
      products.filter(p => Product.isWash(p.type)),
      'sort_order'
    )

    let add_ons = []
    if (product_id) {
      const product = washProducts.find(p => p.id === product_id)
      if (product) {
        add_ons = product.add_ons
      }
    }

    const { car, address } = event

    const productProps = event.id ? { disabled: true, readonly: true } : {}

    return (
      <div className="EventForm WashEventForm">
        <div className="wrapper">
          <div>
            <Form onSubmit={this.onSubmit}>
              {event.cancelled_date ? (
                <Field
                  type="text"
                  label="Cancelled at:"
                  value={moment(event.cancelled_date).format('M/D/Y h:mmA')}
                  disabled
                  readonly
                />
              ) : null}
              <Field
                type="select"
                label="Technician:"
                value={event.employee_id}
                onChange={e => this.onEventChange('employee_id', Number(e.target.value))}
                options={[
                  <option key="-1" value="-1"></option>,
                  ..._.sortBy(employees, 'last_name').map(m => (
                    <option key={m.id} value={m.id}>
                      {getFullName(m)}
                    </option>
                  ))
                ]}
              />
              <Field
                type="select"
                label="Customer:"
                value={event.customer_id}
                onChange={e => this.onEventChange('customer_id', Number(e.target.value))}
                options={[
                  <option key="-1" value="" />,
                  ..._.sortBy(customers, 'last_name').map(m => (
                    <option key={m.id} value={m.id}>
                      {getFullName(m)} ({m.email})
                    </option>
                  ))
                ]}
              />
              {event.id ? null : (
                <Field
                  type="select"
                  label="Vehicle:"
                  value={car_id}
                  onChange={e => this.onChange('car_id', Number(e.target.value))}
                  options={[
                    <option key="-1" value="" />,
                    ...cars.map(c => (
                      <option key={c.id} value={c.id}>
                        {c.toString()}
                      </option>
                    ))
                  ]}
                />
              )}
              {event.id ? null : (
                <Field
                  type="select"
                  label="Location:"
                  value={address_id}
                  onChange={e => this.onChange('address_id', Number(e.target.value))}
                  options={[
                    <option key="-1" value="" />,
                    ...addresses.map(a => (
                      <option key={a.id} value={a.id}>
                        {a.toString()}
                      </option>
                    ))
                  ]}
                />
              )}
              <Field
                type="select"
                label="Corporate Account:"
                value={event.corporate_account_id}
                onChange={e => this.onEventChange('corporate_account_id', Number(e.target.value))}
                options={[
                  <option key="-1" value="" />,
                  ...corporateAccounts.map(ca => (
                    <option key={ca.id} value={ca.id}>
                      {ca.toString()}
                    </option>
                  ))
                ]}
              />
              <Field
                type="date"
                label="Date:"
                value={event.date}
                onChange={e => this.onEventChange('date', e.target.value)}
              />
              <Field
                type="time"
                label="Start Time:"
                value={event.startTime}
                onChange={e => this.onEventChange('startTime', e.target.value)}
              />
              <Field
                type="time"
                label="End Time:"
                value={event.endTime}
                onChange={e => this.onEventChange('endTime', e.target.value)}
              />
            </Form>

            <div className="section">
              <h4>Wash info</h4>
              <Field
                type="select"
                label="Product:"
                value={product_id}
                onChange={e => this.onChange('product_id', Number(e.target.value))}
                options={[
                  <option key="-1" value="" />,
                  ..._.sortBy(washProducts, 'sort_order').map(p => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))
                ]}
                {...productProps}
              />

              {product_id && add_ons.length ? (
                <Field
                  className="checkboxgroup"
                  type="checkboxgroup"
                  label="Add-ons:"
                  value={add_on_ids}
                  options={add_ons.map(p => ({ value: p.id, label: p.name }))}
                  onChange={e => this.onChange('add_on_ids', e.target.value)}
                  {...productProps}
                />
              ) : null}
            </div>

            {event.id && (car || address) ? (
              <div className="section">
                <h4>Location info</h4>
                {car ? (
                  <Field type="text" label="Vehicle:" value={car.toString()} readonly disabled />
                ) : null}

                {address || event.address_info ? (
                  <Field
                    type="text"
                    label="Address:"
                    value={address ? address.toString() : event.address_info}
                    disabled
                    readonly
                  />
                ) : null}

                {address && address.extra_info ? (
                  <Field
                    className="extra-info"
                    type="textarea"
                    label="Extra info:"
                    value={address.extra_info}
                    disabled
                    readonly
                  />
                ) : null}
              </div>
            ) : null}

            {charge && charge.should_charge_date ? (
              <div className="section">
                <h4>Payment info</h4>

                {charge.payment_api !== 2 ? (
                  <ChargeFormV1 charge={charge} />
                ) : (
                  <ChargeFormV2 charge={charge} />
                )}

                <Field
                  type="text"
                  label="Payment API Version:"
                  value={charge.payment_api}
                  disabled
                  readonly
                />
              </div>
            ) : null}

            {event.id ? null : (
              <div className="section">
                <Field
                  type="checkbox"
                  label="Charge customer's card"
                  checked={charge_card}
                  onChange={e => this.onChange('charge_card', e.target.checked)}
                />
                {charge_card ? (
                  <Field
                    type="checkbox"
                    label="Use custom price"
                    checked={useCustomAmount}
                    onChange={e => this.onChange('useCustomAmount', e.target.checked)}
                  />
                ) : null}
                {charge_card ? (
                  useCustomAmount ? (
                    <Field
                      type="currency"
                      label="Custom price"
                      value={custom_amount}
                      onChange={e => this.onChange('custom_amount', e.target.value)}
                    />
                  ) : (
                    <Field type="text" label="Price" value={this.getPrice()} disabled readonly />
                  )
                ) : null}
              </div>
            )}

            <div className="section">
              {this.didStartTimeChange() ? (
                <Field
                  type="checkbox"
                  label={event.id ? 'Notify customer of time change:' : 'Send notification email:'}
                  checked={notify_customer}
                  onChange={e => this.onChange('notify_customer', e.target.checked)}
                />
              ) : null}
            </div>
          </div>
          {/* <div className="add-on-wrapper">
            <CollapsibleSection label="Apply add-ons to this appointment">
              <SelectableProductList
                type="ADD_ON"
                onChange={this.onAddOnChange}
                selectedIds={selectedAddOnIds}
              />
            </CollapsibleSection>
          </div> */}
        </div>
        {selectedAddOnIds.length ? (
          <ActionBar>
            <div>Total add-on amount: {this.calculateAddonTotal()}</div>
          </ActionBar>
        ) : null}
        <ActionBar>
          <SaveButton onClick={this.onSubmit} disabled={saving || !this.isValid()} />
          {event.id ? <DeleteButton onClick={this.onDeleteClick} disabled={saving} /> : null}
          {event.id && !event.cancelled_date ? (
            <Button
              type="secondary"
              onClick={() => this.setState({ showCancelModal: true })}
              disabled={saving}>
              Cancel
            </Button>
          ) : null}
          {event.id && !event.cancelled_date && charge && !charge.charged_date ? (
            <Button
              type="secondary"
              onClick={() => this.onSendCompleteNotificationClick()}
              disabled={saving}
              title="Sends via text message, and if that fails, sends via email">
              Send Wash Complete notification
            </Button>
          ) : null}
          {charge && !charge.charged_date ? (
            <Button type="secondary" onClick={this.onCaptureClick} disabled={saving}>
              Charge customer's card
            </Button>
          ) : null}
          {this.renderUnavailableMessage()}
        </ActionBar>
        {showCancelModal ? (
          <CancelAppointmentModal
            charge={charge}
            onCancelClick={() => this.setState({ showCancelModal: false })}
            onConfirmClick={this.onCancelClick}
          />
        ) : null}
      </div>
    )
  }
}

const mapState = state => ({
  events: state.events,
  customers: state.customers,
  employees: state.employees,
  products: state.products,
  corporateAccounts: state.corporateAccounts
})

export default connect(mapState)(WashEventForm)
