import './style.scss'

import React, { Component, Fragment } from 'react'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadCars } from '../../../store/actions/cars'
import AuthRoute from '../../shared/AuthRoute/AuthRoute'
import CarList from '../List/CarList'
import CarDetail from '../Detail/CarDetail'
import CarForm from '../Form/CarForm'
import TitleBar from '../../shared/TitleBar/TitleBar'
import Car from '../../../models/Car'
import { getUrlPrefix } from '../../../util/string-util'

type Props = {
  cars: Car[],
  urlPrefix: string,
  backTo?: String,
  match: Object
}

export class Cars extends Component<Props> {
  componentDidMount() {
    const { cars, urlPrefix } = this.props
    if (!cars.length) {
      loadCars(urlPrefix)
    }
  }

  onSave = (car: Object) => {
    const prefix = getUrlPrefix('vehicles')
    this.props.history.push(`${prefix}/${car.id}`)
  }

  render() {
    const { cars, urlPrefix, backTo, match } = this.props
    const { path } = match

    return (
      <div className="Cars">
        <Switch>
          <AuthRoute
            exact
            path={path}
            render={props => <CarList {...props} cars={cars} backTo={backTo} />}
          />
          <AuthRoute
            path={`${path}/new`}
            render={props => (
              <Fragment>
                <TitleBar backTo={path} label="New Vehicle" />
                <CarForm {...props} cars={cars} onSave={this.onSave} urlPrefix={urlPrefix} />
              </Fragment>
            )}
          />
          <AuthRoute
            path={`${path}/:carId`}
            render={props => <CarDetail {...props} cars={cars} urlPrefix={urlPrefix} />}
          />
        </Switch>
      </div>
    )
  }
}

const mapState = state => ({
  cars: state.cars
})

export default connect(mapState)(Cars)
