import './style.scss'

import React, { Component } from 'react'
import mapboxgl, { Map, Marker } from 'mapbox-gl'
import { TOKEN, AUSTIN_TX, reverseGeocode, geocode } from '../../../util/map-util'

mapboxgl.accessToken = TOKEN

type Props = {
  address: String,
  geolocate: Boolean,
  onGeolocateSuccess: Function
}

export default class AustinMap extends Component<Props> {
  map: Map

  static defaultProps = {
    geolocate: true
  }

  componentDidMount() {
    this.addMap()
    this.geolocate()
  }

  addMap = () => {
    const { address } = this.props

    const map = new mapboxgl.Map({
      container: this.node,
      style: 'mapbox://styles/mapbox/streets-v9',
      center: AUSTIN_TX,
      zoom: 8.75
    })

    this.map = map

    map.on('load', async function () {
      map.addLayer({
        id: 'austin',
        type: 'fill',
        source: {
          type: 'geojson',
          data
        },
        layout: {},
        paint: {
          'fill-color': '#3683dc',
          'fill-opacity': 0.4
        }
      })

      if (address) {
        const result = await geocode(address)
        if (result.features.length) {
          const center = result.features[0].center
          try {
            new Marker({ color: '#4668f4' }).setLngLat(center).addTo(map)
            map.flyTo({ center, speed: 1.5 })
          } catch (err) {
            console.error(err)
          }
        }
      }
    })
  }

  geolocate = () => {
    if (!this.props.geolocate) {
      return
    }

    navigator.geolocation.getCurrentPosition(
      async (position: Position) => {
        const lng = position.coords.longitude
        const lat = position.coords.latitude

        new Marker({ color: '#4668f4' }).setLngLat([lng, lat]).addTo(this.map)
        this.map.flyTo({ center: [lng, lat], speed: 1.5 })

        const result = await reverseGeocode(lng, lat)
        console.log(result)

        if (!(result && result.features && result.features[0])) {
          console.log('[parseUserAddress] no feature')
        }

        const feature = result.features[0]
        if (feature.place_type[0] !== 'address') {
          console.log('[parseUserAddress] place_type !== address')
          return
        }

        const [address, city, stateAndZip] = feature.place_name.split(',').map(p => p.trim())
        const [state, zip] = stateAndZip.split(' ')
        if (!(address && city && state && zip)) {
          console.log('[parseUserAddress] missing parts', { address, city, state, zip })
          return
        }

        console.log({ address, city, state, zip })
        this.props.onGeolocateSuccess({ address, city, state, zip })
      },
      (err: PositionError) => {
        console.error(err)
      }
    )
  }

  render() {
    return <div className="AustinMap" ref={node => (this.node = node)}></div>
  }
}

const data = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: AUSTIN_TX
      }
    },
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.74419999999999, 30.41246983126061],
            [-97.77690318620373, 30.409687899624128],
            [-97.80834420460114, 30.40144947778565],
            [-97.83731022078788, 30.388072487151174],
            [-97.86268504289967, 30.370072976495983],
            [-97.88349248031297, 30.34814498679741],
            [-97.89893401479858, 30.323133522728607],
            [-97.90841931369191, 30.29600172458098],
            [-97.91158844091132, 30.267793556619562],
            [-97.90832498687088, 30.239593491119688],
            [-97.89875972135779, 30.212484766855642],
            [-97.88326475456282, 30.18750783536393],
            [-97.86243855381909, 30.165620579725186],
            [-97.83708249410495, 30.147661803246525],
            [-97.80816990984118, 30.13431934547084],
            [-97.77680885844994, 30.12610399779765],
            [-97.74419999999999, 30.123330168739397],
            [-97.71159114155004, 30.12610399779765],
            [-97.6802300901588, 30.13431934547084],
            [-97.65131750589504, 30.147661803246525],
            [-97.6259614461809, 30.165620579725186],
            [-97.60513524543715, 30.18750783536393],
            [-97.58964027864221, 30.212484766855642],
            [-97.58007501312909, 30.239593491119688],
            [-97.57681155908867, 30.267793556619562],
            [-97.57998068630808, 30.29600172458098],
            [-97.5894659852014, 30.323133522728607],
            [-97.604907519687, 30.34814498679741],
            [-97.62571495710031, 30.370072976495983],
            [-97.65108977921211, 30.388072487151174],
            [-97.68005579539886, 30.40144947778565],
            [-97.71149681379627, 30.409687899624128],
            [-97.74419999999999, 30.41246983126061]
          ]
        ]
      }
    }
  ]
}
