import BaseModel from './BaseModel'

export default class Car extends BaseModel {
  make: String
  model: String
  color: String
  license_plate: String
  is_default: Boolean

  toString() {
    const { color, make, model, license_plate } = this

    let parts = []
    if (color) {
      parts.push(color)
    }

    if (make) {
      parts.push(make)
    }

    if (model) {
      parts.push(model)
    }

    if (license_plate) {
      parts.push(`(${license_plate})`)
    }

    return parts.join(' ')
  }
}
