import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import classNames from 'classnames'
import _debug from 'debug'
import { formatMoney } from 'accounting'
import { Calendar } from '../shared'
import axios from 'axios'
import { hideCovidBar } from '../../store/actions/app'
import { fetchCorporateAccountFromUrl } from '../../store/actions/corporateAccount'
import { handleError, isAppointmentError } from '../../util/error-util'
import { scrollToTop } from '../../util/dom-util'
import Product from '../../models/Product'
import { Footer } from '../LandingPage/sections/Footer'
import { calculateTotals } from '../../util/number-util'
import Event from '../../models/Event'
import { CorporateAccount, Car, User } from '../../models'
import { addPurchaseToDataLayer } from '../../util/google-util'
import { store } from '../../store/store'
import ShineRiteImage from '../../assets/logo-color.png'
import history from '../../history'
import { setDiscountCode } from '../../store/actions/shoppingCart'
import CorporateLandingPage from '../CorporateLandingPage/CorporateLandingPage'
import BubbleHeader from '../BubbleHeader/BubbleHeader'
import { DISCOUNT_PRODUCT } from '../CorporateAccountSignUp/CorporateAccountSignUpPage'
import { loadCars } from '../../store/actions/cars'
import ManageLocationInfo from '../BookingPage/ManageLocationInfo'
import { loadAppointments } from '../../store/actions/appointments'
import Cart from '../UserSignupPage/Cart/Cart'
import ProductGrid from '../UserSignupPage/ProductGrid/ProductGrid'

const debug = _debug('carwash:components:UserSignupPage')

const Type = {
  PLAN: 'PLAN',
  PRODUCT: 'PRODUCT'
}

const PRODUCT_INFO = 'PRODUCT_INFO'
const APPOINTMENT_INFO = 'APPOINTMENT_INFO'
const CONFIRM_INFO = 'CONFIRM_INFO'
const DONE = 'DONE'

type Props = {
  user: User,
  cars: Car[],
  history: Object,
  match: Object,
  location: Location
}

type State = {
  // Data
  corporateAccount: CorporateAccount,
  products: Product[],
  availableTimes: Event[],

  // Plan info
  selection: ?Object,
  selectedAddOns: Product[],
  discountCode?: String,
  discountPercent?: Number,
  discountAmount?: Number,
  appointmentTime: String,
  appointmentEvent: Event,

  // Car info
  car: Car,

  // Other state
  showProducts: Boolean,
  saving: boolean,
  loading: boolean,
  loadingAppts: Boolean,
  step: null | PRODUCT_INFO | APPOINTMENT_INFO | CONFIRM_INFO | DONE,
  transitionTo: PRODUCT_INFO | APPOINTMENT_INFO | CONFIRM_INFO | DONE | null,
  showManageLocationInfo: Boolean
}

export class CorporateBookingPage extends Component<Props, State> {
  queryName: String

  constructor(props: Props) {
    super(props)

    try {
      const params = new URLSearchParams(props.location.search)
      const name = params.get('p')
      if (name) {
        this.queryName = decodeURIComponent(name)
      }
    } catch (err) {
      // no op
    }

    this.state = {
      products: [],
      selectedAddOns: [],
      discountCode: null,
      discountPercent: 0,
      discountAmount: 0,
      availableTimes: [],

      selection: {},
      appointmentTime: '',
      appointmentEvent: null,

      showProducts: false,
      saving: false,
      loading: true,
      loadingAppts: false,
      step: PRODUCT_INFO,
      transitionTo: null,
      showManageLocationInfo: false,

      corporateAccount: null
    }
  }

  componentDidMount() {
    this.checkUrl()
    this.setCar()
    this.fetchProducts()
    this.fetchCorporateAccount()
  }

  fetchProducts = async () => {
    try {
      const res = await axios.get('/products?type__in=WASH,DETAIL,CORPORATE_SPECIAL')
      const products = res.data.results.map(p => new Product(p))
      this.setState({ products })
    } catch (err) {
      handleError(err, true)
    }
  }

  fetchCorporateAccount = () => {
    let corporateUrl = this.getCorporateUrl()
    let payload = {
      corporateUrl: corporateUrl,
      onSuccess: data => {
        const corporateAccount = new CorporateAccount(data.results[0])
        this.setState({ corporateAccount, loading: false })
      },
      onError: () => {
        this.props.history.push('/')
      }
    }
    store.dispatch(fetchCorporateAccountFromUrl(payload))
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.step !== prevState.step) {
      scrollToTop()
    }
  }

  checkUrl = () => {
    const { match, location, history } = this.props

    if (location.pathname.includes('/step-') && !location.pathname.includes('/step-1')) {
      history.replace(`${match.url}/step-1`)
    }
  }

  getCorporateUrl = () => {
    const { match } = this.props
    return match.params.slug
  }

  setCar = async () => {
    try {
      let { cars } = this.props
      if (!cars.length) {
        cars = await loadCars()
      }

      let car = cars.find(c => c.is_default)
      if (!car && cars.length) {
        car = cars[0]
      }

      this.setState({ car })
    } catch (err) {
      debug('[setCar] error: ', err)
    }
  }

  fetchAvailableTimes = async () => {
    try {
      this.setState({ loadingAppts: true })

      const { selection, selectedAddOns } = this.state
      const productIds = [selection.id]
      const addOnIds = selectedAddOns.map(p => p.id)
      productIds.push(...addOnIds)
      const res = await axios.post('/events/availabletimes', {
        product_ids: productIds
        // corporate_account_id: this.state.corporateAccount.id
      })
      this.setState({ availableTimes: res.data, loadingAppts: false })
    } catch (err) {
      debug('[fetchAvailableTimes] error: ', err)
      handleError(err)
      this.setState({ loadingAppts: false })
    }
  }

  onBasicInfoSubmit = async (e: Event) => {
    e.preventDefault()
    hideCovidBar()
    this.setState({ transitionTo: PRODUCT_INFO })

    setTimeout(async () => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null,
        step: PRODUCT_INFO
      })

      history.push(`${match.url}/step-2`)
    }, 200)
  }

  onProductDoneClick = (product: Product) => {
    this.setState({ transitionTo: APPOINTMENT_INFO })

    setTimeout(() => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null,
        step: APPOINTMENT_INFO
      })
      history.push(`${match.url}/step-3`)
      this.fetchAvailableTimes()
    }, 200)
  }

  onAppointmentInfoSubmit = () => {
    const { token } = this.state
    if (token) {
      return this.activateAccount()
    }

    this.setState({ transitionTo: CONFIRM_INFO })

    setTimeout(() => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null,
        step: CONFIRM_INFO
      })

      history.push(`${match.url}/step-4`)
    }, 200)
  }

  onConfirmClick = () => {
    this.setState({ saving: true }, async () => {
      const { shoppingCart, history, match } = this.props

      try {
        const {
          products,
          selection,
          selectedAddOns,
          packageDeal,
          corporateAccount,
          appointmentTime,
          car,
          address
        } = this.state

        const res = await axios.post('/purchase', {
          selection,
          addOns: selectedAddOns.map(addOn => addOn.id),
          packageDeal,
          discountCode: shoppingCart.discountApplied ? shoppingCart.discountCode : null,
          appointmentTime,
          carId: car ? car.id : null,
          addressId: address ? address.id : null,
          eventType: 'CORPORATE_WASH',
          corporateAccountId: corporateAccount.id
        })

        debug('[onConfirmClick] res: ', res)

        try {
          const gaProducts = [products.find(p => p.id === selection.id), ...selectedAddOns]
          const { event } = res.data
          addPurchaseToDataLayer(event.id, event.charge.amount, 'EXISTING_CUSTOMER', gaProducts)
        } catch (err) {
          console.error(err)
        }

        this.setState({ transitionTo: DONE, saving: false })

        setTimeout(() => {
          const { history, match } = this.props

          this.setState({
            transitionTo: null,
            step: DONE
          })

          history.push(`${match.url}/done`)

          loadAppointments()
        }, 200)
      } catch (err) {
        debug('[onConfirmClick] error: ', err)
        this.setState({ saving: false })
        handleError(err, true)
        if (isAppointmentError(err)) {
          this.setState({
            step: APPOINTMENT_INFO
          })

          history.push(`${match.url}/step-3`)

          this.fetchAvailableTimes()
        }
      }
    })
  }

  onDoneClick = async () => {
    this.props.history.push('/')
  }

  renderCart = (inline: Boolean = false) => {
    const { history, match } = this.props
    const { products, selection, selectedAddOns, appointmentTime, appointmentEvent } = this.state

    return (
      <Cart
        selection={products.find(p => p.id === selection.id)}
        addOns={selectedAddOns}
        appointmentTime={appointmentTime}
        appointmentEvent={appointmentEvent}
        onRemoveProduct={() => {
          this.setState({ step: PRODUCT_INFO, selection: {}, selectedAddOns: [] })
          history.push(`${match.url}/step-2`)
        }}
        onRemoveAddOn={(addOn: Product) => {
          this.setState({ selectedAddOns: selectedAddOns.filter(p => p !== addOn) })
        }}
        onDiscountApplied={(discountCode, discountPercent, discountAmount) => {
          this.setState({ discountCode, discountPercent, discountAmount })
        }}
        inline={inline}
      />
    )
  }

  renderBasicInfo = () => {
    const { corporateAccount, transitionTo } = this.state

    const ui = (key: String, showHeader: Boolean) => (
      <div
        key={key}
        className={classNames('section basic-info', {
          hiding: transitionTo === PRODUCT_INFO
        })}>
        <div className="container">
          {corporateAccount ? (
            <>
              {showHeader ? (
                <BubbleHeader>
                  <div className="corporate-account">
                    <div className="corparate-account-parent-container">
                      <div className="corporate-account-info-container">
                        <img
                          className="logo-shinerite"
                          src={ShineRiteImage}
                          alt="Shine Rite logo"
                        />
                        <h1> @ </h1>
                        <img
                          className="logo-account"
                          src={corporateAccount.logo}
                          alt="Shine Rite logo"
                        />
                      </div>
                      <div className="corporate-welcome-text-container">
                        <p>
                          Shine Rite is an approved vendor for {corporateAccount.company_name}.
                          Please enter your info below and we will send special offers just for
                          residents.
                        </p>
                      </div>
                    </div>
                  </div>
                </BubbleHeader>
              ) : (
                <div style={{ width: 1, height: 100 }} />
              )}

              <div className="get-started-wrapper">
                <button id="signup-next-3" className="primary" onClick={this.onBasicInfoSubmit}>
                  Get Started
                </button>
              </div>
            </>
          ) : (
            <div style={{ height: 120 }} />
          )}
        </div>
      </div>
    )

    return (
      <>
        {ui('basic-info', true)}
        <CorporateLandingPage />
        {ui('basic-info-2', false)}
      </>
    )
  }

  renderProductInfo = () => {
    const { products, transitionTo } = this.state

    const headerText = 'Schedule your wash with us'

    return (
      <div
        key="plan"
        className={classNames('section product-info', {
          hiding: transitionTo === APPOINTMENT_INFO
        })}>
        <div className="container">
          <BubbleHeader text={headerText}></BubbleHeader>
          <ProductGrid
            products={products}
            mainWashType="CORPORATE_WASH"
            showCompare={false}
            onChange={product => {
              if (product === DISCOUNT_PRODUCT) {
                setDiscountCode('DETAIL10')
                history.push('/signup')
                return
              }

              this.setState({
                selection: { type: Type.PRODUCT, id: product.id },
                selectedAddOns: []
              })
              this.onProductDoneClick(product)
            }}
          />
        </div>
      </div>
    )
  }

  renderAppointmentInfo = () => {
    const { history, match } = this.props
    const {
      token,
      selection,
      availableTimes,
      appointmentTime,
      saving,
      loadingAppts,
      transitionTo
    } = this.state

    if (!selection.id) {
      return null
    }

    return (
      <div
        key="appointment"
        className={classNames('appointment', {
          hiding: transitionTo === CONFIRM_INFO
        })}>
        <div className="detail-form">
          <div className="form-header">
            <h1>Choose appointment</h1>
          </div>
          {loadingAppts ? (
            <div className="calendar-placeholder">
              <div className="div-1" />
              <div className="div-2" />
              <div className="div-3" />
            </div>
          ) : (
            <Calendar
              events={availableTimes}
              views={['month']}
              onSelectEvent={event => {
                this.setState({ appointmentTime: event.start, appointmentEvent: event })
                this.onAppointmentInfoSubmit()
              }}
              readonly
            />
          )}
          <div className="footer">
            <button
              type="button"
              className="secondary"
              onClick={() => {
                this.setState({ step: PRODUCT_INFO })
                history.push(`${match.url}/step-2`)
              }}>
              Back
            </button>
            <button
              id="signup-next-2"
              className="primary"
              disabled={saving || !appointmentTime}
              onClick={this.onAppointmentInfoSubmit}>
              {token ? 'Submit' : 'Next'}
            </button>
          </div>
        </div>

        {window.innerWidth > 1000 ? this.renderCart() : null}
      </div>
    )
  }

  renderConfirmation = () => {
    const { history, match } = this.props
    const {
      selection,
      transitionTo,
      saving,
      showManageLocationInfo,
      car,
      address,
      packageDeal,
      corporateAccount
    } = this.state

    if (!selection.id && !packageDeal) {
      return null
    }

    return (
      <div
        key="confirm"
        className={classNames('section confirm', {
          hiding: transitionTo === DONE
        })}>
        <div className="container">
          <h1>Confirm</h1>

          <div className="confirm-car">
            <div className="gridfields">
              <div className="all-caps">
                <span>Vehicle/Location Info</span>
                <span
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() => this.setState({ showManageLocationInfo: true })}>
                  EDIT
                </span>
              </div>
              <div>
                <span>Vehicle</span>
                <span>{car ? car.toString() : ''}</span>
              </div>
              <div>
                <span>Address</span>
                <span>{corporateAccount.address}</span>
              </div>
            </div>
          </div>

          {this.renderCart(true)}

          <div className="footer">
            <button
              type="button"
              className="secondary"
              onClick={() => {
                this.setState({ step: APPOINTMENT_INFO })
                history.push(`${match.url}/step-3`)
              }}>
              Back
            </button>
            <button
              id="purchase-confirm"
              className="primary"
              onClick={this.onConfirmClick}
              disabled={saving}>
              Confirm Purchase
            </button>
          </div>
        </div>

        {showManageLocationInfo ? (
          <ManageLocationInfo
            showAddress={false}
            selectedCarId={car ? car.id : null}
            selectedAddressId={address ? address.id : null}
            onClose={(car: Car) => {
              const nextState = { showManageLocationInfo: false }
              if (car) {
                nextState.car = car
              }

              this.setState(nextState)
            }}
          />
        ) : null}
      </div>
    )
  }

  renderDoneMessage = () => {
    const { selection } = this.state

    if (!selection.id) {
      return null
    }

    return (
      <div key="done">
        <h1>You're all set!</h1>
        <div className="done-message">
          <p>
            You will receive an appointment confirmation email as well as a text when our technician
            is on their way.
          </p>
          <p>Please remove all trash and personal items before our technician arrives.</p>
          <p>See you soon.</p>
        </div>
        <div className="footer">
          <button className="primary" onClick={this.onDoneClick}>
            Done
          </button>
        </div>
      </div>
    )
  }

  renderSummary = () => {
    const { selection, products, selectedAddOns, discountPercent, discountAmount } = this.state
    let total = 0
    if (selection && selection.id) {
      const product = products.find(p => p.id === selection.id)
      const totals = calculateTotals(product, selectedAddOns, discountPercent, discountAmount)
      total = totals.total
    }

    return (
      <div className="summary gridfields">
        <div>
          <span>Total</span>
          <span>{formatMoney(total / 100)}</span>
        </div>
      </div>
    )
  }

  render() {
    const { match } = this.props
    const { step, loading } = this.state

    if (!step) {
      return null
    }

    return (
      <>
        <div className="UserSignupPage CorporateAccountSignUpPage CorporateBookingPage">
          {window.innerWidth <= 1000 ? this.renderSummary() : null}
          {loading ? null : (
            <Switch>
              <Route path={`${match.path}/step-1`} render={this.renderBasicInfo} />
              <Route path={`${match.path}/step-2`} render={this.renderProductInfo} />
              <Route path={`${match.path}/step-3`} render={this.renderAppointmentInfo} />
              <Route path={`${match.path}/step-4`} render={this.renderConfirmation} />
              <Route path={`${match.path}/done`} render={this.renderDoneMessage} />
              <Route
                exact
                path={match.path}
                render={() => <Redirect to={`${match.url}/step-1`} />}
              />
            </Switch>
          )}
        </div>
        <Footer />
      </>
    )
  }
}

const mapState = state => ({
  shoppingCart: state.shoppingCart,
  cars: state.cars,
  showPlaceholderDetail: state.app.settings.corporateSignupShowPlaceholderDetail
})

export default connect(mapState)(CorporateBookingPage)
