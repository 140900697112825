import { FETCH_PACKAGE_DEALS, FETCH_PACKAGE_DEALS_SUCCESS, REMOVE_PACKAGE_DEAL_FROM_CART } from '../actionTypes'

export const fetchPackageDeals = payload => ({
  type: FETCH_PACKAGE_DEALS,
  payload: payload
})

export const fetchPackageDealsSuccess = payload => ({
  type: FETCH_PACKAGE_DEALS_SUCCESS,
  payload: payload
})

export const removePackageDealFromCart = payload => ({
  type: REMOVE_PACKAGE_DEAL_FROM_CART,
  payload: payload
})
