import { createStore, Store, compose, applyMiddleware } from 'redux'
import thunk from "redux-thunk";
import { createEpicMiddleware } from 'redux-observable';


import { rootEpic } from './epics';
import rootReducer from './reducers';

const epicMiddleware = createEpicMiddleware();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store: Store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunk, epicMiddleware)),
)

epicMiddleware.run(rootEpic);
