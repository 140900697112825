import './style.scss'

import React, { Component, Fragment } from 'react'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import {
  saveAppointment,
  cancelAppointment,
  rescheduleAppointment
} from '../../../store/actions/appointments'
import { handleError } from '../../../util/error-util'
import { toast } from '../../../store/actions/app'
import {
  Form,
  Field,
  SaveButton,
  DeleteButton,
  confirm,
  ActionBar,
  Calendar,
  Button
} from '../../shared'
import Event from '../../../models/Event'
import { CANCEL_FEE_MSG } from '../../../contants'
import { ScreenWithClose } from '../../shared/Screen/Screen'

type Props = {
  appointment?: Event,
  urlPrefix: string,
  onSave: Function
}

type State = {
  appointment: Event,
  availableTimes: Object[],
  showCalendar: Boolean,
  newStartDate: String,
  saving: boolean
}

export default class AppointmentForm extends Component<Props, State> {
  state = {
    appointment: null,
    availableTimes: [],
    showCalendar: false,
    newStartDate: null,
    saving: false
  }

  static defaultProps = {
    onSave: _.noop
  }

  componentDidMount() {
    this.setAppointment()
  }

  componentDidUpdate(prevProps) {
    if (this.props.appointment !== prevProps.appointment) {
      this.setAppointment()
    }
  }

  setAppointment = () => {
    this.setState({ appointment: new Event(this.props.appointment) })
  }

  onChange = (prop: string, value: any) => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.appointment[prop] = value
    this.forceUpdate()
  }

  onSubmit = async () => {
    try {
      const { urlPrefix, onSave } = this.props
      const { appointment } = this.state
      this.setState({ saving: true })

      toast('Saving...')
      const result = await saveAppointment(appointment, urlPrefix)
      toast('Saved')

      this.setState({ saving: false })
      onSave(result)
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  didMakeCutoff = () => {
    const cutoff = moment().add(1, 'days').toDate()

    return new Date(this.state.appointment.start) > cutoff
  }

  onCancelClick = async () => {
    try {
      const options = this.didMakeCutoff()
        ? undefined
        : {
            message: CANCEL_FEE_MSG,
            confirmButtonText: 'Yes, cancel my appointment',
            cancelButtonText: 'Go back'
          }

      if (!(await confirm(options))) {
        return
      }

      const { urlPrefix } = this.props
      const { appointment } = this.state

      this.setState({ saving: true })

      toast('Cancelling...')
      const msg = await cancelAppointment(appointment, urlPrefix)
      toast(`Done. ${msg}`)
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  onRescheduleClick = async () => {
    try {
      const { appointment } = this.state
      const cutoffDate = moment().add(1, 'day').toDate()

      if (new Date(appointment.start) < cutoffDate) {
        toast(
          'Appointments within 24 hours cannot be rescheduled. You need to cancel and book a new one.'
        )
        return
      }

      this.setState({ showCalendar: true })
      const res = await axios.post('/events/availabletimes', { event_id: appointment.id })
      this.setState({ availableTimes: res.data })
    } catch (err) {
      handleError(err)
    }
  }

  onNewStartDateSubmit = async () => {
    try {
      const { newStartDate } = this.state

      if (!newStartDate) {
        return this.hideCalendar()
      }

      const appt: Event = _.cloneDeep(this.state.appointment)
      appt.start = newStartDate

      toast('Rescheduling...')
      const savedAppt = await rescheduleAppointment(appt)
      toast('Done.')

      this.setState({ appointment: savedAppt })

      this.hideCalendar()
    } catch (err) {
      handleError(err, true)
    }
  }

  hideCalendar = () => {
    this.setState({ showCalendar: false, newStartDate: null })
  }

  render() {
    const { saving, availableTimes, newStartDate, showCalendar } = this.state
    const appointment: Event = this.state.appointment

    if (!appointment) {
      return null
    }

    return (
      <div className="AppointmentForm">
        <Form onSubmit={this.onSubmit}>
          <Field
            type="text"
            label="Date:"
            value={appointment.startDateToString()}
            onChange={e => this.onChange('name', e.target.value)}
            disabled
            readonly
          />
          {appointment.cancelled_date ? (
            <Field
              type="text"
              label="Cancelled at:"
              value={moment(appointment.cancelled_date).format('M/D/Y h:mmA')}
              onChange={e => this.onChange('name', e.target.value)}
              disabled
              readonly
            />
          ) : null}
          <ActionBar>
            {appointment.id &&
            new Date(appointment.start) > new Date() &&
            !appointment.cancelled_date ? (
              <Fragment>
                {appointment.corporate_account ? null : (
                  <SaveButton onClick={this.onRescheduleClick} disabled={saving}>
                    Reschedule
                  </SaveButton>
                )}
                <DeleteButton onClick={this.onCancelClick} disabled={saving}>
                  Cancel Appointment
                </DeleteButton>
              </Fragment>
            ) : null}
          </ActionBar>
        </Form>
        {showCalendar ? (
          <ScreenWithClose onClose={this.hideCalendar}>
            <div className="AppointmentForm_Portal">
              <Calendar
                events={availableTimes}
                views={['month']}
                onSelectEvent={event => this.setState({ newStartDate: event.start })}
                readonly
              />
              <ActionBar>
                <Button onClick={this.hideCalendar}>Cancel</Button>
                <SaveButton onClick={this.onNewStartDateSubmit} disabled={!newStartDate}>
                  Reschedule appointment
                </SaveButton>
              </ActionBar>
            </div>
          </ScreenWithClose>
        ) : null}
      </div>
    )
  }
}
