import axios from 'axios'
import _debug from 'debug'
import { store } from '../store'
import { PackageDeal } from '../../models'

const debug = _debug('carwash:store:actions:shoppingCart')

export function setDiscountCode(discountCode: string) {
  store.dispatch({ type: 'SET_DISCOUNT_CODE', discountCode })
}

export function applyDiscountPercent(discountCode: string, discountPercent: number) {
  store.dispatch({
    type: 'APPLY_DISCOUNT_PERCENT',
    discountCode,
    discountPercent
  })
}

export function applyDiscountAmount(discountCode: string, discountAmount: number) {
  store.dispatch({
    type: 'APPLY_DISCOUNT_AMOUNT',
    discountCode,
    discountAmount
  })
}

export function clearShoppingCart() {
  store.dispatch({ type: 'RESET_CART' })
}

/**
 * Checks for either a valid discount code or a valid package deal code
 *
 * @param {String} code
 */
export async function checkPromoCode(code: string) {
  try {
    const res = await axios.post('/apply-discount', { discountCode: code })
    const { discountPercent, discountAmount } = res.data
    return {
      type: 'DISCOUNT',
      discountPercent,
      discountAmount
    }
  } catch (err) {
    try {
      const res = await axios.post('/package_deals/check', { code })
      const packageDeal = new PackageDeal(res.data)
      return {
        type: 'PACKAGE_DEAL',
        packageDeal
      }
    } catch (err) {
      debug('[fetchDiscount] error: ', err)
      throw err
    }
  }
}
