import { Product, PackageDeal } from '../models'

const COLORS = {
  WASH: '#50bda3',
  CORPORATE_WASH: '#50bda3',
  DETAIL: '#1894a2',
  SPECIAL: '#4468f1',
  CORPORATE_SPECIAL: '#4468f1'
}

export function getColor(product: Product) {
  if (!product) {
    return COLORS.WASH
  }

  return COLORS[product.type]
}

export function getPackageDealInfo(packageDeal: PackageDeal) {
  if (!packageDeal) {
    return null
  }

  const product = packageDeal.products.find(p => Product.isWash(p.type))
  if (!product) {
    return null
  }

  const addOns = product.add_ons
  const selectedAddOns = packageDeal.products.filter(p => !Product.isWash(p.type))

  return { product, addOns, selectedAddOns }
}
