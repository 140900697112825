import './style.scss'

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { isAdmin } from '../../../store/util'
import AddButton from '../../shared/AddButton/AddButton'
import TitleBar from '../../shared/TitleBar/TitleBar'
import CorporateAccount from '../../../models/CorporateAccount'
import moment from 'moment'

type Props = {
  corporateAccounts: CorporateAccount[],
  location: Object,
  history: Object,
  match: Object
}

export default class CorporateAccountList extends Component<Props> {
  onAddClick = () => {
    const { location, history } = this.props
    history.push(`${location.pathname}/new`)
  }

  render() {
    const { corporateAccounts, match } = this.props
    const { url } = match
    return (
      <div className="CorporateAccountList">
        <TitleBar label="CorporateAccounts">
          {isAdmin() ? <AddButton onClick={this.onAddClick} /> : null}
        </TitleBar>
        <div className="list list-primary">
          {corporateAccounts.map(acct => (
            <Link key={acct.id} className="plain" to={`${url}/${acct.id}`}>
              <span>{acct.company_name}</span>
              <span>{acct.url}</span>
              <span>{moment(acct.date).format('dddd')}</span>
            </Link>
          ))}
        </div>
      </div>
    )
  }
}
