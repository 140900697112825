import './style.scss'

import React from 'react'
import { Link } from 'react-router-dom'
import stamp from '../../../assets/icon-covid-stamp-senitizeshine.png'
import { Button } from '../../shared'
import { isMobile } from '../../../util/dom-util'

type Props = {
  onClose: Function
}

export default function CovidBar(props: Props) {
  const { onClose } = props

  return isMobile() ? (
    <div className="CovidBar">
      <Link to="/covid-19" className="imglink">
        <img className="stamp" src={stamp} alt="stamp" />
      </Link>
      <span className="description">
        <Link to="/covid-19">Disinfect &amp; Protect Your Vehicle</Link>
      </span>
      <Link className="button primary caps" to="/covid-19">
        More info
      </Link>
      <Button icon="x" type="plain" className="close" onClick={onClose} />
    </div>
  ) : (
    <div className="CovidBar">
      <img className="stamp" src={stamp} alt="stamp" />
      <span className="description">
        <div>Disinfect &amp; Protect</div>
        <div>Your Vehicle</div>
        <div>
          <Link className="button primary caps" to="/covid-19">
            More info
          </Link>
        </div>
      </span>
      <Button icon="x" type="plain" className="close" onClick={onClose} />
    </div>
  )
}
