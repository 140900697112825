import moment from 'moment'
import { parseTimeInput } from '../util/date-util'
import Product from './Product'
import User from './User'
import Address from './Address'
import BaseModel from './BaseModel'
import Car from './Car'
import CorporateAccount from './CorporateAccount'

export default class Event extends BaseModel {
  id: Number
  type: String
  title: String
  address: Address
  address_info: String
  description: String
  start: Date
  end: Date
  cancelled_date: Date
  allDay: Boolean
  user_id: Number
  employee_id: Number
  customer_id: Number
  products: Product[]
  customer: User
  charge_id: String
  corporate_account: CorporateAccount

  constructor(data: Object) {
    if (!data) data = {}
    super(data)
    this.start = data.start ? new Date(data.start) : null
    this.end = data.end ? new Date(data.end) : null
    this.cancelled_date = data.cancelled_date ? new Date(data.cancelled_date) : null
    this.customer = new User(data && data.customer ? data.customer : undefined)
    this.car = new Car(data && data.car ? data.car : undefined)
    this.address = new Address(data && data.address ? data.address : undefined)
    this.products = (data && data.products ? data.products : []).map(p => new Product(p))
    this.corporate_account =
      data && data.corporate_account ? new CorporateAccount(data.corporate_account) : null
  }

  static build(eventForm: Object) {
    const {
      id,
      type,
      title,
      address,
      description,
      date,
      allDay,
      startTime,
      endTime,
      employee_id,
      customer_id,
      products
    } = eventForm

    let start = null
    let end = null

    const result = parseTimeInput(startTime)

    if (result) {
      start = moment(date)
      start.set('hours', result.hours)
      start.set('minutes', result.minutes)
    }

    if (allDay) {
      end = start.clone()
      end.hours(23).minutes(59).seconds(59)
    } else {
      const result = parseTimeInput(endTime)
      if (result) {
        end = moment(date)
        end.set('hours', result.hours)
        end.set('minutes', result.minutes)
      }
    }

    return new Event({
      id,
      type,
      title,
      address,
      description,
      allDay,
      employee_id: employee_id || undefined,
      customer_id: customer_id || undefined,
      start: start ? start.toJSON() : '',
      end: end ? end.toJSON() : '',
      products: products || []
    })
  }

  toString() {
    let parts = []
    const dateStr = moment(this.start).format('M/D/Y h:mmA')
    parts.push(dateStr)

    const wash: Product = this.products.find(p => Product.isWash(p.type))
    if (wash) {
      parts.push(wash.name)
    }

    parts.push(this.car.toString())
    parts.push(this.address.toString())

    return parts.join(' • ')
  }

  startDateToString() {
    return moment(this.start).format('M/D/Y h:mmA')
  }

  isWash() {
    return ['WASH', 'CORPORATE_WASH'].includes(this.type)
  }
}
