import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import SkipTheWait from '../../../../assets/image-skipthewat.png'
import BgSkipTheWay from '../../../../assets/bg-skiptheway.png'
import { isMobile } from '../../../../util/dom-util'

const dots = [
  'serviceOne',
  'serviceTwo',
  'serviceThree',
  'serviceFour',
  'serviceFive',
  'serviceSix'
]

type Props = {
  noButtons: Boolean
}

export class SkipWait extends Component<Props> {
  state = {
    serviceOne: 'active',
    serviceTwo: '',
    serviceThree: '',
    serviceFour: '',
    serviceFive: '',
    serviceSix: ''
  }

  handleSelection = (e, section) => {
    let newState = {
      serviceOne: '',
      serviceTwo: '',
      serviceThree: '',
      serviceFour: '',
      serviceFive: '',
      serviceSix: ''
    }

    newState[section] = 'active showService'

    this.setState(newState)
  }

  onTouchStart = (e: TouchEvent) => {
    this.gesture = {
      startX: e.touches[0].pageX
    }
  }

  onTouchMove = (e: TouchEvent) => {
    if (!this.gesture) {
      return
    }

    this.gesture.lastX = e.touches[0].pageX

    const diff = this.gesture.lastX - this.gesture.startX
    if (diff > 35) {
      this.gesture = null
      this.move(-1)
    } else if (diff < -35) {
      this.gesture = null
      this.move(1)
    }
  }

  onTouchEnd = (e: TouchEvent) => {
    this.gesture = null
  }

  move = (i: Number) => {
    const services = _.invert(this.state)
    const active = services['active'] || services['active showService']
    const index = dots.indexOf(active)

    let newIndex = index + i
    if (newIndex > dots.length - 1) {
      newIndex = 0
    }
    if (newIndex < 0) {
      newIndex = dots.length - 1
    }

    const section = dots[newIndex]
    this.handleSelection(null, section)
  }

  render() {
    const {
      serviceOne
      // serviceTwo,
      // serviceThree,
      // serviceFour,
      // serviceFive,
      // serviceSix
    } = this.state

    const touchProps = {
      onTouchStart: this.onTouchStart,
      onTouchMove: this.onTouchMove,
      onTouchEnd: this.onTouchEnd
    }

    return isMobile() ? (
      <section className="SkipWait_Mobile" style={{ backgroundImage: `url(${BgSkipTheWay})` }}>
        <div className="skip-wait-content">
          <Content />
        </div>
        <img src={SkipTheWait} alt="bmw-car" />
      </section>
    ) : (
      <section className="SkipWait container is-full">
        <div className="columns skip-wait">
          <div className="column left">
            <img className="bg-cut" src={BgSkipTheWay} alt="BgSkipTheWay" />
            <div className="inner-content">
              <div className="dirty-car" {...touchProps}>
                <img className="bmw" src={SkipTheWait} alt="SkipTheWait" />
              </div>
            </div>
          </div>
          <div className="column right">
            <div className="inner-content skipcar-text" {...touchProps}>
              <div className={`service-one ${serviceOne}`}>
                <Content />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const Content = () => (
  <>
    <h1>Skip the wait</h1>
    <h4>Our professionals come to you</h4>
    <p>
      Book online and have our experienced staff come to you, fully equipped and ready to clean your
      car. Our fully insured staff go through a lengthy and comprehensive training program.
    </p>
  </>
)
