import moment from 'moment'
import { Event } from '../models'

export function parseTimeInput(value: string) {
  const match = /(\d\d):(\d\d)/.exec(value)
  if (!match) {
    return null
  }

  return { hours: Number(match[1]), minutes: Number(match[2]) }
}

export function toDateString(value: string) {
  const date = moment(value)
  if (!date.isValid()) {
    return ''
  }

  return date.format('YYYY-MM-DD')
}

export function toTimeString(value: string) {
  const date = moment(value)
  if (!date.isValid()) {
    return ''
  }

  return date.format('HH:mm')
}

export function getSlot(event: Object) {
  event = new Event(event)
  const startHour = Number(moment(event.start).format('H'))
  const startTime = removeZeros(moment(event.start).format('h:mmA'))
  const endTime = removeZeros(moment(event.end).format('h:mmA'))
  const timeOfDay = startHour < 12 ? 'Morning' : 'Afternoon'
  return `${timeOfDay} ${startTime}-${endTime}`
}

export function removeZeros(str: String) {
  return str.replace(':00', '')
}
