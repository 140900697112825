import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import { formatMoney } from 'accounting'
import Button from '../../../shared/Button/Button'
import Product from '../../../../models/Product'
import { ReactComponent as Checkmark } from './checkmark.svg'

type Props = {
  products: Product[],
  showColumn: Number,
  onChange: Function
}

const defaultRows = [
  'Hand Wash and Dry',
  'Wheels Clean and Tire Shine',
  'Wipe Down Dash, Console and Door Jambs',
  'Interior and Exterior Windows',
  'Cup Holders and Door Pockets',
  'Pressurized Air Blowout',
  'Full Vacuum Including Trunk',
  'Spray Wax'
]

const customRows = [
  ['Carnauba Wax Applied by Hand', [1, 3, 4]],
  ['Thorough Upholstery & Carpet Shampoo', [2, 4]],
  ['Deep Clean, Condition and Protect Leather Seats', [2, 4]],
  ['Vinyl Clean and Shine', [2, 4]],
  ['Smooth Out Light Paint Scratches', [3, 4]],
  ['Enhance Paint Gloss', [3, 4]]
]

const headers = {
  'Shine Interior Detail': (
    <>
      <div>Shine Interior</div>
      <div>Detail</div>
    </>
  ),
  'Shine Exterior Detail': (
    <>
      <div>Shine Exterior</div>
      <div>Detail</div>
    </>
  )
}

export default class ProductTable extends Component<Props, State> {
  render() {
    const { products, showColumn, onChange } = this.props

    const groups = _.groupBy(products, 'type')

    const washes = groups.WASH || []
    const details = groups.DETAIL || []

    const sortedProducts = [...washes, ...details]

    const getStyle = (index: Number) => {
      if (showColumn == null) {
        return null
      }

      const display = index === showColumn ? '' : 'none'
      return { display }
    }

    return (
      <div className="ProductTable">
        <table>
          <thead>
            <tr className="productname-row">
              <th />
              {sortedProducts.map((p, i) => (
                <th key={p.id} style={getStyle(i)}>
                  {headers[p.name] || p.name}
                </th>
              ))}
            </tr>
            <tr className="producttime-row">
              <th style={{ textAlign: 'left' }}>Service time</th>
              <th style={getStyle(0)}>1.5-2.5 hours</th>
              <th style={getStyle(1)}>1.5-2.5 hours</th>
              <th style={getStyle(2)}>3-5 hours</th>
              <th style={getStyle(3)}>3-5 hours</th>
              <th style={getStyle(4)}>4-8 hours</th>
            </tr>
          </thead>
          <tbody>
            {defaultRows.map((label, i) => (
              <tr key={i}>
                <td>{label}</td>
                {sortedProducts.map((p, j) => (
                  <td key={p.id} className="checkmark-cell" style={getStyle(j)}>
                    <Checkmark />
                  </td>
                ))}
              </tr>
            ))}
            {customRows.map(([label, indexes], i) => (
              <tr key={i}>
                <td>{label}</td>
                {sortedProducts.map((p, j) => (
                  <td key={j} className="checkmark-cell" style={getStyle(j)}>
                    {indexes.includes(j) ? <Checkmark /> : null}
                  </td>
                ))}
              </tr>
            ))}
            <tr className="price-row">
              <td></td>
              {sortedProducts.map((p, i) => (
                <td key={p.id} style={getStyle(i)}>
                  {formatMoney(p.amount / 100).replace('.00', '')}
                </td>
              ))}
            </tr>
            <tr className="button-row">
              <td />
              {sortedProducts.map((p, i) => (
                <td key={p.id} style={getStyle(i)}>
                  <Button type="primary" onClick={() => onChange(p)}>
                    Book
                  </Button>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
