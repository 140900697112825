import React, { useState } from 'react'
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Charge } from '../../../models'
import { Field } from '../../shared'

type Props = {
  charge: Charge,
  onCancelClick: Function,
  onConfirmClick: Function
}

export default function CancelAppointmentModal(props: Props) {
  const { charge, onCancelClick } = props
  const [amount, setAmount] = useState('')

  const onConfirmClick = () => {
    let parsedAmount = Number(amount)
    if (isNaN(parsedAmount)) {
      alert('Invalid dollar amount.')
      return
    }

    parsedAmount = Math.floor(parsedAmount * 100)
    props.onConfirmClick(parsedAmount)
  }

  const refundLabel = charge.payment_api === 2 ? 'refund amount' : 'cancellation fee'

  return (
    <Dialog open onClose={onCancelClick} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Cancel Appointment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter a custom {refundLabel} or leave blank to use the system default.
        </DialogContentText>
        <Field
          type="currency"
          label={_.capitalize(refundLabel)}
          value={amount}
          onChange={e => setAmount(e.target.value)}
          usePlaceholders
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick} color="primary">
          Go back
        </Button>
        <Button onClick={onConfirmClick} color="primary">
          Confirm and cancel appointment
        </Button>
      </DialogActions>
    </Dialog>
  )
}
