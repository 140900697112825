import axios from 'axios'
import _debug from 'debug'
import { store } from '../store'
import User from '../../models/User'

const debug = _debug('carwash:store:actions:employees')

export async function loadEmployees(urlPrefix: string = '', urlSuffix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/employees${urlSuffix}`)
    const employees = res.data.results.map(obj => new User(obj))
    store.dispatch({ type: 'SET_EMPLOYEES', employees })
  } catch (err) {
    debug('[loadEmployees] error: ', err)
    throw err
  }
}

export async function loadEmployee(id: number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/employees/${id}`)
    store.dispatch({ type: 'SET_EMPLOYEE', employee: new User(res.data) })
  } catch (err) {
    debug('[loadEmployee] error: ', err)
    throw err
  }
}

export async function saveEmployee(data: Object, urlPrefix: string = '') {
  return data.id ? updateEmployee(data, urlPrefix) : createEmployee(data, urlPrefix)
}

export async function createEmployee(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/employees`, data)
    const employee = new User(res.data)
    store.dispatch({ type: 'ADD_EMPLOYEE', employee })
    return employee
  } catch (err) {
    debug('[createEmployee] error: ', err)
    throw err
  }
}

export async function updateEmployee(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.put(`${urlPrefix}/employees/${data.id}`, data)
    const employee = new User(res.data)
    store.dispatch({ type: 'UPDATE_EMPLOYEE', employee })
    return employee
  } catch (err) {
    debug('[updateEmployee] error: ', err)
    throw err
  }
}

export async function deleteEmployee(data: Object, urlPrefix: string = '') {
  try {
    await axios.delete(`${urlPrefix}/employees/${data.id}`)
    store.dispatch({ type: 'DELETE_EMPLOYEE', employee: data })
  } catch (err) {
    debug('[deleteEmployee] error: ', err)
    throw err
  }
}
