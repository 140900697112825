import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import classNames from 'classnames'
import _debug from 'debug'
import { formatMoney } from 'accounting'
import { Input, Calendar, Form } from '../shared'
import axios from 'axios'
import {
  loadSession,
  hideCovidBar,
  disableNavUrls,
  enableNavUrls,
  toast,
  initSession
} from '../../store/actions/app'
import { loadCorporateAccount } from '../../store/actions/corporateAccounts'
import { handleError, isAppointmentError } from '../../util/error-util'
import { isValidEmail } from '../../util/string-util'
import { scrollToTop } from '../../util/dom-util'
import Product from '../../models/Product'
import Cart from './Cart/Cart'
import { Footer } from '../LandingPage/sections/Footer'
import { tryParsePhoneNumber, calculateTotals } from '../../util/number-util'
import Event from '../../models/Event'
import User from '../../models/User'
import Car from '../../models/Car'
import Address from '../../models/Address'
import { EmailUser, CorporateAccount } from '../../models'
import { addPurchaseToDataLayer } from '../../util/google-util'
import ShineRiteImage from '../../assets/logo-color.png'
import history from '../../history'
import {
  setDiscountCode,
  applyDiscountPercent,
  clearShoppingCart
} from '../../store/actions/shoppingCart'
import { ProductGrid, AddOnGrid, Terms } from '../UserSignupPage'
import CorporateLandingPage from '../CorporateLandingPage/CorporateLandingPage'
import BubbleHeader from '../BubbleHeader/BubbleHeader'
import { icons } from '../../images'
import { getColor } from '../../util/product-util'
import PaymentForm from '../PaymentForm/PaymentForm'
import CustomerInstructions from '../UserSignupPage/CustomerInstructions'
import { log, makeLog } from '../../analytics'

const debug = _debug('carwash:components:UserSignupPage')

const logNextBtn = makeLog('CorporateAccountSignUpPage/NextButton')
const logBackBtn = makeLog('CorporateAccountSignUpPage/BackButton')

const Type = {
  PLAN: 'PLAN',
  PRODUCT: 'PRODUCT'
}

const StoreKey = {
  NORMAL: 'CorporateAccountSignUpPageState',
  PRE_REDIRECT: 'CorporateAccountSignUpPageState_PreRedirect'
}

const PRODUCT_INFO = 'PRODUCT_INFO'
const APPOINTMENT_INFO = 'APPOINTMENT_INFO'
const ACCOUNT_INFO = 'ACCOUNT_INFO'
const PAYMENT_INFO = 'PAYMENT_INFO'
const DONE = 'DONE'

export const DISCOUNT_PRODUCT = new Product({
  id: -1,
  type: 'DETAIL',
  name: 'Schedule a detail',
  description:
    'Schedule a detail any other day of the week at your home or office, use code DETAIL10 for 10% off'
})

type Props = {
  shoppingCart: Object,
  signupInfo?: Object,
  emailuser?: EmailUser,
  history: Object,
  match: Object,
  location: Location
}

type State = {
  // Data
  corporateAccount: CorporateAccount,
  plans: Object[],
  subplans: Object[],
  products: Product[],
  availableTimes: Event[],

  // Plan info
  selection: ?Object,
  selectedAddOns: Product[],
  discountCode?: String,
  discountPercent?: Number,
  discountAmount?: Number,
  appointmentTime: String,
  appointmentEvent: Event,
  token: String,

  // Car info
  address: String,
  city: String,
  state: String,
  zip: String,
  stallInfo: String,
  hasCoveredParking: Boolean,
  make: String,
  model: String,
  color: String,
  license: String,

  // Account info
  firstName: string,
  lastName: string,
  email: string,
  email2: string,
  phoneNumber: string,
  password: string,
  acceptedTermsDate: Date,

  // Other state
  sessionId: String,
  showProducts: Boolean,
  saving: boolean,
  loading: boolean,
  loadingAppts: Boolean,
  transitionTo: ACCOUNT_INFO | APPOINTMENT_INFO | PAYMENT_INFO | DONE | null,
  discountMessage: string
}

export class CorporateAccountSignUpPage extends Component<Props, State> {
  queryName: String
  shouldStoreState = true
  initialState: String = '{}'

  constructor(props: Props) {
    super(props)

    try {
      const params = new URLSearchParams(props.location.search)
      const name = params.get('p')
      if (name) {
        this.queryName = decodeURIComponent(name)
      }
    } catch (err) {
      // no op
    }

    this.state = {
      plans: [],
      subplans: [],
      products: [],
      selectedAddOns: [],
      discountCode: null,
      discountPercent: 0,
      discountAmount: 0,
      availableTimes: [],

      selection: {},
      appointmentTime: '',
      appointmentEvent: null,
      token: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      stallInfo: '',
      hasCoveredParking: false,
      make: '',
      model: '',
      color: '',
      license: '',

      firstName: '',
      lastName: '',
      email: '',
      email2: '',
      phoneNumber: '',
      password: '',
      acceptedTermsDate: null,
      isGuest: false,

      showProducts: false,
      saving: false,
      loading: true,
      loadingAppts: false,
      transitionTo: null,
      discountMessage: '',

      corporateAccount: null
    }

    this.initialState = JSON.stringify(this.state)

    const signupInfo = props.signupInfo
    if (signupInfo) {
      const user: User = signupInfo.user
      const car: Car = signupInfo.car
      const address: Address = signupInfo.address
      this.state.address = address.street || ''
      this.state.city = address.city || ''
      this.state.state = address.state || ''
      this.state.zip = address.zip || ''
      this.state.stallInfo = address.extra_info || ''
      this.state.hasCoveredParking =
        address.has_covered_parking != null ? address.has_covered_parking : false

      this.state.make = car.make || ''
      this.state.model = car.model || ''
      this.state.color = car.color || ''
      this.state.license = car.license_plate || ''

      this.state.firstName = user.first_name || ''
      this.state.lastName = user.last_name || ''
      this.state.email = user.email || ''
      this.state.email2 = user.email || ''
      this.state.phoneNumber = user.phone || ''
    }
  }

  componentDidMount() {
    this.init()
  }

  init = async () => {
    const { firstWashDiscount } = this.props
    disableNavUrls()

    const params = new URLSearchParams(this.props.location.search)

    const res = await axios.get('/checkout-session')
    const storedState = res.data[StoreKey.NORMAL]
    const storedPreRedirectState = res.data[StoreKey.PRE_REDIRECT]

    if (params.get('session_id') && storedPreRedirectState) {
      setTimeout(hideCovidBar, 500)
      const nextState = {
        ...storedPreRedirectState,
        loading: false,
        saving: false
      }

      this.setState(nextState, () => {
        this.activateAccount()
      })
    } else if (storedPreRedirectState || storedState) {
      setTimeout(hideCovidBar, 500)
      const state = storedPreRedirectState || storedState
      const nextState = {
        ...state,
        loading: false,
        saving: false
      }

      this.setState(nextState)

      if (nextState.discountCode && firstWashDiscount) {
        applyDiscountPercent(firstWashDiscount.code, firstWashDiscount.percent)
      }

      this.fetchProducts()
    } else {
      this.fetchCorporateAccount()
      this.fetchProducts()
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      scrollToTop()
    }

    if (this.shouldStoreState && this.props.location.pathname !== prevProps.location.pathname) {
      this.storeState(StoreKey.NORMAL)
    }
  }

  storeState = (key: String) => {
    try {
      axios.post('/checkout-session', { [key]: this.state })
    } catch (err) {
      // no op
    }
  }

  resetState = () => {
    this.shouldStoreState = false
    const { selection, isGuest } = this.state
    const initialState = JSON.parse(this.initialState)
    this.setState({ ...initialState, selection, isGuest, loading: false, saving: false })
  }

  getCorporateUrl = () => {
    const { match } = this.props
    return match.params.slug
  }

  fetchCorporateAccount = async () => {
    const url = this.getCorporateUrl()
    try {
      const corporateAccount = await loadCorporateAccount(url)
      this.setState({ corporateAccount, loading: false })
    } catch (err) {
      console.error(err)
      toast(`Account not found at /${url}`)
    }
  }

  fetchProducts = async () => {
    try {
      const res = await axios.get('/products?type__in=WASH,DETAIL,CORPORATE_SPECIAL')
      const products = res.data.results.map(p => new Product(p))
      this.setState({ products })
    } catch (err) {
      handleError(err, true)
    }
  }

  fetchAvailableTimes = async () => {
    try {
      this.setState({ loadingAppts: true })

      const { selection, selectedAddOns } = this.state
      const productIds = [selection.id]
      const addOnIds = selectedAddOns.map(p => p.id)
      productIds.push(...addOnIds)
      const res = await axios.post('/events/availabletimes', {
        product_ids: productIds
        // corporate_account_id: this.state.corporateAccount.id
      })
      this.setState({ availableTimes: res.data, loadingAppts: false })
    } catch (err) {
      debug('[fetchAvailableTimes] error: ', err)
      handleError(err)
      this.setState({ loadingAppts: false })
    }
  }

  onProductDoneClick = (product: Product) => {
    const { email } = this.state

    this.setState({ transitionTo: APPOINTMENT_INFO })

    log('CorporateAccountSignUpPage/ProductSelected', {
      productId: product.id,
      email: this.state.email
    })

    setTimeout(() => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null
      })

      history.push(`${match.url}/addons`)

      logNextBtn({
        from: '/service',
        to: '/addons',
        email,
        productIds: [product.id]
      })
    }, 200)
  }

  onAddOnInfoSubmit = async () => {
    this.setState({ transitionTo: APPOINTMENT_INFO })
    this.fetchAvailableTimes()
    setTimeout(() => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null
      })

      history.push(`${match.url}/date`)

      const { email, selection, selectedAddOns } = this.state
      const productIds = [selection.id, ...selectedAddOns.map(p => p.id)]
      logNextBtn({ from: '/addons', to: '/date', email, productIds })
    }, 200)
  }

  onAppointmentInfoSubmit = () => {
    const { token } = this.state
    if (token) {
      return this.activateAccount()
    }

    this.setState({ transitionTo: ACCOUNT_INFO })

    setTimeout(() => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null
      })

      history.push(`${match.url}/info`)

      const { email, selection, selectedAddOns, appointmentTime } = this.state
      const productIds = [selection.id, ...selectedAddOns.map(p => p.id)]
      logNextBtn({ from: '/date', to: '/info', email, productIds, appointmentTime })
    }, 200)
  }

  onBasicInfoSubmit = async (e: Event, isGuest: Boolean = false) => {
    try {
      e.preventDefault()
      const { firstWashDiscount } = this.props
      hideCovidBar()
      this.setState({ saving: true })
      const { firstName, lastName, email, phoneNumber } = this.state

      this.setState({ isGuest })

      if (!isGuest) {
        await axios.post('/users/upsert', {
          first_name: firstName,
          last_name: lastName,
          email,
          phone: phoneNumber
        })
      }

      if (firstWashDiscount) {
        applyDiscountPercent(firstWashDiscount.code, firstWashDiscount.percent)
        this.setState({
          discountCode: firstWashDiscount.code,
          discountMessage: firstWashDiscount.message
        })
      }

      this.setState({ transitionTo: PRODUCT_INFO })

      setTimeout(async () => {
        const { history, match } = this.props

        this.setState({
          transitionTo: null,
          saving: false
        })

        history.push(`${match.url}/service`)

        logNextBtn({ from: '/start', to: '/service', email })
      }, 200)
    } catch (err) {
      debug('[onAccountInfoSubmit] error: ', err)
      this.setState({ saving: false })
      handleError(err, true)
    }
  }

  onAccountInfoSubmit = async (e: Event) => {
    try {
      e.preventDefault()
      this.setState({ saving: true })
      const { location } = this.props
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        selection,
        selectedAddOns,
        appointmentTime,
        // address,
        // city,
        // state,
        // zip,
        stallInfo,
        hasCoveredParking,
        make,
        model,
        color,
        license
      } = this.state

      const successUrl = location.pathname.replace('/info', '/payment')
      const cancelUrl = successUrl

      const res = await axios.post('/c/account/create', {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,

        address: ' ',
        city: ' ',
        state: ' ',
        zip: ' ',
        stallInfo,
        hasCoveredParking,
        make,
        model,
        color,
        license,

        makeAutopilotChanges: false,
        successUrl,
        cancelUrl
      })

      const { user, sessionId } = res.data
      this.setState({ user, sessionId, transitionTo: PAYMENT_INFO })

      setTimeout(async () => {
        const { history, match } = this.props

        this.setState({
          transitionTo: null,
          password: '',
          saving: false
        })

        history.push(`${match.url}/payment`)

        const productIds = [selection.id, ...selectedAddOns.map(p => p.id)]
        logNextBtn({ from: '/account', to: '/payment', email, productIds, appointmentTime })
      }, 200)
    } catch (err) {
      debug('[onAccountInfoSubmit] error: ', err)
      this.setState({ saving: false })
      handleError(err, true)
    }
  }

  onAcceptTerms = e => {
    const { user } = this.state
    if (!e.target.checked) {
      this.setState({ acceptedTermsDate: null })
      return
    }

    const acceptedTermsDate = new Date()
    axios.post(`/users/${user.id}/agree_to_terms`, {
      acceptedTermsDate: acceptedTermsDate.toJSON()
    })

    this.setState({ acceptedTermsDate })
  }

  onPaymentInfoSubmit = async (token: Object) => {
    this.setState({ token }, () => {
      this.activateAccount()
    })
  }

  activateAccount = async () => {
    const { shoppingCart, history, match } = this.props
    const {
      email,
      products,
      token,
      selection,
      selectedAddOns,
      appointmentTime,
      corporateAccount,
      discountCode,
      isGuest
    } = this.state

    this.setState({ saving: true })

    try {
      const res = await axios.post('/c/account/activate', {
        tokenId: token.id,
        selection,
        addOns: selectedAddOns.map(addOn => addOn.id),
        discountCode: discountCode
          ? discountCode
          : shoppingCart.discountApplied
          ? shoppingCart.discountCode
          : null,
        appointmentTime,
        eventType: 'CORPORATE_WASH',
        corporateAccountId: corporateAccount.id,
        makeAutopilotChanges: false,
        login: !isGuest
      })

      const { event } = res.data

      try {
        const gaProducts = [products.find(p => p.id === selection.id), ...selectedAddOns]
        addPurchaseToDataLayer(event.id, event.charge.amount, 'NEW_CUSTOMER', gaProducts)
      } catch (err) {
        console.error(err)
      }

      this.setState({
        saving: false,
        transitionTo: DONE
      })

      clearShoppingCart()
      this.resetState()

      setTimeout(() => {
        const { history, match } = this.props

        this.setState({
          transitionTo: null
        })

        history.push(`${match.url}/done`)

        const productIds = [selection.id, ...selectedAddOns.map(p => p.id)]
        logNextBtn({ from: '/payment', to: '/done', email, productIds, appointmentTime })
      }, 200)
    } catch (err) {
      debug('[activateAccount] error: ', err)
      this.setState({ saving: false })
      handleError(err, true)
      if (isAppointmentError(err)) {
        history.push(`${match.url}/date`)
        this.fetchAvailableTimes()
      }
    }
  }

  onDoneClick = async () => {
    await loadSession()
    enableNavUrls()
    this.props.history.push('/')
  }

  onPasswordInfoSubmit = async (e: Event) => {
    try {
      e.preventDefault()
      const { password } = this.state
      this.setState({ saving: true })
      const res = await axios.post('/c/account/set_password', { password })
      const { user, csrf } = res.data
      initSession(new User(user), undefined, undefined, csrf)
      this.setState({ saving: false })
      this.props.history.push('/')
      log('CorporateAccountSignUpPage/SavePassword', { from: '/done', to: '/', email: user.email })
    } catch (err) {
      debug('[onPasswordInfoSubmit] error: ', err)
      this.setState({ saving: false })
      handleError(err, true)
    }
  }

  renderCart = () => {
    const { emailuser, history, match } = this.props
    const {
      email,
      products,
      selection,
      selectedAddOns,
      appointmentTime,
      appointmentEvent
    } = this.state

    return (
      <Cart
        selection={products.find(p => p.id === selection.id)}
        addOns={selectedAddOns}
        appointmentTime={appointmentTime}
        appointmentEvent={appointmentEvent}
        emailuser={emailuser}
        onRemoveProduct={() => {
          this.setState({ selection: {}, selectedAddOns: [] })
          history.push(`${match.url}/service`)
          log('CorporateAccountSignUpPage/Cart/Emptied', { email })
        }}
        onRemoveAddOn={(addOn: Product) => {
          this.setState({ selectedAddOns: selectedAddOns.filter(p => p !== addOn) })
          log('CorporateAccountSignUpPage/Cart/AddonRemoved', { email, productId: addOn.id })
        }}
        onDiscountApplied={(discountCode, discountPercent, discountAmount) => {
          this.setState({ discountCode, discountPercent, discountAmount })
        }}
        inline
      />
    )
  }

  renderBasicInfo = () => {
    const {
      corporateAccount,

      // Account fields
      firstName,
      lastName,
      email,
      phoneNumber,

      transitionTo,
      saving
    } = this.state

    const valid = Boolean(
      firstName.trim() && lastName.trim() && email.trim() && isValidEmail(email)
    )

    const ui = (key: String, showHeader: Boolean) => (
      <div
        key={key}
        className={classNames('section basic-info', {
          hiding: transitionTo === PRODUCT_INFO
        })}>
        <div className="container">
          {corporateAccount ? (
            <>
              {showHeader ? (
                <BubbleHeader>
                  <div className="corporate-account">
                    <div className="corparate-account-parent-container">
                      <div className="corporate-account-info-container">
                        <img
                          className="logo-shinerite"
                          src={ShineRiteImage}
                          alt="Shine Rite logo"
                        />
                        <h1> @ </h1>
                        <img
                          className="logo-account"
                          src={corporateAccount.logo}
                          alt="Shine Rite logo"
                        />
                      </div>
                      <div className="corporate-welcome-text-container">
                        <p>
                          Shine Rite is an approved vendor for {corporateAccount.company_name}.
                          Please enter your info below and we will send special offers just for
                          residents.
                        </p>
                      </div>
                    </div>
                  </div>
                </BubbleHeader>
              ) : (
                <div style={{ width: 1, height: 100 }} />
              )}

              <div className="basic-info-section">
                <div className="basic-info-form-header">
                  <h2>We just need some basic info to get you started</h2>
                  <div className="color-divider" />
                </div>
                <Form onSubmit={this.onBasicInfoSubmit}>
                  <Input
                    type="text"
                    name="fname"
                    autoComplete="given-name"
                    label="First name"
                    className="formfield"
                    value={firstName}
                    onChange={e => this.setState({ firstName: e.target.value })}
                  />
                  <Input
                    type="text"
                    name="lname"
                    autoComplete="family-name"
                    label="Last name"
                    className="formfield"
                    value={lastName}
                    onChange={e => this.setState({ lastName: e.target.value })}
                  />
                  <Input
                    type="email"
                    name="email"
                    autoComplete="email"
                    autoCapitalize="off"
                    label="Email"
                    className="formfield"
                    value={email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                  <Input
                    type="tel"
                    name="phone"
                    autoComplete="tel"
                    label="Cell phone number (optional)"
                    className="formfield"
                    value={phoneNumber}
                    onChange={e =>
                      this.setState({
                        phoneNumber: tryParsePhoneNumber(phoneNumber, e.target.value)
                      })
                    }
                  />
                  <div className="footer">
                    <button
                      id="corporate-signup-next-1"
                      className="primary"
                      onClick={this.onBasicInfoSubmit}
                      disabled={saving || !valid}>
                      Submit
                    </button>
                    <button
                      id="corporate-signup-next-1-guest"
                      className="secondary"
                      onClick={e => this.onBasicInfoSubmit(e, true)}
                      disabled={saving}>
                      Continue as guest
                    </button>
                  </div>
                </Form>
              </div>
            </>
          ) : (
            <div style={{ height: 120 }} />
          )}
        </div>
      </div>
    )

    return (
      <>
        {ui('basic-info', true)}
        <CorporateLandingPage />
        {ui('basic-info-2', false)}
      </>
    )
  }

  renderProductInfo = () => {
    const { match } = this.props
    const { products, discountMessage, transitionTo } = this.state

    const headerText = 'Schedule your wash with us'

    return (
      <div
        key="plan"
        className={classNames('section product-info', {
          hiding: transitionTo === APPOINTMENT_INFO
        })}>
        <div className="container">
          <BubbleHeader text={headerText}></BubbleHeader>
          {discountMessage ? (
            <div className="basic-card">
              <span>
                <img src={icons.dollar} alt="Discount" />
              </span>
              <span>{discountMessage}</span>
            </div>
          ) : null}
          <ProductGrid
            products={products}
            mainWashType="CORPORATE_WASH"
            showCompare={false}
            onChange={product => {
              if (product === DISCOUNT_PRODUCT) {
                setDiscountCode('DETAIL10')
                history.push('/signup')
                return
              }

              this.setState({
                selection: { type: Type.PRODUCT, id: product.id },
                selectedAddOns: []
              })
              this.onProductDoneClick(product)
            }}
          />
        </div>

        <div className="footer">
          <button
            id="corporate-signup-back-1"
            type="button"
            className="secondary"
            onClick={() => {
              history.push(`${match.url}/start`)
              logBackBtn({ from: '/service', to: '/start' })
            }}>
            Back
          </button>
        </div>
      </div>
    )
  }

  renderAddOnInfo = () => {
    const { history, match } = this.props
    const { email, products, selection, selectedAddOns, transitionTo } = this.state

    let addOns = []
    let fixedAddOns = []
    let product = {}
    if (selection.id) {
      product = products.find(p => p.id === selection.id)
      addOns = product.add_ons
    }

    return (
      <div key="addon" className="addon-step">
        <BubbleHeader text="Select Add-ons" />

        <div
          className={classNames('container', {
            hiding: transitionTo === APPOINTMENT_INFO
          })}>
          <AddOnGrid
            addOns={addOns}
            selectedAddOns={selectedAddOns}
            fixedAddOns={fixedAddOns}
            color={getColor(product)}
            onChange={(addOn: Product, checked: Boolean) => {
              if (checked) {
                this.setState({ selectedAddOns: [...selectedAddOns, addOn] })
                log('CorporateAccountSignUpPage/AddonSelected', { email, productId: addOn.id })
              } else {
                this.setState({ selectedAddOns: selectedAddOns.filter(p => p.id !== addOn.id) })
                log('CorporateAccountSignUpPage/AddonRemoved', { email, productId: addOn.id })
              }
            }}
          />

          <div className="footer">
            <button
              id="corporate-signup-back-2"
              type="button"
              className="secondary"
              onClick={() => {
                this.setState({ selection: {}, selectedAddOns: [] })
                history.push(`${match.url}/service`)
                logBackBtn({ from: '/addons', to: '/service', email })
              }}>
              Back
            </button>
            <button
              id="corporate-signup-next-2"
              className="primary"
              onClick={this.onAddOnInfoSubmit}>
              Next
            </button>
          </div>
        </div>
      </div>
    )
  }

  renderAppointmentInfo = () => {
    const { history, match } = this.props
    const {
      email,
      token,
      availableTimes,
      appointmentTime,
      saving,
      loadingAppts,
      transitionTo
    } = this.state

    return (
      <div
        key="appointment"
        className={classNames('appointment', {
          hiding: transitionTo === ACCOUNT_INFO
        })}>
        <BubbleHeader text="Choose Appointment" />

        {loadingAppts ? (
          <div className="calendar-placeholder">
            <div className="div-1" />
            <div className="div-2" />
            <div className="div-3" />
          </div>
        ) : (
          <Calendar
            events={availableTimes}
            views={['month']}
            onSelectEvent={event => {
              this.setState({ appointmentTime: event.start, appointmentEvent: event }, () => {
                this.onAppointmentInfoSubmit()
              })
            }}
            readonly
          />
        )}
        <div className="footer">
          <button
            id="corporate-signup-back-3"
            type="button"
            className="secondary"
            onClick={() => {
              history.push(`${match.url}/addons`)
              logBackBtn({ from: '/date', to: '/addons', email })
            }}>
            Back
          </button>
          <button
            id="corporate-signup-next-3"
            className="primary"
            disabled={saving || !appointmentTime}
            onClick={this.onAppointmentInfoSubmit}>
            {token ? 'Submit' : 'Next'}
          </button>
        </div>
      </div>
    )
  }

  renderAccountInfo = () => {
    const { history, match } = this.props
    const {
      // Account fields
      firstName,
      lastName,
      email,
      // email2,
      phoneNumber,
      password,
      isGuest,

      // Vehicle fields
      // address,
      // city,
      // state,
      // zip,
      stallInfo,
      hasCoveredParking,
      make,
      model,
      color,
      license,

      saving,
      transitionTo
    } = this.state

    let accountValid
    if (isGuest) {
      accountValid = Boolean(
        firstName.trim() && lastName.trim() && email.trim() && isValidEmail(email)
      )
    } else {
      accountValid = Boolean(
        firstName.trim() &&
          lastName.trim() &&
          email.trim() &&
          isValidEmail(email) &&
          password.trim().length >= 6
      )
    }

    const addressValid = Boolean(make.trim() && model.trim() && color.trim())

    const valid = accountValid && addressValid

    return (
      <div
        key="account"
        className={classNames('account', {
          hiding: transitionTo === PAYMENT_INFO
        })}>
        <BubbleHeader text="Your info" />

        <form id="account-form" name="account-form">
          <div className="card">
            {/* Account fields */}
            <h3 className="form-section">Personal info</h3>
            <Input
              type="text"
              name="fname"
              autoComplete="given-name"
              label="First name"
              className="formfield"
              value={firstName}
              onChange={e => this.setState({ firstName: e.target.value })}
            />
            <Input
              type="text"
              name="lname"
              autoComplete="family-name"
              label="Last name"
              className="formfield"
              value={lastName}
              onChange={e => this.setState({ lastName: e.target.value })}
            />
            <Input
              type="email"
              name="email"
              autoComplete="email"
              autoCapitalize="off"
              label="Email"
              className="formfield"
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
            />
            <Input
              type="tel"
              name="phone"
              autoComplete="tel"
              label="Cell phone number"
              className="formfield"
              value={phoneNumber}
              onChange={e =>
                this.setState({ phoneNumber: tryParsePhoneNumber(phoneNumber, e.target.value) })
              }
            />
            {isGuest ? null : (
              <Input
                type="password"
                label="Create password"
                className="formfield"
                value={password}
                onChange={e => this.setState({ password: e.target.value })}
              />
            )}
          </div>

          <div className="color-divider vehicle-info-divider" />
          <div className="card">
            {/* Vehicle fields */}
            <h3 className="form-section form-section-vehicle">Vehicle info</h3>
            <Input
              type="text"
              name="shineritevehiclemake"
              autoComplete="off"
              label="Make"
              className="formfield"
              value={make}
              onChange={e => this.setState({ make: e.target.value })}
            />
            <Input
              type="text"
              name="shineritevehiclemodel"
              autoComplete="off"
              label="Model"
              className="formfield"
              value={model}
              onChange={e => this.setState({ model: e.target.value })}
            />
            <Input
              type="text"
              name="shineritevehiclecolor"
              autoComplete="off"
              label="Color"
              className="formfield"
              value={color}
              onChange={e => this.setState({ color: e.target.value })}
            />
            <Input
              type="text"
              name="shineritevehiclelicenseplate"
              autoComplete="off"
              label="License plate (optional)"
              className="formfield"
              value={license}
              onChange={e => this.setState({ license: e.target.value })}
            />
            <Input
              type="checkbox"
              name="shineritevehiclecoveredparking"
              label="Is covered parking available?"
              className="formfield checkbox"
              checked={hasCoveredParking}
              onChange={e => this.setState({ hasCoveredParking: e.target.checked })}
            />
            <Input
              type="textarea"
              name="shineritevehiclegatecode"
              autoComplete="off"
              label="Stall number / gate code / other instructions / how'd you hear about us?"
              className="formfield"
              value={stallInfo}
              onChange={e => this.setState({ stallInfo: e.target.value })}
            />
          </div>
          <div className="footer">
            <button
              id="corporate-signup-back-4"
              type="button"
              className="secondary"
              onClick={() => {
                history.push(`${match.url}/date`)
                logBackBtn({ from: '/account', to: '/date', email })
              }}>
              Back
            </button>
            <button
              id="corporate-signup-next-4"
              className="primary"
              onClick={this.onAccountInfoSubmit}
              disabled={saving || !valid}>
              Next
            </button>
          </div>
        </form>
      </div>
    )
  }

  renderPaymentInfo = () => {
    const { history, match } = this.props
    const { firstName, lastName, email, acceptedTermsDate, saving, transitionTo } = this.state

    return (
      <div
        key="payment"
        className={classNames('payment-step', {
          hiding: transitionTo === DONE
        })}>
        <BubbleHeader text="Payment info" />
        <PaymentForm customerName={`${firstName} ${lastName}`} onSuccess={this.onPaymentInfoSubmit}>
          {form => (
            <>
              <div className="color-divider cart-divider" />
              {this.renderCart(true)}
              <div className="color-divider terms-divider" />
              <div className="terms">
                <Terms
                  acceptedTermsDate={acceptedTermsDate}
                  onChange={e =>
                    this.setState({ acceptedTermsDate: e.target.checked ? new Date() : null })
                  }
                />
              </div>
              <div className="footer">
                <button
                  type="button"
                  className="secondary"
                  disabled={saving || form.saving}
                  onClick={() => {
                    history.push(`${match.url}/account`)
                    logBackBtn({ from: '/paymen', to: '/account', email })
                  }}>
                  Back
                </button>
                <button
                  id="signup-confirm"
                  type="submit"
                  className="primary"
                  disabled={saving || form.saving || !form.valid || !acceptedTermsDate}>
                  Confirm Purchase
                </button>
              </div>
            </>
          )}
        </PaymentForm>

        {/* {window.innerWidth > 1000 ? this.renderCart() : null} */}
      </div>
    )
  }

  renderDoneMessage = () => {
    const { selection, password, isGuest, saving } = this.state

    if (!selection.id) {
      return null
    }

    return (
      <div key="done" className="done-wrapper">
        <h1>
          Welcome{' '}
          <span role="img" aria-label="tada">
            🎉
          </span>
        </h1>
        <div className="done-message">
          <p>Thanks for signing up!</p>
          <CustomerInstructions />
        </div>

        {isGuest ? (
          <form className="password-card" onSubmit={this.onPasswordInfoSubmit}>
            <p>Create a password for faster checkout next time:</p>
            <Input
              type="password"
              name="password"
              autoComplete="password"
              autoCapitalize="off"
              label="Password"
              className="formfield"
              value={password}
              onChange={e => this.setState({ password: e.target.value })}
            />
            <div className="footer">
              <button
                id="corporate-signup-complete-guest"
                type="submit"
                className="primary"
                disabled={saving}>
                Save
              </button>
            </div>
          </form>
        ) : (
          <div className="footer">
            <button id="corporate-signup-complete" className="primary" onClick={this.onDoneClick}>
              Done
            </button>
          </div>
        )}
      </div>
    )
  }

  renderSummary = () => {
    const { shoppingCart } = this.props
    const { discountAmount, discountPercent } = shoppingCart
    const { selection, products, selectedAddOns } = this.state
    let total = 0
    if (selection && selection.id) {
      const product = products.find(p => p.id === selection.id)
      const totals = calculateTotals(product, selectedAddOns, discountPercent, discountAmount)
      total = totals.total
    }

    return (
      <div className="summary gridfields">
        <div>
          <span>Total</span>
          <span>{formatMoney(total / 100)}</span>
        </div>
      </div>
    )
  }

  render() {
    const { match, location } = this.props
    const { loading } = this.state

    return (
      <>
        <div className="CorporateAccountSignUpPage">
          {!location.pathname.endsWith('/done') ? this.renderSummary() : null}
          {loading ? null : (
            <Switch>
              <Route path={`${match.path}/start`} render={this.renderBasicInfo} />
              <Route path={`${match.path}/service`} render={this.renderProductInfo} />
              <Route path={`${match.path}/addons`} render={this.renderAddOnInfo} />
              <Route path={`${match.path}/date`} render={this.renderAppointmentInfo} />
              <Route path={`${match.path}/info`} render={this.renderAccountInfo} />
              <Route path={`${match.path}/payment`} render={this.renderPaymentInfo} />
              <Route path={`${match.path}/done`} render={this.renderDoneMessage} />
              <Route
                exact
                path={match.path}
                render={() => <Redirect to={`${match.url}/start`} />}
              />
            </Switch>
          )}
        </div>
        <Footer />
      </>
    )
  }
}

const mapState = state => ({
  shoppingCart: state.shoppingCart,
  showPlaceholderDetail: state.app.settings.corporateSignupShowPlaceholderDetail,
  firstWashDiscount: state.app.settings.corporateSignupFirstWashDiscount,
  signupInfo: state.app.signupInfo,
  emailuser: state.emailusers[0]
})

export default connect(mapState)(CorporateAccountSignUpPage)
