import './style.scss'

import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import BasicInfo from './BasicInfo'
import ChangePlan from './ChangePlan'
import { loadCustomer } from '../../../store/actions/customers'

type Props = {
  customer: ?Object,
  urlPrefix: string,
  match: Object
}

type State = {
  invoices: Object[],
  subscriptions: ?(Object[])
}

export class CustomerDetail extends Component<Props, State> {
  state = {
    invoices: [],
    subscriptions: []
  }

  async componentDidMount() {
    const { urlPrefix, match } = this.props
    const id = match.params.customerId
    loadCustomer(id, urlPrefix)

    const res = await axios.get(`${urlPrefix}/customers/${id}/billing`)
    const { invoices, subscriptions } = res.data
    this.setState({ invoices, subscriptions })
  }

  render() {
    const { customer, urlPrefix, match } = this.props
    const { url, path } = match
    const { invoices, subscriptions } = this.state
    if (!customer) {
      return 'Loading...'
    }

    return (
      <div className="CustomerDetail">
        <Switch>
          <Route
            path={`${path}/info`}
            render={props => (
              <BasicInfo
                {...props}
                customer={customer}
                invoices={invoices}
                subscriptions={subscriptions}
                urlPrefix={urlPrefix}
              />
            )}
          />
          <Route
            path={`${path}/change-plan`}
            render={props => (
              <ChangePlan
                {...props}
                customer={customer}
                subscriptions={subscriptions}
                urlPrefix={urlPrefix}
              />
            )}
          />
          <Route
            exact
            path={path}
            render={props => <Redirect to={`${url}/info`} />}
          />
        </Switch>
      </div>
    )
  }
}

const mapState = (state, props) => {
  const id = Number(props.match.params.customerId)
  const customer = state.customers.find(m => m.id === id)

  return {
    customer
  }
}

export default connect(mapState)(CustomerDetail)
