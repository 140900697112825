import _ from 'lodash'

const initialState = []

export default function userActivities(state = initialState, action) {
  let userActivities, index
  switch (action.type) {
    case 'SET_USER_ACTIVITIES':
      userActivities = [...state, ...action.userActivities]
      return _.uniqBy(userActivities, m => m.id)
    case 'SET_USER_ACTIVITY':
      userActivities = [...state]
      index = _.findIndex(userActivities, m => m.id === action.userActivity.id)
      if (index === -1) {
        userActivities.push(action.userActivity)
      } else {
        userActivities.splice(index, 1, action.userActivity)
      }

      return userActivities
    case 'ADD_USER_ACTIVITY':
      return [action.userActivity].concat(state)
    case 'UPDATE_USER_ACTIVITY':
      userActivities = [...state]
      index = _.findIndex(userActivities, m => m.id === action.userActivity.id)
      userActivities.splice(index, 1, action.userActivity)
      return userActivities
    case 'DELETE_USER_ACTIVITY':
      userActivities = state.filter(m => m.id !== action.userActivity.id)
      return userActivities
    default:
      return state
  }
}
