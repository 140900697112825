import './style.scss'

import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Dashboard } from '../Dashboard/Dashboard'
import Customers from '../../Customers'
import Events from '../../Events'
import Products from '../../Products'
import Plans from '../../Plans'
import AdminAccount from '../../AdminAccount/AdminAccount'
import CorporateAccounts from '../../CorporateAccounts'
import Employees from '../../Employees'
import DjangoAdmin from './DjangoAdmin'
import UserActivities from '../../UserActivities'

export default class OrgAdminDashboard extends Component<Props, State> {
  render() {
    const links = [
      { url: '/calendar', label: 'Calendar', icon: 'calendar' },
      { url: '/customers', label: 'Customers', icon: 'account' },
      { url: '/technicians', label: 'Technicians', icon: 'account' },
      // {
      //   url: '/plans',
      //   label: 'Plans',
      //   subtitle: '(Recurring)',
      //   icon: 'dollar'
      // },
      {
        url: '/products',
        label: 'Products',
        // subtitle: '(One-time)',
        icon: 'coins'
      },
      { url: '/myaccount', label: 'Account', icon: 'key' },
      { url: '/corporate-accounts', label: 'Corporate Accounts', icon: 'key' },
      { url: '/customer-activity', label: 'Customer Activity', icon: 'list' },
      { url: '/django-admin', label: 'Django Admin', icon: 'login' }
    ]

    return (
      <div className="OrgAdminDashboard">
        <Dashboard links={links}>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/calendar" />} />
            <Route path="/calendar" render={props => <Events {...props} urlPrefix="/a" />} />
            <Route path="/customers" render={props => <Customers {...props} urlPrefix="/a" />} />
            <Route path="/technicians" render={props => <Employees {...props} urlPrefix="/a" />} />
            <Route path="/products" render={props => <Products {...props} urlPrefix="/a" />} />
            <Route path="/plans" render={props => <Plans {...props} urlPrefix="/a" />} />
            <Route path="/myaccount" render={props => <AdminAccount {...props} urlPrefix="/a" />} />
            <Route
              path="/corporate-accounts"
              render={props => <CorporateAccounts {...props} urlPrefix="/a" />}
            />
            <Route path="/customer-activity" component={UserActivities} />
            <Route path="/django-admin" component={DjangoAdmin} />
          </Switch>
        </Dashboard>
      </div>
    )
  }
}
