const initialState = {
  discountCode: '',
  discountAmount: 0,
  discountPercent: 0,
  discountApplied: false
}

export default function shoppingCart(state = initialState, action) {
  switch (action.type) {
    case 'SET_DISCOUNT_CODE':
      return {
        ...state,
        discountCode: action.discountCode
      }
    case 'APPLY_DISCOUNT_AMOUNT':
      return {
        ...state,
        discountApplied: true,
        discountCode: action.discountCode,
        discountAmount: action.discountAmount
      }
    case 'APPLY_DISCOUNT_PERCENT':
      return {
        ...state,
        discountApplied: true,
        discountCode: action.discountCode,
        discountPercent: action.discountPercent
      }
    case 'RESET_CART':
      return initialState
    default:
      return state
  }
}
