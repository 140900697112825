import './style.scss'

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AddButton from '../../shared/AddButton/AddButton'
import TitleBar from '../../shared/TitleBar/TitleBar'
import Car from '../../../models/Car'

type Props = {
  cars: Car[],
  backTo?: String,
  location: Object,
  history: Object,
  match: Object
}

export default class CarList extends Component<Props> {
  onAddClick = () => {
    const { location, history } = this.props
    history.push(`${location.pathname}/new`)
  }

  render() {
    const { cars, backTo, match } = this.props
    const { url } = match

    return (
      <div className="CarList">
        <TitleBar label="Vehicles" backTo={backTo}>
          <AddButton onClick={this.onAddClick} />
        </TitleBar>
        <div className="list list-primary">
          {cars.map(car => (
            <Link key={car.id} className="plain" to={`${url}/${car.id}`}>
              <span>{car.toString()}</span>
              {car.address ? <span>{car.address.toString()}</span> : null}
            </Link>
          ))}
        </div>
      </div>
    )
  }
}
