export default class BaseModel {
  id: Number
  created_date: Date
  modified_date: Date

  constructor(data: Object = {}) {
    for (const [k, v] of Object.entries(data)) {
      this[k] = v
    }

    if (typeof this.created_date === 'string') {
      this.created_date = new Date(this.created_date)
    }

    if (typeof this.modified_date === 'string') {
      this.modified_date = new Date(this.modified_date)
    }
  }
}
