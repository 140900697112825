import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import Nav from '../Nav/Nav'
import Main from '../Main/Main'
import { loadSession, toast, hideCovidBar } from '../../store/actions/app'
import { Toast } from '../shared/Toast/Toast'
import history from '../../history'
import { icons } from '../../images'
import { isAdmin } from '../../store/util'
import { isMobile } from '../../util/dom-util'
import CovidBar from '../covid/CovidBar/CovidBar'
import { store } from '../../store/store'

const IGNORE_ROUTES = /\/(login|signup|our-process|account|myaccount|faq|contact|wash|forgot-password|reset-password|covid-19)/

type Props = {
  user: Object,
  toast: ?string,
  didLoad: Boolean,
  navigationEnabled: Boolean
}

type State = {}

class App extends Component<Props, State> {
  state = {}

  async componentDidMount() {
    window._toast = toast

    document.addEventListener('scroll', this.onScroll)
    setTimeout(() => this.onScroll(), 100)

    const { user, org } = await loadSession()

    if (!isAdmin(user)) {
      store.dispatch({ type: 'SHOW_COVID_BAR', showCovidBar: true })
    }

    if (!user) {
      try {
        const params = new URLSearchParams(window.location.search)
        const auth = params.get('auth')
        if (auth) {
          history.push('/login')
          return
        }
      } catch (err) {
        // no op
      }
    }

    const { pathname } = window.location
    if (pathname === '/') {
      return
    }

    if (!user && !IGNORE_ROUTES.test(pathname)) {
      if (org.id === 1) {
        history.push('/')
      } else {
        history.push('/login')
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    const y =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0

    const PIVOT = 28

    if (y > PIVOT && !this.scrolled) {
      this.scrolled = true
      this.forceUpdate()
    } else if (y <= PIVOT && this.scrolled) {
      this.scrolled = false
      this.forceUpdate()
    }
  }

  hideCovid = () => {
    hideCovidBar()
  }

  render() {
    const { user, org, toast, didLoad, showCovidBar, navigationEnabled } = this.props
    const { pathname } = window.location

    if (!didLoad) {
      if (pathname === '/') {
        return null
      }

      return (
        <div className="App loading">
          <img src={icons.logo} alt="logo" />
        </div>
      )
    }

    return (
      <div
        className={classNames('App', {
          'logged-in': !!user,
          'is-admin': isAdmin(user),
          'is-mobile': isMobile(),
          'show-covid-bar': showCovidBar,
          landing: !user && pathname === '/',
          scrolled: this.scrolled
        })}>
        {showCovidBar ? <CovidBar onClose={this.hideCovid} /> : null}
        <Nav user={user} org={org} navigationEnabled={navigationEnabled} />
        <Main user={user} org={org} />
        {toast ? <Toast message={toast} /> : null}
      </div>
    )
  }
}

const mapState = state => {
  const { didLoad, user, org, toast, showCovidBar, navigationEnabled } = state.app

  return {
    didLoad,
    user,
    org,
    toast,
    showCovidBar,
    navigationEnabled
  }
}

export default withRouter(connect(mapState)(App))
