import './style.scss'

import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import Button from '../Button/Button'

type Props = {
  className: String,
  title: String,
  hide: Function,
  buttons: any,
  size: 'SMALL' | 'MEDIUM' | 'LARGE',
  children: any,
  closeOnEsc: Boolean
}

const div = document.createElement('div')
document.body.appendChild(div)

export default class Dialog extends Component<Props> {
  static defaultProps = {
    size: 'MEDIUM',
    closeOnEsc: true
  }

  componentDidMount() {
    document.body.style.overflowY = 'hidden'
    document.documentElement.style.overflowY = 'hidden'
    this.addListeners()
  }

  componentWillUnmount() {
    document.body.style.overflowY = ''
    document.documentElement.style.overflowY = ''
    this.removeListeners()
  }

  addListeners = () => {
    document.addEventListener('keydown', this.onKeyDown)
  }

  removeListeners = () => {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  onKeyDown = (e: KeyboardEvent) => {
    const { hide } = this.props
    if (e.key === 'Escape' && hide) {
      hide()
    }
  }

  render() {
    const { className, title, hide, size, buttons, children } = this.props

    return createPortal(
      <div className={classNames('Dialog', className)}>
        <div className={classNames('content', size.toLowerCase())}>
          {title ? <div className="header">{title}</div> : null}
          <Button className="close-x" icon="x" onClick={hide} />
          <div className="body">{children}</div>
          <div className="footer">
            {buttons || (
              <Button type="primary" onClick={hide}>
                Done
              </Button>
            )}
          </div>
        </div>
      </div>,
      div
    )
  }
}
