import './style.scss'

import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import Form from '../../shared/Form/Form'
import Field from '../../shared/Field/Field'
import SaveButton from '../../shared/SaveButton/SaveButton'
import { saveCustomer, deleteCustomer } from '../../../store/actions/customers'
import { handleError } from '../../../util/error-util'
import { toast } from '../../../store/actions/app'
import ActionBar from '../../shared/ActionBar/ActionBar'
import DeleteButton from '../../shared/DeleteButton/DeleteButton'
import confirm from '../../shared/confirm/confirm'
import history from '../../../history'
import { isAdmin } from '../../../store/util'
import User from '../../../models/User'
import { areFieldsEmpty, isValidEmail } from '../../../util/string-util'

const defaultCustomer = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  send_email: true
}

const OPTIONS = (
  <Fragment>
    <option value="-1"></option>
    <option value="PENDING">Pending</option>
    <option value="GUEST">Guest</option>
    <option value="ACTIVE">Active</option>
    <option value="INACTIVE">Inactive</option>
  </Fragment>
)

type Props = {
  customer?: User,
  urlPrefix: string,
  onSave: Function
}

type State = {
  customer: ?User,
  saving: boolean
}

export default class CustomerForm extends Component<Props, State> {
  state = {
    customer: null,
    saving: false
  }

  static defaultProps = {
    onSave: _.noop
  }

  componentDidMount() {
    this.setCustomer()
  }

  componentDidUpdate(prevProps) {
    if (this.props.customer !== prevProps.customer) {
      this.setCustomer()
    }
  }

  setCustomer = () => {
    this.setState({
      customer: _.cloneDeep(this.props.customer || defaultCustomer)
    })
  }

  onChange = (prop: string, value: any) => {
    const customer = { ...this.state.customer }
    customer[prop] = value
    this.setState({ customer })
  }

  onSubmit = async () => {
    try {
      const { urlPrefix, onSave } = this.props
      const { id, first_name, last_name, email, phone, type } = this.state.customer
      const customer = { id, first_name, last_name, email, phone, type }

      this.setState({ saving: true })

      toast('Saving...')
      const result = await saveCustomer(customer, urlPrefix)
      toast('Saved')

      this.setState({ saving: false })
      onSave(result)
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  onDeleteClick = async () => {
    try {
      if (!(await confirm())) {
        return
      }

      const { urlPrefix } = this.props
      const { customer } = this.state

      this.setState({ saving: true })

      toast('Saving...')
      await deleteCustomer(customer, urlPrefix)
      toast('Saved')

      history.replace('/customers')
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  isValid = () => {
    const customer: User = this.state.customer

    const valid =
      isValidEmail(customer.email) &&
      !areFieldsEmpty(customer, ['first_name', 'last_name', 'phone'])

    return valid
  }

  render() {
    const { customer, saving } = this.state

    if (!customer) {
      return null
    }

    const admin = isAdmin()

    return (
      <div className="CustomerForm">
        <Form onSubmit={this.onSubmit}>
          <Field
            type="text"
            label="First Name:"
            value={customer.first_name}
            onChange={e => this.onChange('first_name', e.target.value)}
          />
          <Field
            type="text"
            label="Last Name:"
            value={customer.last_name}
            onChange={e => this.onChange('last_name', e.target.value)}
          />
          <Field
            type="email"
            label="Email:"
            value={customer.email}
            onChange={e => this.onChange('email', e.target.value)}
          />
          <Field
            type="tel"
            label="Phone:"
            value={customer.phone}
            onChange={e => this.onChange('phone', e.target.value)}
          />
          {!admin || customer.id ? null : (
            <Field
              type="password"
              label="Password:"
              value={customer.password}
              onChange={e => this.onChange('password', e.target.value)}
            />
          )}
          {admin ? (
            <Field
              type="select"
              label="Status:"
              value={customer.type}
              onChange={e => this.onChange('type', e.target.value)}
              options={OPTIONS}
            />
          ) : null}
          {!admin && customer.id ? null : (
            <Field
              type="checkbox"
              label="Send welcome email:"
              checked={customer.send_email}
              onChange={e => this.onChange('send_email', e.target.checked)}
            />
          )}
          <ActionBar>
            <SaveButton onClick={this.onSubmit} disabled={saving || !this.isValid()} />
            {admin && customer.id ? (
              <DeleteButton onClick={this.onDeleteClick} disabled={saving} />
            ) : null}
          </ActionBar>
        </Form>
      </div>
    )
  }
}
