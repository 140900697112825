import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadEvent } from '../../../store/actions/events'
import EventForm from '../Form/EventForm'
import TitleBar from '../../shared/TitleBar/TitleBar'

type Props = {
  event: ?Object,
  customers?: Object[],
  locations?: Object[],
  urlPrefix: string,
  match: Object
}

type State = {}

export class EventDetail extends Component<Props, State> {
  state = {}

  async componentDidMount() {
    const { urlPrefix, match } = this.props
    const id = match.params.eventId
    loadEvent(id, urlPrefix)
  }

  render() {
    const { event, customers, locations, urlPrefix } = this.props
    if (!event) {
      return 'Loading...'
    }

    return (
      <div className="EventDetail">
        <TitleBar backTo="/events" label="Event Detail" />
        <EventForm
          event={event}
          customers={customers}
          locations={locations}
          urlPrefix={urlPrefix}
        />
      </div>
    )
  }
}

const mapState = (state, props) => {
  const id = Number(props.match.params.eventId)
  const { events, customers, locations } = state
  const event = events.find(m => m.id === id)

  return {
    event,
    customers,
    locations
  }
}

export default connect(mapState)(EventDetail)
