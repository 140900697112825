import _ from 'lodash'
import { toast } from '../store/actions/app'

export function handleError(err: Error, showServerMessage: Boolean = false) {
  console.error(err)
  if (showServerMessage || process.env.NODE_ENV === 'development') {
    return handleDevelopmentError(err)
  }

  toast('Oops! An error occurred. Please try again later.')
}

function handleDevelopmentError(err: Error) {
  let message = err.message
  if (err.response && Array.isArray(err.response.data)) {
    message = err.response.data.join('\n')
  } else if (err.response && _.isObject(err.response.data)) {
    message = ''
    const { data } = err.response
    if (data.message) {
      message = data.message
    } else {
      for (const key in data) {
        message += `${data[key]}\n`
      }
    }
  }

  toast(message)
}

export function isAppointmentError(err: Error) {
  return err && err.response && err.response.data && err.response.data.date
}

export function getErrorInfo(err: Error) {
  try {
    const { code, message } = err.response.data
    return { code, message }
  } catch (err) {
    return null
  }
}
