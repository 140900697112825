// @flow
import './style.scss'

import React, { Component } from 'react'
import carImg from '../../../assets/car-shinerite-plates.png'

type Props = {
  make: string,
  model: string,
  color: string,
  license: string,
  stallInfo: string,
  hasCoveredParking: boolean
}

type State = {}

export class VehiclePreview extends Component<Props, State> {
  state = {}

  render() {
    const { make, model, color, license, stallInfo, hasCoveredParking } = this.props

    let vehicle = `${make} ${model}`
    if (license) {
      vehicle += ` (${license})`
    }
    if (color) {
      vehicle = `${color} ${vehicle}`
    }

    return (
      <div className="VehiclePreview">
        <div className="img-wrapper">
          <img src={carImg} alt="Vehicle" />
        </div>
        <div className="bottom">
          <div className="vehicle">{vehicle}</div>
          <div className="extra-info">
            {stallInfo.trim() ? (
              <>
                <div>Other info:</div>
                <div className="stall-info"> {stallInfo}</div>
              </>
            ) : null}
            <div>Covered parking: {hasCoveredParking ? 'Yes' : 'No'}</div>
          </div>
        </div>
      </div>
    )
  }
}
