import { formatMoney } from 'accounting'
import moment from 'moment'
import React from 'react'
import { DATETIME_FORMAT } from '../../../contants'
import { Charge } from '../../../models'
import { Field } from '../../shared'

type Props = {
  charge: Charge
}

export function ChargeFormV1(props: Props) {
  const { charge } = props

  return (
    <>
      {charge.amount ? (
        <Field
          type="text"
          label="Amount:"
          value={formatMoney(charge.amount / 100)}
          disabled
          readonly
        />
      ) : null}

      {charge.charged_date ? (
        <Field
          type="text"
          label="Charged at:"
          value={moment(charge.charged_date).format(DATETIME_FORMAT)}
          disabled
          readonly
        />
      ) : (
        <Field
          type="text"
          label="Will charge at:"
          value={moment(charge.should_charge_date).format(DATETIME_FORMAT)}
          disabled
          readonly
        />
      )}
    </>
  )
}

export function ChargeFormV2(props: Props) {
  const { charge } = props

  return (
    <>
      {charge.stripe_initial_charge ? (
        <>
          <Field
            type="text"
            label="First charge amount:"
            value={formatMoney(charge.stripe_initial_charge.amount / 100)}
            disabled
            readonly
          />
          <Field
            type="text"
            label="First charge date:"
            value={moment(charge.stripe_initial_charge.created * 1000).format(DATETIME_FORMAT)}
            disabled
            readonly
          />
        </>
      ) : null}

      {charge.getPendingAmount() ? (
        <Field
          type="text"
          label="Pending Amount:"
          value={formatMoney(charge.getPendingAmount() / 100)}
          disabled
          readonly
        />
      ) : null}

      {charge.stripe_final_charge ? (
        <>
          <Field
            type="text"
            label="Second charge amount:"
            value={formatMoney(charge.stripe_final_charge.amount / 100)}
            disabled
            readonly
          />
          <Field
            type="text"
            label="Second charge date:"
            value={moment(charge.stripe_final_charge.created * 1000).format(DATETIME_FORMAT)}
            disabled
            readonly
          />
        </>
      ) : null}

      {charge.stripe_initial_charge && charge.stripe_final_charge ? (
        <Field
          type="text"
          label="TOTAL:"
          value={formatMoney(
            (charge.stripe_initial_charge.amount + charge.stripe_final_charge.amount) / 100
          )}
          disabled
          readonly
        />
      ) : null}
    </>
  )
}
