import axios from 'axios'
import _debug from 'debug'
import { point } from '@turf/helpers'
import distance from '@turf/distance'

const debug = _debug('carwash:util:map-util')

export const TOKEN =
  'pk.eyJ1Ijoid2luZHdhcmRhcHBzIiwiYSI6ImNrNTJsaDBrYzA4NzUzbG50bXlnanQ2MDAifQ.y6jYYVl3eMzwlRoOyee4HA'

export const AUSTIN_TX = [-97.7442, 30.2679]
const BASE_URL = 'https://api.mapbox.com'
const DRIVING_URL = BASE_URL + '/directions/v5/mapbox/driving'
const GEOCODE_URL = BASE_URL + '/geocoding/v5/mapbox.places'

export async function getDirections(src: float[], dst: float[]) {
  try {
    const coords = src.join(',') + ';' + dst.join(',')
    const url = `${DRIVING_URL}/${coords}.json?access_token=${TOKEN}`
    const res = await axios.get(url, { withCredentials: false })

    return res.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function geocode(address: String) {
  try {
    const url = `${GEOCODE_URL}/${encodeURIComponent(address)}.json?access_token=${TOKEN}`
    const res = await axios.get(url, { withCredentials: false })

    return res.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function reverseGeocode(lng: number, lat: number) {
  try {
    const url = `${GEOCODE_URL}/${lng},${lat}.json?access_token=${TOKEN}`
    const res = await axios.get(url, { withCredentials: false })

    return res.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function getDistance(address: String) {
  try {
    const geocodeResult = await geocode(address)
    const center = geocodeResult.features[0].center

    const from = point(AUSTIN_TX)
    const to = point(center)
    const options = { units: 'miles' }

    const miles = distance(from, to, options)

    return miles
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function checkDistance(address: String) {
  let distanceMiles

  try {
    distanceMiles = await getDistance(address)
  } catch (err) {
    debug('[checkDistance] error: ', err)
    throw new Error(`There was a problem finding ${address}`)
  }

  if (Number.isFinite(distanceMiles)) {
    debug(`${address} is ${distanceMiles} miles from central Austin.`)
    if (distanceMiles > 10) {
      throw new Error("We're sorry, but we are currently only servicing the Central Austin area.")
    }
  } else {
    debug(`[checkDistance] distanceMiles=${distanceMiles}`)
  }
}

window._getDistance = getDistance
window._reverseGeocode = reverseGeocode
