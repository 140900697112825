import './style.scss'

import React, { Component } from 'react'
import { Portal } from 'react-portal'
import Button from '../Button/Button'
import { sleep } from '../../../util'

type Props = {
  children: any
}

export default function Screen(props: Props) {
  return (
    <Portal>
      <div className="Screen">{props.children}</div>
    </Portal>
  )
}

type ScreenWithCloseProps = {
  ...Props,
  onClose: Function
}

export class ScreenWithClose extends Component<ScreenWithCloseProps> {
  node: HTMLDivElement

  componentDidMount() {
    this.addListeners()
  }

  componentWillUnmount() {
    this.removeListeners()
  }

  addListeners = () => {
    document.addEventListener('keydown', this.onKeyDown)
  }

  removeListeners = () => {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.close()
    }
  }

  close = async () => {
    this.node.classList.add('closing')
    await sleep(250)
    this.props.onClose()
  }

  render() {
    const { children } = this.props

    return (
      <Portal>
        <div className="Screen ScreenWithClose" ref={node => (this.node = node)}>
          <div className="left">
            <Button icon="x" onClick={this.close} />
          </div>
          {children}
        </div>
      </Portal>
    )
  }
}
