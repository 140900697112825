/* eslint-disable jsx-a11y/alt-text */
import './style.scss'

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import imgSm from './back-sm.svg'
import imgMd from './back-md.svg'
import Button from '../Button/Button'

type Props = {
  label: string,
  to?: string,
  small: Boolean,
  noLabel?: Boolean,
  onClick?: Function
}

export default class BackButton extends Component<Props> {
  render() {
    const { to, label, noLabel, small, onClick } = this.props

    const Component = to ? Link : Button
    const props = to
      ? { to, className: 'BackButton plain' }
      : { onClick, className: 'BackButton' }

    return (
      <Component {...props}>
        <img src={small ? imgSm : imgMd} />
        {noLabel ? null : <label>Back</label>}
      </Component>
    )
  }
}
