import './style.scss'

import React, { Component, Fragment } from 'react'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadPlans } from '../../../store/actions/plans'
import AuthRoute from '../../shared/AuthRoute/AuthRoute'
import PlanList from '../List/PlanList'
import PlanDetail from '../Detail/PlanDetail'
import PlanForm from '../Form/PlanForm'
import TitleBar from '../../shared/TitleBar/TitleBar'
import Plan from '../../../models/Plan'

type Props = {
  plans: Plan[],
  urlPrefix: string,
  match: Object
}

export class Plans extends Component<Props> {
  componentDidMount() {
    const { plans, urlPrefix } = this.props
    if (!plans.length) {
      loadPlans(urlPrefix)
    }
  }

  onSave = (plan: Object) => {
    this.props.history.push(`/plans/${plan.id}`)
  }

  render() {
    const { plans, urlPrefix, match } = this.props
    const { path } = match
    return (
      <div className="Plans">
        <Switch>
          <AuthRoute exact path={path} render={props => <PlanList {...props} plans={plans} />} />
          <AuthRoute
            path={`${path}/new`}
            render={props => (
              <Fragment>
                <TitleBar backTo={path} label="New Plan" />
                <PlanForm {...props} plans={plans} onSave={this.onSave} urlPrefix={urlPrefix} />
              </Fragment>
            )}
          />
          <AuthRoute
            path={`${path}/:planId`}
            render={props => <PlanDetail {...props} plans={plans} urlPrefix={urlPrefix} />}
          />
        </Switch>
      </div>
    )
  }
}

const mapState = state => ({
  plans: state.plans
})

export default connect(mapState)(Plans)
