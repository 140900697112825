import React, { Component } from 'react'
import _ from 'lodash'
import DirtyCar from '../../../../assets/dirtycar1.png'

import './style.scss'

const dots = [
  'serviceOne',
  'serviceTwo',
  'serviceThree',
  'serviceFour',
  'serviceFive',
  'serviceSix'
]

type Props = {
  noButtons: Boolean
}

export class WhyWait extends Component<Props> {
  state = {
    serviceOne: 'active',
    serviceTwo: '',
    serviceThree: '',
    serviceFour: '',
    serviceFive: '',
    serviceSix: ''
  }

  handleSelection = (e, section) => {
    let newState = {
      serviceOne: '',
      serviceTwo: '',
      serviceThree: '',
      serviceFour: '',
      serviceFive: '',
      serviceSix: ''
    }

    newState[section] = 'active showService'

    this.setState(newState)
  }

  onTouchStart = (e: TouchEvent) => {
    this.gesture = {
      startX: e.touches[0].pageX
    }
  }

  onTouchMove = (e: TouchEvent) => {
    if (!this.gesture) {
      return
    }

    this.gesture.lastX = e.touches[0].pageX

    const diff = this.gesture.lastX - this.gesture.startX
    if (diff > 35) {
      this.gesture = null
      this.move(-1)
    } else if (diff < -35) {
      this.gesture = null
      this.move(1)
    }
  }

  onTouchEnd = (e: TouchEvent) => {
    this.gesture = null
  }

  move = (i: Number) => {
    const services = _.invert(this.state)
    const active = services['active'] || services['active showService']
    const index = dots.indexOf(active)

    let newIndex = index + i
    if (newIndex > dots.length - 1) {
      newIndex = 0
    }
    if (newIndex < 0) {
      newIndex = dots.length - 1
    }

    const section = dots[newIndex]
    this.handleSelection(null, section)
  }

  render() {
    const {
      serviceOne,
      serviceTwo,
      serviceThree,
      serviceFour,
      serviceFive,
      serviceSix
    } = this.state

    const touchProps = {
      onTouchStart: this.onTouchStart,
      onTouchMove: this.onTouchMove,
      onTouchEnd: this.onTouchEnd
    }

    return (
      <section className="WhyWait is-full">
        <div className="columns why-wait">
          <div className="column left">
            <div className="inner-content">
              <h3>We Love Our Customers</h3>
              <p>We do the job right</p>
            </div>
          </div>
          <div className="column right">
            <div className="inner-content" {...touchProps}>
              <div className={`service-one ${serviceOne}`}>
                <h3>We focus on the details</h3>
                <p>With a wide range of detail services we can ensure your vehicle stays clean</p>
              </div>
              <div className={`service-two ${serviceTwo}`}>
                <h3>Compressed Air</h3>
                <p>
                  High pressure tools allows us to get the cracks and crevices that others miss.
                </p>
              </div>
              <div className={`service-three ${serviceThree}`}>
                <h3>Steam Cleaning</h3>
                <p>Low moisture steam allows us to clean without using harsh chemicals.</p>
              </div>
              <div className={`service-four ${serviceFour}`}>
                <h3>Wax / Shine</h3>
                <p>Proprietary polymer soap and wax will make your vehicle look brand new.</p>
              </div>
              <div className={`service-five ${serviceFive}`}>
                <h3>Detailing</h3>
                <p>Our professional technicians are the best in the business.</p>
              </div>
              <div className={`service-six ${serviceSix}`}>
                <h3>100% satisfaction guarantee</h3>
                <p>We love our customers.</p>
              </div>
            </div>
          </div>
          <div className="dirty-car" {...touchProps}>
            <img src={DirtyCar} alt="dirty car" />
            <div
              className={`pulse one ${serviceOne}`}
              onClick={e => this.handleSelection(e, 'serviceOne')}>
              <span className="inner-circle" />
            </div>
            <div
              className={`pulse two ${serviceTwo}`}
              onClick={e => this.handleSelection(e, 'serviceTwo')}>
              <span className="inner-circle" />
            </div>
            <div
              className={`pulse three ${serviceThree}`}
              onClick={e => this.handleSelection(e, 'serviceThree')}>
              <span className="inner-circle" />
            </div>
            <div
              className={`pulse four ${serviceFour}`}
              onClick={e => this.handleSelection(e, 'serviceFour')}>
              <span className="inner-circle" />
            </div>
            <div
              className={`pulse five ${serviceFive}`}
              onClick={e => this.handleSelection(e, 'serviceFive')}>
              <span className="inner-circle" />
            </div>
            <div
              className={`pulse six ${serviceSix}`}
              onClick={e => this.handleSelection(e, 'serviceSix')}>
              <span className="inner-circle" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
