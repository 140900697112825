import './style.scss'

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AddButton from '../../shared/AddButton/AddButton'
import TitleBar from '../../shared/TitleBar/TitleBar'
import Address from '../../../models/Address'

type Props = {
  addresses: Address[],
  backTo?: String,
  location: Object,
  history: Object,
  match: Object
}

export default class AddressList extends Component<Props> {
  onAddClick = () => {
    const { location, history } = this.props
    history.push(`${location.pathname}/new`)
  }

  render() {
    const { addresses, backTo, match } = this.props
    const { url } = match

    return (
      <div className="AddressList">
        <TitleBar label="Addresses" backTo={backTo}>
          <AddButton onClick={this.onAddClick} />
        </TitleBar>
        <div className="list list-primary">
          {addresses.map(a => (
            <Link key={a.id} className="plain" to={`${url}/${a.id}`}>
              <span>{a.toString()}</span>
            </Link>
          ))}
        </div>
      </div>
    )
  }
}
