import _ from 'lodash'

const initialState = []

export default function corporateAccounts(state = initialState, action) {
  let corporateAccounts, index
  switch (action.type) {
    case 'SET_CORPORATE_ACCOUNTS':
      corporateAccounts = [...state, ...action.corporateAccounts]
      return _.uniqBy(corporateAccounts, m => m.id)
    case 'SET_CORPORATE_ACCOUNT':
      corporateAccounts = [...state]
      index = _.findIndex(corporateAccounts, m => m.id === action.corporateAccount.id)
      if (index === -1) {
        corporateAccounts.push(action.corporateAccount)
      } else {
        corporateAccounts.splice(index, 1, action.corporateAccount)
      }

      return corporateAccounts
    case 'ADD_CORPORATE_ACCOUNT':
      return [action.corporateAccount].concat(state)
    case 'UPDATE_CORPORATE_ACCOUNT':
      corporateAccounts = [...state]
      index = _.findIndex(corporateAccounts, m => m.id === action.corporateAccount.id)
      corporateAccounts.splice(index, 1, action.corporateAccount)
      return corporateAccounts
    case 'DELETE_CORPORATE_ACCOUNT':
      corporateAccounts = state.filter(m => m.id !== action.corporateAccount.id)
      return corporateAccounts
    default:
      return state
  }
}
