import './style.scss'

import React from 'react'
import { Services } from '../LandingPage/sections/Services'
import { WhyWait } from '../LandingPage/sections/WhyWait'
import { ScheduleMyAppt } from '../LandingPage/sections/ScheduleMyAppt'
import { Process } from '../LandingPage/sections/Process'
import { SkipWait } from '../LandingPage/sections/SkipWait'
import { ShineRiteGreen } from '../LandingPage/sections/ShineRiteGreen'
import { Ratings } from '../LandingPage/sections/Ratings'
import { MobileFriendly } from '../LandingPage/sections/MobileFriendly'
import { isMobile } from '../../util/dom-util'

export default function CorporateLandingPage() {
  return (
    <div className="LandingPage CorporateLandingPage">
      <Services noButtons />
      <WhyWait noButtons />
      {isMobile() ? <ScheduleMyAppt noButtons /> : null}
      <Process noButtons />
      <SkipWait noButtons />
      <ShineRiteGreen noButtons />
      {isMobile() ? null : <Ratings noButtons />}
      {isMobile() ? null : <ScheduleMyAppt noButtons />}
      <MobileFriendly noButtons />
    </div>
  )
}
