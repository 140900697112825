import './style.scss'

import React, { Component } from 'react'
import classNames from 'classnames'

type Props = {
  id: string,
  name: string,
  className: string,
  onSubmit: Function,
  children: any
}

export default class Form extends Component<Props> {
  onSubmit = (e: Event) => {
    e.preventDefault()
    this.props.onSubmit()
  }

  render() {
    const { id, name, className, children } = this.props

    return (
      <form id={id} name={name} className={classNames('Form', className)} onSubmit={this.onSubmit}>
        {children}
      </form>
    )
  }
}
