import './style.scss'

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { loadPlan, getSubscribers } from '../../../store/actions/plans'
import TitleBar from '../../shared/TitleBar/TitleBar'
import PlanForm from '../Form/PlanForm'
import { handleError } from '../../../util/error-util'
import { getFullName } from '../../../util/string-util'
import Plan from '../../../models/Plan'

type Props = {
  plan: ?Object,
  plans: Plan[],
  urlPrefix: string,
  match: Object
}

type State = {
  subscribers: Object[]
}

export class PlanDetail extends Component<Props, State> {
  state = {
    subscribers: null
  }

  async componentDidMount() {
    const { urlPrefix, match } = this.props
    const id = match.params.planId
    loadPlan(id, urlPrefix)

    try {
      const subscribers = await getSubscribers(id, urlPrefix)
      this.setState({ subscribers })
    } catch (err) {
      this.setState({ subscribers: [] })
      handleError(err)
    }
  }

  render() {
    const { plan, plans, urlPrefix } = this.props
    const { subscribers } = this.state

    if (!plan) {
      return 'Loading...'
    }

    return (
      <div className="PlanDetail">
        <TitleBar backTo="/plans" label="Plan Detail" />
        <PlanForm plan={plan} plans={plans} urlPrefix={urlPrefix} />
        {plan.metadata.type === 'PLAN' ? (
          <Fragment>
            <div className="header">Members subscribed to this plan</div>

            {!subscribers ? (
              <div>Loading...</div>
            ) : !subscribers.length ? (
              <div>No subscribers yet</div>
            ) : (
              <div className="subscribers list list-primary">
                {subscribers.map(m => (
                  <Link key={m.id} to={`/members/${m.id}`} className="plain">
                    <span>{getFullName(m)}</span>
                    <span>{m.email}</span>
                  </Link>
                ))}
              </div>
            )}
          </Fragment>
        ) : null}
      </div>
    )
  }
}

const mapState = (state, props) => {
  const id = props.match.params.planId
  const plan = state.plans.find(m => m.id === id)

  return {
    plan
  }
}

export default connect(mapState)(PlanDetail)
