import './style.scss'

import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import { formatMoney } from 'accounting'
import { createPortal } from 'react-dom'
import { Product, EmailUser, PackageDeal, CorporateAccount, Event } from '../../../models'
import { Button, Input } from '../../shared'
import { handleError } from '../../../util/error-util'
import { calculateTotals } from '../../../util/number-util'
import { connect } from 'react-redux'
import { store } from '../../../store/store'
import { checkPromoCode } from '../../../store/actions/shoppingCart'

type Props = {
  selection: Product,
  addOns: Product[],
  packageDeal?: PackageDeal,
  corporateAccount?: CorporateAccount,
  appointmentTime: String,
  appointmentEvent: Event,
  emailuser?: EmailUser,
  onRemoveProduct: Function,
  onRemoveAddOn: Function,
  onDiscountApplied: Function,
  inline?: Boolean,
  showCovidBar?: Boolean,
  onAddPackageDeal: Function,
  onRemovePackageDeal: Function
}

type State = {
  discountCode: String,
  discountAmount: Number,
  discountPercent: Number,
  discountApplied: Boolean,
  saving: Boolean
}

let initialState = {
  discountCode: '',
  discountAmount: 0,
  discountPercent: 0,
  discountApplied: false,
  saving: false
}

export class Cart extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.node = document.createElement('div')
    document.body.appendChild(this.node)

    this.state = {
      ..._.cloneDeep(initialState),
      ...props.shoppingCart
    }

    if (props.emailuser) {
      this.state.discountApplied = true
      this.state.discountCode = '__EMAILUSER__'
      this.state.discountPercent = 0.1
      this.autoApply = true
    }
  }

  componentDidMount() {
    const { shoppingCart } = this.props
    if (shoppingCart.discountCode && !shoppingCart.discountApplied) {
      this.onApplyDiscountClick()
    } else if (this.autoApply) {
      this.initDiscount()
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state !== prevState) {
      initialState = _.cloneDeep(this.state)
    }
  }

  componentWillUnmount() {
    this.node.remove()
  }

  initDiscount = () => {
    const { onDiscountApplied } = this.props
    const { discountCode, discountPercent, discountAmount } = this.state
    onDiscountApplied(discountCode, discountPercent, discountAmount)
  }

  onApplyDiscountClick = () => {
    const { discountApplied, saving } = this.state
    if (discountApplied || saving) {
      return
    }

    this.setState({ saving: true }, async () => {
      const { onAddPackageDeal } = this.props
      const { discountCode } = this.state
      try {
        const res = await checkPromoCode(discountCode)
        if (res.type === 'DISCOUNT') {
          const { discountPercent, discountAmount } = res
          this.setState({ saving: false, discountApplied: true, discountPercent, discountAmount })
          this.applyDiscount(discountCode, discountPercent, discountAmount)
        } else {
          const { packageDeal } = res
          this.setState({ saving: false, discountCode: '' })
          onAddPackageDeal(packageDeal)
        }
      } catch (err) {
        this.setState({ saving: false })
        handleError(err, true)
      }
    })
  }

  applyDiscount = (discountCode, discountPercent, discountAmount) => {
    if (discountPercent) {
      store.dispatch({
        type: 'APPLY_DISCOUNT_PERCENT',
        discountCode,
        discountPercent
      })
    } else {
      store.dispatch({
        type: 'APPLY_DISCOUNT_AMOUNT',
        discountCode,
        discountAmount
      })
    }
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.onApplyDiscountClick()
    }
  }

  render() {
    const {
      selection,
      addOns,
      appointmentTime,
      emailuser,
      onRemoveProduct,
      onRemoveAddOn,
      inline,
      showCovidBar,
      packageDeal,
      onRemovePackageDeal
    } = this.props

    const { discountCode, discountApplied, discountPercent, discountAmount, saving } = this.state

    const removePackageDeal = () => {
      // store.dispatch(removePackageDealFromCart())
      onRemovePackageDeal()
    }

    let filteredAddOns = addOns
    if (packageDeal) {
      const packageDealAddOns = packageDeal.products.filter(p => p.type === 'ADD_ON')
      const packageDealAddOnIds = packageDealAddOns.map(p => p.id)
      filteredAddOns = filteredAddOns.filter(p => !packageDealAddOnIds.includes(p.id))
    }

    const { subtotal, total } = calculateTotals(
      selection,
      filteredAddOns,
      discountPercent,
      discountAmount,
      packageDeal
    )

    const ui = (
      <div className={classNames('Cart', { inline, 'show-covid-bar': showCovidBar })}>
        <h3>Cart</h3>

        {packageDeal && (
          <div className="packagedeal">
            <h4>PACKAGE DEAL</h4>
            <div className="gridfields">
              {_.sortBy(packageDeal.products, p => (Product.isWash(p.type) ? -1 : 1)).map(
                (product, i) => (
                  <div>
                    <span>{Product.isWash(product.type) ? 'Service' : 'Add-on'}</span>
                    <span>{product.name}</span>
                  </div>
                )
              )}
              <div>
                <span>Price</span>
                <span>{formatMoney(packageDeal.amount / 100)}</span>
              </div>
              {appointmentTime ? (
                <div>
                  <span>Date</span>
                  <span>{moment(appointmentTime).format('dddd, MMM. D h:mmA')}</span>
                </div>
              ) : null}
            </div>
            <div className="footer">
              <Button type="link" danger onClick={removePackageDeal}>
                Remove
              </Button>
            </div>
          </div>
        )}

        {selection && (
          <div className="selection">
            <h4>SELECTION</h4>
            <div className="gridfields">
              <div>
                <span>Name</span>
                <span>{selection.name}</span>
              </div>
              <div>
                <span>Price</span>
                <span>{formatMoney(selection.amount / 100)}</span>
              </div>
              {appointmentTime ? (
                <div>
                  <span>Date</span>
                  <span>{moment(appointmentTime).format('dddd, MMM. D h:mmA')}</span>
                </div>
              ) : null}
            </div>
            <div className="footer">
              <Button type="link" danger onClick={onRemoveProduct}>
                Remove
              </Button>
            </div>
          </div>
        )}

        {filteredAddOns.length ? (
          <div className="addons">
            <h4>ADD-ONS</h4>
            {filteredAddOns.map(p => (
              <div key={p.id} className="addon">
                <div className="gridfields">
                  <div>
                    <span>Name</span>
                    <span>{p.name}</span>
                  </div>
                  <div>
                    <span>Price</span>
                    <span>{formatMoney(p.amount / 100)}</span>
                  </div>
                </div>
                <div className="footer">
                  <Button type="plain" onClick={() => onRemoveAddOn(p)}>
                    Remove
                  </Button>
                </div>
              </div>
            ))}
          </div>
        ) : null}

        {emailuser || packageDeal ? null : (
          <div className="discount-section">
            <h4>PROMO CODE</h4>
            <div className="discount-input">
              <Input
                disabled={saving || discountApplied}
                label="Promo code"
                value={discountCode}
                onChange={e => this.setState({ discountCode: e.target.value })}
                onKeyDown={this.onKeyDown}
              />
              <Button
                disabled={saving || discountApplied}
                type="primary"
                onClick={this.onApplyDiscountClick}>
                Apply
              </Button>
            </div>
          </div>
        )}

        <div className="totals">
          <div className="gridfields">
            {discountApplied ? (
              <Fragment>
                <div className="subtotal">
                  <span>Subtotal</span>
                  <span>{formatMoney(subtotal / 100)}</span>
                </div>
                <div className="discount-applied">
                  <span>Discount</span>
                  {discountPercent ? <span>-{Math.round(discountPercent * 100)}%</span> : null}
                  {discountAmount ? <span>-{formatMoney(discountAmount / 100)}</span> : null}
                </div>
              </Fragment>
            ) : null}
            <div className="total">
              <span>Total</span>
              <span>{formatMoney(total / 100)}</span>
            </div>
          </div>
        </div>
      </div>
    )

    return inline || window.innerWidth <= 999 ? ui : createPortal(ui, this.node)
  }
}

const mapState = state => {
  const { app, shoppingCart } = state
  const { showCovidBar } = app

  return {
    shoppingCart,
    showCovidBar
  }
}

export default connect(mapState)(Cart)
