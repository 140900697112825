import { Product } from '../models'

export function addPurchaseToDataLayer(
  transactionId: String,
  transactionTotal: String,
  transactionAffiliation: String,
  products: Product[]
) {
  try {
    const gaProducts = products.map(p => ({
      name: p.name,
      sku: String(p.id),
      price: p.amount,
      quantity: 1
    }))

    const data = {
      transactionId,
      transactionTotal,
      transactionAffiliation,
      products: gaProducts
    }

    console.log('passing data to dataLayer: ', data)
    window.dataLayer.push(data)
  } catch (err) {
    console.error(err)
  }
}
