import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Video from '../../../../assets/carwashv2.mp4'
import { ReactComponent as DownArrow } from '../../../../assets/downwards-arrow-key.svg'
import LogoBanner from '../../../../assets/logo-color.png'

import './style.scss'

type Props = {
  ctaUrl: String
}

export class Hero extends Component<Props> {
  scrolled: Boolean = false

  state = {
    menu: ''
  }

  handleClick = () => {
    const menu = this.state.menu === '' ? 'active' : ''

    this.setState({ menu })
  }

  render() {
    const { ctaUrl } = this.props

    return (
      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column hero-tag is-half">
                <div className="desktop-logo">
                  <img
                    src={LogoBanner}
                    // src={logoSrc}
                    alt="logo"
                  />
                  <div className="banner-text">
                    <h1 className="bold-font-text">Time To Get Your Car Shining Rite!</h1>
                    <small>We come to you with zero contact</small>
                  </div>
                  <div className="signup-wrapper">
                    <Link id="book-now-2" to={ctaUrl} className="button primary signup">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="mobile-logo">
                  <img src={LogoBanner} alt="logo" />
                  <h1>Time To Get Your Car Shining Rite!</h1>
                  <small>We come to you with zero contact</small>
                </div>
                <div className="cta">
                  <Link id="book-now-3" to={ctaUrl} className="button primary signup">
                    Learn More
                  </Link>
                </div>
                <div className="arrow-one">
                  <DownArrow />
                </div>
                <div className="arrow-two">
                  <DownArrow />
                </div>
                <div className="arrow-three">
                  <DownArrow />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slanted">
          {process.env.NODE_ENV === 'production' ? (
            <video autoPlay playsInline muted loop preload="true">
              <source src={Video} />
            </video>
          ) : null}
        </div>
      </section>
    )
  }
}
