import _ from 'lodash'

const initialState = []

export default function reservations(state = initialState, action) {
  let reservations, index
  switch (action.type) {
    case 'SET_RESERVATIONS':
      reservations = [...state, ...action.reservations]
      return _.uniqBy(reservations, m => m.id)
    case 'SET_RESERVATION':
      reservations = [...state]
      index = _.findIndex(reservations, m => m.id === action.reservation.id)
      if (index === -1) {
        reservations.push(action.reservation)
      } else {
        reservations.splice(index, 1, action.reservation)
      }

      return reservations
    case 'ADD_RESERVATION':
      return [action.reservation].concat(state)
    case 'UPDATE_RESERVATION':
      reservations = [...state]
      index = _.findIndex(reservations, m => m.id === action.reservation.id)
      reservations.splice(index, 1, action.reservation)
      return reservations
    case 'DELETE_RESERVATION':
      reservations = state.filter(m => m.id !== action.reservation.id)
      return reservations
    default:
      return state
  }
}
