import './style.scss'

import React from 'react'

type Props = {
  children: Function
}

export default function ActionBar(props: Props) {
  return <div className="ActionBar">{props.children}</div>
}
