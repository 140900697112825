import './style.scss'

import React, { Component, Fragment } from 'react'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadProducts } from '../../../store/actions/products'
import AuthRoute from '../../shared/AuthRoute/AuthRoute'
import ProductList from '../List/ProductList'
import ProductDetail from '../Detail/ProductDetail'
import ProductForm from '../Form/ProductForm'
import TitleBar from '../../shared/TitleBar/TitleBar'
import Plan from '../../../models/Plan'
import { loadPlans } from '../../../store/actions/plans'

type Props = {
  plans: Plan[],
  products: Object[],
  urlPrefix: string,
  match: Object
}

export class Products extends Component<Props> {
  componentDidMount() {
    const { products, plans, urlPrefix } = this.props
    if (!products.length) {
      loadProducts(urlPrefix)
    }

    if (!plans.length) {
      loadPlans(urlPrefix)
    }
  }

  onSave = (product: Object) => {
    this.props.history.push(`/products/${product.id}`)
  }

  render() {
    const { plans, products, urlPrefix, match } = this.props
    const { path } = match

    return (
      <div className="Products">
        <Switch>
          <AuthRoute
            exact
            path={path}
            render={props => <ProductList {...props} products={products} />}
          />
          <AuthRoute
            path={`${path}/new`}
            render={props => (
              <Fragment>
                <TitleBar backTo={path} label="New Product" />
                <ProductForm
                  {...props}
                  products={products}
                  plans={plans}
                  onSave={this.onSave}
                  urlPrefix={urlPrefix}
                />
              </Fragment>
            )}
          />
          <AuthRoute
            path={`${path}/:productId`}
            render={props => (
              <ProductDetail {...props} products={products} plans={plans} urlPrefix={urlPrefix} />
            )}
          />
        </Switch>
      </div>
    )
  }
}

const mapState = state => ({
  plans: state.plans,
  products: state.products
})

export default connect(mapState)(Products)
