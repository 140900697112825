import './style.scss'

import React, { Component } from 'react'
import { formatMoney } from 'accounting'
import classNames from 'classnames'
import Product from '../../../models/Product'
import { ReactComponent as BottleIcon } from '../../../assets/carwash-icon-bottle.svg'
import { ReactComponent as BottleIcon2 } from '../../../assets/carwash-icon-bottle-2.svg'
import { ReactComponent as BottleIcon3 } from '../../../assets/carwash-icon-bottle-3.svg'
import { ReactComponent as BottleIcon4 } from '../../../assets/carwash-icon-bottle-4.svg'
import { ReactComponent as BrushIcon } from '../../../assets/carwash-icon-brush.svg'
import { ReactComponent as BucketIcon } from '../../../assets/carwash-icon-bucket.svg'
import { ReactComponent as BucketIcon2 } from '../../../assets/carwash-icon-bucket-2.svg'
import { ReactComponent as ExteriorIcon } from '../../../assets/carwash-icon-exterior.svg'
import { ReactComponent as ExteriorIcon2 } from '../../../assets/carwash-icon-exterior-2.svg'
import { ReactComponent as HandIcon } from '../../../assets/carwash-icon-hand.svg'
import { ReactComponent as HandIcon2 } from '../../../assets/carwash-icon-hand-2.svg'
import { ReactComponent as PersonIcon } from '../../../assets/carwash-icon-person.svg'
import { ReactComponent as PressureWasherIcon } from '../../../assets/carwash-icon-pressurewasher.svg'
import { ReactComponent as SeatIcon } from '../../../assets/carwash-icon-seat.svg'
import { ReactComponent as ShineIcon } from '../../../assets/carwash-icon-shine.svg'
import { ReactComponent as VacuumIcon } from '../../../assets/carwash-icon-vacuum.svg'
import { ReactComponent as VacuumIcon2 } from '../../../assets/carwash-icon-vacuum-2.svg'
import { ReactComponent as VacuumIcon3 } from '../../../assets/carwash-icon-vacuum-3.svg'
import { ReactComponent as WindowIcon } from '../../../assets/carwash-icon-window.svg'
import { Tooltip } from '../../shared'

const icons = {
  BottleIcon,
  BottleIcon2,
  BottleIcon3,
  BottleIcon4,
  BrushIcon,
  BucketIcon,
  BucketIcon2,
  ExteriorIcon,
  ExteriorIcon2,
  HandIcon,
  HandIcon2,
  PersonIcon,
  PressureWasherIcon,
  SeatIcon,
  ShineIcon,
  VacuumIcon,
  VacuumIcon2,
  VacuumIcon3,
  WindowIcon
}

type Props = {
  addOn: Product,
  selectedAddOns: Product[],
  fixedAddOns: Product[],
  onChange: Function,
  color: String
}

const TOOLTIP_TEXT = 'This add-on is included in the package deal'

export default class AddOn extends Component<Props, State> {
  render() {
    const { addOn, selectedAddOns, fixedAddOns, color, onChange } = this.props

    const selected = selectedAddOns.map(p => p.id).includes(addOn.id)
    const fixedAddOnIds = fixedAddOns.map(p => p.id)
    const Icon = icons[addOn.icon_position]
    const colorKey = color.replace('#', '')
    const isFixed = fixedAddOnIds.includes(addOn.id)
    const disabled = !addOn.amount || isFixed

    const ui = (
      <div
        className={classNames('AddOn', `color-${colorKey}`, {
          selected,
          disabled
        })}
        onClick={!disabled ? () => onChange(addOn, !selected) : undefined}>
        <div className="img-row">{Icon ? <Icon /> : null}</div>
        <div className="title">{addOn.name}</div>
        <div className="price">
          {addOn.amount > 0 ? formatMoney(addOn.amount / 100).replace('.00', '') : '(included)'}
        </div>
      </div>
    )

    return isFixed ? <Tooltip text={TOOLTIP_TEXT}>{ui}</Tooltip> : ui
  }
}
