import WorkSchedule from './WorkSchedule'
import BaseModel from './BaseModel'

export default class User extends BaseModel {
  email: String
  first_name: String
  id: String
  isVerified: String
  last_name: String
  org: String
  phone: String
  photo: String
  type: String
  workschedule: WorkSchedule

  constructor(data: Object = {}) {
    super(data)
    this.workschedule = new WorkSchedule(data && data.workschedule ? data.workschedule : undefined)
  }

  getFullName() {
    return `${this.first_name} ${this.last_name}`.trim() || this.email
  }

  toString() {
    return this.getFullName()
  }
}
