import _ from 'lodash'
import { getFullName } from '../../util/string-util'

const initialState = []

function sort(customers) {
  return _.sortBy(customers, m => getFullName(m))
}

export default function customers(state = initialState, action) {
  let customers, index
  switch (action.type) {
    case 'SET_CUSTOMERS':
      customers = action.customers
      return sort(_.uniqBy(customers, m => m.id))
    case 'SET_CUSTOMER':
      customers = [...state]
      index = _.findIndex(customers, m => m.id === action.customer.id)
      if (index === -1) {
        customers.push(action.customer)
      } else {
        customers.splice(index, 1, action.customer)
      }

      return sort(customers)
    case 'ADD_CUSTOMER':
      return sort([action.customer].concat(state))
    case 'UPDATE_CUSTOMER':
      customers = [...state]
      index = _.findIndex(customers, m => m.id === action.customer.id)
      customers.splice(index, 1, action.customer)
      return sort(customers)
    case 'DELETE_CUSTOMER':
      customers = state.filter(m => m.id !== action.customer.id)
      return customers
    default:
      return state
  }
}
