import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import Form from '../../shared/Form/Form'
import Field from '../../shared/Field/Field'
import { saveUserActivity, deleteUserActivity } from '../../../store/actions/userActivities'
import { handleError } from '../../../util/error-util'
import { toast } from '../../../store/actions/app'
import DeleteButton from '../../shared/DeleteButton/DeleteButton'
import confirm from '../../shared/confirm/confirm'
import ActionBar from '../../shared/ActionBar/ActionBar'
import history from '../../../history'
import UserActivity from '../../../models/UserActivity'
import moment from 'moment'
import { DATETIME_FORMAT } from '../../../contants'

type Props = {
  userActivity?: UserActivity,
  urlPrefix: string,
  onSave: Function
}

type State = {
  userActivity: ?UserActivity,
  saving: boolean
}

export default class UserActivityForm extends Component<Props, State> {
  state = {
    userActivity: null,
    saving: false
  }

  static defaultProps = {
    onSave: _.noop
  }

  componentDidMount() {
    this.setUserActivity()
  }

  componentDidUpdate(prevProps) {
    if (this.props.userActivity !== prevProps.userActivity) {
      this.setUserActivity()
    }
  }

  setUserActivity = () => {
    this.setState({ userActivity: new UserActivity(this.props.userActivity) })
  }

  onChange = (prop: string, value: any) => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.userActivity[prop] = value
    this.forceUpdate()
  }

  onSubmit = async () => {
    try {
      const { urlPrefix, onSave } = this.props
      const { userActivity } = this.state
      this.setState({ saving: true })

      toast('Saving...')
      const result = await saveUserActivity(userActivity, urlPrefix)
      toast('Saved')

      this.setState({ saving: false })
      onSave(result)
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  onDeleteClick = async () => {
    try {
      if (!(await confirm())) {
        return
      }

      const { urlPrefix } = this.props
      const { userActivity } = this.state

      this.setState({ saving: true })

      toast('Saving...')
      await deleteUserActivity(userActivity, urlPrefix)
      toast('Saved')

      history.goToListView()
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  render() {
    const { userActivity, saving } = this.state

    if (!userActivity) {
      return null
    }

    return (
      <div className="UserActivityForm">
        <Form onSubmit={this.onSubmit}>
          <Field
            type="text"
            label="Timestamp:"
            value={moment(userActivity.created_date).format(DATETIME_FORMAT)}
            disabled
          />
          <Field
            type="text"
            label="User:"
            value={userActivity.data ? userActivity.data.auid : ''}
            disabled
          />
          <Field type="text" label="Type:" value={userActivity.type} disabled />
          <Field
            className="jsondata"
            type="textarea"
            label="Data:"
            value={JSON.stringify(userActivity.data, null, 2)}
            disabled
          />
          <ActionBar>
            {userActivity.id ? (
              <DeleteButton onClick={this.onDeleteClick} disabled={saving} />
            ) : null}
          </ActionBar>
        </Form>
      </div>
    )
  }
}
