import './style.scss'

import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadEvents } from '../../../store/actions/events'
import AuthRoute from '../../shared/AuthRoute/AuthRoute'
import EventList from '../List/EventList'
import EventDetail from '../Detail/EventDetail'
import EventForm from '../Form/EventForm'
import { loadCustomers } from '../../../store/actions/customers'
import { loadEmployees } from '../../../store/actions/employees'
import TitleBar from '../../shared/TitleBar/TitleBar'
import { Event, User, Product, CorporateAccount } from '../../../models'
import { loadProducts } from '../../../store/actions/products'
import { loadCorporateAccounts } from '../../../store/actions/corporateAccounts'

type Props = {
  events: Event[],
  customers: User[],
  employees: User[],
  products: Product[],
  corporateAccounts: CorporateAccount[],
  urlPrefix: string,
  match: Object
}

export class Events extends Component<Props> {
  componentDidMount() {
    const { customers, employees, products, corporateAccounts, urlPrefix } = this.props
    loadEvents(
      urlPrefix,
      moment().startOf('month').add(-1, 'weeks').toDate(),
      moment().endOf('month').add(1, 'weeks').toDate()
    )

    if (!customers.length) {
      loadCustomers(urlPrefix)
    }

    if (!employees.length) {
      loadEmployees(urlPrefix)
    }

    if (!products.length) {
      loadProducts(urlPrefix)
    }

    if (!corporateAccounts.length) {
      loadCorporateAccounts(urlPrefix)
    }
  }

  onSave = (event: Object) => {
    this.props.history.push(`/events/${event.id}`)
  }

  render() {
    const { events, urlPrefix, match } = this.props
    const { path } = match

    return (
      <div className="Events">
        <Switch>
          <AuthRoute
            exact
            path={path}
            render={props => <EventList {...props} events={events} urlPrefix={urlPrefix} />}
          />
          <AuthRoute
            path={`${path}/new`}
            render={props => (
              <Fragment>
                <TitleBar backTo={path} label="New Event" />
                <EventForm {...props} onSave={this.onSave} urlPrefix={urlPrefix} />
              </Fragment>
            )}
          />
          <AuthRoute
            path={`${path}/:eventId`}
            render={props => <EventDetail {...props} urlPrefix={urlPrefix} />}
          />
        </Switch>
      </div>
    )
  }
}

const mapState = state => ({
  events: state.events,
  customers: state.customers,
  employees: state.employees,
  products: state.products,
  corporateAccounts: state.corporateAccounts
})

export default connect(mapState)(Events)
