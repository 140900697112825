import React from 'react'
import IconPay from '../../../../assets/icon-pay.png'
import IconShine from '../../../../assets/icon-shine.png'
import IconSchedule from '../../../../assets/icon-schedule.png'
import IconChooseService from '../../../../assets/icon-choose-service.png'
import { isMobile } from '../../../../util/dom-util'

import './style.scss'

type Props = {
  noButtons: Boolean
}

export const Process = (props: Props) => {
  return (
    <div className="Process">
      <div className="container">
        <div className="columns">
          <div className="column">
            <h1 className="simple-process">Our Process Is Simple</h1>
          </div>
        </div>

        {isMobile() ? (
          <div className="columns">
            <div className="column">
              <ul className="progressbar">
                <li className="active">
                  <div className="column step">
                    <img src={IconChooseService} alt="IconChooseService" />
                    <p>Choose your service</p>
                  </div>
                </li>
                <li className="active">
                  <div className="column step">
                    <img src={IconSchedule} alt="IconSchedule" />
                    <p>Book your appointment around your schedule</p>
                  </div>
                </li>
                <li className="active">
                  <div className="column step">
                    <img src={IconShine} alt="IconShine" />
                    <p>We make your car SHINE</p>
                  </div>
                </li>
                <li className="active">
                  <div className="column step">
                    <img src={IconPay} alt="IconPay" />
                    <p>Pay & tip online</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <>
            <div className="columns extra-padding">
              <div className="column">
                <ul className="progressbar">
                  <li className="active"></li>
                  <li className="active"></li>
                  <li className="active"></li>
                  <li className="active"></li>
                </ul>
              </div>
            </div>

            <div className="columns extra-padding content">
              <div className="column step">
                <img src={IconChooseService} alt="IconChooseService" />
                <p>Choose your service</p>
              </div>
              <div className="column step">
                <img src={IconSchedule} alt="IconSchedule" />
                <p>Book your appointment around your schedule</p>
              </div>
              <div className="column step">
                <img src={IconShine} alt="IconShine" />
                <p>We make your car SHINE</p>
              </div>
              <div className="column step">
                <img src={IconPay} alt="IconPay" />
                <p>Pay & tip online</p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
