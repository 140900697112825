import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadCorporateAccount } from '../../../store/actions/corporateAccounts'
import TitleBar from '../../shared/TitleBar/TitleBar'
import CorporateAccountForm from '../Form/CorporateAccountForm'
import CorporateAccount from '../../../models/CorporateAccount'

type Props = {
  corporateAccount: ?CorporateAccount,
  urlPrefix: string,
  match: Object
}

type State = {}

export class CorporateAccountDetail extends Component<Props, State> {
  state = {}

  async componentDidMount() {
    const { urlPrefix, match } = this.props
    const id = match.params.corporateAccountId
    loadCorporateAccount(id, urlPrefix)
  }

  render() {
    const { corporateAccount, urlPrefix } = this.props

    if (!corporateAccount) {
      return 'Loading...'
    }

    return (
      <div className="CorporateAccountDetail">
        <TitleBar backTo="/corporate-accounts" label="Account Detail" />
        <CorporateAccountForm corporateAccount={corporateAccount} urlPrefix={urlPrefix} />
      </div>
    )
  }
}

const mapState = (state, props) => {
  const id = Number(props.match.params.corporateAccountId)
  const corporateAccount = state.corporateAccounts.find(m => m.id === id)

  return {
    corporateAccount
  }
}

export default connect(mapState)(CorporateAccountDetail)
