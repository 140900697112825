import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { Portal } from 'react-portal'

type Props = {
  value: any,
  checked?: boolean,
  type: string,
  name: string,
  label: string,
  placeholder?: string,
  autoComplete?: string,
  autoCapitalize?: string,
  autoFocus?: boolean,
  options?: Object[],
  onChange: Function,
  onSelect: Function
}

type State = {
  type: String,
  autocomplete: Boolean,
  showSuggestions: Boolean
}

const NUMBER_TYPES = ['num', 'number', 'currency', 'percent']

let _id = 1

export default class Input extends Component<Props, State> {
  inputNode: HTMLInputElement
  justClicked = false

  static defaultProps = {
    onSelect: function noop() {}
  }

  constructor(props: Props) {
    super(props)
    this.id = `Input_${_id++}`

    this.state = {
      type: props.type === 'autocomplete' ? 'text' : null,
      autocomplete: props.type === 'autocomplete',
      showSuggestions: false
    }
  }

  componentDidMount() {
    this.checkFile()
  }

  componentDidUpdate(prevProps: Props) {
    const { value } = this.props
    this.checkFile()
    if (this.state.autocomplete) {
      if (value && value !== prevProps.value && !this.state.showSuggestions && !this.justClicked) {
        this.setState({ showSuggestions: true })
      }
    }
  }

  checkFile = () => {
    const { type, value } = this.props
    if (type === 'file' && value[0] && this.inputNode && !this.inputNode.files.length) {
      const file = new File([], _.last(value[0].split('/')))
      const dt = new DataTransfer()
      dt.items.add(file)
      this.inputNode.files = dt.files
    }
  }

  renderAutocomplete = () => {
    const node = this.inputNode
    if (!node) {
      return null
    }

    const { showSuggestions } = this.state
    if (!showSuggestions) {
      return null
    }

    const { options } = this.props
    if (!options.length) {
      return null
    }

    const rect = node.getBoundingClientRect()
    const style = {
      top: rect.bottom - 15,
      left: rect.left,
      width: rect.width
    }

    const onBackgroundClick = (e: Event) => {
      if (e.target === e.currentTarget) {
        this.setState({ showSuggestions: false })
      }
    }

    const onClick = option => {
      this.justClicked = true
      this.setState({ showSuggestions: false })
      this.props.onChange({ target: { value: option.label } })
      this.props.onSelect(option)
      setTimeout(() => (this.justClicked = false), 150)
    }

    return (
      <Portal>
        <div className="Input_Portal" onClick={onBackgroundClick}>
          <ul style={style}>
            {options.map((o, i) => (
              <li key={i} onClick={() => onClick(o)}>
                {o.label}
              </li>
            ))}
          </ul>
        </div>
      </Portal>
    )
  }

  render() {
    const { label, className, options, onSelect, ...props } = this.props
    const { type, autocomplete } = this.state

    let extraProps = {}
    if (props.type === 'file') {
      extraProps.value = undefined
    }

    if (NUMBER_TYPES.includes(props.type)) {
      props.onBlur = () => {
        const { value, onChange } = props
        if (typeof value === 'string') {
          const parsedValue = parseFloat(value)
          if (!isNaN(parsedValue)) {
            onChange({ target: { value: parsedValue } })
          }
        }
      }
    }

    return (
      <div
        className={classNames('Input', className, {
          'has-value': Boolean(props.value)
        })}>
        {props.type === 'currency' ? <span className="dollarsign">$</span> : null}
        {props.type === 'percent' ? <span className="percentsign">%</span> : null}
        {props.type === 'textarea' ? (
          <textarea id={this.id} {...props} type={undefined} />
        ) : (
          <>
            <input
              id={this.id}
              {...props}
              {...extraProps}
              type={type || props.type}
              ref={node => (this.inputNode = node)}
              onFocus={() => {
                if (autocomplete) {
                  this.setState({ showSuggestions: true })
                }
              }}
            />
            {autocomplete ? this.renderAutocomplete() : null}
          </>
        )}
        <label htmlFor={this.id}>{label}</label>
        {props.type === 'password' ? (
          <span className="toggle" onClick={() => this.setState({ type: type ? null : 'text' })}>
            {type === 'text' ? 'Hide' : 'Show'}
          </span>
        ) : null}
      </div>
    )
  }
}
