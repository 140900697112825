export default class Product {
  id: Number
  name: String
  description: String
  type: String
  amount: Number
  currency: String
  interval: String
  trialAmount: Number
  duration: Number
  trialDuration: Number
  active: Boolean
  public: Boolean
  sort_order: Number
  icon_position: String
  parentId: String
  parent: String
  add_ons: Product[]

  constructor({
    id = '',
    name = '',
    description = '',
    type = 'WASH',
    amount = '',
    currency = 'USD',
    interval = '',
    trialAmount = 0,
    duration = 0,
    trialDuration = 0,
    active = true,
    isPublic = true,
    sort_order = 0,
    icon_position = '',
    parentId = '',
    parent = '',
    add_ons = []
  } = {}) {
    this.id = id
    this.name = name
    this.description = description
    this.type = type
    this.amount = amount
    this.currency = currency
    this.interval = interval
    this.trialAmount = trialAmount
    this.duration = duration
    this.trialDuration = trialDuration
    this.active = active
    this.public = isPublic
    this.sort_order = sort_order
    this.icon_position = icon_position
    this.parentId = parentId
    this.parent = parent
    this.add_ons = add_ons ? add_ons.map(addon => new Product(addon)) : []
  }

  static isWash(type: string) {
    return /(WASH|DETAIL|SPECIAL)/.test(type)
  }
}
