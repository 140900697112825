import './style.scss'

import React, { Component } from 'react'
import { Portal } from 'react-portal'

type Props = {
  text: string,
  children: any
}

type State = {
  show: Boolean,
  style: Object
}

export default class Tooltip extends Component<Props, State> {
  node: HTMLElement

  state = {
    show: false,
    style: {}
  }

  onRef = (node: HTMLElement) => {
    if (node) {
      this.addListeners(node)
    } else {
      this.removeListeners()
    }

    this.node = node
  }

  componentWillUnmount() {
    this.removeListeners()
  }

  addListeners = (node: HTMLElement) => {
    node.addEventListener('mouseenter', this.onMouseEnter)
    node.addEventListener('mouseleave', this.onMouseLeave)
  }

  removeListeners = () => {
    const node = this.node
    if (!node) {
      return
    }

    node.addEventListener('mouseenter', this.onMouseEnter)
    node.addEventListener('mouseleave', this.onMouseLeave)
  }

  onMouseEnter = (e: Event) => {
    const rect = e.currentTarget.getBoundingClientRect()
    const top = rect.bottom
    const left = rect.left + rect.width / 2
    const style = {
      top,
      left
    }

    this.setState({ show: true, style })
  }

  onMouseLeave = (e: Event) => {
    this.setState({ show: false, style: {} })
  }

  render() {
    const { text, children } = this.props
    const { show, style } = this.state

    return (
      <>
        {React.cloneElement(children, { ref: this.onRef })}
        {show ? (
          <Portal>
            <div className="Tooltip" style={style}>
              <svg>
                <path d="M 5 0 L 10 5 L 0 5 L 5 0" />
              </svg>
              <div className="content">{text}</div>
            </div>
          </Portal>
        ) : null}
      </>
    )
  }
}
