import '../node_modules/@fortawesome/fontawesome-free/css/all.css'
import './antd.scss'
import './index.scss'
import './theme.scss'
import './animations.scss'

import 'url-search-params-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import axios from 'axios'
import { Provider } from 'react-redux'
import debug from 'debug'
import App from './components/App/App'
import { store } from './store/store'
import history from './history'

window._debug = debug
window._axios = axios

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = '/api'
} else {
  const { protocol, hostname } = window.location
  const baseURL = `${protocol}//${hostname}:8000/api`

  axios.defaults.baseURL = baseURL
  axios.defaults.withCredentials = true
  window._axios = axios
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}
