import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import _debug from 'debug'
import Calendar from '../../shared/Calendar/Calendar'
import EventForm from '../Form/EventForm'
import { loadEvents } from '../../../store/actions/events'

const debug = _debug('carwash:components:events')

type Props = {
  events: Object[],
  urlPrefix: string,
  location: Object,
  history: Object,
  match: Object
}

export default class EventList extends Component<Props> {
  intervalId = null

  componentDidMount() {
    debug('[componentDidMount] starting refresh timer')
    this.intervalId = setInterval(() => {
      debug('[componentDidMount] refreshing')
      loadEvents.refresh()
    }, 1000 * 60)
  }

  componentWillUnmount() {
    debug('[componentWillUnmount] stopping refresh timer')
    clearInterval(this.intervalId)
  }

  onRangeChange = (range: Object | Array) => {
    const { urlPrefix } = this.props

    let start, end
    if (Array.isArray(range)) {
      start = _.first(range)
      end = _.last(range)
    } else {
      start = range.start
      end = range.end
    }

    loadEvents(urlPrefix, start, end)
  }

  renderForm = (event: Object) => {
    const { urlPrefix } = this.props

    return <EventForm event={event} onSave={this.onSave} urlPrefix={urlPrefix} />
  }

  render() {
    const { events } = this.props

    return (
      <div className="EventList">
        <Calendar events={events} renderForm={this.renderForm} onRangeChange={this.onRangeChange} />
      </div>
    )
  }
}
