import './style.scss'

import React, { Component, Fragment } from 'react'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadCustomers } from '../../../store/actions/customers'
import AuthRoute from '../../shared/AuthRoute/AuthRoute'
import CustomerList from '../List/CustomerList'
import CustomerDetail from '../Detail/CustomerDetail'
import CustomerForm from '../Form/CustomerForm'
import TitleBar from '../../shared/TitleBar/TitleBar'

type Props = {
  customers: Object[],
  urlPrefix: string,
  match: Object
}

export class Customers extends Component<Props> {
  componentDidMount() {
    if (!this.props.customers.length) {
      loadCustomers(this.props.urlPrefix)
    }
  }

  onSave = async (customer: Object) => {
    this.props.history.push(`/customers/${customer.id}`)
  }

  render() {
    const { customers, urlPrefix, match } = this.props
    const { path } = match
    return (
      <div className="Customers">
        <Switch>
          <AuthRoute
            exact
            path={path}
            render={props => (
              <CustomerList
                {...props}
                customers={customers}
                urlPrefix={urlPrefix}
              />
            )}
          />
          <AuthRoute
            path={`${path}/new`}
            render={props => (
              <Fragment>
                <TitleBar backTo={path} label="New Customer" />
                <CustomerForm
                  {...props}
                  onSave={this.onSave}
                  urlPrefix={urlPrefix}
                />
              </Fragment>
            )}
          />
          <AuthRoute
            path={`${path}/:customerId`}
            render={props => (
              <CustomerDetail {...props} urlPrefix={urlPrefix} />
            )}
          />
        </Switch>
      </div>
    )
  }
}

const mapState = state => ({
  customers: state.customers
})

export default connect(mapState)(Customers)
