import './style.scss'

import React from 'react'
import Icon5star from '../../../../assets/icon-5star.png'
import reviews from '../../../../assets/reviews.png'
import { isMobile } from '../../../../util/dom-util'

type Props = {
  ctaUrl: String,
  noButtons: Boolean
}

export const Ratings = (props: Props) => {
  return (
    <div className="Ratings">
      <div className="container">
        <div className="columns simple-Ratings-col">
          <div className="column">
            <img src={Icon5star} className="rating-img" alt="Icon5star" />

            <h1 className="simple-Ratings">Shine Rite is rated 5.0/5.0 on social ratings sites</h1>
          </div>
        </div>
        {isMobile() ? (
          <div className="reviews-wrapper">
            <img className="reviews" src={reviews} alt="reviews" />
          </div>
        ) : (
          <div className="columns extra-padding content">
            <div className="column reviews-column">
              <img className="reviews" src={reviews} alt="reviews" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
