import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { loadCarsForCustomer } from '../../../store/actions/cars'
import Car from '../../../models/Car'
import User from '../../../models/User'

type Props = {
  customer: User,
  cars: Car[],
  urlPrefix: String
}

export class CarList extends Component<Props> {
  componentDidMount() {
    const { customer, urlPrefix } = this.props
    loadCarsForCustomer(customer.id, urlPrefix)
  }

  render() {
    const { cars } = this.props

    return (
      <Fragment>
        <div className="header">Vehicles</div>
        <div className="list list-primary">
          {cars.map((car, i) => (
            <div key={i} className="car">
              <span>{car.toString()}</span>
              <span>{car.is_default ? <span className="default">(Default)</span> : null}</span>
            </div>
          ))}
        </div>
      </Fragment>
    )
  }
}

const mapState = state => ({
  cars: state.cars
})

export default connect(mapState)(CarList)
