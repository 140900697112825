import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import LandingPage from '../LandingPage/LandingPage'
import UserSignupPage from '../UserSignupPage/UserSignupPage'
import CorporateAccountSignUpPage from '../CorporateAccountSignUp'
import OrgAdminDashboard from '../dashboards/OrgAdminDashboard/OrgAdminDashboard'
import Login from '../auth/Login/Login'
import ForgotPassword from '../auth/ForgotPassword/ForgotPassword'
import ResetPassword from '../auth/ResetPassword/ResetPassword'
import AccountCreated from '../Account/AccountCreated'
import OurProcess from '../LandingPage/OurProcess'
import Services from '../LandingPage/Services'
import Faq from '../LandingPage/Faq/Faq'
import Contact from '../LandingPage/Contact/Contact'
import { scrollToTop } from '../../util/dom-util'
import CustomerHome from '../CustomerHome/CustomerHome'
import PaymentPage from '../PaymentPage/PaymentPage'
import CovidPage from '../covid/CovidPage/CovidPage'
import { AddCardSuccess, AddCardCancel } from '../UserSignupPage/stripe'

type Props = {
  user: Object,
  org: Object,
  didLoad: boolean,
  location?: Location
}

const dashboards = {
  ACTIVE: CustomerHome,
  GUEST: CustomerHome,
  PENDING: CustomerHome,
  INACTIVE: CustomerHome,
  ORG_ADMIN: OrgAdminDashboard
}

export class Main extends Component<Props, State> {
  componentDidUpdate(prevProps: Props) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      scrollToTop()
    }
  }

  render() {
    const { user, didLoad } = this.props

    if (!didLoad) {
      return null
    }

    const Component = user ? dashboards[user.type] : null

    return (
      <div className="Main">
        {Component ? (
          <Component {...this.props} />
        ) : (
          <Switch>
            {/* New user pages */}
            <Route exact path="/" component={LandingPage} />
            <Route path="/our-process" component={OurProcess} />
            <Route path="/services" component={Services} />
            <Route path="/faq" component={Faq} />
            <Route path="/contact" component={Contact} />
            <Route path="/covid-19" component={CovidPage} />
            <Route path="/signup" component={UserSignupPage} />
            <Route path="/signup-success" component={AddCardSuccess} />
            <Route path="/signup-cancel" component={AddCardCancel} />
            <Route path="/account/:slug" component={CorporateAccountSignUpPage} />
            {/* Account pages */}
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/account-created" component={AccountCreated} />
            <Route path="/wash/:washSlug" component={PaymentPage} />
            <Route
              path="/myaccount"
              render={() => <Redirect to={`/login?redirect=${encodeURIComponent('/myaccount')}`} />}
            />
          </Switch>
        )}
      </div>
    )
  }
}

const mapState = state => ({
  didLoad: state.app.didLoad
})

export default withRouter(connect(mapState)(Main))
