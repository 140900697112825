import './style.scss'

import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'
import { formatMoney } from 'accounting'
import { Event, Charge } from '../../models'
import { handleError } from '../../util/error-util'
import moment from 'moment'
import { DATETIME_FORMAT, DATE_FORMAT } from '../../contants'
import { Field, Button } from '../shared'

type Props = {
  location: Location,
  match: Object
}

type State = {
  event: Event,
  charge: Charge,
  tip: '15' | '20' | '25' | 'custom',
  customAmount: String,
  feedback: String,
  saving: Boolean,
  feedbackSubmitted: Boolean
}

export default class PaymentPage extends Component<Props, State> {
  state = {
    event: null,
    tip: '15',
    customAmount: '',
    feedback: '',
    saving: false,
    feedbackSubmitted: false
  }

  async componentDidMount() {
    try {
      const { match, location } = this.props
      const slug = match.params.washSlug
      const res = await axios.get(`/events/${slug}/prepare_payment`)
      const event = new Event(res.data)

      const params = new URLSearchParams(location.search)
      let tip = params.get('t')
      if (!['15', '20', '25', 'custom'].includes(tip)) {
        tip = '15'
      }

      const res2 = await axios.get(`/events/${event.id}/charge`)
      const charge = new Charge(res2.data)

      this.setState({ event, charge, tip })
    } catch (err) {
      handleError(err, true)
    }
  }

  onSubmit = () => {
    this.setState({ saving: true }, async () => {
      try {
        const { event, tip, customAmount } = this.state
        let data = {}
        if (tip === 'custom') {
          const dollars = Number(customAmount)
          if (isNaN(dollars)) {
            return alert('Invalid value')
          }

          const cents = dollars * 100
          data.amount = cents
        } else {
          const value = Number(tip)
          if (isNaN(value)) {
            return alert('Invalid value')
          }

          const percent = value / 100
          data.percent = percent
        }

        const res = await axios.post(`/events/${event.id}/submit_payment`, data)

        this.setState({
          event: new Event(res.data.event),
          charge: new Charge(res.data.charge),
          saving: false
        })
      } catch (err) {
        handleError(err, true)
        this.setState({ saving: false })
      }
    })
  }

  onFeedbackSubmit = () => {
    this.setState({ saving: true }, async () => {
      try {
        const { event, feedback } = this.state

        await axios.post('/messages', {
          event: event.id,
          type: 'WASH_FEEDBACK',
          body: feedback.trim()
        })

        this.setState({
          feedbackSubmitted: true,
          saving: false
        })
      } catch (err) {
        handleError(err, true)
        this.setState({ saving: false })
      }
    })
  }

  getChargeAmount = () => {
    const charge: Charge = this.state.charge
    let chargeAmount = charge.amount
    if (!chargeAmount) {
      if (charge.stripe_initial_charge) {
        chargeAmount = charge.stripe_initial_charge.amount
      }
    }

    return chargeAmount
  }

  getTipAmount = () => {
    const { tip, customAmount } = this.state
    const chargeAmount = this.getChargeAmount()
    let tipAmount = 0

    if (tip === 'custom') {
      let parsedValue =
        typeof customAmount === 'number' ? customAmount : Number(customAmount.trim())

      if (isNaN(parsedValue) || parsedValue < 0) {
        parsedValue = 0
      }
      tipAmount = parsedValue * 100
    } else {
      const parsedTipValue = Number(tip) / 100
      tipAmount = chargeAmount * parsedTipValue
    }

    return tipAmount
  }

  getLabel = () => {
    const { saving } = this.state
    const charge: Charge = this.state.charge

    if (saving) {
      return 'Processing...'
    }

    if (!charge) {
      return ''
    }

    const chargeAmount = this.getChargeAmount()
    const tipAmount = this.getTipAmount()

    if (!chargeAmount) {
      return ''
    }

    let label = ''

    if (charge.payment_api === 2) {
      if (tipAmount) {
        label = `Pay ${formatMoney(tipAmount / 100)}`
      }
    } else {
      label = `Pay ${formatMoney(chargeAmount / 100)}`
      if (tipAmount) {
        label += ` + a ${formatMoney(tipAmount / 100)} tip`
      }
    }
    return label
  }

  getRemainingAmount = (tip: Number) => {
    const charge: Charge = _.cloneDeep(this.state.charge)
    charge.tip_percent = tip

    let rv

    if (charge.payment_api === 2) {
      rv = charge.getPendingAmount()
    } else {
      rv = charge.getTotal()
    }

    return formatMoney(rv / 100)
  }

  isValid = () => {
    const { tip, customAmount } = this.state
    if (tip !== 'custom') {
      return true
    }

    if (typeof customAmount === 'number') {
      return customAmount >= 0
    }

    const value = customAmount.trim()
    if (value === '') {
      return true
    }

    const parsedValue = parseFloat(value)
    return Number.isFinite(parsedValue) && parsedValue >= 0
  }

  renderDoneMessage = () => {
    const { feedback, feedbackSubmitted, saving } = this.state

    return (
      <div className="PaymentPage">
        <h2>Thank you! Your payment has been processed.</h2>
        <p>See you again soon.</p>
        <div className="done">
          <Link to="/" className="button primary">
            Done
          </Link>
        </div>
        {feedbackSubmitted ? (
          <p className="feedback-textbox-label">Thanks for the message.</p>
        ) : (
          <Fragment>
            <p className="feedback-textbox-label">
              Loving our service or want to give us feedback?
            </p>
            <div>
              <textarea
                value={feedback}
                onChange={e => this.setState({ feedback: e.target.value })}
              />
            </div>
            <div className="feedback-button">
              <Button
                type="primary"
                onClick={this.onFeedbackSubmit}
                disabled={saving || !feedback.trim().length}>
                Submit
              </Button>
            </div>
          </Fragment>
        )}
      </div>
    )
  }

  render() {
    const { event, tip, customAmount, saving } = this.state
    const charge: Charge = this.state.charge

    if (!event) {
      return <div className="PaymentPage loading">Loading...</div>
    }

    if (charge && charge.charged_date) {
      return this.renderDoneMessage()
    }

    return (
      <div className="PaymentPage">
        <h1>Thanks for choosing to Shine Rite.</h1>
        <p>
          If you are satisfied with your wash on {moment(event.start).format(DATETIME_FORMAT)},
          please leave a tip for your technician:
        </p>
        <div className="options">
          <button
            className={classNames({ primary: tip === '15' })}
            onClick={() => this.setState({ tip: '15' })}>
            <span>15%</span>
            <span>({this.getRemainingAmount(0.15)})</span>
          </button>
          <button
            className={classNames({ primary: tip === '20' })}
            onClick={() => this.setState({ tip: '20' })}>
            <span>20%</span>
            <span>({this.getRemainingAmount(0.2)})</span>
          </button>
          <button
            className={classNames({ primary: tip === '25' })}
            onClick={() => this.setState({ tip: '25' })}>
            <span>25%</span>
            <span>({this.getRemainingAmount(0.25)})</span>
          </button>
          <button
            className={classNames({ primary: tip === 'custom' })}
            onClick={() => this.setState({ tip: 'custom' })}>
            Custom
          </button>
        </div>
        <div className="custom-amount">
          {tip === 'custom' ? (
            <Field
              type="currency"
              label="Custom amount:"
              value={customAmount}
              onChange={e => this.setState({ customAmount: e.target.value })}
            />
          ) : (
            <div style={{ height: 43 }} />
          )}
        </div>
        {this.getLabel() ? (
          <div className="pay">
            <Button type="primary" onClick={this.onSubmit} disabled={saving || !this.isValid()}>
              {this.getLabel()}
            </Button>
          </div>
        ) : (
          <div style={{ height: 103 }} />
        )}

        {charge.payment_api === 2 ? (
          <div style={{ paddingTop: 20, fontSize: '90%', textAlign: 'center', color: '#aaa' }}>
            {formatMoney(charge.stripe_initial_charge.amount / 100)} was charged on{' '}
            {moment(charge.stripe_initial_charge.created * 1000).format(DATE_FORMAT)}.
          </div>
        ) : null}
      </div>
    )
  }
}
