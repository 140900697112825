import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { connect } from 'react-redux'

type Props = {
  ...RouteProps,
  user: Object,
  didLoad: Boolean
}

export function AuthRoute(props: Props) {
  const { user, didLoad, ...rest } = props
  if (didLoad && !user) {
    return 'Error'
  }

  return <Route {...rest} />
}

const mapState = state => ({
  user: state.app.user,
  didLoad: state.app.didLoad
})

export default connect(mapState)(AuthRoute)
