import './base.scss'
import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import { Hero } from './sections/Hero'
import { Services } from './sections/Services'
import { WhyWait } from './sections/WhyWait'
import { SkipWait } from './sections/SkipWait'
import { ShineRiteGreen } from './sections/ShineRiteGreen'
import { Ratings } from './sections/Ratings'
import { MobileFriendly } from './sections/MobileFriendly'
import { Process } from './sections/Process'
import { Footer } from './sections/Footer'
import { isMobile, getScrollY } from '../../util/dom-util'
import { ScheduleMyAppt } from './sections/ScheduleMyAppt'
import ProductGridWrapper from '../UserSignupPage/ProductGrid/ProductGridWrapper'

type Props = {
  ctaUrl: String
}

type State = {}

export default class LandingPage extends Component<Props, State> {
  parentNode: HTMLElement
  node: HTMLElement
  pivotY: Number

  state = {}

  static defaultProps = {
    ctaUrl: '/signup'
  }

  componentDidMount() {
    window.scrollTo(0, window.scrollY)
    document.documentElement.style.overflowX = 'hidden'
    document.body.style.overflowX = 'hidden'

    if (isMobile()) {
      setTimeout(this.checkNodes, 1000)
    }
  }

  componentWillUnmount() {
    document.documentElement.style.overflowX = ''
    document.body.style.overflowX = ''

    window.removeEventListener('scroll', this.onScroll)
  }

  checkNodes = () => {
    const node = (this.node = document.querySelector('.hero .cta a.button.primary'))
    if (!(node && this.parentNode)) {
      return
    }

    const parentRect = this.parentNode.getBoundingClientRect()
    const rect = node.getBoundingClientRect()
    this.pivotY = rect.top - parentRect.top - 55

    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  onScroll = () => {
    const y = getScrollY()
    this.onScrollDebounced(y)
  }

  onScrollDebounced = _.debounce((y: Number) => {
    const node = this.node
    if (!node) {
      return
    }

    if (y < this.pivotY && node.classList.contains('sticky')) {
      node.classList.remove('sticky')
    } else if (y >= this.pivotY && !node.classList.contains('sticky')) {
      node.classList.add('sticky')
    }
  }, 10)

  render() {
    const { ctaUrl } = this.props

    return (
      <div className="LandingPage" ref={node => (this.parentNode = node)}>
        <Hero ctaUrl={ctaUrl} />
        <ProductGridWrapper ctaUrl={ctaUrl} showCompare={false} />
        <Services />
        <WhyWait />
        {isMobile() ? <ScheduleMyAppt ctaUrl={ctaUrl} /> : null}
        <Process />
        <SkipWait />
        <ShineRiteGreen />
        {isMobile() ? null : <Ratings />}
        {isMobile() ? null : <ScheduleMyAppt ctaUrl={ctaUrl} />}
        <MobileFriendly />
        <Footer ctaUrl={ctaUrl} />
      </div>
    )
  }
}
