import _ from 'lodash'
import { getFullName } from '../../util/string-util'

const initialState = []

function sort(employees) {
  return _.sortBy(employees, m => getFullName(m))
}

export default function employees(state = initialState, action) {
  let employees, index
  switch (action.type) {
    case 'SET_EMPLOYEES':
      employees = action.employees
      return sort(_.uniqBy(employees, m => m.id))
    case 'SET_EMPLOYEE':
      employees = [...state]
      index = _.findIndex(employees, m => m.id === action.employee.id)
      if (index === -1) {
        employees.push(action.employee)
      } else {
        employees.splice(index, 1, action.employee)
      }

      return sort(employees)
    case 'ADD_EMPLOYEE':
      return sort([action.employee].concat(state))
    case 'UPDATE_EMPLOYEE':
      employees = [...state]
      index = _.findIndex(employees, m => m.id === action.employee.id)
      employees.splice(index, 1, action.employee)
      return sort(employees)
    case 'DELETE_EMPLOYEE':
      employees = state.filter(m => m.id !== action.employee.id)
      return employees
    default:
      return state
  }
}
