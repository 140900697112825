import axios from 'axios'
import _debug from 'debug'
import { store } from '../store'
import Event from '../../models/Event'

const debug = _debug('carwash:store:actions:appointments')

export async function loadAppointments(urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/events`)
    const appointments = res.data.results.map(data => new Event(data))
    store.dispatch({ type: 'SET_APPOINTMENTS', appointments })
  } catch (err) {
    debug('[loadAppointments] error: ', err)
    throw err
  }
}

export async function loadAppointmentsForCustomer(customerId: Number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/customers/${customerId}/appointments`)
    const appointments = res.data.map(data => new Event(data))
    store.dispatch({ type: 'SET_APPOINTMENTS', appointments })
  } catch (err) {
    debug('[loadCars] error: ', err)
    throw err
  }
}

export async function loadAppointment(id: number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/events/${id}`)
    const appointment = new Event(res.data)
    store.dispatch({ type: 'SET_APPOINTMENT', appointment })
  } catch (err) {
    debug('[loadAppointment] error: ', err)
    throw err
  }
}

export async function saveAppointment(data: Object, urlPrefix: string = '') {
  return data.id ? updateAppointment(data, urlPrefix) : createAppointment(data, urlPrefix)
}

export async function createAppointment(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/events`, data)
    const appointment = new Event(res.data)
    store.dispatch({ type: 'ADD_APPOINTMENT', appointment })
    return appointment
  } catch (err) {
    debug('[createAppointment] error: ', err)
    throw err
  }
}

export async function updateAppointment(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.put(`${urlPrefix}/events/${data.id}`, data)
    const appointment = new Event(res.data)
    store.dispatch({ type: 'UPDATE_APPOINTMENT', appointment })
    return appointment
  } catch (err) {
    debug('[updateAppointment] error: ', err)
    throw err
  }
}

export async function deleteAppointment(data: Object, urlPrefix: string = '') {
  try {
    await axios.delete(`${urlPrefix}/events/${data.id}`)
    store.dispatch({ type: 'DELETE_APPOINTMENT', appointment: data })
  } catch (err) {
    debug('[deleteAppointment] error: ', err)
    throw err
  }
}

export async function cancelAppointment(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/events/${data.id}/cancel`)
    data.cancelled_date = new Date().toJSON()
    store.dispatch({ type: 'UPDATE_APPOINTMENT', appointment: data })
    return res.data.msg
  } catch (err) {
    debug('[cancelAppointment] error: ', err)
    throw err
  }
}

export async function rescheduleAppointment(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/events/${data.id}/reschedule`, data)
    const appointment = new Event(res.data)
    store.dispatch({ type: 'UPDATE_APPOINTMENT', appointment })
    return appointment
  } catch (err) {
    debug('[cancelAppointment] error: ', err)
    throw err
  }
}
