import './style.scss'

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import _debug from 'debug'
import axios from 'axios'
import Form from '../../shared/Form/Form'
import Field from '../../shared/Field/Field'
import SubmitButton from '../../shared/SubmitButton/SubmitButton'
import { toast, initSession, continueSignup } from '../../../store/actions/app'
import User from '../../../models/User'
import Car from '../../../models/Car'
import Address from '../../../models/Address'
import { isValidEmail } from '../../../util/string-util'

const debug = _debug('carwash:components:Login')

type Props = {
  history: Object
}

type State = {
  email: string,
  password: string,
  saving: Boolean
}

export default class Login extends Component<Props, State> {
  state = {
    email: '',
    password: '',
    saving: false
  }

  getUrl = () => {
    try {
      const params = new URLSearchParams(window.location.search)
      if (!params.has('redirect')) {
        throw new Error()
      }

      return decodeURIComponent(params.get('redirect'))
    } catch {
      return '/'
    }
  }

  onSubmit = async () => {
    try {
      this.setState({ saving: true })
      const { history } = this.props
      const { email, password } = this.state
      const res = await axios.post('/login', { email, password })

      const { user, csrf, pending, car, address } = res.data
      if (pending) {
        toast('Your account is still in Pending status. Please complete the signup process.')
        continueSignup(new User(user), new Car(car), new Address(address))
        history.push('/signup')
        return
      }

      initSession(new User(user), undefined, undefined, csrf)
      history.push(this.getUrl())
    } catch (err) {
      debug('[onSubmit] error: ', err)
      toast('Invalid credentials')
      this.setState({ saving: false })
    }
  }

  onChange = (prop: String, value: any) => {
    this.setState({ [prop]: value })
  }

  render() {
    const { email, password, saving } = this.state
    const valid = isValidEmail(email) && !!password.trim()

    return (
      <div className="Login form-centered">
        <h3>Login</h3>
        <Form id="login-form" name="login-form" onSubmit={this.onSubmit}>
          <Field
            label="Email"
            type="email"
            autoComplete="email"
            value={email}
            onChange={e => this.onChange('email', e.target.value)}
            usePlaceholders
            autoFocus
          />
          <Field
            label="Password"
            type="password"
            value={password}
            onChange={e => this.onChange('password', e.target.value)}
            usePlaceholders
          />
          <SubmitButton disabled={saving || !valid} onClick={this.onSubmit} />
        </Form>
        <p className="forgot">
          <Link to="/forgot-password">Forgot your password?</Link>
        </p>
      </div>
    )
  }
}
