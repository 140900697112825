import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadCar } from '../../../store/actions/cars'
import TitleBar from '../../shared/TitleBar/TitleBar'
import CarForm from '../Form/CarForm'
import Car from '../../../models/Car'
import { getUrlPrefix } from '../../../util/string-util'

type Props = {
  car: ?Car,
  cars: Car[],
  urlPrefix: string,
  match: Object
}

type State = {}

export class CarDetail extends Component<Props, State> {
  state = {}

  async componentDidMount() {
    const { urlPrefix, match } = this.props
    const id = match.params.carId
    loadCar(id, urlPrefix)
  }

  render() {
    const { car, cars, urlPrefix } = this.props
    if (!car) {
      return 'Loading...'
    }

    const backTo = getUrlPrefix('vehicles')

    return (
      <div className="CarDetail">
        <TitleBar backTo={backTo} label="Vehicle Detail" />
        <CarForm car={car} cars={cars} urlPrefix={urlPrefix} />
      </div>
    )
  }
}

const mapState = (state, props) => {
  const id = Number(props.match.params.carId)
  const car = state.cars.find(m => m.id === id)

  return {
    car
  }
}

export default connect(mapState)(CarDetail)
