import React from 'react'
import Car from '../../../../assets/car.png'
import { ReactComponent as Calendar } from '../../../../assets/calendar.svg'
import { ReactComponent as Eco } from '../../../../assets/water-cycle.svg'
import { ReactComponent as Thumb } from '../../../../assets/review.svg'
import { ReactComponent as Award } from '../../../../assets/award.svg'

import './style.scss'

export const Services = () => (
  <div className="container">
    <section className="Services">
      <div className="columns">
        <div className="column is-full">
          <h1>Professional Detailing with a Personal Touch</h1>
          <h2>Skip the car wash and long waits</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.
            Risus commodo viverra maecenas accumsan lacus vel facilisis.
          </p> */}
        </div>
      </div>

      <div className="columns">
        <div className="column display-centered">
          <div className="feature-side-one">
            <div className="first">
              <span>
                <Calendar />
              </span>
              <h3>Flexible Scheduling</h3>
            </div>
            <div className="second">
              <span>
                <Eco />
              </span>
              <h3>Eco-friendly</h3>
            </div>
          </div>
        </div>

        <div className="column car-graphic">
          <img src={Car} alt="Awesome car" />
        </div>

        <div className="column display-centered">
          <div className="feature-side-two">
            <div className="first">
              <span>
                <Thumb />
              </span>
              <h3>100% Satisfaction Guarantee</h3>
            </div>
            <div className="second">
              <span>
                <Award />
              </span>
              <h3>Top-rated technicians</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)
