import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import Form from '../../shared/Form/Form'
import Field from '../../shared/Field/Field'
import SaveButton from '../../shared/SaveButton/SaveButton'
import { saveCar, deleteCar } from '../../../store/actions/cars'
import { handleError } from '../../../util/error-util'
import { toast } from '../../../store/actions/app'
import DeleteButton from '../../shared/DeleteButton/DeleteButton'
import confirm from '../../shared/confirm/confirm'
import ActionBar from '../../shared/ActionBar/ActionBar'
import history from '../../../history'
import Car from '../../../models/Car'
import { areFieldsEmpty } from '../../../util/string-util'

type Props = {
  car?: Car,
  cars: Car[],
  urlPrefix: string,
  saveButtonLabel?: String,
  onSave: Function
}

type State = {
  car: Car,
  saving: boolean
}

export default class CarForm extends Component<Props, State> {
  initialDefaultValue: Boolean

  state = {
    car: null,
    saving: false
  }

  static defaultProps = {
    onSave: _.noop,
    saveButtonLabel: 'Save'
  }

  componentDidMount() {
    this.setCar()
  }

  componentDidUpdate(prevProps) {
    if (this.props.car !== prevProps.car) {
      this.setCar()
    }
  }

  setCar = () => {
    const car = new Car(this.props.car)
    this.initialDefaultValue = car.is_default
    this.setState({ car })
  }

  onChange = (prop: string, value: any) => {
    // eslint-disable-next-line react/no-direct-mutation-state
    _.set(this.state.car, prop, value)
    this.forceUpdate()
  }

  onSubmit = async () => {
    try {
      const { urlPrefix, onSave } = this.props
      const { car } = this.state
      this.setState({ saving: true })

      toast('Saving...')
      const savedCar: Car = await saveCar(car, urlPrefix)
      toast('Saved')

      this.setState({ saving: false })
      onSave(savedCar)
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  onDeleteClick = async () => {
    try {
      if (!(await confirm())) {
        return
      }

      const { urlPrefix } = this.props
      const { car } = this.state

      this.setState({ saving: true })

      toast('Deleting...')
      await deleteCar(car, urlPrefix)
      toast('Deleted')

      history.goToListView()
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  isValid = () => {
    const { car } = this.state
    const valid = !areFieldsEmpty(car, ['make', 'model', 'color'])
    return valid
  }

  hasMultipleCars = () => {
    const { cars } = this.props
    const { car } = this.state

    if (cars.length > 1) {
      return true
    }

    if (cars.length === 1) {
      return !car.id
    }

    return false
  }

  render() {
    const { saveButtonLabel } = this.props
    const { car, saving } = this.state

    if (!car) {
      return null
    }

    return (
      <div className="CarForm">
        <Form onSubmit={this.onSubmit}>
          {this.hasMultipleCars() && !this.initialDefaultValue ? (
            <Field
              type="checkbox"
              label="Set as default:"
              checked={car.is_default}
              onChange={e => this.onChange('is_default', e.target.checked)}
            />
          ) : null}
          <Field
            type="text"
            label="Make:"
            value={car.make}
            onChange={e => this.onChange('make', e.target.value)}
          />
          <Field
            type="text"
            label="Model:"
            value={car.model}
            onChange={e => this.onChange('model', e.target.value)}
          />
          <Field
            type="text"
            label="Color:"
            value={car.color}
            onChange={e => this.onChange('color', e.target.value)}
          />
          <Field
            type="text"
            label="License plate:"
            value={car.license_plate}
            onChange={e => this.onChange('license_plate', e.target.value)}
          />
          <ActionBar>
            <SaveButton onClick={this.onSubmit} disabled={!this.isValid() || saving}>
              {saveButtonLabel}
            </SaveButton>
            {car.id ? <DeleteButton onClick={this.onDeleteClick} disabled={saving} /> : null}
          </ActionBar>
        </Form>
      </div>
    )
  }
}
