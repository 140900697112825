import { combineEpics } from 'redux-observable'
// import {
//   createCorporateAccountEpic,
//   updateCorporateAccountEpic,
//   fetchCorporateAccountsEpic,
//   fetchCorporateAccountFromUrlEpic
// } from './corporateAccountEpics'

import { fetchPackageDealsEpic } from './packageDealEpics'

export const rootEpic = combineEpics(
  // createCorporateAccountEpic,
  // updateCorporateAccountEpic,
  // fetchCorporateAccountsEpic,
  // fetchCorporateAccountFromUrlEpic,
  fetchPackageDealsEpic
)
