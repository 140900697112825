import React, { Component } from 'react'
import axios from 'axios'
import { getBaseUrl } from '../../util/string-util'
import Footer from '../Footer/Footer'

type Props = {}

type State = {
  html: ''
}

export default class OurProcess extends Component<Props, State> {
  state = {
    html: ''
  }

  async componentDidMount() {
    const res = await axios.get('/media/our-process.html', {
      baseURL: getBaseUrl()
    })
    this.setState({ html: res.data })
  }

  render() {
    const { html } = this.state
    return (
      <div className="LandingPageBase OurProcess">
        <div className="heading">
          <div className="container">
            <h1>Why we are different</h1>
          </div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <Footer />
      </div>
    )
  }
}
