import './style.scss'

import React, { Component } from 'react'
import Button from '../../../shared/Button/Button'
import Product from '../../../../models/Product'
import { Dialog } from '../../../shared'
import ReactMarkdown from 'react-markdown'
import ProductTable from '../ProductTable/ProductTable'
import history from '../../../../history'

type Props = {
  product: Product,
  products: Product[],
  onChange: Function
}

type State = {
  showModal: Boolean
}

export default class ProductInfo extends Component<Props, State> {
  state = {
    showModal: false
  }

  show = (e: Event) => {
    e.stopPropagation()
    const { product } = this.props
    history.push(`/signup/step-1?p=${product.id}`)
    this.unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        this.hide()
      }
    })

    this.setState({ showModal: true })
  }

  hide = () => {
    this.unlisten()
    this.setState({ showModal: false })
  }

  onHideClick = (e: Event) => {
    if (e) {
      e.stopPropagation()
    }

    history.goBack()
  }

  render() {
    const { product, products, index, onChange } = this.props
    const { showModal } = this.state

    return (
      <>
        <Button className="ProductInfo" icon="info" onClick={this.show} />
        {showModal ? (
          <Dialog
            className="ProductInfo_Dialog"
            title={product.name}
            hide={this.onHideClick}
            buttons={null}>
            <ReactMarkdown escapeHtml={false}>{product.description}</ReactMarkdown>
            {product.type === 'SPECIAL' ? null : (
              <ProductTable products={products} showColumn={index} onChange={onChange} />
            )}
          </Dialog>
        ) : null}
      </>
    )
  }
}
