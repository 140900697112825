import account from './account.svg'
import arrowDown from './arrow-down-sm.svg'
import browser from './browser.svg'
import calendar from './calendar.svg'
import calendar2 from './calendar-2.svg'
import calendar3 from './calendar-3.svg'
import car from './car.svg'
import carwash from './carwash.svg'
import clipboard from './clipboard.svg'
import clock from './clock.svg'
import coins from './coins.svg'
import copy from './copy.svg'
import dollar from './dollar.svg'
import download from './download.svg'
import file from './file.svg'
import folder from './folder.svg'
import home from './home.svg'
import info from './info.svg'
import key from './key.svg'
import list from './list.svg'
import login from './login.svg'
import logo from './logo.png'
import logout from './logout.svg'
import mail from './mail.svg'
import menu from './menu.svg'
import menuWhite from './menu-white.svg'
import minusWhite from './minus-white.svg'
import pencil from './pencil.svg'
import plus from './plus.svg'
import plusWhite from './plus-white.svg'
import ring from './ring.svg'
import search from './search.svg'
import settings from './settings.svg'
import share from './share.svg'
import star from './star.svg'
import star2 from './star-2.svg'
import thumbsUp from './thumbs-up.svg'
import trash from './trash.svg'
import trees from './trees.svg'
import triangle from './triangle.svg'
import upload from './upload.svg'
import x from './x.svg'

export const icons = {
  account,
  arrowDown,
  browser,
  calendar,
  calendar2,
  calendar3,
  car,
  carwash,
  clipboard,
  clock,
  coins,
  copy,
  dollar,
  download,
  file,
  folder,
  home,
  info,
  key,
  list,
  login,
  logo,
  logout,
  mail,
  menu,
  'menu-white': menuWhite,
  'minus-white': minusWhite,
  pencil,
  plus,
  'plus-white': plusWhite,
  ring,
  search,
  settings,
  share,
  star,
  star2,
  thumbsUp,
  trash,
  trees,
  triangle,
  upload,
  x
}
