import './style.scss'

import React from 'react'
import { Input } from '../../shared'

type Props = {
  acceptedTermsDate: Date,
  onChange: Function
}

export default function Terms(props: Props) {
  const { acceptedTermsDate, onChange } = props

  return (
    <div className="Terms">
      <p>
        <b>Terms</b>
      </p>
      <p>
        Please remove all trash and personal items before our technician arrives. Our interior clean
        does NOT include large trash removal.
      </p>

      <p>Extremely dirty vehicles may need to be rescheduled to complete additional work. </p>

      <p>
        We have a 24-hour cancellation policy. Missed or cancelled appointments within 24 hours are
        charged 50% of the service cost.
      </p>

      <p>
        After your wash is complete, you will receive an email to tip your technician. Auto tip is
        set at 15% but you can adjust the tip as needed.
      </p>

      <p>
        By agreeing, you give us permission to send you text messages for scheduling purposes. We
        will also occasionally send you special offers. If you would like to opt out of special
        offers, please text STOP when you receive the first special offer text.
      </p>

      <Input
        type="checkbox"
        name="shineriteagreeterms"
        label="I accept"
        className="formfield checkbox"
        checked={!!acceptedTermsDate}
        onChange={onChange}
      />
    </div>
  )
}
