import React, { useState } from 'react'
import { Event } from '../../../models'
import GenericEventForm from './GenericEventForm'
import WashEventForm from './WashEventForm'

type Props = {
  event?: Event,
  employees: Object[],
  customers: Object[],
  urlPrefix: string,
  onSave: Function
}

export default function EventForm(props: Props) {
  const { event } = props
  const verb = event && event.id ? 'Edit' : 'New'
  const isWash = event ? ['WASH', 'CORPORATE_WASH'].includes(event.type) : true
  const [type, setType] = useState(event && isWash ? 'WASH' : 'GENERIC')
  const Form = type === 'WASH' ? WashEventForm : GenericEventForm

  return (
    <div className="EventFormWrapper">
      <div className="header">
        {verb === 'New' ? (
          <select value={type} onChange={e => setType(e.target.value)}>
            <option value="WASH">New Wash Appointment</option>
            <option value="GENERIC">Other Event</option>
          </select>
        ) : isWash ? (
          'Edit Wash Appointment'
        ) : (
          'Edit Event'
        )}
      </div>

      <Form {...props} />
    </div>
  )
}
