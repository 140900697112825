import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadAddress } from '../../../store/actions/addresses'
import TitleBar from '../../shared/TitleBar/TitleBar'
import AddressForm from '../Form/AddressForm'
import Address from '../../../models/Address'
import { getUrlPrefix } from '../../../util/string-util'

type Props = {
  address: ?Address,
  urlPrefix: string,
  match: Object
}

type State = {}

export class AddressDetail extends Component<Props, State> {
  state = {}

  async componentDidMount() {
    const { urlPrefix, match } = this.props
    const id = match.params.addressId
    loadAddress(id, urlPrefix)
  }

  render() {
    const { address, urlPrefix } = this.props
    if (!address) {
      return 'Loading...'
    }

    const backTo = getUrlPrefix('addresses')

    return (
      <div className="AddressDetail">
        <TitleBar backTo={backTo} label="Address Detail" />
        <AddressForm address={address} urlPrefix={urlPrefix} />
      </div>
    )
  }
}

const mapState = (state, props) => {
  const id = Number(props.match.params.addressId)
  const address = state.addresses.find(m => m.id === id)

  return {
    address
  }
}

export default connect(mapState)(AddressDetail)
