import './style.scss'

import React from 'react'

export default function Footer() {
  return (
    <div className="Footer">
      <div className="container">© {new Date().getFullYear()} Shine Rite</div>
    </div>
  )
}
