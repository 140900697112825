import React, { Component } from 'react'
import axios from 'axios'
import Form from '../shared/Form/Form'
import Field from '../shared/Field/Field'
import SaveButton from '../shared/SaveButton/SaveButton'
import { toast, initSession } from '../../store/actions/app'
import ActionBar from '../shared/ActionBar/ActionBar'
import User from '../../models/User'

type State = {
  password: string,
  saving: boolean,
  history: Object
}

export default class AccountCreated extends Component<Props, State> {
  userId: string = null

  state = {
    password: '',
    saving: false
  }

  componentDidMount() {
    const match = window.location.href.match(/H(\d+)I/)
    if (!match) {
      toast('Oops! There was an error processing your request.')
      return
    }

    this.userId = match[1]
  }

  onSubmit = async () => {
    try {
      const { password } = this.state
      this.setState({ saving: true })

      const res = await axios.post(`/customers/${this.userId}/set_password`, {
        password
      })

      const { user, csrf } = res.data
      initSession(new User(user), undefined, undefined, csrf)
      toast('Password updated')
      this.props.history.push('/')
    } finally {
      this.setState({ saving: false })
    }
  }

  render() {
    const { password, saving } = this.state

    return (
      <div className="AccountCreated form-centered">
        <h3>Create a password</h3>
        <Form onSubmit={this.onSubmit}>
          <Field
            type="password"
            label="Password:"
            value={password}
            onChange={e => this.setState({ password: e.target.value })}
          />
          <ActionBar>
            <SaveButton onClick={this.onSubmit} disabled={saving} />
          </ActionBar>
        </Form>
      </div>
    )
  }
}
