import { loadStripe } from '@stripe/stripe-js'
import { getStripeKey } from '../store/util'

let script

export function addStripeScript() {
  script = document.createElement('script')
  script.id = 'stripe-script'
  script.async = true
  script.src = 'https://js.stripe.com/v3/'
  document.body.appendChild(script)
}

export function removeStripeScript() {
  delete window.Stripe
  if (script) {
    document.body.removeChild(script)
  }
}

export async function redirectToStripe(sessionId: string) {
  try {
    const stripe = await loadStripe(getStripeKey())
    const { error } = await stripe.redirectToCheckout({ sessionId })
    if (error) {
      throw new Error(error)
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}
