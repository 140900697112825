import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import Form from '../../shared/Form/Form'
import Field from '../../shared/Field/Field'
import SaveButton from '../../shared/SaveButton/SaveButton'
import { saveEmployee, deleteEmployee } from '../../../store/actions/employees'
import { handleError } from '../../../util/error-util'
import { toast } from '../../../store/actions/app'
import ActionBar from '../../shared/ActionBar/ActionBar'
import DeleteButton from '../../shared/DeleteButton/DeleteButton'
import confirm from '../../shared/confirm/confirm'
import history from '../../../history'

const defaultEmployee = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  type: 'ORG_STAFF'
}

type Props = {
  employee?: Object,
  urlPrefix: string,
  onSave: Function
}

type State = {
  employee: ?Object,
  saving: boolean
}

export default class EmployeeForm extends Component<Props, State> {
  state = {
    employee: null,
    saving: false
  }

  static defaultProps = {
    onSave: _.noop
  }

  componentDidMount() {
    this.setEmployee()
  }

  componentDidUpdate(prevProps) {
    if (this.props.employee !== prevProps.employee) {
      this.setEmployee()
    }
  }

  setEmployee = () => {
    this.setState({
      employee: _.cloneDeep(this.props.employee || defaultEmployee)
    })
  }

  onChange = (prop: string, value: any) => {
    const employee = { ...this.state.employee }
    employee[prop] = value
    this.setState({ employee })
  }

  onSubmit = async () => {
    try {
      const { urlPrefix, onSave } = this.props
      const { id, first_name, last_name, email, password, phone, type } = this.state.employee
      const employee = { id, first_name, last_name, email, password, phone, type }

      this.setState({ saving: true })

      toast('Saving...')
      const result = await saveEmployee(employee, urlPrefix)
      toast('Saved')

      this.setState({ saving: false })
      onSave(result)
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  onDeleteClick = async () => {
    try {
      if (!(await confirm())) {
        return
      }

      const { urlPrefix } = this.props
      const { employee } = this.state

      this.setState({ saving: true })

      toast('Saving...')
      await deleteEmployee(employee, urlPrefix)
      toast('Saved')

      history.replace('/technicians')
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  render() {
    const { employee, saving } = this.state

    if (!employee) {
      return null
    }

    return (
      <div className="EmployeeForm">
        <Form onSubmit={this.onSubmit}>
          <Field
            type="text"
            label="First Name:"
            value={employee.first_name}
            onChange={e => this.onChange('first_name', e.target.value)}
          />
          <Field
            type="text"
            label="Last Name:"
            value={employee.last_name}
            onChange={e => this.onChange('last_name', e.target.value)}
          />
          <Field
            type="email"
            label="Email:"
            value={employee.email}
            onChange={e => this.onChange('email', e.target.value)}
          />
          <Field
            type="tel"
            label="Phone:"
            value={employee.phone}
            onChange={e => this.onChange('phone', e.target.value)}
          />
          {employee.id ? null : (
            <Field
              type="password"
              label="Password:"
              value={employee.password}
              onChange={e => this.onChange('password', e.target.value)}
            />
          )}
          <ActionBar>
            <SaveButton onClick={this.onSubmit} disabled={saving} />
            {employee.id ? <DeleteButton onClick={this.onDeleteClick} disabled={saving} /> : null}
          </ActionBar>
        </Form>
      </div>
    )
  }
}
