import React, { Component } from 'react'
import _debug from 'debug'
import axios from 'axios'
import moment from 'moment'
import classNames from 'classnames'
import { formatMoney } from 'accounting'
import SaveButton from '../../shared/SaveButton/SaveButton'
import { handleError } from '../../../util/error-util'
import { toast } from '../../../store/actions/app'
import Field from '../../shared/Field/Field'
import DeleteButton from '../../shared/DeleteButton/DeleteButton'
import confirm from '../../shared/confirm/confirm'
import TitleBar from '../../shared/TitleBar/TitleBar'
import ActionBar from '../../shared/ActionBar/ActionBar'

const debug = _debug('carwash:components:UserSignupPage')

type Props = {
  subscriptions: Object[],
  urlPrefix: string
}

type State = {
  plans: Object[],
  activePlanId: Number,
  effectiveDate: String,
  saving: Boolean
}

export default class ChangePlan extends Component<Props, State> {
  state = {
    plans: [],
    activePlanId: null,
    effectiveDate: moment().format('YYYY-MM-DD'),
    saving: false
  }

  async componentDidMount() {
    this.setPlan()

    try {
      const res = await axios.get('/c/plans')
      const { plans } = res.data
      this.setState({ plans })
    } catch (err) {
      debug('[componentDidMount] error: ', err)
      handleError(err)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.subscriptions !== prevProps.subscriptions) {
      this.setPlan()
    }
  }

  setPlan = () => {
    const sub = this.props.subscriptions[0]
    if (sub) {
      this.setState({ activePlanId: sub.items.data[0].plan.id })
    }
  }

  onSaveClick = async () => {
    try {
      const { customer, urlPrefix } = this.props
      const { activePlanId, effectiveDate } = this.state
      this.setState({ saving: true })
      toast('Saving...')
      await axios.post(`${urlPrefix}/customers/${customer.id}/set_plan`, {
        plan: activePlanId,
        date: effectiveDate
      })
      toast('Saved')
    } catch (err) {
      handleError(err)
    } finally {
      this.setState({ saving: false })
    }
  }

  onDeleteClick = async () => {
    try {
      if (!(await confirm())) {
        return
      }

      const { customer, urlPrefix } = this.props
      this.setState({ saving: true })
      toast('Saving...')
      const res = await axios.post(
        `${urlPrefix}/customers/${customer.id}/cancel_plan`
      )
      const sub = res.data

      const message = `Saved. ${
        customer.first_name
      }'s account will become inactive after ${moment(
        sub.current_period_end * 1000
      ).format('M/D/YYYY')}`

      toast(message, 8000)
    } catch (err) {
      handleError(err)
    } finally {
      this.setState({ saving: false })
    }
  }

  render() {
    const { customer, match } = this.props
    const { plans, activePlanId, effectiveDate, saving } = this.state
    if (!plans.length) {
      return 'Loading...'
    }

    return (
      <div className="ChangePlan">
        <TitleBar
          backTo={match.url.replace('/change-plan', '')}
          label={`Change ${customer.first_name}'s membership plan`}
        />
        <div className="plans">
          {plans
            .filter(p => p.type === 'PLAN')
            .map(p => (
              <div
                key={p.id}
                className={classNames('plan', {
                  active: p.id === activePlanId
                })}
                onClick={() => this.setState({ activePlanId: p.id })}
              >
                <span className="name">{p.name}</span>
                <span className="price">
                  {formatMoney(p.amount / 100)} per {p.interval}
                </span>
              </div>
            ))}
        </div>
        <Field
          type="date"
          label="Effective date:"
          value={effectiveDate}
          onChange={e => this.setState({ effectiveDate: e.target.value })}
        />
        <ActionBar>
          <SaveButton disabled={saving} onClick={this.onSaveClick} />
          <DeleteButton disabled={saving} onClick={this.onDeleteClick}>
            Cancel Subscription
          </DeleteButton>
        </ActionBar>
      </div>
    )
  }
}
