import { getUser } from './store/util'
import _debug from 'debug'
import { v4 as uuid } from 'uuid'
import { createUserActivity } from './store/actions/userActivities'

const debug = _debug('carwash:analytics')

let auid
try {
  auid = localStorage.getItem('auid')
  if (!auid) {
    auid = uuid()
    localStorage.setItem('auid', auid)
  }
} catch (err) {
  console.error(err)
}

export async function log(type: String, data: Object = {}) {
  data.auid = auid
  const user = getUser()
  const userActivity = await createUserActivity({
    user,
    type,
    data: JSON.stringify(data)
  })

  debug('[log] userActivity=', userActivity)
}

export function makeLog(type: String) {
  return function scopedLog(data: Object) {
    return log(type, data)
  }
}

export function signup() {
  try {
    var priceSelector = document.querySelector('div.total').innerText.split('$')[1]
    var transactionID = new Date().toString()
    window.ga('gtm4.require', 'ecommerce')
    window.ga('gtm4.ecommerce:addTransaction', {
      id: transactionID,
      revenue: priceSelector
    })
    window.ga('gtm4.ecommerce:send')
  } catch (err) {
    console.error(err)
  }
}
