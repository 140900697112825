import _ from 'lodash'

const initialState = []

export default function plans(state = initialState, action) {
  let plans, index
  switch (action.type) {
    case 'SET_PLANS':
      plans = [...state, ...action.plans]
      return _.uniqBy(plans, m => m.id)
    case 'SET_PLAN':
      plans = [...state]
      index = _.findIndex(plans, m => m.id === action.plan.id)
      if (index === -1) {
        plans.push(action.plan)
      } else {
        plans.splice(index, 1, action.plan)
      }

      return plans
    case 'ADD_PLAN':
      return [action.plan].concat(state)
    case 'UPDATE_PLAN':
      plans = [...state]
      index = _.findIndex(plans, m => m.id === action.plan.id)
      plans.splice(index, 1, action.plan)
      return plans
    case 'DELETE_PLAN':
      plans = state.filter(m => m.id !== action.plan.id)
      return plans
    default:
      return state
  }
}
