const initialState = {
  didLoad: false,
  user: null,
  org: {},
  settings: {},
  toast: null,
  appData: {},
  signupInfo: null,
  showCovidBar: false,
  navigationEnabled: true
}

export default function app(state = initialState, action) {
  switch (action.type) {
    case 'SET_SESSION':
      return {
        ...state,
        didLoad: true,
        user: action.user,
        org: {
          ...state.org,
          ...action.org
        },
        settings: {
          ...state.settings,
          ...action.settings
        }
      }
    case 'SET_TOAST':
      return {
        ...state,
        toast: action.message
      }
    case 'SET_APP_DATA':
      return {
        ...state,
        appData: {
          ...state.appData,
          [action.key]: action.value
        }
      }
    case 'SET_SIGNUP_INFO':
      return {
        ...state,
        signupInfo: action.signupInfo
      }
    case 'UPDATE_CURRENT_USER':
      return {
        ...state,
        user: action.user
      }
    case 'SHOW_COVID_BAR':
      return {
        ...state,
        showCovidBar: action.showCovidBar
      }
    case 'ENABLE_NAVIGATION':
      return {
        ...state,
        navigationEnabled: true
      }
    case 'DISABLE_NAVIGATION':
      return {
        ...state,
        navigationEnabled: false
      }
    default:
      return state
  }
}
