import axios from 'axios'
import querystring from 'querystring'
import _debug from 'debug'
import { store } from '../store'
import Car from '../../models/Car'

const debug = _debug('carwash:store:actions:cars')

export async function loadCars(urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/cars`)
    const cars = res.data.results.map(data => new Car(data))
    store.dispatch({ type: 'SET_CARS', cars })
    return cars
  } catch (err) {
    debug('[loadCars] error: ', err)
    throw err
  }
}

export async function loadCarsForCustomer(customerId: Number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/customers/${customerId}/cars`)
    const cars = res.data.map(data => new Car(data))
    store.dispatch({ type: 'SET_CARS', cars })
    return cars
  } catch (err) {
    debug('[loadCars] error: ', err)
    throw err
  }
}

export async function loadCar(id: number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/cars/${id}`)
    const car = new Car(res.data)
    store.dispatch({ type: 'SET_CAR', car })
    return car
  } catch (err) {
    debug('[loadCar] error: ', err)
    throw err
  }
}

export async function saveCar(data: Object, urlPrefix: string = '') {
  return data.id ? updateCar(data, urlPrefix) : createCar(data, urlPrefix)
}

export async function createCar(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/cars`, data)
    const car = new Car(res.data)
    store.dispatch({ type: 'ADD_CAR', car })
    return car
  } catch (err) {
    debug('[createCar] error: ', err)
    throw err
  }
}

export async function updateCar(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.put(`${urlPrefix}/cars/${data.id}`, data)
    const car = new Car(res.data)
    store.dispatch({ type: 'UPDATE_CAR', car })
    return car
  } catch (err) {
    debug('[updateCar] error: ', err)
    throw err
  }
}

export async function deleteCar(data: Object, urlPrefix: string = '') {
  try {
    await axios.delete(`${urlPrefix}/cars/${data.id}`)
    store.dispatch({ type: 'DELETE_CAR', car: data })
  } catch (err) {
    debug('[deleteCar] error: ', err)
    throw err
  }
}

export async function searchCars(urlPrefix: string = '', searchQuery: Object) {
  try {
    const res = await axios.get(`${urlPrefix}/cars?${querystring.stringify(searchQuery)}`)
    const cars = res.data.results.map(data => new Car(data))
    return cars
  } catch (err) {
    debug('[searchCars] error: ', err)
    throw err
  }
}
