import './style.scss'

import React, { Component } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { loadCars } from '../../../store/actions/cars'
import Car from '../../../models/Car'
import { AddButton } from '../../shared'
import { ScreenWithClose } from '../../shared/Screen/Screen'
import CarForm from '../Form/CarForm'

type Props = {
  car: Car,
  cars: Car[],
  onChange: Function
}

type State = {
  showForm: Boolean
}

export class SelectOrAddCar extends Component<Props, State> {
  state = {
    showForm: false
  }

  componentDidMount() {
    const { cars, urlPrefix } = this.props
    if (!cars.length) {
      loadCars(urlPrefix)
    }
  }

  onSave = (car: Car) => {
    this.props.onChange(car)
    this.setState({ showForm: false })
  }

  onClick = (car: Car) => {
    this.props.onChange(car)
  }

  render() {
    const { car, cars } = this.props
    const { showForm } = this.state

    return (
      <div className="select-or-add SelectOrAddCar">
        <h3>
          <span>Vehicle</span>
          <AddButton onClick={() => this.setState({ showForm: true })} />
        </h3>
        {cars.map(addr => (
          <div
            className={classNames('item', { selected: car && addr.id === car.id })}
            key={addr.id}
            onClick={() => this.onClick(addr)}>
            {addr.toString()}
          </div>
        ))}
        {showForm ? (
          <ScreenWithClose onClose={() => this.setState({ showForm: false })}>
            <div className="select-or-add-portal">
              <h1>New Vehicle</h1>
              <CarForm cars={cars} onSave={this.onSave} />
            </div>
          </ScreenWithClose>
        ) : null}
      </div>
    )
  }
}

const mapState = state => ({
  cars: state.cars
})

export default connect(mapState)(SelectOrAddCar)
