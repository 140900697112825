import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import Button from '../Button/Button'
import axios from 'axios'

type Props = {
  initialValue?: String,
  url?: String,
  onSearch?: Function,
  onResult?: Function,
  onClear?: Function
}

type State = {
  value: String
}

export default class Search extends Component<Props, State> {
  state = {
    value: this.props.initialValue
  }

  static defaultProps = {
    initialValue: '',
    onClear: _.noop
  }

  onChange = (e: Event) => {
    this.setState({ value: e.target.value })
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.onClick()
    }
  }

  onClick = async () => {
    const { url, onSearch, onResult } = this.props
    const { value } = this.state
    if (onSearch) {
      onSearch(value)
    } else {
      const res = await axios.get(`${url}?q=${value}`)
      onResult(res.data)
    }
  }

  onClear = () => {
    this.setState({ value: '' })
    this.props.onClear()
  }

  render() {
    const { value } = this.state

    return (
      <div className="Search">
        <input
          type="text"
          placeholder="Search..."
          className={classNames({ empty: !value.trim() })}
          value={value}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
        />
        <Button className="clear" icon="x" onClick={this.onClear} />
        <Button className="search" icon="search" onClick={this.close} />
      </div>
    )
  }
}
