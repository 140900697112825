import './style.scss'

import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import Input from '../Input/Input'

type FieldType =
  | 'text'
  | 'textarea'
  | 'email'
  | 'number'
  | 'percent'
  | 'tel'
  | 'password'
  | 'checkbox'
  | 'checkboxgroup'
  | 'radio'
  | 'select'
  | 'date'
  | 'time'
  | 'duration'

type Props = {
  className?: string,
  name?: string,
  label: string,
  placeholder?: string,
  value?: any,
  checked?: boolean,
  disabled?: boolean,
  readonly?: boolean,
  autoFocus?: boolean,
  usePlaceholders?: boolean,
  useStaticPlaceholders?: boolean,
  type: FieldType,
  autoComplete?: String,
  options: any,
  children: any,
  onChange: Function,
  checkboxFirst?: Boolean
}

export default class Field extends Component<Props> {
  static defaultProps = {
    usePlaceholders: false
  }

  render() {
    const {
      className,
      name,
      label,
      placeholder,
      value,
      checked,
      disabled,
      readonly,
      type,
      autoComplete,
      onChange,
      options,
      children,
      usePlaceholders,
      useStaticPlaceholders,
      checkboxFirst,
      autoFocus
    } = this.props

    let input
    switch (type) {
      case 'text':
      case 'email':
      case 'password':
      case 'number':
      case 'percent':
      case 'tel':
      case 'date':
      case 'time':
      case 'currency':
      case 'file':
        input = (
          <Input
            type={type}
            name={name}
            className={type}
            label={usePlaceholders ? label : null}
            placeholder={placeholder}
            value={value != null ? value : ''}
            autoComplete={autoComplete}
            disabled={disabled}
            readOnly={readonly}
            onChange={onChange}
            autoFocus={autoFocus}
          />
        )
        break
      case 'checkbox':
      case 'radio':
        input = (
          <input
            type={type}
            name={name}
            checked={checked}
            autoComplete={autoComplete}
            disabled={disabled}
            readOnly={readonly}
            onChange={onChange}
          />
        )
        break
      case 'checkboxgroup':
        input = (
          <div className="checkboxgroup">
            {options.map(o => (
              <div key={o.value}>
                <label>
                  <input
                    type="checkbox"
                    name={name}
                    checked={value.includes(o.value)}
                    disabled={disabled}
                    readOnly={readonly}
                    onChange={e => {
                      if (e.target.checked) {
                        onChange({ target: { value: [...value, o.value] } })
                      } else {
                        onChange({ target: { value: value.filter(v => v !== o.value) } })
                      }
                    }}
                  />
                  <span>{o.label}</span>
                </label>
              </div>
            ))}
          </div>
        )
        break
      case 'select':
        input = (
          <select
            name={name}
            value={value != null ? value : ''}
            autoComplete={autoComplete}
            disabled={disabled}
            readOnly={readonly}
            onChange={onChange}>
            {options}
          </select>
        )
        break
      case 'textarea':
        input =
          disabled || readonly ? (
            <div className="textarea">{value != null ? value : ''}</div>
          ) : (
            <textarea
              name={name}
              value={value != null ? value : ''}
              autoComplete={autoComplete}
              disabled={disabled}
              readOnly={readonly}
              onChange={onChange}
            />
          )
        break
      case 'duration':
        const [val, unit] = value

        input = (
          <div className="duration">
            <input
              type="number"
              value={val != null ? val : ''}
              disabled={disabled}
              readonly={readonly}
              min="1"
              onChange={e => onChange('value', Number(e.target.value))}
            />
            <select
              value={unit != null ? unit : ''}
              disabled={disabled}
              readonly={readonly}
              onChange={e => onChange('unit', e.target.value)}>
              <option value="HOUR">Hour(s)</option>
              <option value="DAY">Day(s)</option>
              <option value="WEEK">Week(s)</option>
              <option value="MONTH">Month(s)</option>
            </select>
          </div>
        )
        break
      default:
        throw new Error(`Invalid type: ${type}`)
    }

    return (
      <div
        className={classNames('Field', className, {
          'no-placeholders': !usePlaceholders,
          'static-placeholders': useStaticPlaceholders
        })}>
        {['checkbox', 'radio'].includes(type) && checkboxFirst ? (
          <label>
            {input}
            {label}
          </label>
        ) : (
          <Fragment>
            {usePlaceholders ? null : <label>{label}</label>}
            {input}
          </Fragment>
        )}
        {children}
      </div>
    )
  }
}
