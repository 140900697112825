import _ from 'lodash'

const initialState = []

export default function emailusers(state = initialState, action) {
  let emailUsers, index
  switch (action.type) {
    case 'SET_EMAILUSERS':
      emailUsers = [...state, ...action.emailUsers]
      return _.uniqBy(emailUsers, m => m.id)
    case 'SET_EMAILUSER':
      emailUsers = [...state]
      index = _.findIndex(emailUsers, m => m.id === action.emailUser.id)
      if (index === -1) {
        emailUsers.push(action.emailUser)
      } else {
        emailUsers.splice(index, 1, action.emailUser)
      }

      return emailUsers
    case 'ADD_EMAILUSER':
      // Only want one in the store.
      return [action.emailUser]
    case 'UPDATE_EMAILUSER':
      emailUsers = [...state]
      index = _.findIndex(emailUsers, m => m.id === action.emailUser.id)
      emailUsers.splice(index, 1, action.emailUser)
      return emailUsers
    case 'DELETE_EMAILUSER':
      emailUsers = state.filter(m => m.id !== action.emailUser.id)
      return emailUsers
    default:
      return state
  }
}
