import _ from 'lodash'
import BaseModel from './BaseModel'
import Product from './Product'
import Shift from './Shift'
import User from './User'

export default class CorporateAccount extends BaseModel {
  id: Number
  company_name: String
  address: String
  url: String
  logo: String
  description: String
  recurring: Boolean
  date: String
  start: String
  end: String
  products: Product[]
  technicians: User[]
  shifts: Shift[]

  constructor(data: Object = {}) {
    super(data)
    this.products = (data.products || []).map(p => new Product(p))
    this.technicians = (data.technicians || []).map(u => new User(u))
    this.shifts = (data.shifts || []).map(s => new Shift(s))
  }

  asForm() {
    const data = _.clone(this)

    if (!data.shifts.length) {
      data.shifts = [
        {
          start_time: '08:00',
          end_time: '12:00',
          technicians: [],
          shift_products: []
        },
        {
          start_time: '13:00',
          end_time: '17:00',
          technicians: [],
          shift_products: []
        }
      ]
    }

    if (typeof data.products[0] === 'object') {
      data.products = data.products.map(p => p.id)
    }

    return data
  }

  toString() {
    return `${this.company_name} @ ${this.address}`
  }
}
