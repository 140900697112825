import './style.scss'

import React, { Component, Fragment } from 'react'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import { formatMoney } from 'accounting'
import { createPortal } from 'react-dom'
import Button from '../../shared/Button/Button'
import { Product, EmailUser, Event } from '../../../models'
import Input from '../../shared/Input/Input'
import { handleError } from '../../../util/error-util'
import { calculateTotals } from '../../../util/number-util'
import { connect } from 'react-redux'
import { getSlot } from '../../../util/date-util'

type Props = {
  selection: Product,
  addOns: Product[],
  appointmentTime: String,
  appointmentEvent: Event,
  emailuser?: EmailUser,
  onRemoveProduct: Function,
  onRemoveAddOn: Function,
  onDiscountApplied: Function,
  inline?: Boolean,
  showCovidBar?: Boolean
}

type State = {
  discountCode: String,
  discountAmount: Number,
  discountPercent: Number,
  discountApplied: Boolean,
  saving: Boolean
}

let initialState = {
  discountCode: '',
  discountAmount: 0,
  discountPercent: 0,
  discountApplied: false,
  saving: false
}

export class Cart extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.node = document.createElement('div')
    document.body.appendChild(this.node)

    this.state = {
      ..._.cloneDeep(initialState),
      ...props.shoppingCart
    }

    if (props.emailuser) {
      this.state.discountApplied = true
      this.state.discountCode = '__EMAILUSER__'
      this.state.discountPercent = 0.1
      this.autoApply = true
    }
  }

  componentDidMount() {
    const { shoppingCart } = this.props
    if (shoppingCart.discountCode && !shoppingCart.discountApplied) {
      this.onApplyDiscountClick()
    } else if (this.autoApply) {
      this.initDiscount()
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state !== prevState) {
      initialState = _.cloneDeep(this.state)
    }
  }

  componentWillUnmount() {
    this.node.remove()
  }

  initDiscount = () => {
    const { onDiscountApplied } = this.props
    const { discountCode, discountPercent, discountAmount } = this.state
    onDiscountApplied(discountCode, discountPercent, discountAmount)
  }

  onApplyDiscountClick = () => {
    const { discountApplied, saving } = this.state
    if (discountApplied || saving) {
      return
    }

    this.setState({ saving: true }, async () => {
      try {
        const { onDiscountApplied } = this.props
        const { discountCode } = this.state
        const res = await axios.post('/apply-discount', { discountCode })
        const { discountPercent, discountAmount } = res.data
        this.setState({ saving: false, discountApplied: true, discountPercent, discountAmount })
        onDiscountApplied(discountCode, discountPercent, discountAmount)
      } catch (err) {
        this.setState({ saving: false })
        handleError(err, true)
      }
    })
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.onApplyDiscountClick()
    }
  }

  getSlot = () => {
    return getSlot(this.props.appointmentEvent)
  }

  render() {
    const {
      selection,
      addOns,
      appointmentTime,
      emailuser,
      onRemoveProduct,
      onRemoveAddOn,
      inline,
      showCovidBar
    } = this.props

    if (!selection) {
      return null
    }

    const { discountCode, discountApplied, discountPercent, discountAmount, saving } = this.state

    const { subtotal, total } = calculateTotals(selection, addOns, discountPercent, discountAmount)

    const ui = (
      <div className={classNames('Cart', { inline, 'show-covid-bar': showCovidBar })}>
        <h3>Cart</h3>
        <div className="selection">
          <h4>SELECTION</h4>
          <div className="gridfields">
            <div>
              <span>Name</span>
              <span>{selection.name}</span>
            </div>
            <div>
              <span>Price</span>
              <span>{formatMoney(selection.amount / 100)}</span>
            </div>
            {appointmentTime ? (
              <div>
                <span>Date</span>
                <span>{moment(appointmentTime).format('dddd, MMM. D h:mmA')}</span>
              </div>
            ) : null}
          </div>
          <div className="footer">
            <Button type="plain" onClick={onRemoveProduct}>
              Remove
            </Button>
          </div>
        </div>
        {addOns.length ? (
          <div className="addons">
            <h4>ADD-ONS</h4>
            {addOns.map(p => (
              <div key={p.id} className="addon">
                <div className="gridfields">
                  <div>
                    <span>Name</span>
                    <span>{p.name}</span>
                  </div>
                  <div>
                    <span>Price</span>
                    <span>{formatMoney(p.amount / 100)}</span>
                  </div>
                </div>
                <div className="footer">
                  <Button type="plain" onClick={() => onRemoveAddOn(p)}>
                    Remove
                  </Button>
                </div>
              </div>
            ))}
          </div>
        ) : null}
        {emailuser ? null : (
          <div className="discount-section">
            <h4>DISCOUNT</h4>
            <div className="discount-input">
              <Input
                disabled={saving || discountApplied}
                label="Discount code"
                value={discountCode}
                onChange={e => this.setState({ discountCode: e.target.value })}
                onKeyDown={this.onKeyDown}
              />
              <Button
                disabled={saving || discountApplied}
                type="primary"
                onClick={this.onApplyDiscountClick}>
                Apply
              </Button>
            </div>
          </div>
        )}
        <div className="totals">
          <div className="gridfields">
            {discountApplied ? (
              <Fragment>
                <div className="subtotal">
                  <span>Subtotal</span>
                  <span>{formatMoney(subtotal / 100)}</span>
                </div>
                <div className="discount-discountApplied">
                  <span>Discount</span>
                  {discountPercent ? <span>-{Math.round(discountPercent * 100)}%</span> : null}
                  {discountAmount ? <span>-{formatMoney(discountAmount / 100)}</span> : null}
                </div>
              </Fragment>
            ) : null}
            <div className="total">
              <span>Total</span>
              <span>{formatMoney(total / 100)}</span>
            </div>
          </div>
        </div>
      </div>
    )

    return inline || window.innerWidth <= 999 ? ui : createPortal(ui, this.node)
  }
}

const mapState = state => {
  const { app, shoppingCart } = state
  const { showCovidBar } = app

  return {
    shoppingCart,
    showCovidBar
  }
}

export default connect(mapState)(Cart)
