import axios from 'axios'
import _debug from 'debug'
import { store } from '../store'
import CorporateAccount from '../../models/CorporateAccount'

const debug = _debug('carwash:store:actions:corporateAccounts')

export async function loadCorporateAccounts(urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/corporate_accounts`)
    const corporateAccounts = res.data.results.map(data => new CorporateAccount(data))
    store.dispatch({ type: 'SET_CORPORATE_ACCOUNTS', corporateAccounts })
    return corporateAccounts
  } catch (err) {
    debug('[loadCorporateAccounts] error: ', err)
    throw err
  }
}

export async function loadCorporateAccount(id: number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/corporate_accounts/${id}`)
    const corporateAccount = new CorporateAccount(res.data)
    store.dispatch({ type: 'SET_CORPORATE_ACCOUNT', corporateAccount })
    return corporateAccount
  } catch (err) {
    debug('[loadCorporateAccount] error: ', err)
    throw err
  }
}

export async function saveCorporateAccount(data: FormData, urlPrefix: string = '') {
  return data.has('id') && data.get('id')
    ? updateCorporateAccount(data, urlPrefix)
    : createCorporateAccount(data, urlPrefix)
}

export async function createCorporateAccount(data: FormData, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/corporate_accounts`, data)
    const corporateAccount = new CorporateAccount(res.data)
    store.dispatch({ type: 'ADD_CORPORATE_ACCOUNT', corporateAccount })
    return corporateAccount
  } catch (err) {
    debug('[createCorporateAccount] error: ', err)
    throw err
  }
}

export async function updateCorporateAccount(data: FormData, urlPrefix: string = '') {
  try {
    const res = await axios.put(`${urlPrefix}/corporate_accounts/${data.get('id')}`, data)
    const corporateAccount = new CorporateAccount(res.data)
    store.dispatch({ type: 'UPDATE_CORPORATE_ACCOUNT', corporateAccount })
    return corporateAccount
  } catch (err) {
    debug('[updateCorporateAccount] error: ', err)
    throw err
  }
}

export async function deleteCorporateAccount(data: Object, urlPrefix: string = '') {
  try {
    await axios.delete(`${urlPrefix}/corporate_accounts/${data.id}`)
    store.dispatch({ type: 'DELETE_CORPORATE_ACCOUNT', corporateAccount: data })
  } catch (err) {
    debug('[deleteCorporateAccount] error: ', err)
    throw err
  }
}
