import './style.scss'

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { isAdmin, getAppData } from '../../../store/util'
import AddButton from '../../shared/AddButton/AddButton'
import TitleBar from '../../shared/TitleBar/TitleBar'
import Search from '../../shared/Search/Search'
import { loadEmployees } from '../../../store/actions/employees'
import { setAppData } from '../../../store/actions/app'

type Props = {
  employees: Object[],
  urlPrefix: string,
  location: Object,
  history: Object,
  match: Object
}

export default class EmployeeList extends Component<Props> {
  onAddClick = () => {
    const { location, history } = this.props
    history.push(`${location.pathname}/new`)
  }

  onSearch = (q: string) => {
    const { urlPrefix } = this.props
    loadEmployees(urlPrefix, `?q=${q}`)
    setAppData('employee.search', q)
  }

  onClear = () => {
    const { urlPrefix } = this.props
    loadEmployees(urlPrefix)
    setAppData('employee.search', '')
  }

  render() {
    const { employees, match } = this.props

    const { url } = match
    return (
      <div className="EmployeeList">
        <TitleBar label="Technicians">
          {isAdmin() ? <AddButton onClick={this.onAddClick} /> : null}
          <Search
            initialValue={getAppData('employee.search')}
            onSearch={this.onSearch}
            onClear={this.onClear}
          />
        </TitleBar>
        <div className="list list-primary">
          {employees.map(m => (
            <Link key={m.id} className="plain" to={`${url}/${m.id}`}>
              <span>
                {m.last_name}, {m.first_name}
              </span>
              <span>{m.email}</span>
            </Link>
          ))}
        </div>
      </div>
    )
  }
}
