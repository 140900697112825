import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { Button, SaveButton, CollapsibleSection, Form, Field, ActionBar, TitleBar } from '../shared'
import axios from 'axios'
import { toast, logout, updateCurrentUser } from '../../store/actions/app'
import { handleError } from '../../util/error-util'
import User from '../../models/User'
import Appointments from '../Appointments'
import Addresses from '../Address'
import Cars from '../Cars'
import { icons } from '../../images'
import CustomerForm from '../Customers/Form/CustomerForm'
import StripeRedirect from '../StripeForm/StripeRedirect'

type Props = {
  user: User,
  stripe: Object,
  location: Location,
  history: Object,
  match: Object
}

type State = {
  password: String,
  saving: Boolean
}

export class Account extends Component<Props, State> {
  updateInfoSection: CollapsibleSection
  updateCcSection: CollapsibleSection
  changePasswordSection: CollapsibleSection

  state = {
    password: '',
    saving: false
  }

  componentDidMount() {
    try {
      const params = new URLSearchParams(window.location.search)
      if (params.get('stripesuccess') === 'true') {
        toast('Card updated')
      }
    } catch (err) {
      console.error(err)
    }
  }

  onSavePassword = async () => {
    try {
      this.setState({ saving: true })
      const { password } = this.state
      toast('Saving...')
      await axios.post(`/change_password`, { password })
      this.setState({ password: '' })
      toast('Saved')
      if (this.changePasswordSection) {
        this.changePasswordSection.close()
      }
    } catch (err) {
      handleError(err)
    } finally {
      this.setState({ saving: false })
    }
  }

  onCustomerInfoSave = (user: User) => {
    updateCurrentUser(user)
    if (this.updateInfoSection) {
      this.updateInfoSection.close()
    }
  }

  onUpdateCardSuccess = (token: Object) => {
    this.setState({ saving: true }, async () => {
      try {
        toast('Saving...')
        await axios.post(`/c/account/update_cc`, { token: token.id })
        toast('Saved')
        if (this.updateCcSection) {
          this.updateCcSection.close()
        }
      } catch (err) {
        handleError(err)
      } finally {
        this.setState({ saving: false })
      }
    })
  }

  isValid = () => {
    const { password, saving } = this.state
    return !saving && password.length > 6
  }

  render() {
    const { user, match } = this.props
    const { password } = this.state
    const { url, path } = match

    return (
      <div className="Account">
        <Route
          exact
          path={path}
          render={props => (
            <div className="account-content">
              <TitleBar backTo="/" label={user.getFullName()} />
              <div className="links">
                <Link to={`${url}/appointments`}>
                  <span>Appointments</span>
                  <img src={icons.arrowDown} alt="arrow-right" />
                </Link>
                <Link to={`${url}/vehicles`}>
                  <span>Vehicles</span>
                  <img src={icons.arrowDown} alt="arrow-right" />
                </Link>
                <Link to={`${url}/addresses`}>
                  <span>Addresses</span>
                  <img src={icons.arrowDown} alt="arrow-right" />
                </Link>
              </div>

              <CollapsibleSection label="Update Info" ref={inst => (this.updateInfoSection = inst)}>
                <CustomerForm customer={user} onSave={this.onCustomerInfoSave} />
              </CollapsibleSection>

              <CollapsibleSection
                label="Change Password"
                ref={inst => (this.changePasswordSection = inst)}>
                <Form onSubmit={this.onSavePassword}>
                  <Field
                    type="password"
                    label="New password:"
                    value={password}
                    onChange={e => this.setState({ password: e.target.value })}
                  />
                  <ActionBar>
                    <SaveButton onClick={this.onSavePassword} disabled={!this.isValid()} />
                  </ActionBar>
                </Form>
              </CollapsibleSection>

              <CollapsibleSection
                label="Update credit card"
                ref={inst => (this.updateCcSection = inst)}>
                <StripeRedirect
                  successUrl={`${match.url}?stripesuccess=true`}
                  cancelUrl={`${match.url}?stripecancel=true`}
                />
              </CollapsibleSection>

              <ActionBar>
                <Button className="logout" type="secondary" icon="logout" onClick={logout}>
                  Logout
                </Button>
              </ActionBar>
            </div>
          )}
        />

        <Route
          path={`${path}/appointments`}
          render={props => <Appointments backTo={url} {...props} />}
        />
        <Route path={`${path}/vehicles`} render={props => <Cars backTo={url} {...props} />} />
        <Route path={`${path}/addresses`} render={props => <Addresses backTo={url} {...props} />} />
      </div>
    )
  }
}

const mapState = state => ({
  user: state.app.user
})

export default connect(mapState)(Account)
