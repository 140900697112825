import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { formatMoney } from 'accounting'
import { isAdmin } from '../../../store/util'
import AddButton from '../../shared/AddButton/AddButton'
import TitleBar from '../../shared/TitleBar/TitleBar'
import Field from '../../shared/Field/Field'

type Props = {
  products: Object[],
  location: Object,
  history: Object,
  match: Object
}

type State = {
  filter: any
}

export default class ProductList extends Component<Props, State> {
  state = {
    filter: ''
  }

  onAddClick = () => {
    const { location, history } = this.props
    history.push(`${location.pathname}/new`)
  }

  render() {
    const { products, match } = this.props
    const { filter } = this.state
    const { url } = match

    let list = filter ? products.filter(p => p.type === filter) : products
    list = _.sortBy(list, 'sort_order')

    return (
      <div className="ProductList">
        <TitleBar label="Products">
          {isAdmin() ? <AddButton onClick={this.onAddClick} /> : null}
        </TitleBar>
        <div className="filter-bar">
          <Field
            type="select"
            label="Filter by type:"
            value={filter}
            onChange={e => this.setState({ filter: e.target.value })}
            options={[
              <option key="" value="">
                Show All
              </option>,
              <option key="WASH" value="WASH">
                Wash
              </option>,
              <option key="CORPORATE_WASH" value="CORPORATE_WASH">
                Corporate Wash
              </option>,
              <option key="DETAIL" value="DETAIL">
                Detail
              </option>,
              <option key="SPECIAL" value="SPECIAL">
                Special
              </option>,
              <option key="CORPORATE_SPECIAL" value="CORPORATE_SPECIAL">
                Corporate Special
              </option>,
              <option key="ADD_ON" value="ADD_ON">
                Add-on
              </option>
            ]}
          />
        </div>
        <div className="list list-primary">
          {list.map(product => (
            <Link key={product.id} className="plain" to={`${url}/${product.id}`}>
              <span>{product.name}</span>
              <span>{product.type}</span>
              <span>
                {formatMoney(product.amount / 100)} {product.interval ? '/' : ''} {product.interval}
              </span>
            </Link>
          ))}
        </div>
      </div>
    )
  }
}
