import BaseModel from './BaseModel'

export default class Charge extends BaseModel {
  event_id: Number
  stripe_initial_charge: String
  stripe_final_charge: String
  tip_percent: Number
  tip_amount: Number
  amount: Number
  payment_api: Number
  should_charge_date: Date
  charged_date: Date

  constructor(data: Object = {}) {
    super(data)

    if (typeof this.stripe_initial_charge === 'string' && this.stripe_initial_charge.length) {
      this.stripe_initial_charge = JSON.parse(this.stripe_initial_charge)
    }

    if (typeof this.stripe_final_charge === 'string' && this.stripe_final_charge.length) {
      this.stripe_final_charge = JSON.parse(this.stripe_final_charge)
      this.amount = this.stripe_final_charge.amount
    }
  }

  getTip() {
    let tip = 0
    if (this.tip_amount) {
      tip = this.tip_amount
    } else if (this.tip_percent) {
      const total = this.amount || 0
      tip = Math.round(total * this.tip_percent)
    }

    return tip
  }

  getTotal() {
    return this.amount + this.getTip()
  }

  getCombinedTotal() {
    if (this.payment_api !== 2) {
      return this.amount
    }

    if (!this.stripe_final_charge) {
      return this.amount
    }

    return this.stripe_final_charge.amount + this.stripe_initial_charge.amount
  }

  getPendingAmount() {
    if (this.payment_api === 2 && this.stripe_initial_charge && !this.stripe_final_charge) {
      return this.getTotal() - this.stripe_initial_charge.amount
    }

    return 0
  }
}
