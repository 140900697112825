import './style.scss'

import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import stamp from '../../../assets/icon-covid-stamp-senitizeshine.png'
import imgBasicDisinfect from '../../../assets/icon-covid-basic-disinfectant.png'
import imgInterior from '../../../assets/icon-covid-complete-interior.png'
import imgKidSafe from '../../../assets/icon-covid-child-safe.png'
import imgEpa from '../../../assets/icon-covid-epa-approved.png'
import imgNonToxic from '../../../assets/icon-covid-non-toxic.png'
import imgPetSafe from '../../../assets/icon-covid-pet-safe.png'

type Props = {
  onClose: Function
}

export default function CovidPage(props: Props) {
  const user = useSelector((state) => state.app.user)
  const ctaUrl = user ? '/book' : '/signup'

  return (
    <div className="CovidPage">
      <div className="banner">
        <h1>Disinfect &amp; Protect Your Vehicle</h1>
        <img className="stamp" src={stamp} alt="stamp" />
        <div>
          <Link className="button primary caps" to={ctaUrl}>
            Get started
          </Link>
        </div>
      </div>

      <div className="point">
        <div className="header">
          <div className="img-wrapper">
            <img src={imgBasicDisinfect} alt="feature" />
          </div>
          <div className="title">
            <h2>Basic Disinfectant</h2>
            <h3>Hard Surface points of frequent contact</h3>
          </div>
        </div>
        <div className="body">
          <p>
            We add this service to every interior package at no additional charge. EPA approved
            sanitizer kills 99.9% of bacteria and viruses on hard surfaces. Our Technicians wipe
            down hard surface points of frequent contact protecting you for up to 7 days.
          </p>
        </div>
      </div>

      <div className="point">
        <div className="header">
          <div className="img-wrapper">
            <img src={imgInterior} alt="feature" />
          </div>
          <div className="title">
            <h2>Complete Interior Disinfectact</h2>
            <h3>Hard and Soft Surfaces, Entire Interior Sanitized</h3>
          </div>
        </div>
        <div className="body">
          <p>
            EPA approved sanitizer kills 99.9% of bacteria and viruses on all surfaces of your
            vehicle. Our Technicians treat every interior surface of your vehicle to give you peace
            of mind. You can add this service to any interior package when booking.
          </p>
        </div>
      </div>

      <div className="point">
        <div className="header">
          <div className="title">
            <h2 style={{ textAlign: 'center' }}>Your Safety</h2>
          </div>
        </div>
        <div className="body">
          <p>
            The EPA and CDC have stated that if a product has been proven to kill viruses it is
            considered to be effective against COVID-19. COVID-19 is a new virus and yet to be
            tested.
          </p>
        </div>
      </div>

      <div className="images">
        <div className="image">
          <img src={imgKidSafe} alt="feature" />
          <span className="caps">Child safe</span>
        </div>
        <div className="image">
          <img src={imgPetSafe} alt="feature" />
          <span className="caps">Pet safe</span>
        </div>
        <div className="image">
          <img src={imgEpa} alt="feature" />
          <span className="caps">EPA approved</span>
        </div>
        <div className="image">
          <img src={imgNonToxic} alt="feature" />
          <span className="caps">Non-toxic</span>
        </div>
      </div>
    </div>
  )
}
