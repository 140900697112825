import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import Form from '../../shared/Form/Form'
import Field from '../../shared/Field/Field'
import SaveButton from '../../shared/SaveButton/SaveButton'
import { savePlan, deletePlan } from '../../../store/actions/plans'
import { handleError } from '../../../util/error-util'
import { toast } from '../../../store/actions/app'
import Plan from '../../../models/Plan'
import DeleteButton from '../../shared/DeleteButton/DeleteButton'
import confirm from '../../shared/confirm/confirm'
import history from '../../../history'
import ActionBar from '../../shared/ActionBar/ActionBar'

type Props = {
  plan?: Plan,
  plans: Plan[],
  urlPrefix: string,
  onSave: Function
}

type State = {
  plan: ?Plan,
  saving: boolean
}

const CONFIRM_DELETE_MESSAGE =
  'Are you sure? All customers subscribed to this plan will need to be added to a new plan.'

export default class PlanForm extends Component<Props, State> {
  state = {
    plan: null,
    saving: false
  }

  static defaultProps = {
    onSave: _.noop
  }

  componentDidMount() {
    this.setPlan()
  }

  componentDidUpdate(prevProps) {
    if (this.props.plan !== prevProps.plan) {
      this.setPlan()
    }
  }

  setPlan = () => {
    const plan = new Plan(this.props.plan)
    this.setState({ plan })
  }

  onChange = (prop: string, value: any) => {
    const plan = { ...this.state.plan }
    _.set(plan, prop, value)
    this.setState({ plan })
  }

  onSubmit = async () => {
    try {
      const { urlPrefix, onSave } = this.props
      let plan = _.cloneDeep(this.state.plan)

      if (typeof plan.amount === 'string') {
        plan.amount = Number(plan.amount)
      }

      plan.amount *= 100

      if (!plan.description.trim()) {
        plan.description = null
      }

      plan.metadata.sort_order = Number(plan.metadata.sort_order)

      if (_.isObject(plan.product)) {
        plan.product = plan.product.id
      }

      this.setState({ saving: true })

      toast('Saving...')
      const result = await savePlan(plan, urlPrefix)
      toast('Saved')

      this.setState({ saving: false })
      onSave(result)
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  onDeleteClick = async () => {
    try {
      if (!(await confirm({ message: CONFIRM_DELETE_MESSAGE }))) {
        return
      }

      const { urlPrefix } = this.props
      const { plan } = this.state

      this.setState({ saving: true })

      toast('Saving...')
      await deletePlan(plan, urlPrefix)
      toast('Saved')

      history.goToListView()
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  onAddFeatureClick = (e: Event) => {
    e.preventDefault()
    const plan: Plan = _.cloneDeep(this.state.plan)
    plan.features.push({ type: '', durationValue: 8, durationUnit: 'HOUR' })
    this.setState({ plan })
  }

  onRemoveFeatureClick = (e: Event, feature: Object) => {
    e.preventDefault()
    const { plan } = this.state
    plan.features = plan.features.filter(f => f !== feature)
    this.forceUpdate()
  }

  onFeatureChange = (feature: Object, prop: String, value: any) => {
    feature[prop] = value
    this.forceUpdate()
  }

  onFeatureDurationChange = (feature: Object, prop: String, value: any) => {
    if (prop === 'unit') {
      feature.durationUnit = value
    } else {
      feature.durationValue = value
    }

    this.forceUpdate()
  }

  render() {
    const { plans } = this.props
    const { plan, saving } = this.state

    if (!plan) {
      return null
    }

    return (
      <div className="PlanForm">
        <Form onSubmit={this.onSubmit}>
          <Field
            type="text"
            label="Name:"
            value={plan.nickname}
            onChange={e => this.onChange('nickname', e.target.value)}
          />
          <Field
            type="select"
            label="Type:"
            value={plan.metadata.type}
            onChange={e => this.onChange('metadata.type', e.target.value)}
            options={[
              <option key="PLAN" value="PLAN">
                Plan
              </option>,
              <option key="CHILD_PLAN" value="CHILD_PLAN">
                Sub-plan
              </option>,
              <option key="ADD_ON" value="ADD_ON">
                Add-on
              </option>
            ]}
          />
          {plan.metadata.type === 'CHILD_PLAN' ? (
            <Field
              type="select"
              label="Parent:"
              value={plan.metadata.parentId}
              onChange={e => this.onChange('metadata.parentId', e.target.value)}
              options={plans.map(p => (
                <option key={p.id} value={p.id}>
                  {p.nickname}
                </option>
              ))}
            />
          ) : null}
          <Field
            type="textarea"
            label="Description:"
            value={plan.description}
            onChange={e => this.onChange('description', e.target.value)}
          />
          {plan.id ? null : (
            <Field
              type="currency"
              label="Amount:"
              value={plan.amount}
              onChange={e => this.onChange('amount', e.target.value)}
            />
          )}
          {plan.id ? null : (
            <Field
              type="select"
              label="Interval:"
              value={plan.interval}
              onChange={e => this.onChange('interval', e.target.value)}
              options={[
                <option key="day" value="day">
                  Day
                </option>,
                <option key="week" value="week">
                  Week
                </option>,
                <option key="month" value="month">
                  Month
                </option>,
                <option key="year" value="year">
                  Year
                </option>
              ]}
            />
          )}
          <Field
            type="percent"
            label="Discount for add-ons:"
            value={plan.metadata.addon_discount}
            onChange={e => this.onChange('metadata.addon_discount', e.target.value)}
          />
          <Field
            type="number"
            label="Sort order:"
            value={plan.metadata.sort_order}
            onChange={e => this.onChange('metadata.sort_order', e.target.value)}
          />
          <Field
            type="checkbox"
            label="Active:"
            checked={plan.active}
            onChange={e => this.onChange('active', e.target.checked)}
          />
          <Field
            type="text"
            label="Icon:"
            value={plan.metadata.icon_position}
            onChange={e => this.onChange('metadata.icon_position', e.target.value)}
          />
          <ActionBar>
            <SaveButton onClick={this.onSubmit} disabled={saving} />
            {plan.id ? <DeleteButton onClick={this.onDeleteClick} disabled={saving} /> : null}
          </ActionBar>
        </Form>
      </div>
    )
  }
}
