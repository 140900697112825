import _ from 'lodash'

const initialState = []

export default function events(state = initialState, action) {
  let events, index
  switch (action.type) {
    case 'SET_EVENTS':
      events = [...state, ...action.events]
      return _.uniqBy(events, m => m.id)
    case 'SET_EVENT':
      events = [...state]
      index = _.findIndex(events, m => m.id === action.event.id)
      if (index === -1) {
        events.push(action.event)
      } else {
        events.splice(index, 1, action.event)
      }

      return events
    case 'ADD_EVENT':
      return [action.event].concat(state)
    case 'UPDATE_EVENT':
      events = [...state]
      index = _.findIndex(events, m => m.id === action.event.id)
      events.splice(index, 1, action.event)
      return events
    case 'DELETE_EVENT':
      events = state.filter(m => m.id !== action.event.id)
      return events
    default:
      return state
  }
}
