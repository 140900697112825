import './style.scss'

import React from 'react'
import { Link } from 'react-router-dom'
import { getImageUrl } from '../../../../util/string-util'

type Props = {
  ctaUrl: String,
  noButtons: Boolean
}

export const ScheduleMyAppt = (props: Props) => {
  const { ctaUrl = '/signup', noButtons } = props

  return (
    <div className="ScheduleMyAppt">
      <div className="left">
        <h1>
          <span className="logo">
            <img src={getImageUrl('/static/logo.png')} alt="shine-rite" />
          </span>{' '}
          is here to make your day easier
        </h1>
      </div>
      <div className="right">
        {noButtons ? null : (
          <Link id="book-now-7" to={ctaUrl}>
            SCHEDULE MY APPOINTMENT
          </Link>
        )}
      </div>
    </div>
  )
}
