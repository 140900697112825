import './manage-location.scss'

import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import Car from '../../models/Car'
import { loadCars } from '../../store/actions/cars'
import CarForm from '../Cars/Form/CarForm'
import AddressForm from '../Address/Form/AddressForm'
import { Button, AddButton, BackButton, ActionBar, TitleBar } from '../shared'
import Address from '../../models/Address'
import { loadAddresses } from '../../store/actions/addresses'
import { ScreenWithClose } from '../shared/Screen/Screen'

type Props = {
  selectedCarId: Number,
  selectedAddressId: Number,
  cars: Car[],
  addresses: Address[],
  onClose: Function,
  showAddress: Boolean
}

type State = {
  car: Car,
  address: Address,
  selectedCarId: Number,
  selectedAddressId: Number,
  showCarForm: Boolean,
  showAddressForm: Boolean
}

export class ManageLocationInfo extends Component<Props, State> {
  state = {
    selectedCarId: this.props.selectedCarId,
    selectedAddressId: this.props.selectedAddressId,
    showCarForm: false,
    showAddressForm: false
  }

  static defaultProps = {
    showAddress: true
  }

  componentDidMount() {
    const { cars, addresses } = this.props
    if (!cars.length) {
      loadCars()
    }
    if (!addresses.length) {
      loadAddresses()
    }
  }

  onDoneClick = () => {
    const { cars, addresses, onClose } = this.props
    const { selectedCarId, selectedAddressId } = this.state

    const car = cars.find(c => c.id === selectedCarId)
    const address = addresses.find(a => a.id === selectedAddressId)

    onClose(car, address)
  }

  render() {
    const { cars, addresses, showAddress, onClose } = this.props
    const { selectedCarId, selectedAddressId, showCarForm, showAddressForm } = this.state

    const car = cars.find(c => c.id === selectedCarId)
    const address = addresses.find(a => a.id === selectedAddressId)

    return (
      <ScreenWithClose onClose={onClose}>
        <div className="ManageLocationInfo">
          {showCarForm ? (
            <Fragment>
              <TitleBar label={car && car.id ? 'Edit Vehicle' : 'New Vehicle'}>
                <BackButton onClick={() => this.setState({ showCarForm: false })} noLabel />
              </TitleBar>
              <CarForm
                car={car}
                cars={cars}
                onSave={car => this.setState({ showCarForm: false, selectedCarId: car.id })}
                saveButtonLabel="Done"
              />
            </Fragment>
          ) : showAddressForm ? (
            <Fragment>
              <TitleBar label={address && address.id ? 'Edit Address' : 'New Address'}>
                <BackButton onClick={() => this.setState({ showAddressForm: false })} noLabel />
              </TitleBar>
              <AddressForm
                address={address}
                onSave={address =>
                  this.setState({ showAddressForm: false, selectedAddressId: address.id })
                }
                saveButtonLabel="Done"
              />
            </Fragment>
          ) : (
            <Fragment>
              <div className="vehicles">
                <TitleBar label="Vehicles">
                  <AddButton
                    onClick={() => this.setState({ showCarForm: true, selectedCarId: null })}
                  />
                </TitleBar>
                <div className="list">
                  {cars.map(car => (
                    <div
                      key={car.id}
                      className={classNames('car', { selected: car.id === selectedCarId })}
                      onClick={() => this.setState({ selectedCarId: car.id })}>
                      <span>{car.toString()}</span>
                      <span>
                        <Button
                          type="plain"
                          onClick={() => {
                            this.setState({ selectedCarId: car.id, showCarForm: true })
                          }}>
                          Edit
                        </Button>
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              {showAddress ? (
                <div className="addresses">
                  <TitleBar label="Addresses">
                    <AddButton
                      onClick={() =>
                        this.setState({ showAddressForm: true, selectedAddressId: null })
                      }
                    />
                  </TitleBar>
                  <div className="list">
                    {addresses.map(addr => (
                      <div
                        key={addr.id}
                        className={classNames('address', {
                          selected: addr.id === selectedAddressId
                        })}
                        onClick={() => this.setState({ selectedAddressId: addr.id })}>
                        <span>{addr.toString()}</span>
                        <span>
                          <Button
                            type="plain"
                            onClick={() => {
                              this.setState({ selectedAddressId: addr.id, showAddressForm: true })
                            }}>
                            Edit
                          </Button>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}

              <ActionBar>
                <Button type="primary" onClick={this.onDoneClick}>
                  Done
                </Button>
              </ActionBar>
            </Fragment>
          )}
        </div>
      </ScreenWithClose>
    )
  }
}

const mapState = state => ({
  cars: state.cars,
  addresses: state.addresses
})

export default connect(mapState)(ManageLocationInfo)
