import './style.scss'

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TitleBar from '../../shared/TitleBar/TitleBar'
import UserActivity from '../../../models/UserActivity'
import moment from 'moment'
import { DATETIME_FORMAT } from '../../../contants'

type Props = {
  userActivities: UserActivity[],
  location: Object,
  history: Object,
  match: Object
}

export default class UserActivityList extends Component<Props> {
  onAddClick = () => {
    const { location, history } = this.props
    history.push(`${location.pathname}/new`)
  }

  render() {
    const { userActivities, match } = this.props
    const { url } = match
    return (
      <div className="UserActivityList">
        <TitleBar label="Customer Activity" />
        <div className="list list-primary">
          {userActivities.map(m => (
            <Link key={m.id} className="plain" to={`${url}/${m.id}`}>
              <span>{moment(m.created_date).format(DATETIME_FORMAT)}</span>
              <span>{m.data.auid}</span>
              <span>{m.type}</span>
            </Link>
          ))}
        </div>
      </div>
    )
  }
}
