import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { loadAddressesForCustomer } from '../../../store/actions/addresses'
import Address from '../../../models/Address'
import User from '../../../models/User'

type Props = {
  customer: User,
  addresses: Address[],
  urlPrefix: String
}

export class AddressList extends Component<Props> {
  componentDidMount() {
    const { customer, urlPrefix } = this.props
    loadAddressesForCustomer(customer.id, urlPrefix)
  }

  render() {
    const { addresses } = this.props

    return (
      <Fragment>
        <div className="header">Addresses</div>
        <div className="list list-primary">
          {addresses.map((address, i) => (
            <div key={i} className="address">
              <span>{address.toString()}</span>
              <span>{address.is_default ? <span className="default">(Default)</span> : null}</span>
            </div>
          ))}
        </div>
      </Fragment>
    )
  }
}

const mapState = state => ({
  addresses: state.addresses
})

export default connect(mapState)(AddressList)
