import axios from 'axios'
import _debug from 'debug'
import { store } from '../store'
import Event from '../../models/Event'

const debug = _debug('carwash:store:actions:reservations')

export async function loadReservations(urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/reservations`)
    store.dispatch({ type: 'SET_RESERVATIONS', reservations: res.data.results })
  } catch (err) {
    debug('[loadReservations] error: ', err)
    throw err
  }
}

export async function loadReservation(id: number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/reservations/${id}`)
    store.dispatch({ type: 'SET_RESERVATION', reservation: res.data })
  } catch (err) {
    debug('[loadReservation] error: ', err)
    throw err
  }
}

export async function checkPrice(event: Event) {
  try {
    const res = await axios.post('/reservations/check_price', event)
    const { priceInfo } = res.data
    return priceInfo
  } catch (err) {
    debug('[checkPrice] error: ', err)
    throw err
  }
}

export async function saveReservation(data: Object, urlPrefix: string = '') {
  return data.id
    ? updateReservation(data, urlPrefix)
    : createReservation(data, urlPrefix)
}

export async function createReservation(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/reservations`, data)
    const reservation = res.data
    store.dispatch({ type: 'ADD_RESERVATION', reservation })
    return reservation
  } catch (err) {
    debug('[createReservation] error: ', err)
    throw err
  }
}

export async function updateReservation(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.put(`${urlPrefix}/reservations/${data.id}`, data)
    const reservation = res.data
    store.dispatch({ type: 'UPDATE_RESERVATION', reservation })
    return reservation
  } catch (err) {
    debug('[updateReservation] error: ', err)
    throw err
  }
}

export async function deleteReservation(data: Object, urlPrefix: string = '') {
  try {
    await axios.delete(`${urlPrefix}/reservations/${data.id}`)
    store.dispatch({ type: 'DELETE_RESERVATION', reservation: data })
  } catch (err) {
    debug('[deleteReservation] error: ', err)
    throw err
  }
}
