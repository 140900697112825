import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

/**
 * Navigates from a detail view to a list view by
 * removing the last item of the url.
 */
history.goToListView = function goToListView() {
  const pathParts = this.location.pathname.split('/')
  pathParts.pop()
  this.replace(pathParts.join('/'))
}

export default history
