// @flow
import './style.scss'

import React, { Component } from 'react'
import AustinMap from '../AustinMap/AustinMap'

type Props = {
  street: string,
  city: string,
  state: string,
  zip: string
}

type State = {
  myState: string
}

export class LocationPreview extends Component<Props, State> {
  state = {
    myState: ''
  }

  render() {
    const { street, city, state, zip } = this.props
    let fullAddress = null
    if (street && city && state && zip) {
      fullAddress = `${street}, ${city}, ${state} ${zip}`
    }

    return (
      <div className="LocationPreview">
        <AustinMap geolocate={false} address={fullAddress} />
        <div className="bottom">{fullAddress}</div>
      </div>
    )
  }
}
