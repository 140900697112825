import './style.scss'

import React, { Component } from 'react'
import axios from 'axios'
import { handleError } from '../../util/error-util'
import { redirectToStripe } from '../../util/stripe-util'

type Props = {
  successUrl: String,
  cancelUrl: String
}

type State = {}

export default class StripeRedirect extends Component<Props, State> {
  state = {}

  async componentDidMount() {
    try {
      const { successUrl, cancelUrl } = this.props
      const res = await axios.post('/c/account/update_cc', {
        successUrl,
        cancelUrl
      })

      redirectToStripe(res.data.sessionId)
    } catch (err) {
      handleError(err, true)
    }
  }

  render() {
    return <div className="StripeRedirect">Loading...</div>
  }
}
