import './style.scss'

import React from 'react'
import { toast } from '../../../store/actions/app'

export function Toast({ message }) {
  return (
    <div className="Toast">
      <div className="content" onClick={() => toast('')}>
        {message}
      </div>
    </div>
  )
}
