import './style.scss'

import React from 'react'
import { Link } from 'react-router-dom'
import Icon1 from '../../../../assets/icon1.png'
import Icon2 from '../../../../assets/icon2.png'
import Icon3 from '../../../../assets/icon3.png'
import Icon4 from '../../../../assets/icon4.png'
// import ImageShineRight from '../../../../assets/image-shine-right.png'
import ImageShineRightGreen from '../../../../assets/image-shine-right-green.jpg'
import { isMobile } from '../../../../util/dom-util'

export const ShineRiteGreen = ({ noButtons = false }) =>
  isMobile() ? (
    <section className="ShineRiteGreen_Mobile">
      <img src={ImageShineRightGreen} alt="ImageShineRightGreen" />
      <p className="green-icon-text green">Shine Rite is Green!</p>
      {/* <img className="phone" src={ImageShineRight} alt="ImageShineRight" /> */}
      {noButtons ? null : (
        <Link id="book-now-5" to="/signup" className="button primary signup">
          Learn More
        </Link>
      )}
    </section>
  ) : (
    <div className="container">
      <section className="ShineRiteGreen">
        <div className="columns">
          <div className="column">
            <img src={ImageShineRightGreen} alt="ImageShineRightGreen" />
            <p className="green-icon-text green is-green">Shine Rite is Green!</p>
          </div>
          <div className="column">
            <img src={Icon1} className="green-icon-image" alt="Icon1" />
            <p className="green-icon-text">We use 95% less water than a traditional car wash.</p>
          </div>
          <div className="column">
            <img src={Icon2} className="green-icon-image" alt="Icon2" />
            <p className="green-icon-text">
              Our products are safe for our customers and the environment.
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column">{/* <img src={ImageShineRight} alt="ImageShineRight" /> */}</div>
          <div className="column green-icons">
            <img src={Icon3} className="green-icon-image" alt="Icon3" />
            <p className="green-icon-text">
              Use less gas, no driving to the car wash or running your vehicle while washing.
            </p>
          </div>
          <div className="column">
            <img src={Icon4} className="green-icon-image" alt="Icon4" />
            <p className="green-icon-text">
              Almost no water reaches the ground to run off or be absorbed.
            </p>
          </div>
        </div>
        <div className="signup-wrapper align-end">
          {noButtons ? null : (
            <Link id="book-now-6" to="/signup" className="button primary signup">
              Learn More
            </Link>
          )}
        </div>
      </section>
    </div>
  )
