import _ from 'lodash'
import Car from '../../models/Car'

const initialState = []

export default function cars(state = initialState, action) {
  let cars: Car[]
  let car: Car
  let index: Number

  switch (action.type) {
    case 'SET_CARS':
      cars = [...state, ...action.cars]
      return _.uniqBy(cars, m => m.id)
    case 'SET_CAR':
      cars = [...state]
      index = _.findIndex(cars, m => m.id === action.car.id)
      if (index === -1) {
        cars.push(action.car)
      } else {
        cars.splice(index, 1, action.car)
      }

      return cars
    case 'ADD_CAR':
      car = action.car
      cars = state
      if (car.is_default) {
        cars = state.map(c => {
          const updatedCar = _.cloneDeep(c)
          updatedCar.is_default = false
          return updatedCar
        })
      }

      return [car, ...cars]
    case 'UPDATE_CAR':
      car = action.car
      cars = [...state]

      if (car.is_default) {
        cars = cars.map(c => {
          const updatedCar = _.cloneDeep(c)
          updatedCar.is_default = false
          return updatedCar
        })
      }

      index = _.findIndex(cars, c => c.id === car.id)
      cars.splice(index, 1, car)
      return cars
    case 'DELETE_CAR':
      cars = state.filter(m => m.id !== action.car.id)
      return cars
    default:
      return state
  }
}
