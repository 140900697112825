import './style.scss'

import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { Portal } from 'react-portal'
import Button from '../Button/Button'
import { MOBILE_WIDTH } from '../../../contants'

type Props = {
  anchor?: 'left' | 'right',
  icon?: String,
  className?: String,
  children?: any
}

type State = {
  isOpen: boolean
}

export default class ActionMenu extends Component<Props, State> {
  button: HTMLButtonElement

  state = {
    isOpen: false
  }

  static defaultProps = {
    anchor: 'left',
    icon: 'menu'
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.isOpen && !prevState.isOpen) {
      this.addListeners()
    } else if (!this.state.isOpen && prevState.isOpen) {
      this.removeListeners()
    }
  }

  componentWillUnmount() {
    this.removeListeners()
  }

  addListeners = () => {
    document.addEventListener('keydown', this.onKeyDown)
  }

  removeListeners = () => {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && this.state.isOpen) {
      this.close()
    }
  }

  onButtonClick = () => {
    this.setState({ isOpen: true })
  }

  onBackgroundClick = (e: Event) => {
    if (e.target === e.currentTarget) {
      this.close()
    }
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const { anchor, icon, className, children } = this.props
    const { isOpen } = this.state

    let style
    if (this.button) {
      const rect = this.button.getBoundingClientRect()
      if (anchor === 'left') {
        style = {
          left: rect.left,
          top: rect.bottom
        }
      } else {
        style = {
          right: window.innerWidth - rect.right,
          top: rect.bottom
        }
      }
    }

    return (
      <Fragment>
        <Button
          icon={icon}
          className="ActionMenuTrigger"
          onClick={this.onButtonClick}
          innerRef={node => (this.button = node)}
        />
        {isOpen ? (
          <Portal>
            <div
              className={classNames('ActionMenu', className)}
              onClick={this.onBackgroundClick}
            >
              <div className="menu" style={style} onClick={this.close}>
                <div className="mobile-close">
                  <Button icon="x" onClick={this.close} />
                </div>
                {children}
              </div>
            </div>
          </Portal>
        ) : null}
      </Fragment>
    )
  }
}

export function MaybeActionMenu(props: Props) {
  return window.innerWidth > MOBILE_WIDTH ? (
    props.children
  ) : (
    <ActionMenu {...props} />
  )
}
