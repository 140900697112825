import './style.scss'

import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadUserActivities } from '../../../store/actions/userActivities'
import AuthRoute from '../../shared/AuthRoute/AuthRoute'
import UserActivityList from '../List/UserActivityList'
import UserActivityDetail from '../Detail/UserActivityDetail'
import UserActivity from '../../../models/UserActivity'

type Props = {
  userActivities: UserActivity[],
  urlPrefix: string,
  match: Object
}

export class UserActivities extends Component<Props> {
  componentDidMount() {
    const { userActivities, urlPrefix } = this.props
    if (!userActivities.length) {
      loadUserActivities(urlPrefix)
    }
  }

  onSave = (userActivity: Object) => {
    this.props.history.push(`/userActivities/${userActivity.id}`)
  }

  render() {
    const { userActivities, urlPrefix, match } = this.props
    const { path } = match
    return (
      <div className="UserActivities">
        <Switch>
          <AuthRoute
            exact
            path={path}
            render={props => <UserActivityList {...props} userActivities={userActivities} />}
          />
          <AuthRoute
            path={`${path}/:userActivityId`}
            render={props => <UserActivityDetail {...props} urlPrefix={urlPrefix} />}
          />
        </Switch>
      </div>
    )
  }
}

const mapState = state => ({
  userActivities: state.userActivities
})

export default connect(mapState)(UserActivities)
