import BaseModel from './BaseModel'

export default class WorkSchedule extends BaseModel {
  mo: Boolean
  tu: Boolean
  we: Boolean
  th: Boolean
  fr: Boolean
  sa: Boolean
  su: Boolean

  start_time: Number
  end_time: Number

  mo_start_time: Number
  mo_end_time: Number
  tu_start_time: Number
  tu_end_time: Number
  we_start_time: Number
  we_end_time: Number
  th_start_time: Number
  th_end_time: Number
  fr_start_time: Number
  fr_end_time: Number
  sa_start_time: Number
  sa_end_time: Number
  su_start_time: Number
  su_end_time: Number

  toString() {
    return `${this.start_time} ${this.end_time}`
  }
}
