import './style.scss'

import React, { Component } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { loadAddresses } from '../../../store/actions/addresses'
import Address from '../../../models/Address'
import { AddButton } from '../../shared'
import { ScreenWithClose } from '../../shared/Screen/Screen'
import { AddressForm } from '../Form/AddressForm'

type Props = {
  address: Address,
  addresses: Address[],
  onChange: Function,
  children: any
}

type State = {
  showForm: Boolean
}

export class SelectOrAddAddress extends Component<Props, State> {
  state = {
    showForm: false
  }

  componentDidMount() {
    const { addresses, urlPrefix } = this.props
    if (!addresses.length) {
      loadAddresses(urlPrefix)
    }
  }

  onSave = (address: Address) => {
    this.props.onChange(address)
    this.setState({ showForm: false })
  }

  onClick = (address: Address) => {
    this.props.onChange(address)
  }

  render() {
    const { address, addresses, children } = this.props
    const { showForm } = this.state

    return (
      <div className="select-or-add SelectOrAddAddress">
        <h3>
          <span>Locations</span>
          <AddButton onClick={() => this.setState({ showForm: true })} />
        </h3>
        {addresses
          .filter(a => a.street !== '')
          .map(addr => (
            <div
              className={classNames('item', { selected: address && addr.id === address.id })}
              key={addr.id}
              onClick={() => this.onClick(addr)}>
              {addr.toString()}
            </div>
          ))}
        {children}
        {showForm ? (
          <ScreenWithClose onClose={() => this.setState({ showForm: false })}>
            <div className="select-or-add-portal">
              <h1>New Address</h1>
              <AddressForm addresses={addresses} onSave={this.onSave} />
            </div>
          </ScreenWithClose>
        ) : null}
      </div>
    )
  }
}

const mapState = state => ({
  addresses: state.addresses
})

export default connect(mapState)(SelectOrAddAddress)
