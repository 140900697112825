import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadUserActivity } from '../../../store/actions/userActivities'
import TitleBar from '../../shared/TitleBar/TitleBar'
import UserActivityForm from '../Form/UserActivityForm'
import UserActivity from '../../../models/UserActivity'

type Props = {
  userActivity: ?UserActivity,
  urlPrefix: string,
  match: Object
}

type State = {}

export class UserActivityDetail extends Component<Props, State> {
  state = {}

  async componentDidMount() {
    const { urlPrefix, match } = this.props
    const id = match.params.userActivityId
    loadUserActivity(id, urlPrefix)
  }

  render() {
    const { userActivity, urlPrefix } = this.props

    if (!userActivity) {
      return 'Loading...'
    }

    return (
      <div className="UserActivityDetail">
        <TitleBar backTo="/customer-activity" label="Activity Detail" />
        <UserActivityForm userActivity={userActivity} urlPrefix={urlPrefix} />
      </div>
    )
  }
}

const mapState = (state, props) => {
  const id = Number(props.match.params.userActivityId)
  const userActivity = state.userActivities.find(m => m.id === id)

  return {
    userActivity
  }
}

export default connect(mapState)(UserActivityDetail)
