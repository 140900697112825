import _ from 'lodash'
import Event from '../../models/Event'

const initialState = []

export default function appointments(state = initialState, action) {
  let appointments: Event[]
  let index: Number

  switch (action.type) {
    case 'SET_APPOINTMENTS':
      appointments = [...state, ...action.appointments]
      return _.sortBy(
        _.uniqBy(appointments, a => a.id),
        a => a.start
      ).reverse()

    case 'SET_APPOINTMENT':
      appointments = [...state]
      index = _.findIndex(appointments, m => m.id === action.appointment.id)
      if (index === -1) {
        appointments.push(action.appointment)
      } else {
        appointments.splice(index, 1, action.appointment)
      }

      return appointments
    case 'ADD_APPOINTMENT':
      return [action.appointment].concat(state)
    case 'UPDATE_APPOINTMENT':
      appointments = [...state]
      index = _.findIndex(appointments, m => m.id === action.appointment.id)
      appointments.splice(index, 1, action.appointment)
      return appointments
    case 'DELETE_APPOINTMENT':
      appointments = state.filter(m => m.id !== action.appointment.id)
      return appointments
    default:
      return state
  }
}
