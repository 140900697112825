import axios from 'axios'
import { store } from '../store'
import { CorporateAccount } from '../../models'
import {
  CREATE_CORPORATE_ACCOUNT,
  UPDATE_CORPORATE_ACCOUNT,
  FETCH_CORPORATE_ACCOUNTS_REQUEST,
  CORPORATE_ACCOUNT_SUCCESS,
  FETCH_CORPORATE_ACCOUNT_FROM_URL
} from '../actionTypes'

export const createCorporateAccount = payload => ({
  type: CREATE_CORPORATE_ACCOUNT,
  payload: payload
})

export const fetchCorporateAccounts = payload => ({
  type: FETCH_CORPORATE_ACCOUNTS_REQUEST,
  payload: payload
})

export const corporateAccountSuccess = payload => ({
  type: CORPORATE_ACCOUNT_SUCCESS,
  payload: payload
})

export const fetchCorporateAccountFromUrl = payload => ({
  type: FETCH_CORPORATE_ACCOUNT_FROM_URL,
  payload: payload
})

export const updateCorporateAccount = async data => {
  try {
    const id = data.get('id')
    const res = await axios.put(`/a/corporate_accounts/${id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    const corporateAccount = new CorporateAccount(res.data)
    store.dispatch({
      type: UPDATE_CORPORATE_ACCOUNT,
      corporateAccount
    })

    return corporateAccount
  } catch (err) {
    console.error(err)
    throw err
  }
}
