import axios from 'axios'
import _debug from 'debug'
import { store } from '../store'

const debug = _debug('carwash:store:actions:plans')

export async function loadPlans(urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/plans`)
    store.dispatch({ type: 'SET_PLANS', plans: res.data })
  } catch (err) {
    debug('[loadPlans] error: ', err)
    throw err
  }
}

export async function loadPlan(id: number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/plans/${id}`)
    store.dispatch({ type: 'SET_PLAN', plan: res.data })
  } catch (err) {
    debug('[loadPlan] error: ', err)
    throw err
  }
}

export async function getSubscribers(id: number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/plans/${id}/subscribers`)
    return res.data
  } catch (err) {
    debug('[getSubscribers] error: ', err)
    throw err
  }
}

export async function savePlan(data: Object, urlPrefix: string = '') {
  return data.id ? updatePlan(data, urlPrefix) : createPlan(data, urlPrefix)
}

export async function createPlan(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/plans`, data)
    const plan = res.data
    store.dispatch({ type: 'ADD_PLAN', plan })
    return plan
  } catch (err) {
    debug('[createPlan] error: ', err)
    throw err
  }
}

export async function updatePlan(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.put(`${urlPrefix}/plans/${data.id}`, data)
    const plan = res.data
    store.dispatch({ type: 'UPDATE_PLAN', plan })
    return plan
  } catch (err) {
    debug('[updatePlan] error: ', err)
    throw err
  }
}

export async function deletePlan(data: Object, urlPrefix: string = '') {
  try {
    await axios.delete(`${urlPrefix}/plans/${data.id}`)
    store.dispatch({ type: 'DELETE_PLAN', plan: data })
  } catch (err) {
    debug('[deletePlan] error: ', err)
    throw err
  }
}
