import './style.scss'

import React, { Component, Fragment } from 'react'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadAddresses } from '../../../store/actions/addresses'
import AuthRoute from '../../shared/AuthRoute/AuthRoute'
import AddressList from '../List/AddressList'
import AddressDetail from '../Detail/AddressDetail'
import AddressForm from '../Form/AddressForm'
import TitleBar from '../../shared/TitleBar/TitleBar'
import Address from '../../../models/Address'
import { getUrlPrefix } from '../../../util/string-util'

type Props = {
  addresses: Address[],
  backTo?: String,
  urlPrefix: string,
  match: Object
}

export class Addresses extends Component<Props> {
  componentDidMount() {
    const { addresses, urlPrefix } = this.props
    if (!addresses.length) {
      loadAddresses(urlPrefix)
    }
  }

  onSave = (address: Object) => {
    const prefix = getUrlPrefix('addresses')
    this.props.history.push(`${prefix}/${address.id}`)
  }

  render() {
    const { addresses, urlPrefix, backTo, match } = this.props
    const { path } = match
    return (
      <div className="Addresses">
        <Switch>
          <AuthRoute
            exact
            path={path}
            render={props => <AddressList {...props} addresses={addresses} backTo={backTo} />}
          />
          <AuthRoute
            path={`${path}/new`}
            render={props => (
              <Fragment>
                <TitleBar backTo={path} label="New Address" />
                <AddressForm {...props} onSave={this.onSave} urlPrefix={urlPrefix} />
              </Fragment>
            )}
          />
          <AuthRoute
            path={`${path}/:addressId`}
            render={props => <AddressDetail {...props} urlPrefix={urlPrefix} />}
          />
        </Switch>
      </div>
    )
  }
}

const mapState = state => ({
  addresses: state.addresses
})

export default connect(mapState)(Addresses)
