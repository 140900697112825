import { mergeMap, map, catchError } from 'rxjs/operators'
import { from } from 'rxjs'
import { ofType } from 'redux-observable'
import axios from 'axios'

import { FETCH_PACKAGE_DEALS } from '../actionTypes'
import { fetchPackageDealsSuccess } from '../actions/packageDeals';

export const fetchPackageDealsEpic = action$ =>
  action$.pipe(
    ofType(FETCH_PACKAGE_DEALS),
    mergeMap(action =>
      from(
        new Promise((resolve, reject) => {
          axios.get(`/package_deals?code=${action.payload.code}`).then(response => {
            action.payload.onSuccess(response.data.results)
            resolve(response.data.results)
          })
        })
      ).pipe(
        map(response =>
          fetchPackageDealsSuccess({ packageDeals: response })
        ),
        catchError(error => Promise.resolve(error))
      )
    )
  )
