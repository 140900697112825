import moment from 'moment'
import Product from '../models/Product'
import { PackageDeal } from '../models'

export function formatCurrency(cents: number) {
  const dollars = (cents / 100).toFixed(2)
  return '$' + dollars
}

export function convertNumberToDuration(seconds: number) {
  const duration = moment.duration(seconds * 1000)

  let val = Math.floor(duration.asMonths())
  if (val) {
    return [val, 'MONTH']
  }

  val = Math.floor(duration.asWeeks())
  if (val) {
    return [val, 'WEEK']
  }

  val = Math.floor(duration.asDays())
  if (val) {
    return [val, 'DAY']
  }

  val = Math.floor(duration.asHours())
  if (val) {
    return [val, 'HOUR']
  }

  val = Math.floor(duration.asMinutes())
  if (val) {
    return [val, 'MINUTE']
  }

  throw new Error(`Invalid input: ${seconds}`)
}

export function applyDiscount(basePrice: Number, discount: Number) {
  if (typeof discount !== 'number') {
    discount = Number(discount)

    if (isNaN(discount)) {
      return basePrice
    }
  }

  const discountAmount = (basePrice * discount) / 100

  return basePrice - discountAmount
}

export function tryParsePhoneNumber(prevValue: String, value: String) {
  if (prevValue.length > value.length) {
    // User pressed backspace. Don't interfere.
    return value
  }

  if (/^\(\d\d\d\) \d\d\d-\d\d\d\d$/.test(prevValue) && value.length > prevValue.length) {
    // User added extra chars. Deny.
    return prevValue
  }

  if (/^\d\d$/.test(prevValue) && /^\d\d\d$/.test(value)) {
    // User entered area code. Wrap in parentheses.
    return `(${value}) `
  }

  if (/^\(\d\d\d\) \d\d$/.test(prevValue) && /^\(\d\d\d\) \d\d\d$/.test(value)) {
    // User entered prefix. Add hyphen.
    return `${value}-`
  }

  return value
}

export function calculateTotals(
  selection?: Product,
  addOns?: Product[],
  discountPercent?: Number,
  discountAmount?: Number,
  packageDeal?: PackageDeal
) {
  addOns = addOns || []

  let subtotal = 0

  if (selection) {
    subtotal = selection.amount + addOns.reduce((acc, addOn) => acc + addOn.amount, 0)
  } else if (packageDeal) {
    const includedAddOns = packageDeal.products.filter(p => !Product.isWash(p.type))
    const includedAddOnIds = includedAddOns.map(p => p.id)
    const extraAddOns = addOns.filter(p => !includedAddOnIds.includes(p.id))
    subtotal = packageDeal.amount + extraAddOns.reduce((acc, addOn) => acc + addOn.amount, 0)
  }

  let total = discountPercent
    ? subtotal - discountPercent * subtotal
    : discountAmount
    ? subtotal - discountAmount
    : subtotal

  return { subtotal, total }
}
