import React from 'react'

export default function CustomerInstructions() {
  return (
    <div className="CustomerInstructions">
      <p>
        You will receive an appointment confirmation email as well as a text when our technician is
        on their way.
      </p>

      <p>Please remove all trash and personal items before our technician arrives.</p>

      <p>
        We have a 24-hour cancellation policy. Missed or cancelled appointments within 24 hours are
        charged 50% of the service cost. See you soon.
      </p>
    </div>
  )
}
