import './style.scss'

import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import _ from 'lodash'
import { formatMoney } from 'accounting'
import classNames from 'classnames'
import Button from '../../shared/Button/Button'
import Product from '../../../models/Product'

import img1 from '../../../assets/luxurycar.jpg'
import img2 from '../../../assets/petpic.jpg'
import img3 from '../../../assets/clean-interior.jpg'
import img4 from '../../../assets/clean-exterior.jpg'
import img5 from '../../../assets/clean-exterior2.jpg'
import img6 from '../../../assets/special.jpg'
import ProductTable from './ProductTable/ProductTable'
import { isMobile } from '../../../util/dom-util'
import ProductInfo from './ProductInfo/ProductInfo'
import { getColor } from '../../../util/product-util'

const IMAGES = [img6, img1, img2, img3, img4, img5]

type Props = {
  products: Product[],
  onChange: Function,
  showCompare: Boolean
}

type State = {
  selectedId: Number
}

export default class ProductGrid extends Component<Props, State> {
  static defaultProps = {
    showCompare: true
  }

  state = {
    selectedId: null
  }

  onClick = (product: Product) => {
    this.props.onChange(product)
  }

  renderGroup = (
    title: string,
    products: Product[],
    className: String = null,
    style: Object = {}
  ) => {
    const { products: allProducts, onChange } = this.props

    if (!products.length) {
      return null
    }

    return (
      <div className="group">
        <h2>{title}</h2>
        <div className="products">
          {products.map((product, i) => {
            this.i++
            const img = IMAGES[this.i]

            return (
              <div
                key={i}
                className={classNames('Product', className, { full: i === 2 })}
                style={{ ...style, backgroundImage: `url(${img})` }}>
                <div className="blackout">
                  <div className="top">
                    <div className="left">
                      <h3>{product.name}</h3>
                      <div className="price">
                        {formatMoney(product.amount / 100).replace('.00', '')}
                      </div>
                    </div>
                    <div className="right">
                      <Button
                        id={`signup-next-1-product-${i + 1}`}
                        icon="plus-white"
                        style={{ backgroundColor: getColor(product) }}
                        onClick={() => onChange(product)}
                      />
                    </div>
                  </div>

                  <ReactMarkdown escapeHtml={false}>{product.description}</ReactMarkdown>
                  {isMobile() ? (
                    <ProductInfo
                      product={product}
                      products={allProducts}
                      index={allProducts.indexOf(product)}
                      onChange={onChange}
                    />
                  ) : null}
                </div>
              </div>
            )
          })}
        </div>
        <div className="color-divider" />
      </div>
    )
  }

  render() {
    const { products, showCompare } = this.props

    const groups = _.groupBy(products, 'type')

    const washes = groups.WASH || []
    const details = groups.DETAIL || []
    const special = groups.SPECIAL || groups.CORPORATE_SPECIAL || []

    this.i = -1

    return (
      <div className="ProductGrid">
        {this.renderGroup('Current Special', special, 'special full', {
          backgroundPosition: 'center'
        })}
        {this.renderGroup('Wash Packages', washes)}
        {this.renderGroup('Detail Packages', details)}
        {isMobile() || !showCompare ? null : (
          <div>
            <h2>Compare Packages</h2>
            <ProductTable {...this.props} />
          </div>
        )}
      </div>
    )
  }
}
