import './DjangoAdmin.scss'

import React from 'react'

export default function DjangoAdmin() {
  return (
    <div className="DjangoAdmin">
      <iframe title="Django Admin" src={`${window.location.origin}/admin/`} />
    </div>
  )
}
