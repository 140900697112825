import _ from 'lodash'

const initialState = []

export default function subscriptions(state = initialState, action) {
  let subscriptions, index
  switch (action.type) {
    case 'SET_SUBSCRIPTIONS':
      subscriptions = [...state, ...action.subscriptions]
      return _.uniqBy(subscriptions, m => m.id)
    case 'SET_SUBSCRIPTION':
      subscriptions = [...state]
      index = _.findIndex(subscriptions, m => m.id === action.subscription.id)
      if (index === -1) {
        subscriptions.push(action.subscription)
      } else {
        subscriptions.splice(index, 1, action.subscription)
      }

      return subscriptions
    case 'ADD_SUBSCRIPTION':
      return [action.subscription].concat(state)
    case 'UPDATE_SUBSCRIPTION':
      subscriptions = [...state]
      index = _.findIndex(subscriptions, m => m.id === action.subscription.id)
      subscriptions.splice(index, 1, action.subscription)
      return subscriptions
    case 'DELETE_SUBSCRIPTION':
      subscriptions = state.filter(m => m.id !== action.subscription.id)
      return subscriptions
    default:
      return state
  }
}
