import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import Form from '../../shared/Form/Form'
import Field from '../../shared/Field/Field'
import SaveButton from '../../shared/SaveButton/SaveButton'
import { saveAddress, deleteAddress } from '../../../store/actions/addresses'
import { handleError } from '../../../util/error-util'
import { toast } from '../../../store/actions/app'
import DeleteButton from '../../shared/DeleteButton/DeleteButton'
import confirm from '../../shared/confirm/confirm'
import ActionBar from '../../shared/ActionBar/ActionBar'
import history from '../../../history'
import Address from '../../../models/Address'
import { areFieldsEmpty } from '../../../util/string-util'
import { checkDistance } from '../../../util/map-util'

type Props = {
  addresses: Address[],
  address?: Address,
  urlPrefix: string,
  onSave: Function
}

type State = {
  address: Address,
  saving: boolean
}

export class AddressForm extends Component<Props, State> {
  initialDefaultValue: Boolean

  state = {
    address: null,
    saving: false
  }

  static defaultProps = {
    onSave: _.noop
  }

  componentDidMount() {
    this.setAddress()
  }

  componentDidUpdate(prevProps) {
    if (this.props.address !== prevProps.address) {
      this.setAddress()
    }
  }

  setAddress = () => {
    const address = new Address(this.props.address)
    this.initialDefaultValue = address.is_default
    this.setState({ address })
  }

  onChange = (prop: string, value: any) => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.address[prop] = value
    this.forceUpdate()
  }

  onSubmit = async () => {
    const { urlPrefix, onSave } = this.props
    const address: Address = this.state.address
    this.setState({ saving: true })

    toast('Saving...')

    try {
      await checkDistance(address.toString())
    } catch (err) {
      handleError(err, true)
      this.setState({ saving: false })
      return
    }

    try {
      const result = await saveAddress(address, urlPrefix)
      toast('Saved')
      this.setState({ saving: false })
      onSave(result)
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  isValid = () => {
    const { address } = this.state
    const valid = !areFieldsEmpty(address, ['street', 'city', 'state', 'zip'])
    return valid
  }

  hasMultipleAddresses = () => {
    const { addresses } = this.props
    const { address } = this.state

    if (addresses.length > 1) {
      return true
    }

    if (addresses.length === 1) {
      return !address.id
    }

    return false
  }

  onDeleteClick = async () => {
    try {
      if (!(await confirm())) {
        return
      }

      const { urlPrefix } = this.props
      const { address } = this.state

      this.setState({ saving: true })

      toast('Deleting...')
      await deleteAddress(address, urlPrefix)
      toast('Deleted')

      history.goToListView()
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  render() {
    const { saving } = this.state
    const address: Address = this.state.address

    if (!address) {
      return null
    }

    return (
      <div className="AddressForm">
        <Form onSubmit={this.onSubmit}>
          {this.hasMultipleAddresses() && !this.initialDefaultValue ? (
            <Field
              type="checkbox"
              label="Set as default:"
              checked={address.is_default}
              onChange={e => this.onChange('is_default', e.target.checked)}
            />
          ) : null}
          <Field
            type="text"
            label="Street address:"
            value={address.street}
            onChange={e => this.onChange('street', e.target.value)}
          />
          <Field
            type="text"
            label="City:"
            value={address.city}
            onChange={e => this.onChange('city', e.target.value)}
          />
          <Field
            type="text"
            label="State:"
            value={address.state}
            onChange={e => this.onChange('state', e.target.value)}
          />
          <Field
            type="text"
            label="Zip:"
            value={address.zip}
            onChange={e => this.onChange('zip', e.target.value)}
          />
          <Field
            type="checkbox"
            label="Is covered parking available?:"
            checked={address.has_covered_parking}
            onChange={e => this.onChange('has_covered_parking', e.target.checked)}
          />
          <Field
            type="textarea"
            label="Gate code / stall number:"
            value={address.extra_info}
            onChange={e => this.onChange('extra_info', e.target.value)}
          />
          <ActionBar>
            <SaveButton onClick={this.onSubmit} disabled={saving || !this.isValid()} />
            {address.id ? <DeleteButton onClick={this.onDeleteClick} disabled={saving} /> : null}
          </ActionBar>
        </Form>
      </div>
    )
  }
}

const mapState = state => ({
  addresses: state.addresses
})

export default connect(mapState)(AddressForm)
