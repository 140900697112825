import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadAppointment } from '../../../store/actions/appointments'
import TitleBar from '../../shared/TitleBar/TitleBar'
import AppointmentForm from '../Form/AppointmentForm'
import Appointment from '../../../models/Appointment'
import { getUrlPrefix } from '../../../util/string-util'

type Props = {
  appointment: ?Appointment,
  urlPrefix: string,
  match: Object
}

type State = {}

export class AppointmentDetail extends Component<Props, State> {
  state = {}

  async componentDidMount() {
    const { urlPrefix, match } = this.props
    const id = match.params.appointmentId
    loadAppointment(id, urlPrefix)
  }

  onRescheduleClick = (e, appt: Event) => {
    e.preventDefault()
    e.stopPropagation()
  }

  onCancelClick = (e, appt: Event) => {
    e.preventDefault()
    e.stopPropagation()
  }

  render() {
    const { appointment, urlPrefix } = this.props
    if (!appointment) {
      return 'Loading...'
    }

    const backTo = getUrlPrefix('appointments')

    return (
      <div className="AppointmentDetail">
        <TitleBar backTo={backTo} label="Appointment Detail" />
        <AppointmentForm appointment={appointment} urlPrefix={urlPrefix} />
      </div>
    )
  }
}

const mapState = (state, props) => {
  const id = Number(props.match.params.appointmentId)
  const appointment = state.appointments.find(m => m.id === id)

  return {
    appointment
  }
}

export default connect(mapState)(AppointmentDetail)
