import './style.scss'

import React, { Component } from 'react'
import classNames from 'classnames'

type Props = {
  data: Object[],
  selectedIds: Number[],
  onChange: Function,
  renderItem: Function
}

export default class SelectableList extends Component<Props> {
  renderItem = (item: Object) => {
    const { selectedIds, onChange, renderItem } = this.props

    return (
      <div
        key={item.id}
        className={classNames('item', {
          active: selectedIds.includes(item.id)
        })}
        onClick={() => onChange(item)}
      >
        {renderItem(item)}
      </div>
    )
  }

  render() {
    const { data } = this.props

    return <div className="SelectableList">{data.map(this.renderItem)}</div>
  }
}
