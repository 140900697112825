import axios from 'axios'
import _debug from 'debug'
import { store } from '../store'
import User from '../../models/User'

const debug = _debug('carwash:store:actions:customers')

export async function loadCustomers(urlPrefix: string = '', urlSuffix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/customers${urlSuffix}`)
    const customers = res.data.results.map(data => new User(data))
    store.dispatch({ type: 'SET_CUSTOMERS', customers })
  } catch (err) {
    debug('[loadCustomers] error: ', err)
    throw err
  }
}

export async function loadCustomer(id: number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/customers/${id}`)
    const customer = new User(res.data)
    store.dispatch({ type: 'SET_CUSTOMER', customer })
  } catch (err) {
    debug('[loadCustomer] error: ', err)
    throw err
  }
}

export async function fetchPlan(customerId: number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/customers/${customerId}/plan`)
    return res.data
  } catch (err) {
    debug('[fetchPlan] error: ', err)
    throw err
  }
}

export async function saveCustomer(data: Object, urlPrefix: string = '') {
  return data.id ? updateCustomer(data, urlPrefix) : createCustomer(data, urlPrefix)
}

export async function createCustomer(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/customers`, data)
    const customer = new User(res.data)
    store.dispatch({ type: 'ADD_CUSTOMER', customer })
    return customer
  } catch (err) {
    debug('[createCustomer] error: ', err)
    throw err
  }
}

export async function updateCustomer(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.put(`${urlPrefix}/customers/${data.id}`, data)
    const customer = new User(res.data)
    store.dispatch({ type: 'UPDATE_CUSTOMER', customer })
    return customer
  } catch (err) {
    debug('[updateCustomer] error: ', err)
    throw err
  }
}

export async function deleteCustomer(data: Object, urlPrefix: string = '') {
  try {
    await axios.delete(`${urlPrefix}/customers/${data.id}`)
    store.dispatch({ type: 'DELETE_CUSTOMER', customer: data })
  } catch (err) {
    debug('[deleteCustomer] error: ', err)
    throw err
  }
}
