import './style.scss'

import React from 'react'
import classNames from 'classnames'
import _debug from 'debug'

const debug = _debug('carwash:shared:ResizableText')

type Props = {
  className?: string,
  min?: Number,
  children: any
}

export default function ResizableText(props: Props) {
  const { className, min, children } = props

  const onRef = (node: HTMLSpanElement) => {
    const check = () => {
      if (!node) {
        return
      }

      const { clientWidth, scrollWidth } = node
      if (scrollWidth <= clientWidth) {
        return
      }

      const fontSize = Number(getComputedStyle(node).fontSize.replace('px', ''))
      const newFontSize = fontSize - 0.5

      if (newFontSize < min) {
        return
      }

      debug(`[check] changing from ${fontSize}px to ${newFontSize}px`)
      node.style.fontSize = `${newFontSize}px`
      setTimeout(check, 100)
    }

    check(node)
  }

  return (
    <span className={classNames('ResizableText', className)} ref={onRef}>
      {children}
    </span>
  )
}

ResizableText.defaultProps = {
  min: 10
}
