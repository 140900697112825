import BaseModel from './BaseModel'

export default class UserActivity extends BaseModel {
  type: String
  data: Object

  constructor(rawdata: Object = {}) {
    super(rawdata)

    if (typeof rawdata.data === 'string') {
      this.data = JSON.parse(rawdata.data)
    }
  }
}
