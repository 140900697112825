import BaseModel from './BaseModel'

export default class Address extends BaseModel {
  street: String
  unit_number: String
  city: String
  state: String
  zip: String
  extra_info: String
  is_default: Boolean
  has_covered_parking: Boolean

  toString() {
    const { street = '', unit_number = '', city = '', state = '', zip = '' } = this

    let parts = []

    if (street) {
      if (unit_number) {
        parts.push(street + ' ' + unit_number)
      } else {
        parts.push(street)
      }
    }

    if (city) {
      parts.push(city)
    }

    if (state) {
      if (zip) {
        parts.push(`${state} ${zip}`)
      } else {
        parts.push(state)
      }
    }

    return parts.join(', ')
  }
}
