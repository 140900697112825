/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ReactMarkdown from 'react-markdown'
import logo from '../../../../images/logo-white.png'
import Dialog from '../../../shared/Dialog/Dialog'

import './style.scss'

type Props = {
  ctaUrl: String
}

export const Footer = (props: Props) => {
  const { ctaUrl = '/signup' } = props
  const [show, toggle] = useState(false)
  const navigationEnabled = useSelector(state => state.app.navigationEnabled)

  return (
    <div className="Footer">
      <div className="container">
        <div className="row">
          <div className="col company">
            <img src={logo} />
            <p>Shine Rite is proudly serving the Central Austin Texas Area.</p>
            {navigationEnabled ? (
              <Link id="book-now-10" className="primary" to={ctaUrl}>
                BOOK NOW
              </Link>
            ) : null}
          </div>
          <div className="col social">
            <h3>Follow us:</h3>
            <p>
              <a href="https://facebook.com/shineriteco" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook" />
              </a>
              <a href="https://instagram.com/shineriteco" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram" />
              </a>
              <a
                href="https://yelp.com/biz/shine-rite-austin"
                target="_blank"
                rel="noopener noreferrer">
                <i className="fab fa-yelp" />
              </a>
              <a
                href="https://g.page/shineriteaustin?share"
                target="_blank"
                rel="noopener noreferrer">
                <i className="fab fa-google" />
              </a>
            </p>
          </div>
          <div className="col phone">
            <h3>Call us today:</h3>
            <p className="contact-info">
              <a href="tel:512-230-0988">
                <span>+1</span> (512) 230-0988
              </a>
            </p>
          </div>
          <div className="col email">
            <h3>Have a question? Email us:</h3>
            <p className="contact-info">
              <a href="mailto:info@shineriteco.com">
                <span>info@</span>shineriteco.com
              </a>
            </p>
            <p className="sub-text">We will reply as soon as possible.</p>
          </div>
        </div>
        <p className="copyright-text">
          Copyright 2020 • Shine Rite • All rights reserved •{' '}
          <span onClick={() => toggle(true)}>Terms and conditions</span>
        </p>
      </div>

      {show ? (
        <Dialog title="Terms and conditions" hide={() => toggle(false)}>
          <ReactMarkdown>{terms}</ReactMarkdown>
        </Dialog>
      ) : null}

      <div className="tint" />
    </div>
  )
}

const terms = `
## SHINE RITE TERMS AND CONDITIONS
Effective Date:  This Agreement was last updated on August 27, 2019

### PAYMENT OF FEES

1. A valid debit/credit card is required to process orders online.
2. Your membership will be billed on the same day each month.  Payment is made upfront for the upcoming month’s services.
3. Your membership and billing cycle will begin on the day you sign up.
4. SHINE RITE never stores your payment information.  We use Stripe, a secure and authorized third-party credit card processor.

### CANCELLATION

1. You can cancel your account at any time by emailing info@shineriteco.com.
2. Please request cancellation within 48 hours of your billing date.
3. If you cancel your service in the middle of your billing cycle, we will finish the pre-paid services for that month and you will not be charged again.

### MEMBERSHIP PRICE CHANGES

1. SHINE RITE prices are subject to change at any time.
2. SHINE RITE will notify you 30 days in advance of any increase in the monthly payment (or membership dues) under your membership.

### LIMITATON OF LIABILITY

1. SHINE RITE shall not be liable to you for any direct, indirect, special, punitive, incidental, exemplary or consequential damages whatsoever in any manner arising out of the services provided by SHINE RITE.
2. SHINE RITE assumes no responsibility or liability in any manner arising out of or in connection with auto washing or detailing services.

### OTHER TERMS AND CONDITIONS

1. SHINE RITE reserves the right to terminate or refuse to renew your agreement for any reason not prohibited by law including, but not limited to, an unsatisfactory payment history.  SHINE RITE reserves the right to collect at any time any delinquent or outstanding balance(s) that has not been paid for any services provided or monthly payments owed.
2. You are solely responsible for maintaining the confidentiality of your password and account.  Please do not share your password or account information.
3. For questions regarding these terms and conditions, please email us at info@shineriteco.com.
`
