import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames'
import _debug from 'debug'
import axios from 'axios'
import { formatMoney } from 'accounting'
import { handleError, isAppointmentError } from '../../util/error-util'
import { scrollToTop } from '../../util/dom-util'
import ProductGrid from '../UserSignupPage/ProductGrid/ProductGrid'
import AddOnGrid from '../UserSignupPage/AddOnGrid/AddOnGrid'
import Cart from '../UserSignupPage/Cart/Cart'
import { Address, Car, Event, Product, User, PackageDeal, CorporateAccount } from '../../models'
import { loadAppointments } from '../../store/actions/appointments'
import { loadCars } from '../../store/actions/cars'
import { loadAddresses } from '../../store/actions/addresses'
import ManageLocationInfo from './ManageLocationInfo'
import { calculateTotals } from '../../util/number-util'
import SelectOrAddAddress from '../Address/SelectOrAddAddress/SelectOrAddAddress'
import SelectOrAddCar from '../Cars/SelectOrAddCar/SelectOrAddCar'
import { DiscreteProgessBar, Calendar } from '../shared'
import { addPurchaseToDataLayer } from '../../util/google-util'
import { getColor, getPackageDealInfo } from '../../util/product-util'
import { toast, hideCovidBar } from '../../store/actions/app'
import BubbleHeader from '../BubbleHeader/BubbleHeader'
import { Footer } from '../LandingPage/sections/Footer'
import CustomerInstructions from '../UserSignupPage/CustomerInstructions'

const debug = _debug('carwash:components:BookingPage')

const Type = {
  PLAN: 'PLAN',
  PRODUCT: 'PRODUCT'
}

const VEHICLE_INFO = 'VEHICLE_INFO'
const PRODUCT_INFO = 'PRODUCT_INFO'
const ADDON_INFO = 'ADDON_INFO'
const APPOINTMENT_INFO = 'APPOINTMENT_INFO'
const CONFIRM_INFO = 'CONFIRM_INFO'
const DONE = 'DONE'

// const STEPS = [
//   ['1', 'Location'],
//   ['2', 'Vehicle'],
//   ['3', 'Service'],
//   ['4', 'Add-ons'],
//   ['5', 'Date'],
//   ['6', 'Confirm']
// ]

const STEPS = [
  ['1', 'Your info', /(location|vehicle)/],
  ['2', 'Service', /(service|addons|date)/],
  ['3', 'Confirm', /(confirm|done)/]
]

type Props = {
  user: User,
  cars: Car[],
  addresses: Address[],
  history: Object,
  match: Object
}

type State = {
  // Data
  products: Product[],
  packageDeal?: PackageDeal,
  availableTimes: Event[],
  corporateAccounts: CorporateAccount[],

  // Plan info
  selection: ?Object,
  selectedAddOns: Product[],
  discountCode?: String,
  discountPercent?: Number,
  discountAmount?: Number,
  appointmentTime: String,
  appointmentEvent: Event,
  car?: Car,
  address?: Address,
  corporateAccount?: CorporateAccount,

  // Other state
  saving: boolean,
  loading: boolean,
  transitionTo:
    | VEHICLE_INFO
    | PRODUCT_INFO
    | ADDON_INFO
    | APPOINTMENT_INFO
    | CONFIRM_INFO
    | DONE
    | null,
  resultMessage: string,
  showManageLocationInfo: Boolean
}

export class BookingPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      products: [],
      selectedAddOns: [],
      corporateAccounts: [],
      discountCode: null,
      discountPercent: 0,
      discountAmount: 0,

      availableTimes: [],

      selection: {},
      appointmentTime: '',
      appointmentEvent: null,
      car: null,
      address: null,
      corporateAccount: null,

      saving: false,
      loading: false,
      transitionTo: null,
      resultMessage: '',
      showManageLocationInfo: false,

      packageDeal: null
    }

    try {
      const params = new URLSearchParams(props.location.search)
      const name = params.get('p')
      if (name) {
        this.queryName = decodeURIComponent(name)
      }
    } catch (err) {
      // no op
    }
  }

  componentDidMount() {
    this.fetchCorporateAccounts()
    this.fetchProducts()
    this.setCar()
    this.setAddress()
  }

  fetchCorporateAccounts = async () => {
    try {
      const res = await axios.get('/events?corporate_account__isnull=false')
      const { results } = res.data
      if (!results.length) {
        return
      }

      const corporateAccounts = _.uniqBy(
        results.map(e => new CorporateAccount(e.corporate_account)),
        ca => ca.id
      )

      this.setState({ corporateAccounts })
    } catch (err) {
      debug('[componentDidMount] error: ', err)
      handleError(err)
    }
  }

  fetchProducts = async () => {
    try {
      const res = await axios.get('/products?type__in=WASH,DETAIL,SPECIAL')
      const products = res.data.results.map(p => new Product(p))
      this.setState({ products }, () => {
        this.checkUrl()
      })
    } catch (err) {
      debug('[componentDidMount] error: ', err)
      handleError(err)
    }
  }

  fetchAvailableTimes = async () => {
    try {
      this.setState({ loading: true })

      const { selection, selectedAddOns } = this.state
      const info = this.getPackageDealInfo()

      let productIds = []
      if (info) {
        productIds = [info.product.id, ...selectedAddOns.map(p => p.id)]
      } else {
        productIds = [selection.id, ...selectedAddOns.map(p => p.id)]
      }

      const data = {
        product_ids: productIds
      }

      // if (corporateAccount) {
      //   data.corporate_account_id = corporateAccount.id
      // }

      const res = await axios.post('/events/availabletimes', data)

      this.setState({ availableTimes: res.data, loading: false })
    } catch (err) {
      debug('[fetchAvailableTimes] error: ', err)
      handleError(err)
      this.setState({ loading: false })
    }
  }

  checkUrl = async () => {
    if (!this.queryName) {
      return
    }

    const { history, match } = this.props
    const { products } = this.state
    const product = products.find(p => p.name === this.queryName)
    if (product) {
      this.setState({
        selection: {
          type: Type.PRODUCT,
          id: product.id
        }
      })

      if (product.add_ons.length) {
        history.push(`${match.url}/addons`)
      } else {
        history.push(`${match.url}/date`)
      }
    } else {
      history.push(`${match.url}/service`)
    }
  }

  getProducts = (): Product[] => {
    const { products } = this.state
    return products
  }

  getPackageDealInfo = () => {
    const { packageDeal } = this.state
    return getPackageDealInfo(packageDeal)
  }

  setCar = async () => {
    try {
      let { cars } = this.props
      if (!cars.length) {
        cars = await loadCars()
      }

      const car = cars.find(c => c.is_default)
      this.setState({ car })
    } catch (err) {
      debug('[setCar] error: ', err)
    }
  }

  setAddress = async () => {
    try {
      let { addresses } = this.props
      if (!addresses.length) {
        addresses = await loadAddresses()
      }

      const address = addresses.filter(a => a.street !== '').find(c => c.is_default)
      this.setState({ address })
    } catch (err) {
      debug('[setAddress] error: ', err)
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      scrollToTop()
    }
  }

  onAddPackageDeal = (packageDeal: PackageDeal) => {
    const { history, match } = this.props
    toast('Promo applied')
    this.setState({ packageDeal }, () => {
      const info = this.getPackageDealInfo()
      if (info) {
        this.setState({ selectedAddOns: info.selectedAddOns })
      }
    })
    history.push(`${match.url}/addons`)
  }

  onLocationInfoSumbit = () => {
    hideCovidBar()
    this.setState({ transitionTo: VEHICLE_INFO })

    setTimeout(() => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null
      })

      history.push(`${match.url}/vehicle`)
    }, 200)
  }

  onVehicleInfoSumbit = () => {
    this.setState({ transitionTo: PRODUCT_INFO })

    setTimeout(() => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null
      })

      history.push(`${match.url}/service`)
    }, 200)
  }

  onProductDoneClick = (product: Product) => {
    const hasAddOns = product.add_ons.length > 0
    if (!hasAddOns) {
      return this.onAddOnInfoSubmit()
    }

    this.setState({ transitionTo: ADDON_INFO })

    setTimeout(() => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null
      })

      history.push(`${match.url}/addons`)
    }, 200)
  }

  onAddOnInfoSubmit = () => {
    this.setState({ transitionTo: APPOINTMENT_INFO })

    setTimeout(() => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null
      })

      history.push(`${match.url}/date`)

      this.fetchAvailableTimes()
    }, 200)
  }

  onAppointmentInfoSubmit = () => {
    this.setState({ transitionTo: CONFIRM_INFO })

    setTimeout(() => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null
      })

      history.push(`${match.url}/confirm`)
    }, 200)
  }

  onConfirmClick = () => {
    this.setState({ saving: true }, async () => {
      const { shoppingCart, history, match } = this.props

      try {
        const {
          selection,
          selectedAddOns,
          packageDeal,
          corporateAccount,
          appointmentTime,
          car,
          address
        } = this.state

        const products = this.getProducts()

        const res = await axios.post('/purchase', {
          selection,
          addOns: selectedAddOns.map(addOn => addOn.id),
          packageDeal,
          discountCode: shoppingCart.discountApplied ? shoppingCart.discountCode : null,
          appointmentTime,
          carId: car ? car.id : null,
          addressId: address ? address.id : null,
          eventType: corporateAccount ? 'CORPORATE_WASH' : 'WASH',
          corporateAccountId: corporateAccount ? corporateAccount.id : null,
          makeAutopilotChanges: !corporateAccount
        })

        debug('[onConfirmClick] res: ', res)

        try {
          const gaProducts = [products.find(p => p.id === selection.id), ...selectedAddOns]
          const { event } = res.data
          addPurchaseToDataLayer(event.id, event.charge.amount, 'EXISTING_CUSTOMER', gaProducts)
        } catch (err) {
          console.error(err)
        }

        this.setState({ transitionTo: DONE, saving: false })

        setTimeout(() => {
          const { history, match } = this.props

          this.setState({
            transitionTo: null
          })

          history.push(`${match.url}/done`)

          loadAppointments()
        }, 200)
      } catch (err) {
        debug('[onConfirmClick] error: ', err)
        this.setState({ saving: false })
        handleError(err, true)
        if (isAppointmentError(err)) {
          history.push(`${match.url}/date`)

          this.fetchAvailableTimes()
        }
      }
    })
  }

  onDoneClick = () => {
    this.props.history.push('/')
  }

  renderCart = (inline: Boolean = false) => {
    const { history, match } = this.props
    const {
      selection,
      corporateAccount,
      packageDeal,
      selectedAddOns,
      appointmentTime,
      appointmentEvent
    } = this.state

    const products = this.getProducts()

    return (
      <Cart
        selection={products.find(p => p.id === selection.id)}
        addOns={selectedAddOns}
        appointmentTime={appointmentTime}
        appointmentEvent={appointmentEvent}
        packageDeal={packageDeal}
        corporateAccount={corporateAccount}
        onAddPackageDeal={this.onAddPackageDeal}
        onRemovePackageDeal={() => {
          this.setState({
            selection: {},
            selectedAddOns: [],
            packageDeal: null
          })
          history.push(`${match.url}/service`)
        }}
        onRemoveProduct={() => {
          this.setState({ selection: {}, selectedAddOns: [] })
          history.push(`${match.url}/service`)
        }}
        onRemoveAddOn={(addOn: Product) => {
          this.setState({ selectedAddOns: selectedAddOns.filter(p => p !== addOn) })
        }}
        onDiscountApplied={(discountCode, discountPercent, discountAmount) => {
          this.setState({ discountCode, discountPercent, discountAmount })
        }}
        inline={inline}
      />
    )
  }

  renderLocationInfo = () => {
    const { address, corporateAccounts, corporateAccount, transitionTo } = this.state

    return (
      <div key="plan">
        <BubbleHeader text="Select a Location" />
        <div
          className={classNames('container', {
            hiding: transitionTo === VEHICLE_INFO
          })}>
          <SelectOrAddAddress
            address={address}
            onChange={(address: Address) => {
              this.setState({ address, corporateAccount: null })
              setTimeout(this.onLocationInfoSumbit, 400)
            }}>
            {corporateAccounts.map(acct => (
              <div
                className={classNames('item', {
                  selected: corporateAccount && corporateAccount.id === acct.id
                })}
                key={acct.id}
                onClick={() => {
                  this.setState({ address: null, corporateAccount: acct })
                  setTimeout(this.onLocationInfoSumbit, 400)
                }}>
                {acct.toString()}
              </div>
            ))}
          </SelectOrAddAddress>

          <div className="footer">
            <button
              className="primary"
              disabled={!(address || corporateAccount)}
              onClick={this.onLocationInfoSumbit}>
              Next
            </button>
          </div>
        </div>
      </div>
    )
  }

  renderVehicleInfo = () => {
    const { history, match } = this.props
    const { car, transitionTo } = this.state

    return (
      <div key="plan">
        <BubbleHeader text="Select Vehicle" />
        <div
          className={classNames('container', {
            hiding: transitionTo === PRODUCT_INFO
          })}>
          <SelectOrAddCar
            car={car}
            onChange={(car: Car) => {
              this.setState({ car })
              setTimeout(this.onVehicleInfoSumbit, 400)
            }}
          />

          <div className="footer">
            <button
              type="button"
              className="secondary"
              onClick={() => {
                history.push(`${match.url}/location`)
              }}>
              Back
            </button>
            <button className="primary" onClick={this.onVehicleInfoSumbit}>
              Next
            </button>
          </div>
        </div>
      </div>
    )
  }

  renderProductInfo = () => {
    const { history, match } = this.props
    let { transitionTo } = this.state
    const products = this.getProducts()
    // const Grid = corporateAccount ? CorporateProductGrid : ProductGrid

    return (
      <div key="product">
        <BubbleHeader text="Select a Package" />
        <div
          className={classNames('container', {
            hiding: transitionTo === ADDON_INFO
          })}>
          <div className="step-description">
            <div>We keep it simple.</div>
            <div>NO HASSLES.</div>
            <div>WE COME TO YOU.</div>
          </div>
          {/* {isMobile() ? <PackageDealsForm onAddPackageDeal={this.onAddPackageDeal} /> : null} */}
          <ProductGrid
            products={products}
            onChange={product => {
              this.setState({
                selection: { type: Type.PRODUCT, id: product.id },
                selectedAddOns: []
              })
              this.onProductDoneClick(product)
            }}
          />
          <div className="footer">
            <button
              type="button"
              className="secondary"
              onClick={() => {
                history.push(`${match.url}/vehicle`)
              }}>
              Back
            </button>
          </div>
        </div>

        {/* {window.innerWidth > 1000 ? this.renderCart() : null} */}
      </div>
    )
  }

  renderAddOnInfo = () => {
    const { history, match } = this.props
    const { selection, packageDeal, selectedAddOns, transitionTo } = this.state
    const products = this.getProducts()

    if (!selection.id && !packageDeal) {
      return null
    }

    let addOns = []
    let fixedAddOns = []
    let product = {}
    if (selection.id) {
      product = products.find(p => p.id === selection.id)
      addOns = product.add_ons
    } else {
      const info = this.getPackageDealInfo()
      if (info) {
        product = info.product
        addOns = info.addOns
        fixedAddOns = info.selectedAddOns
      }
    }

    return (
      <div key="add-ons">
        <BubbleHeader text="Select Add-ons" />
        <div
          className={classNames('container', {
            hiding: transitionTo === APPOINTMENT_INFO
          })}>
          <AddOnGrid
            addOns={addOns}
            selectedAddOns={selectedAddOns}
            fixedAddOns={fixedAddOns}
            color={getColor(product)}
            onChange={(addOn: Product, checked: Boolean) => {
              if (checked) {
                this.setState({ selectedAddOns: [...selectedAddOns, addOn] })
              } else {
                this.setState({ selectedAddOns: selectedAddOns.filter(p => p.id !== addOn.id) })
              }
            }}
          />

          <div className="footer">
            <button
              type="button"
              className="secondary"
              onClick={() => {
                this.setState({ selection: {}, selectedAddOns: [] })
                history.push(`${match.url}/service`)
              }}>
              Back
            </button>
            <button id="purchase-next-1" className="primary" onClick={this.onAddOnInfoSubmit}>
              Next
            </button>
          </div>
        </div>

        {/* {window.innerWidth > 1000 ? this.renderCart() : null} */}
      </div>
    )
  }

  renderAppointmentInfo = () => {
    const { history, match } = this.props
    const {
      selection,
      availableTimes,
      appointmentTime,
      saving,
      loading,
      transitionTo,
      packageDeal
    } = this.state

    if (!selection.id && !packageDeal) {
      return null
    }

    return (
      <div key="appointment">
        <BubbleHeader text="Choose Appointment" />
        <div
          className={classNames('appointment', 'container', 'calendar-wrapper', {
            hiding: transitionTo === CONFIRM_INFO
          })}>
          {loading ? (
            <div className="calendar-placeholder">
              <div className="div-1" />
              <div className="div-2" />
              <div className="div-3" />
            </div>
          ) : (
            <Calendar
              events={availableTimes}
              views={['month']}
              onSelectEvent={event => {
                this.setState({ appointmentTime: event.start, appointmentEvent: event })
                this.onAppointmentInfoSubmit()
              }}
              readonly
            />
          )}
          <div className="footer">
            <button
              type="button"
              className="secondary"
              onClick={() => {
                history.push(`${match.url}/addons`)
              }}>
              Back
            </button>
            <button
              id="purchase-next-2"
              className="primary"
              disabled={saving || !appointmentTime}
              onClick={this.onAppointmentInfoSubmit}>
              Next
            </button>
          </div>
        </div>

        {/* {window.innerWidth > 1000 ? this.renderCart() : null} */}
      </div>
    )
  }

  renderConfirmation = () => {
    const { history, match } = this.props
    const {
      selection,
      transitionTo,
      saving,
      showManageLocationInfo,
      car,
      address,
      packageDeal,
      corporateAccount
    } = this.state

    if (!selection.id && !packageDeal) {
      return null
    }

    return (
      <div key="confirm">
        <BubbleHeader text="Confirm" />
        <div
          className={classNames('container', {
            hiding: transitionTo === DONE
          })}>
          <div className="confirm-step">
            <div className="confirm-car">
              <div className="gridfields">
                <div className="all-caps">
                  <span>Vehicle/Location Info</span>
                </div>
                <div>
                  <span>Vehicle</span>
                  <span>{car ? car.toString() : ''}</span>
                </div>
                <div>
                  <span>Address</span>
                  <span>
                    {corporateAccount
                      ? corporateAccount.address
                      : address
                      ? address.toString()
                      : ''}
                  </span>
                </div>
              </div>
              <div className="gray-divider" />
            </div>

            {this.renderCart(true)}
          </div>

          <div className="footer">
            <button
              type="button"
              className="secondary"
              onClick={() => {
                history.push(`${match.url}/date`)
              }}>
              Back
            </button>
            <button
              id="purchase-confirm"
              className="primary"
              onClick={this.onConfirmClick}
              disabled={saving}>
              Confirm Purchase
            </button>
          </div>
        </div>

        {showManageLocationInfo ? (
          <ManageLocationInfo
            selectedCarId={car ? car.id : null}
            selectedAddressId={address ? address.id : null}
            onClose={(car: Car, address: Address) => {
              const nextState = { showManageLocationInfo: false }
              if (car) {
                nextState.car = car
              }
              if (address) {
                nextState.address = address
              }

              this.setState(nextState)
            }}
          />
        ) : null}
      </div>
    )
  }

  renderDoneMessage = () => {
    const { selection, packageDeal } = this.state

    if (!selection.id && !packageDeal) {
      return null
    }

    return (
      <div key="done" style={{ paddingTop: 60 }}>
        <h1>
          Thanks{' '}
          <span role="img" aria-label="tada">
            🎉
          </span>
        </h1>
        <div className="done-message">
          <p>You're all set! See you soon.</p>
          <CustomerInstructions />
        </div>
        <div className="footer">
          <button className="primary" onClick={this.onDoneClick}>
            Done
          </button>
        </div>
      </div>
    )
  }

  renderSummary = () => {
    const { shoppingCart, location } = this.props
    const { discountAmount, discountPercent } = shoppingCart
    const { selection, selectedAddOns, packageDeal } = this.state
    const products = this.getProducts()

    if (location.pathname.includes('/done')) {
      return null
    }

    const product = products.find(p => p.id === selection.id)
    const totals = calculateTotals(
      product,
      selectedAddOns,
      discountPercent,
      discountAmount,
      packageDeal
    )

    const total = totals.total

    return (
      <div className="summary gridfields">
        <div>
          <span>Total</span>
          <span>{formatMoney(total / 100)}</span>
        </div>
      </div>
    )
  }

  renderProgressBar = () => {
    const step = _.last(this.props.location.pathname.split('/'))
    const current = STEPS.find(s => s[2].test(step))

    return <DiscreteProgessBar current={current} steps={STEPS} />
  }

  render() {
    const { match } = this.props

    return (
      <>
        <div className="BookingPage">
          {window.innerWidth <= 1000 ? this.renderSummary() : null}
          <Route path={`${match.path}/location`} render={this.renderLocationInfo} />
          <Route path={`${match.path}/vehicle`} render={this.renderVehicleInfo} />
          <Route path={`${match.path}/service`} render={this.renderProductInfo} />
          <Route path={`${match.path}/addons`} render={this.renderAddOnInfo} />
          <Route path={`${match.path}/date`} render={this.renderAppointmentInfo} />
          <Route path={`${match.path}/confirm`} render={this.renderConfirmation} />
          <Route path={`${match.path}/done`} render={this.renderDoneMessage} />
          <Route exact path={match.path} render={() => <Redirect to={`${match.url}/location`} />} />
        </div>
        <Footer ctaUrl="/book" />
      </>
    )
  }
}

const mapState = state => ({
  user: state.app.user,
  cars: state.cars,
  addresses: state.addresses,
  shoppingCart: state.shoppingCart
})

export default connect(mapState)(BookingPage)
