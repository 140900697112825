import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import _debug from 'debug'
import axios from 'axios'
import Form from '../../shared/Form/Form'
import Field from '../../shared/Field/Field'
import SubmitButton from '../../shared/SubmitButton/SubmitButton'
import { handleError } from '../../../util/error-util'
import { Fragment } from 'react'

const debug = _debug('carwash:components:ResetPassword')

type Props = {
  location: Location,
  history: Object
}

type State = {
  email: String,
  password: String,
  token: String,
  passwordRequirements: String[],
  saving: Boolean,
  success: Boolean
}

export default class ResetPassword extends Component<Props, State> {
  state = {
    email: '',
    password: '',
    token: _.last(this.props.location.pathname.split),
    passwordRequirements: [],
    saving: false,
    success: false
  }

  async componentDidMount() {
    const params = new URLSearchParams(window.location.search)
    this.setState({
      email: params.get('e'),
      token: params.get('t')
    })

    const res = await axios.get('/reset-password-info')
    this.setState({ passwordRequirements: res.data.password_requirements })
  }

  onSubmit = async () => {
    try {
      const { history } = this.props
      this.setState({ saving: true })
      const { email, password, token } = this.state
      await axios.post('/reset-password', { email, password, token })

      this.setState({ success: true })
      setTimeout(() => {
        history.push('/')
        window.location.reload()
      }, 2000)
    } catch (err) {
      debug('[onSubmit] error: ', err)
      handleError(err, true)
      this.setState({ saving: false })
    }
  }

  onChange = (prop: String, value: any) => {
    this.setState({ [prop]: value })
  }

  render() {
    const { password, passwordRequirements, success, saving } = this.state

    return (
      <div className="ResetPassword form-centered">
        {success ? (
          <Fragment>
            <h3>Password Reset</h3>
            <p>Success!</p>
          </Fragment>
        ) : (
          <Fragment>
            <h3>Password Reset</h3>
            <p>Choose a new password.</p>
            <ul>
              {passwordRequirements.map((req, i) => (
                <li key={i}>{req}</li>
              ))}
            </ul>
            <Form id="login-form" name="login-form" onSubmit={this.onSubmit}>
              <Field
                label="New password"
                type="password"
                value={password}
                onChange={e => this.onChange('password', e.target.value)}
                usePlaceholders
                autoFocus
              />
              <SubmitButton disabled={saving} onClick={this.onSubmit} />
            </Form>
          </Fragment>
        )}
      </div>
    )
  }
}
