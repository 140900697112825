import './style.scss'

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { isAdmin } from '../../../store/util'
import AddButton from '../../shared/AddButton/AddButton'
import TitleBar from '../../shared/TitleBar/TitleBar'
import Event from '../../../models/Event'

type Props = {
  appointments: Event[],
  backTo?: String,
  location: Object,
  history: Object,
  match: Object
}

export default class AppointmentList extends Component<Props> {
  onAddClick = () => {
    const { location, history } = this.props
    history.push(`${location.pathname}/new`)
  }

  render() {
    const { appointments, backTo, match } = this.props
    const { url } = match

    return (
      <div className="AppointmentList">
        <TitleBar label="Appointments" backTo={backTo}>
          {isAdmin() ? <AddButton onClick={this.onAddClick} /> : null}
        </TitleBar>
        <div className="list list-primary">
          {appointments.map(appt => (
            <Link key={appt.id} className="plain" to={`${url}/${appt.id}`}>
              <span>{appt.startDateToString()}</span>
              <span>{appt.address.toString()}</span>
              <span>{appt.car.toString()}</span>
              <span>{appt.cancelled_date ? '(Cancelled)' : ''}</span>
            </Link>
          ))}
        </div>
      </div>
    )
  }
}
