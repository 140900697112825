import './ProductGridWrapper.scss'

import React, { Component } from 'react'
import axios from 'axios'
import _debug from 'debug'
import ProductGrid from './ProductGrid'
import { Product } from '../../../models'
import { handleError } from '../../../util/error-util'
import { hideCovidBar } from '../../../store/actions/app'
import { log } from '../../../analytics'
import history from '../../../history'

const debug = _debug('carwash:components:ProductGridWrapper')

type Props = {
  ctaUrl: String,
  showCompare: Boolean
}

type State = {
  products: Product[]
}

export default class ProductGridWrapper extends Component<Props, State> {
  state = {
    products: null
  }

  componentDidMount() {
    this.fetchProducts()
  }

  fetchProducts = async () => {
    try {
      const res = await axios.get('/products?type__in=WASH,DETAIL,SPECIAL')
      const products = res.data.results.map(p => new Product(p))
      this.setState({ products })
    } catch (err) {
      debug('[componentDidMount] error: ', err)
      handleError(err)
    }
  }

  onProductInfoSubmit = (product: Product) => {
    const { ctaUrl } = this.props
    hideCovidBar()
    log('LandingPage/ProductSelected', { productId: product.id })
    history.push(`${ctaUrl}/service?p=${encodeURIComponent(product.name)}`)
  }

  render() {
    const { showCompare } = this.props
    const { products } = this.state
    if (!products) {
      return null
    }

    return (
      <div className="ProductGridWrapper">
        <div className="header">Pricing</div>
        <ProductGrid
          products={products}
          showCompare={showCompare}
          onChange={product => this.onProductInfoSubmit(product)}
        />
      </div>
    )
  }
}
