import axios from 'axios'
import querystring from 'querystring'
import _debug from 'debug'
import { store } from '../store'
import Address from '../../models/Address'

const debug = _debug('carwash:store:actions:addresses')

export async function loadAddresses(urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/addresses`)
    const addresses = res.data.results.map(data => new Address(data))
    store.dispatch({ type: 'SET_ADDRESSES', addresses })
    return addresses
  } catch (err) {
    debug('[loadAddresses] error: ', err)
    throw err
  }
}

export async function loadAddressesForCustomer(customerId: Number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/customers/${customerId}/addresses`)
    const addresses = res.data.map(data => new Address(data))
    store.dispatch({ type: 'SET_ADDRESSES', addresses })
    return addresses
  } catch (err) {
    debug('[loadAddresses] error: ', err)
    throw err
  }
}

export async function loadAddress(id: number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/addresses/${id}`)
    const address = new Address(res.data)
    store.dispatch({ type: 'SET_ADDRESS', address })
    return address
  } catch (err) {
    debug('[loadAddress] error: ', err)
    throw err
  }
}

export async function saveAddress(data: Object, urlPrefix: string = '') {
  return data.id ? updateAddress(data, urlPrefix) : createAddress(data, urlPrefix)
}

export async function createAddress(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/addresses`, data)
    const address = new Address(res.data)
    store.dispatch({ type: 'ADD_ADDRESS', address })
    return address
  } catch (err) {
    debug('[createAddress] error: ', err)
    throw err
  }
}

export async function updateAddress(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.put(`${urlPrefix}/addresses/${data.id}`, data)
    const address = new Address(res.data)
    store.dispatch({ type: 'UPDATE_ADDRESS', address })
    return address
  } catch (err) {
    debug('[updateAddress] error: ', err)
    throw err
  }
}

export async function deleteAddress(data: Object, urlPrefix: string = '') {
  try {
    await axios.delete(`${urlPrefix}/addresses/${data.id}`)
    store.dispatch({ type: 'DELETE_ADDRESS', address: data })
  } catch (err) {
    debug('[deleteAddress] error: ', err)
    throw err
  }
}

export async function searchAddresses(urlPrefix: string = '', searchQuery: Object) {
  try {
    const res = await axios.get(`${urlPrefix}/addresses?${querystring.stringify(searchQuery)}`)
    const cars = res.data.results.map(data => new Address(data))
    return cars
  } catch (err) {
    debug('[searchAddresses] error: ', err)
    throw err
  }
}
