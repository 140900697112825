import _ from 'lodash'
import Address from '../../models/Address'

const initialState = []

export default function addresses(state = initialState, action) {
  let addresses: Address[]
  let address: Address
  let index: Number

  switch (action.type) {
    case 'SET_ADDRESSES':
      addresses = [...state, ...action.addresses]
      return _.uniqBy(addresses, m => m.id)
    case 'SET_ADDRESS':
      addresses = [...state]
      index = _.findIndex(addresses, m => m.id === action.address.id)
      if (index === -1) {
        addresses.push(action.address)
      } else {
        addresses.splice(index, 1, action.address)
      }

      return addresses
    case 'ADD_ADDRESS':
      address = action.address
      addresses = state
      if (address.is_default) {
        addresses = state.map(c => {
          const updatedAddress = _.cloneDeep(c)
          updatedAddress.is_default = false
          return updatedAddress
        })
      }

      return [address, ...addresses]
    case 'UPDATE_ADDRESS':
      address = action.address
      addresses = [...state]

      if (address.is_default) {
        addresses = addresses.map(c => {
          const updatedAddress = _.cloneDeep(c)
          updatedAddress.is_default = false
          return updatedAddress
        })
      }

      index = _.findIndex(addresses, c => c.id === address.id)
      addresses.splice(index, 1, address)
      return addresses
    case 'DELETE_ADDRESS':
      addresses = state.filter(m => m.id !== action.address.id)
      return addresses
    default:
      return state
  }
}
