import './style.scss'

import React, { Component, Fragment } from 'react'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadEmployees } from '../../../store/actions/employees'
import AuthRoute from '../../shared/AuthRoute/AuthRoute'
import EmployeeList from '../List/EmployeeList'
import EmployeeDetail from '../Detail/EmployeeDetail'
import EmployeeForm from '../Form/EmployeeForm'
import TitleBar from '../../shared/TitleBar/TitleBar'

type Props = {
  employees: Object[],
  urlPrefix: string,
  match: Object
}

export class Employees extends Component<Props> {
  componentDidMount() {
    if (!this.props.employees.length) {
      loadEmployees(this.props.urlPrefix)
    }
  }

  onSave = async (employee: Object) => {
    this.props.history.push(`/technicians/${employee.id}`)
  }

  render() {
    const { employees, urlPrefix, match } = this.props
    const { path } = match
    return (
      <div className="Employees">
        <Switch>
          <AuthRoute
            exact
            path={path}
            render={props => (
              <EmployeeList {...props} employees={employees} urlPrefix={urlPrefix} />
            )}
          />
          <AuthRoute
            path={`${path}/new`}
            render={props => (
              <Fragment>
                <TitleBar backTo={path} label="New Technician" />
                <EmployeeForm {...props} onSave={this.onSave} urlPrefix={urlPrefix} />
              </Fragment>
            )}
          />
          <AuthRoute
            path={`${path}/:employeeId`}
            render={props => <EmployeeDetail {...props} urlPrefix={urlPrefix} />}
          />
        </Switch>
      </div>
    )
  }
}

const mapState = state => ({
  employees: state.employees
})

export default connect(mapState)(Employees)
