import './style.scss'

import React from 'react'

export default function BubbleHeader(props) {
  return (
    <div className="BubbleHeader">
      <div className="blue-bubbles">
        <div className="white-bubbles" />
        {props.text ? <div className="bubble-text">{props.text}</div> : props.children}
      </div>
    </div>
  )
}
