import axios from 'axios'
import moment from 'moment'
import _debug from 'debug'
import { store } from '../store'
import { Event, Charge } from '../../models'

const debug = _debug('carwash:store:actions:events')

export async function loadEvents(urlPrefix: string = '', start: Date = null, end: Date = null) {
  loadEvents.lastArgs = [urlPrefix, start, end]

  try {
    let url = `${urlPrefix}/events`
    if (start && end) {
      const startStr = moment(start).format()
      const endStr = moment(end).format()
      url += `?end__gte=${startStr}&start__lte=${endStr}`
    }

    const res = await axios.get(url)
    const events = res.data.results.map(e => new Event(e))
    store.dispatch({ type: 'SET_EVENTS', events })
    return events
  } catch (err) {
    debug('[loadEvents] error: ', err)
    throw err
  }
}

loadEvents.refresh = async function () {
  const args = loadEvents.lastArgs
  if (!args) {
    return
  }

  return loadEvents(...args)
}

export async function loadEvent(id: number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/events/${id}`)
    const event = new Event(res.data)
    store.dispatch({ type: 'SET_EVENT', event })
    return event
  } catch (err) {
    debug('[loadEvent] error: ', err)
    throw err
  }
}

export async function checkAvailability(event: Event) {
  try {
    const res = await axios.post('/events/check_availability', event)
    const { isAvailable } = res.data
    return isAvailable
  } catch (err) {
    debug('[checkAvailability] error: ', err)
    throw err
  }
}

export async function saveEvent(data: Object, urlPrefix: string = '') {
  return data.id ? updateEvent(data, urlPrefix) : createEvent(data, urlPrefix)
}

export async function createEvent(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/events`, data)
    const event = new Event(res.data)
    store.dispatch({ type: 'ADD_EVENT', event })
    return event
  } catch (err) {
    debug('[createEvent] error: ', err)
    throw err
  }
}

export async function updateEvent(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.put(`${urlPrefix}/events/${data.id}`, data)
    const event = new Event(res.data)
    store.dispatch({ type: 'UPDATE_EVENT', event })
    return event
  } catch (err) {
    debug('[updateEvent] error: ', err)
    throw err
  }
}

export async function deleteEvent(data: Object, urlPrefix: string = '') {
  try {
    await axios.delete(`${urlPrefix}/events/${data.id}`)
    store.dispatch({ type: 'DELETE_EVENT', event: data })
  } catch (err) {
    debug('[deleteEvent] error: ', err)
    throw err
  }
}

export async function loadCharge(data: Object) {
  try {
    const res = await axios.get(`/events/${data.id}/charge`)
    const charge = new Charge(res.data)
    return charge
  } catch (err) {
    debug('[loadCharge] error: ', err)
    throw err
  }
}

export async function sendOnCompleteNotification(
  data: Object,
  force: Boolean = false,
  urlPrefix: string = ''
) {
  try {
    const res = await axios.post(`${urlPrefix}/events/${data.id}/onwashcomplete`, { force })
    const event = new Event(res.data)
    store.dispatch({ type: 'UPDATE_EVENT', event })
    return event
  } catch (err) {
    debug('[captureCharge] error: ', err)
    throw err
  }
}

export async function captureCharge(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/events/${data.id}/capture_charge`)
    const charge = new Charge(res.data)
    return charge
  } catch (err) {
    debug('[captureCharge] error: ', err)
    throw err
  }
}

export async function scheduleAppointment(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/events/schedule_appointment`, data)
    const event = new Event(res.data)
    store.dispatch({ type: 'ADD_EVENT', event })
    return event
  } catch (err) {
    debug('[scheduleAppointment] error: ', err)
    throw err
  }
}

export async function cancelEvent(event: Object, urlPrefix: string = '', data: Object = undefined) {
  try {
    const res = await axios.post(`${urlPrefix}/events/${event.id}/cancel`, data)
    event = new Event(res.data.event)
    store.dispatch({ type: 'UPDATE_EVENT', event })
    return res.data
  } catch (err) {
    debug('[cancelEvent] error: ', err)
    throw err
  }
}
