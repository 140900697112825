/* eslint-disable jsx-a11y/alt-text */
import './style.scss'

import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { icons } from '../../../images'

type Link = {
  url: string,
  icon?: string,
  label?: string,
  className?: string
}

type Props = {
  links: Link[],
  children: any
}

export function Dashboard(props: Props) {
  const { links, children } = props

  return (
    <div className="Dashboard">
      <div className="sidebar">
        {links.map((link, i) => (
          <NavLink key={i} to={link.url} className={link.className}>
            <Fragment>
              {link.icon ? <img src={icons[link.icon]} /> : null}
              {link.label ? (
                <Fragment>
                  <span>{link.label}</span>
                  {link.subtitle ? <span className="subtitle">{link.subtitle}</span> : null}
                </Fragment>
              ) : null}
            </Fragment>
          </NavLink>
        ))}
      </div>
      <div className="content">{children}</div>
    </div>
  )
}
