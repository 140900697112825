import BaseModel from './BaseModel'
import Product from './Product'

export default class PackageDeal extends BaseModel {
  code: String
  amount: Number
  max_usage: Number
  start: Date
  end: Date
  products: Product[]

  constructor(data: Object = {}) {
    super(data)

    this.start = new Date(this.start)
    this.end = new Date(this.end)

    if (data.products && typeof data.products[0] === 'object') {
      this.products = data.products.map(p => new Product(p))
    }
  }
}
