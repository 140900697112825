import './style.scss'

import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom'
import classNames from 'classnames'
import _debug from 'debug'
import { formatMoney } from 'accounting'
import { Input, DiscreteProgessBar, Calendar } from '../shared'
import axios from 'axios'
import { initSession, toast, hideCovidBar } from '../../store/actions/app'
import { handleError, isAppointmentError } from '../../util/error-util'
import { isValidEmail } from '../../util/string-util'
import Product from '../../models/Product'
import ProductGrid from './ProductGrid/ProductGrid'
import AddOnGrid from './AddOnGrid/AddOnGrid'
import Cart from './Cart/Cart'
import { tryParsePhoneNumber, calculateTotals } from '../../util/number-util'
import Event from '../../models/Event'
import User from '../../models/User'
import Car from '../../models/Car'
import Address from '../../models/Address'
import { checkDistance } from '../../util/map-util'
import AustinMap from './AustinMap/AustinMap'
import { EmailUser, PackageDeal } from '../../models'
import { addPurchaseToDataLayer } from '../../util/google-util'
import { getColor, getPackageDealInfo } from '../../util/product-util'
import Terms from './Terms/Terms'
import {
  clearShoppingCart,
  checkPromoCode,
  applyDiscountAmount,
  applyDiscountPercent
} from '../../store/actions/shoppingCart'
import BubbleHeader from '../BubbleHeader/BubbleHeader'
import { Footer } from '../LandingPage/sections/Footer'
import { VehiclePreview } from './VehiclePreview/VehiclePreview'
import { LocationPreview } from './LocationPreview/LocationPreview'
import PaymentForm from '../PaymentForm/PaymentForm'
import CustomerInstructions from './CustomerInstructions'
import { log, makeLog } from '../../analytics'
import * as analytics from '../../analytics'

const debug = _debug('carwash:components:UserSignupPage')

const logNextBtn = makeLog('UserSignupPage/NextButton')
const logBackBtn = makeLog('UserSignupPage/BackButton')

const Type = {
  PLAN: 'PLAN',
  PRODUCT: 'PRODUCT'
}

const StoreKey = {
  NORMAL: 'UserSignupPageState',
  PRE_REDIRECT: 'UserSignupPageState_PreRedirect'
}

const ADDON_INFO = 'ADDON_INFO'
const APPOINTMENT_INFO = 'APPOINTMENT_INFO'
const LOCATION_INFO = 'LOCATION_INFO'
const VEHICLE_INFO = 'VEHICLE_INFO'
const CONFIRM_INFO = 'CONFIRM_INFO'
const ACCOUNT_INFO = 'ACCOUNT_INFO'
const PAYMENT_INFO = 'PAYMENT_INFO'
const DONE = 'DONE'

const STEPS = [
  ['1', 'Service', /(service|addons|date)/],
  ['2', 'Vehicle ', /(location|vehicle)/],
  ['3', 'Your info', /(confirm|account|payment)/],
  ['4', 'Done', /done/]
]

type Props = {
  signupInfo?: Object,
  emailuser?: EmailUser,
  history: Object,
  match: Object,
  location: Location
}

type State = {
  // Data
  plans: Object[],
  subplans: Object[],
  products: Product[],
  availableTimes: Event[],

  // Plan info
  selection: Object,
  selectedAddOns: Product[],
  discountCode?: String,
  discountPercent?: Number,
  discountAmount?: Number,
  appointmentTime: String,
  token: String,

  // Car info
  address: String,
  unitNumber: String,
  city: String,
  state: String,
  zip: String,
  stallInfo: String,
  hasCoveredParking: Boolean,
  make: String,
  model: String,
  color: String,
  license: String,

  // Account info
  firstName: string,
  lastName: string,
  email: string,
  email2: string,
  phoneNumber: string,
  password: string,
  agreeToTerms: Boolean,

  // Other state
  user: Object,
  acceptedTermsDate: Date,
  sessionId: String,
  vehicleMakes: Object[],
  vehicleModels: Object[],
  emailDisabled: Boolean,
  showProducts: Boolean,
  saving: boolean,
  loading: boolean,
  loadingAppts: Boolean,
  paymentMessage: String,
  transitionTo: ADDON_INFO | ACCOUNT_INFO | APPOINTMENT_INFO | PAYMENT_INFO | DONE | null
}

export class UserSignupPage extends Component<Props, State> {
  queryName: String
  manuallyEnteredAddress = false
  shouldStoreState = true
  initialState: String = '{}'

  constructor(props: Props) {
    super(props)

    try {
      const params = new URLSearchParams(props.location.search)
      const name = params.get('p')
      if (name) {
        this.queryName = decodeURIComponent(name)
      }
    } catch (err) {
      // no op
    }

    const emailuser = props.emailuser

    this.state = {
      plans: [],
      subplans: [],
      products: [],
      selectedAddOns: [],
      discountCode: null,
      discountPercent: 0,
      discountAmount: 0,
      availableTimes: [],

      selection: {},
      appointmentTime: '',
      token: '',

      address: '',
      unitNumber: '',
      city: '',
      state: '',
      zip: '',
      stallInfo: '',
      hasCoveredParking: false,
      make: '',
      model: '',
      color: '',
      license: '',

      firstName: '',
      lastName: '',
      email: emailuser ? emailuser.email : '',
      email2: emailuser ? emailuser.email : '',
      phoneNumber: '',
      password: '',
      agreeToTerms: false,

      user: null,
      acceptedTermsDate: null,
      sessionId: null,
      vehicleMakes: [],
      vehicleModels: [],
      emailDisabled: !!emailuser,
      showProducts: false,
      saving: false,
      loading: true,
      loadingAppts: false,
      transitionTo: null,
      paymentMessage: 'Please wait...',

      packageDeal: null
    }

    this.initialState = JSON.stringify(this.state)

    const signupInfo = props.signupInfo
    if (signupInfo) {
      const user: User = signupInfo.user
      const car: Car = signupInfo.car
      const address: Address = signupInfo.address

      this.state.address = address.street || ''
      this.state.unitNumber = address.unit_number || ''
      this.state.city = address.city || ''
      this.state.state = address.state || ''
      this.state.zip = address.zip || ''
      this.state.stallInfo = address.extra_info || ''
      this.state.hasCoveredParking =
        address.has_covered_parking != null ? address.has_covered_parking : false

      this.state.make = car.make || ''
      this.state.model = car.model || ''
      this.state.color = car.color || ''
      this.state.license = car.license_plate || ''

      this.state.firstName = user.first_name || ''
      this.state.lastName = user.last_name || ''
      this.state.email = user.email || ''
      this.state.email2 = user.email || ''
      this.state.phoneNumber = user.phone || ''
    }
  }

  componentDidMount() {
    this.init()
  }

  init = async () => {
    const params = new URLSearchParams(this.props.location.search)

    const res = await axios.get('/checkout-session')
    const storedState = res.data[StoreKey.NORMAL]
    const storedPreRedirectState = res.data[StoreKey.PRE_REDIRECT]

    if (params.get('session_id') && storedPreRedirectState) {
      setTimeout(hideCovidBar, 500)
      const nextState = {
        ...storedPreRedirectState,
        loading: false,
        saving: false,
        paymentMessage: 'Please wait...'
      }

      this.setState(nextState, () => {
        this.activateAccount()
      })
    } else if (storedPreRedirectState || storedState) {
      setTimeout(hideCovidBar, 500)
      const state = storedPreRedirectState || storedState
      const nextState = {
        ...state,
        loading: false,
        saving: false
      }

      this.setState(nextState, async () => {
        await this.fetchProducts()
        this.checkUrl()
      })
    } else {
      this.checkShoppingCart()
      await this.fetchProducts()
      this.checkUrl()
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.shouldStoreState && this.props.location.pathname !== prevProps.location.pathname) {
      this.storeState(StoreKey.NORMAL)
    }

    if (this.state.discountCode !== this.props.shoppingCart.discountCode) {
      this.setState({ discountCode: this.props.shoppingCart.discountCode })
    }
  }

  storeState = (key: String) => {
    try {
      axios.post('/checkout-session', { [key]: this.state })
    } catch (err) {
      // no op
    }
  }

  resetState = () => {
    this.shouldStoreState = false
    const { selection, packageDeal } = this.state
    const initialState = JSON.parse(this.initialState)
    this.setState({ ...initialState, selection, packageDeal, loading: false, saving: false })
  }

  checkUrl = async () => {
    if (!this.queryName) {
      return
    }

    const { history, match } = this.props
    const { products } = this.state
    const product = products.find(p => p.name === this.queryName)
    if (product) {
      this.setState({
        selection: {
          type: Type.PRODUCT,
          id: product.id
        }
      })

      if (product.add_ons.length) {
        history.push(`${match.url}/addons`)
      } else {
        history.push(`${match.url}/date`)
      }
    } else {
      history.push(`${match.url}/service`)
    }
  }

  checkShoppingCart = async () => {
    try {
      const { shoppingCart } = this.props
      const { discountCode, discountApplied } = shoppingCart
      if (discountCode && !discountApplied) {
        const res = await checkPromoCode(discountCode)
        if (res.type === 'DISCOUNT') {
          const { discountPercent, discountAmount } = res
          this.setState({ discountCode, discountPercent, discountAmount })
          toast('Discount applied')
          if (discountAmount) {
            applyDiscountAmount(discountCode, discountAmount)
          } else {
            applyDiscountPercent(discountCode, discountPercent)
          }
        } else {
          const { packageDeal } = res
          this.onAddPackageDeal(packageDeal)
          clearShoppingCart()
        }
      }
    } catch (err) {
      this.setState({ saving: false })
      handleError(err, true)
    }
  }

  fetchProducts = async () => {
    try {
      const res = await axios.get('/products?type__in=WASH,DETAIL,SPECIAL')
      const products = res.data.results.map(p => new Product(p))
      this.setState({ products, loading: false })
    } catch (err) {
      debug('[componentDidMount] error: ', err)
      handleError(err)
    }
  }

  fetchAvailableTimes = async () => {
    try {
      this.setState({ loadingAppts: true })

      const { selection, selectedAddOns } = this.state
      const info = this.getPackageDealInfo()

      let productIds = []
      if (info) {
        productIds = [info.product.id, ...selectedAddOns.map(p => p.id)]
      } else {
        productIds = [selection.id, ...selectedAddOns.map(p => p.id)]
      }

      const res = await axios.post('/events/availabletimes', { product_ids: productIds })

      this.setState({ availableTimes: res.data, loadingAppts: false })
    } catch (err) {
      debug('[fetchAvailableTimes] error: ', err)
      handleError(err)
      this.setState({ loadingAppts: false })
    }
  }

  fetchVehicleMakes = _.debounce(async (searchText: String) => {
    try {
      if (searchText.length < 2) {
        return
      }

      const res = await axios.get(`/vehicles?make__icontains=${searchText}`)
      this.setState({ vehicleMakes: res.data.results })
    } catch (err) {
      debug('[fetchVehicleMakes] error: ', err)
    }
  }, 500)

  getPackageDealInfo = () => {
    const { packageDeal } = this.state
    return getPackageDealInfo(packageDeal)
  }

  onAddPackageDeal = (packageDeal: PackageDeal) => {
    const { history, match } = this.props
    toast('Promo applied')
    this.setState({ packageDeal }, () => {
      const info = this.getPackageDealInfo()
      if (info) {
        this.setState({ selectedAddOns: info.selectedAddOns })
      }
    })
    history.push(`${match.url}/addons`)
  }

  onProductInfoSubmit = (product: Product) => {
    const { email } = this.state

    hideCovidBar()
    log('UserSignupPage/ProductSelected', { productId: product.id, email })

    const nextState = {
      selection: { type: Type.PRODUCT, id: product.id },
      selectedAddOns: []
    }

    const hasAddOns = product.add_ons.length > 0
    if (!hasAddOns) {
      this.setState(nextState, () => this.onAddOnInfoSubmit())
      return
    }

    nextState.transitionTo = ADDON_INFO
    this.setState(nextState)

    setTimeout(() => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null
      })

      history.push(`${match.url}/addons`)
      logNextBtn({
        from: '/service',
        to: '/addons',
        email: this.state.email,
        productIds: [product.id]
      })
    }, 200)
  }

  onAddOnInfoSubmit = async () => {
    this.setState({ transitionTo: APPOINTMENT_INFO })

    setTimeout(() => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null
      })

      history.push(`${match.url}/date`)

      const { email, selection, selectedAddOns } = this.state
      const productIds = [selection.id, ...selectedAddOns.map(p => p.id)]
      logNextBtn({ from: '/addons', to: '/date', email, productIds })

      this.fetchAvailableTimes()
    }, 200)
  }

  onAppointmentInfoSubmit = () => {
    const { token } = this.state
    if (token) {
      return this.activateAccount()
    }

    this.setState({ transitionTo: LOCATION_INFO })

    setTimeout(() => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null
      })

      history.push(`${match.url}/location`)

      const { email, selection, selectedAddOns, appointmentTime } = this.state
      const productIds = [selection.id, ...selectedAddOns.map(p => p.id)]
      logNextBtn({ from: '/date', to: '/location', email, productIds, appointmentTime })
    }, 200)
  }

  onLocationInfoSubmit = async (e: Event) => {
    try {
      e.preventDefault()
      this.setState({ saving: true })

      if (process.env.NODE_ENV === 'production') {
        const { address, city, state, zip } = this.state
        const fullAddress = `${address}, ${city}, ${state} ${zip}`
        await checkDistance(fullAddress)
      }

      this.setState({ transitionTo: VEHICLE_INFO })

      setTimeout(async () => {
        const { history, match } = this.props

        this.setState({
          transitionTo: null,
          saving: false
        })

        history.push(`${match.url}/vehicle`)

        const { email, selection, selectedAddOns, appointmentTime } = this.state
        const productIds = [selection.id, ...selectedAddOns.map(p => p.id)]
        logNextBtn({ from: '/location', to: '/vehicle', email, productIds, appointmentTime })
      }, 200)
    } catch (err) {
      debug('[onLocationInfoSubmit] error: ', err)
      this.setState({ saving: false })
      handleError(err, true)
    }
  }

  onVehicleInfoSubmit = async (e: Event) => {
    e.preventDefault()
    this.setState({ saving: true, transitionTo: CONFIRM_INFO })

    setTimeout(async () => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null,
        saving: false
      })

      history.push(`${match.url}/confirm`)

      const { email, selection, selectedAddOns, appointmentTime } = this.state
      const productIds = [selection.id, ...selectedAddOns.map(p => p.id)]
      logNextBtn({ from: '/vehicle', to: '/confirm', email, productIds, appointmentTime })
    }, 200)
  }

  onConfirmInfoSubmit = async () => {
    this.setState({ saving: true, transitionTo: PAYMENT_INFO })

    setTimeout(async () => {
      const { history, match } = this.props

      this.setState({
        transitionTo: null,
        saving: false
      })

      history.push(`${match.url}/account`)

      const { email, selection, selectedAddOns, appointmentTime } = this.state
      const productIds = [selection.id, ...selectedAddOns.map(p => p.id)]
      logNextBtn({ from: '/confirm', to: '/account', email, productIds, appointmentTime })
    }, 200)
  }

  onAccountInfoSubmit = async (e: Event) => {
    try {
      e.preventDefault()
      this.setState({ saving: true })
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        acceptedTermsDate,
        address,
        unitNumber,
        city,
        state,
        zip,
        stallInfo,
        hasCoveredParking,
        make,
        model,
        color,
        license,
        selection,
        selectedAddOns,
        appointmentTime
      } = this.state

      const res = await axios.post('/c/account/create', {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        acceptedTermsDate,
        address,
        unitNumber,
        city,
        state,
        zip,
        stallInfo,
        hasCoveredParking,
        make,
        model,
        color,
        license,

        successUrl: '/signup/payment',
        cancelUrl: '/signup/account'
      })

      const { user, sessionId } = res.data
      this.setState({
        user,
        sessionId,
        transitionTo: PAYMENT_INFO,
        paymentMessage:
          'Please wait, in a moment you will be redirected to our Stripe payment page...'
      })

      setTimeout(async () => {
        const { history, match } = this.props

        this.setState({
          transitionTo: null,
          saving: false
        })

        history.push(`${match.url}/payment`)

        const productIds = [selection.id, ...selectedAddOns.map(p => p.id)]
        logNextBtn({ from: '/account', to: '/payment', email, productIds, appointmentTime })

        await this.storeState(StoreKey.PRE_REDIRECT)
      }, 200)
    } catch (err) {
      debug('[onAccountInfoSubmit] error: ', err)
      this.setState({ saving: false })
      handleError(err, true)
    }
  }

  onPaymentInfoSubmit = async (token: Object) => {
    this.setState({ token }, () => {
      this.activateAccount()
    })
  }

  activateAccount = async () => {
    const { shoppingCart, history, match } = this.props
    const {
      email,
      products,
      selection,
      selectedAddOns,
      appointmentTime,
      packageDeal,
      discountCode,
      token
    } = this.state

    this.setState({ saving: true })

    analytics.signup()

    try {
      const res = await axios.post('/c/account/activate', {
        selection,
        packageDeal,
        addOns: selectedAddOns.map(addOn => addOn.id),
        discountCode: discountCode
          ? discountCode
          : shoppingCart.discountApplied
          ? shoppingCart.discountCode
          : null,
        appointmentTime,
        tokenId: token.id
      })

      try {
        const gaProducts = [products.find(p => p.id === selection.id), ...selectedAddOns]
        const { event } = res.data
        addPurchaseToDataLayer(event.id, event.charge.amount, 'NEW_CUSTOMER', gaProducts)
      } catch (err) {
        console.error(err)
      }

      this.setState({
        saving: false,
        transitionTo: DONE
      })

      clearShoppingCart()
      this.resetState()

      setTimeout(() => {
        const { history, match } = this.props

        this.setState({
          transitionTo: null
        })

        history.push(`${match.url}/done`)

        const productIds = [selection.id, ...selectedAddOns.map(p => p.id)]
        logNextBtn({ from: '/payment', to: '/done', email, productIds, appointmentTime })
      }, 200)
    } catch (err) {
      debug('[activateAccount] error: ', err)
      this.setState({ saving: false })
      handleError(err, true)
      if (isAppointmentError(err)) {
        history.push(`${match.url}/date`)

        this.fetchAvailableTimes()
      }
    }
  }

  onPasswordInfoSubmit = async (e: Event) => {
    try {
      e.preventDefault()
      const { password } = this.state
      this.setState({ saving: true })
      const res = await axios.post('/c/account/set_password', { password })
      const { user, csrf } = res.data
      initSession(new User(user), undefined, undefined, csrf)
      this.setState({ saving: false })
      this.props.history.push('/')
      log('UserSignupPage/SavePassword', { from: '/done', to: '/', email: user.email })
    } catch (err) {
      debug('[onPasswordInfoSubmit] error: ', err)
      this.setState({ saving: false })
      handleError(err, true)
    }
  }

  renderCart = (inline: Boolean = false) => {
    const { emailuser, history, match } = this.props
    const { email, products, selection, packageDeal, selectedAddOns, appointmentTime } = this.state

    return (
      <Cart
        selection={products.find(p => p.id === selection.id)}
        addOns={selectedAddOns}
        appointmentTime={appointmentTime}
        emailuser={emailuser}
        packageDeal={packageDeal}
        onAddPackageDeal={this.onAddPackageDeal}
        onRemovePackageDeal={() => {
          this.setState({
            selection: {},
            selectedAddOns: [],
            packageDeal: null
          })
          history.push(`${match.url}/service`)
          log('UserSignupPage/Cart/Emptied', { email })
        }}
        onRemoveProduct={() => {
          this.setState({ selection: {}, selectedAddOns: [] })
          history.push(`${match.url}/service`)
          log('UserSignupPage/Cart/Emptied', { email })
        }}
        onRemoveAddOn={(addOn: Product) => {
          this.setState({ selectedAddOns: selectedAddOns.filter(p => p !== addOn) })
          log('UserSignupPage/Cart/AddonRemoved', { email, productId: addOn.id })
        }}
        onDiscountApplied={(discountCode, discountPercent, discountAmount) => {
          this.setState({ discountCode, discountPercent, discountAmount })
        }}
        inline={inline}
      />
    )
  }

  renderProductInfo = () => {
    let { products, transitionTo } = this.state

    return (
      <div key="product" className="product-step">
        <BubbleHeader text="Select a Package" />
        {this.renderProgressBar()}

        <div
          className={classNames('container', {
            hiding: transitionTo === ADDON_INFO
          })}>
          <div className="step-description">
            <div>We keep it simple.</div>
            <div>NO HASSLES.</div>
            <div>WE COME TO YOU.</div>
          </div>

          {/* {isMobile() && !shoppingCart.discountApplied ? (
            <PackageDealsForm onAddPackageDeal={this.onAddPackageDeal} />
          ) : null} */}

          <ProductGrid
            products={products}
            onChange={product => this.onProductInfoSubmit(product)}
          />
        </div>

        {/* {window.innerWidth > 1000 ? this.renderCart() : null} */}
      </div>
    )
  }

  renderAddOnInfo = () => {
    const { history, match } = this.props
    const { email, products, selection, selectedAddOns, transitionTo } = this.state

    let addOns = []
    let fixedAddOns = []
    let product = {}
    if (selection.id) {
      product = products.find(p => p.id === selection.id)
      addOns = product.add_ons
    } else {
      const info = this.getPackageDealInfo()
      if (info) {
        product = info.product
        addOns = info.addOns
        fixedAddOns = info.selectedAddOns
      }
    }

    return (
      <div key="addon" className="addon-step">
        <BubbleHeader text="Select Add-ons" />
        {this.renderProgressBar()}

        <div
          className={classNames('container', {
            hiding: transitionTo === APPOINTMENT_INFO
          })}>
          <AddOnGrid
            addOns={addOns}
            selectedAddOns={selectedAddOns}
            fixedAddOns={fixedAddOns}
            color={getColor(product)}
            onChange={(addOn: Product, checked: Boolean) => {
              if (checked) {
                this.setState({ selectedAddOns: [...selectedAddOns, addOn] })
                log('UserSignupPage/AddonSelected', { email, productId: addOn.id })
              } else {
                this.setState({ selectedAddOns: selectedAddOns.filter(p => p.id !== addOn.id) })
                log('UserSignupPage/AddonRemoved', { email, productId: addOn.id })
              }
            }}
          />

          <div className="footer">
            <button
              id="signup-back-1"
              type="button"
              className="secondary"
              onClick={() => {
                this.setState({ selection: {}, selectedAddOns: [] })
                history.push(`${match.url}/service`)
                logBackBtn({ from: '/addons', to: '/service', email })
              }}>
              Back
            </button>
            <button id="signup-next-2" className="primary" onClick={this.onAddOnInfoSubmit}>
              Next
            </button>
          </div>
        </div>

        {/* {window.innerWidth > 1000 ? this.renderCart() : null} */}
      </div>
    )
  }

  renderAppointmentInfo = () => {
    const { history, match } = this.props
    const { email, token, availableTimes, appointmentTime, saving, loadingAppts, transitionTo } =
      this.state

    return (
      <div key="appointment" className="appointment-step">
        <BubbleHeader text="Choose Appointment" />
        {this.renderProgressBar()}

        <div
          className={classNames('container calendar-wrapper', {
            hiding: transitionTo === ACCOUNT_INFO
          })}>
          {loadingAppts ? (
            <div className="calendar-placeholder">
              <div className="div-1" />
              <div className="div-2" />
              <div className="div-3" />
            </div>
          ) : (
            <Calendar
              events={availableTimes}
              views={['month']}
              onSelectEvent={event => {
                this.setState({ appointmentTime: event.start })
                this.onAppointmentInfoSubmit()
              }}
              readonly
            />
          )}
          <div className="footer">
            <button
              id="signup-back-2"
              type="button"
              className="secondary"
              onClick={() => {
                history.push(`${match.url}/addons`)
                logBackBtn({ from: '/date', to: '/addons', email })
              }}>
              Back
            </button>
            <button
              id="signup-next-3"
              className="primary"
              disabled={saving || !appointmentTime}
              onClick={this.onAppointmentInfoSubmit}>
              {token ? 'Submit' : 'Next'}
            </button>
          </div>
        </div>

        {/* {window.innerWidth > 1000 ? this.renderCart() : null} */}
      </div>
    )
  }

  renderLocationInfo = () => {
    const { history, match } = this.props
    const {
      email,

      address,
      unitNumber,
      city,
      state,
      zip,

      saving,
      transitionTo
    } = this.state

    const valid = Boolean(address.trim() && city.trim() && state.trim() && zip.trim())

    return (
      <div key="location" className="location-step">
        <BubbleHeader text="Let's Check Your Wash Location" />
        {this.renderProgressBar()}

        <div
          className={classNames('container map-wrapper', {
            hiding: transitionTo === ACCOUNT_INFO
          })}>
          <div className="map-info">Serving the Central Austin area.</div>

          <AustinMap
            onGeolocateSuccess={({ address, city, state, zip }) => {
              if (this.manuallyEnteredAddress) {
                console.log('[onGeolocateSuccess] not overwriting user inputted address')
              } else {
                console.log('[onGeolocateSuccess] updating address')
                this.setState({ address, city, state, zip })
              }
            }}
          />
        </div>
        <form onSubmit={this.onLocationInfoSubmit}>
          <Input
            type="text"
            name="address"
            autoComplete="address-line1"
            label="Vehicle Wash Location Address"
            className="formfield"
            value={address}
            onChange={e => {
              this.manuallyEnteredAddress = true
              this.setState({ address: e.target.value })
            }}
          />
          <Input
            type="text"
            name="unitNumber"
            autoComplete="address-line2"
            label="Unit number (optional)"
            className="formfield"
            value={unitNumber}
            onChange={e => {
              this.manuallyEnteredAddress = true
              this.setState({ unitNumber: e.target.value })
            }}
          />
          <div className="input-row">
            <Input
              type="text"
              name="city"
              autoComplete="address-level2"
              label="City"
              className="formfield city"
              value={city}
              onChange={e => {
                this.manuallyEnteredAddress = true
                this.setState({ city: e.target.value })
              }}
            />
            <Input
              type="text"
              name="state"
              autoComplete="address-level1"
              label="State"
              className="formfield state"
              value={state}
              onChange={e => {
                this.manuallyEnteredAddress = true
                this.setState({ state: e.target.value })
              }}
            />
            <Input
              type="text"
              name="zip"
              autoComplete="postal-code"
              label="Zip"
              className="formfield zip"
              value={zip}
              onChange={e => {
                this.manuallyEnteredAddress = true
                this.setState({ zip: e.target.value })
              }}
            />
          </div>

          <div className="footer">
            <button
              id="signup-back-3"
              type="button"
              className="secondary"
              onClick={() => {
                history.push(`${match.url}/date`)
                logBackBtn({ from: '/location', to: '/date', email })
              }}>
              Back
            </button>
            <button
              id="signup-next-4"
              className="primary"
              onClick={this.onLocationInfoSubmit}
              disabled={saving || !valid}>
              Next
            </button>
          </div>
        </form>

        {/* {window.innerWidth > 1000 ? this.renderCart() : null} */}
      </div>
    )
  }

  renderVehicleInfo = () => {
    const { history, match } = this.props
    const {
      email,

      // Vehicle fields
      stallInfo,
      hasCoveredParking,
      make,
      model,
      color,
      license,

      vehicleMakes,
      vehicleModels,

      saving,
      transitionTo
    } = this.state

    const valid = Boolean(make.trim() && model.trim() && color.trim())

    return (
      <div key="vehicle" className="vehicle-step">
        <BubbleHeader text="Add Your Vehicle Information" />
        {this.renderProgressBar()}

        <form
          onSubmit={this.onVehicleInfoSubmit}
          className={classNames('container', {
            hiding: transitionTo === PAYMENT_INFO
          })}>
          <Input
            type="autocomplete"
            name="shineritevehiclemake"
            autoComplete="off"
            autoCapitalize="on"
            label="Make"
            className="formfield"
            value={make}
            options={vehicleMakes.map(v => ({ value: v.id, label: v.make }))}
            onChange={e => {
              this.setState({ make: e.target.value })
              // this.fetchVehicleMakes(e.target.value)
            }}
            onSelect={option => {
              try {
                const vehicle = vehicleMakes.find(v => v.id === option.value)
                let vehicleModels: String[] = JSON.parse(vehicle.models.replace(/'/g, '"'))
                vehicleModels.sort()
                vehicleModels = vehicleModels.map(m => ({
                  value: m,
                  label: m
                }))

                this.setState({ vehicleModels, model: '' })
              } catch (err) {
                console.error(err)
              }
            }}
          />
          <Input
            type="autocomplete"
            name="shineritevehiclemodel"
            autoComplete="off"
            autoCapitalize="on"
            label="Model"
            className="formfield"
            value={model}
            onChange={e => this.setState({ model: e.target.value })}
            options={
              model.trim().length > 0
                ? vehicleModels.filter(o => o.label.toLowerCase().includes(model.toLowerCase()))
                : vehicleModels
            }
          />
          <Input
            type="text"
            name="shineritevehiclecolor"
            autoComplete="off"
            label="Color"
            className="formfield"
            value={color}
            onChange={e => this.setState({ color: e.target.value })}
          />
          <Input
            type="text"
            name="shineritevehiclelicenseplate"
            autoComplete="off"
            autoCapitalize="on"
            label="License plate (optional)"
            className="formfield"
            value={license}
            onChange={e => this.setState({ license: e.target.value.toUpperCase() })}
          />
          <Input
            type="checkbox"
            name="shineritevehiclecoveredparking"
            label="Is covered parking available?"
            className="formfield checkbox"
            checked={hasCoveredParking}
            onChange={e => this.setState({ hasCoveredParking: e.target.checked })}
          />
          <Input
            type="textarea"
            name="shineritevehiclegatecode"
            autoComplete="off"
            label="Stall number / gate code / other instructions / how'd you hear about us?"
            className="formfield"
            value={stallInfo}
            onChange={e => this.setState({ stallInfo: e.target.value })}
          />

          <div className="footer">
            <button
              id="signup-back-4"
              type="button"
              className="secondary"
              onClick={() => {
                history.push(`${match.url}/location`)
                logBackBtn({ from: '/vehicle', to: '/location', email })
              }}>
              Back
            </button>
            <button
              id="signup-next-5"
              className="primary"
              onClick={this.onVehicleInfoSubmit}
              disabled={saving || !valid}>
              Next
            </button>
          </div>
        </form>

        {/* {window.innerWidth > 1000 ? this.renderCart() : null} */}
      </div>
    )
  }

  renderConfirmInfo = () => {
    const { history, match } = this.props
    const {
      email,
      selection,
      packageDeal,
      make,
      model,
      color,
      license,
      hasCoveredParking,
      stallInfo,
      address: street,
      unitNumber,
      city,
      state,
      zip,
      saving,
      transitionTo
    } = this.state

    return (
      <div key="confirm" className="confirm-step">
        <BubbleHeader text="Verify Your Information" />
        {this.renderProgressBar()}

        <div
          className={classNames('container', {
            hiding: transitionTo === DONE
          })}>
          <VehiclePreview
            make={make}
            model={model}
            color={color}
            license={license}
            hasCoveredParking={hasCoveredParking}
            stallInfo={stallInfo}
          />
          <LocationPreview
            street={unitNumber ? `${street} ${unitNumber}` : street}
            city={city}
            state={state}
            zip={zip}
          />

          {selection.id || packageDeal ? this.renderCart(true) : null}

          <div className="footer">
            <button
              id="signup-back-5"
              type="button"
              className="secondary"
              onClick={() => {
                history.push(`${match.url}/vehicle`)
                logBackBtn({ from: '/confirm', to: '/vehicle', email })
              }}>
              Back
            </button>
            <button
              id="signup-next-6"
              className="primary"
              onClick={this.onConfirmInfoSubmit}
              disabled={saving}>
              Next
            </button>
          </div>
        </div>
      </div>
    )
  }

  renderAccountInfo = () => {
    const { history, match } = this.props
    const { firstName, lastName, email, phoneNumber, emailDisabled, transitionTo, saving } =
      this.state

    const valid =
      isValidEmail(email) && Boolean(firstName.trim() && lastName.trim() && phoneNumber.trim())

    return (
      <div
        key="account"
        className={classNames('account-step', {
          hiding: transitionTo === DONE
        })}>
        <BubbleHeader text="Almost There" />
        {this.renderProgressBar()}

        <h3>
          <p>We will text you the day of your wash so you can unlock your vehicle. </p>
          <p>You will receive an email after your wash so you can adjust your tip.</p>
        </h3>
        <h4>Please provide the following:</h4>

        <form className="account-form" onSubmit={this.onAccountInfoSubmit}>
          <Input
            type="text"
            name="fname"
            autoComplete="given-name"
            label="First name"
            className="formfield"
            value={firstName}
            onChange={e => this.setState({ firstName: e.target.value })}
          />
          <Input
            type="text"
            name="lname"
            autoComplete="family-name"
            label="Last name"
            className="formfield"
            value={lastName}
            onChange={e => this.setState({ lastName: e.target.value })}
          />
          <Input
            type="tel"
            name="phone"
            autoComplete="tel"
            label="Cell phone number"
            className="formfield"
            value={phoneNumber}
            onChange={e =>
              this.setState({ phoneNumber: tryParsePhoneNumber(phoneNumber, e.target.value) })
            }
          />
          <Input
            type="email"
            name="email"
            autoComplete="email"
            autoCapitalize="off"
            label="Email"
            className="formfield"
            value={email}
            disabled={emailDisabled}
            onChange={e => this.setState({ email: e.target.value })}
          />

          <div className="footer">
            <button
              id="signup-back-6"
              type="button"
              className="secondary"
              onClick={() => {
                history.push(`${match.url}/confirm`)
                logBackBtn({ from: '/account', to: '/confirm', email })
              }}>
              Back
            </button>
            <button
              id="signup-next-7"
              className="primary"
              disabled={saving || !valid}
              onClick={this.onAccountInfoSubmit}>
              Next
            </button>
          </div>
        </form>

        {/* {window.innerWidth > 1000 ? this.renderCart() : null} */}
      </div>
    )
  }

  renderPaymentInfo = () => {
    const { history, match } = this.props
    const {
      email,
      firstName,
      lastName,
      address,
      city,
      state,
      zip,
      acceptedTermsDate,
      saving,
      transitionTo
    } = this.state

    return (
      <div
        key="payment"
        className={classNames('payment-step', {
          hiding: transitionTo === DONE
        })}>
        <BubbleHeader text="Payment info" />
        {this.renderProgressBar()}
        <PaymentForm
          customerName={`${firstName} ${lastName}`}
          address={{ street: address, city, state, zip }}
          onSuccess={this.onPaymentInfoSubmit}>
          {form => (
            <Fragment>
              <div className="color-divider cart-divider" />
              {this.renderCart(true)}
              <div className="color-divider terms-divider" />
              <div className="terms">
                <Terms
                  acceptedTermsDate={acceptedTermsDate}
                  onChange={e =>
                    this.setState({ acceptedTermsDate: e.target.checked ? new Date() : null })
                  }
                />
              </div>
              <div className="footer">
                <button
                  type="button"
                  className="secondary"
                  disabled={saving || form.saving}
                  onClick={() => {
                    history.push(`${match.url}/account`)
                    logBackBtn({ from: '/payment', to: '/account', email })
                  }}>
                  Back
                </button>
                <button
                  id="signup-confirm"
                  type="submit"
                  className="primary"
                  disabled={saving || form.saving || !form.valid || !acceptedTermsDate}>
                  Confirm Purchase
                </button>
              </div>
            </Fragment>
          )}
        </PaymentForm>

        {/* {window.innerWidth > 1000 ? this.renderCart() : null} */}
      </div>
    )
  }

  renderDoneMessage = () => {
    const { password, selection, packageDeal, saving } = this.state

    if (!selection.id && !packageDeal) {
      return null
    }

    return (
      <div key="done" className="done-step">
        <h1>
          Welcome{' '}
          <span role="img" aria-label="tada">
            🎉
          </span>
        </h1>
        <div className="done-message">
          <p>Thanks for signing up!</p>
          <CustomerInstructions />
        </div>
        <form className="password-card" onSubmit={this.onPasswordInfoSubmit}>
          <p>Create a password for faster checkout next time:</p>
          <Input
            type="password"
            name="password"
            autoComplete="password"
            autoCapitalize="off"
            label="Password"
            className="formfield"
            value={password}
            disabled={saving}
            onChange={e => this.setState({ password: e.target.value })}
          />
          <div className="footer">
            <button id="signup-next-8" type="submit" className="primary">
              Save
            </button>
          </div>
        </form>
      </div>
    )
  }

  renderSummary = () => {
    const { shoppingCart } = this.props
    const { discountAmount, discountPercent } = shoppingCart
    const { selection, products, selectedAddOns, packageDeal } = this.state

    const product = products.find(p => p.id === selection.id)
    const totals = calculateTotals(
      product,
      selectedAddOns,
      discountPercent,
      discountAmount,
      packageDeal
    )

    const total = totals.total

    return (
      <div className="summary gridfields">
        <div>
          <span>Total</span>
          <span>{formatMoney(total / 100)}</span>
        </div>
      </div>
    )
  }

  renderProgressBar = () => {
    const step = _.last(this.props.location.pathname.split('/'))
    const current = STEPS.find(s => s[2].test(step))

    return <DiscreteProgessBar current={current} steps={STEPS} />
  }

  render() {
    const { match, location } = this.props
    const { loading } = this.state

    return (
      <>
        <div className="UserSignupPage2">
          {!location.pathname.endsWith('/done') ? this.renderSummary() : null}

          {loading ? null : (
            <Switch>
              <Route path={`${match.path}/service`} render={this.renderProductInfo} />
              <Route path={`${match.path}/addons`} render={this.renderAddOnInfo} />
              <Route path={`${match.path}/date`} render={this.renderAppointmentInfo} />
              <Route path={`${match.path}/location`} render={this.renderLocationInfo} />
              <Route path={`${match.path}/vehicle`} render={this.renderVehicleInfo} />
              <Route path={`${match.path}/confirm`} render={this.renderConfirmInfo} />
              <Route path={`${match.path}/account`} render={this.renderAccountInfo} />
              <Route path={`${match.path}/payment`} render={this.renderPaymentInfo} />
              <Route path={`${match.path}/done`} render={this.renderDoneMessage} />
              <Route
                exact
                path={match.path}
                render={() => <Redirect to={`${match.url}/service`} />}
              />
            </Switch>
          )}
        </div>
        <Footer />
      </>
    )
  }
}

const mapState = state => ({
  shoppingCart: state.shoppingCart,
  signupInfo: state.app.signupInfo,
  emailuser: state.emailusers[0]
})

export default connect(mapState)(UserSignupPage)
