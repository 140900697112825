import './style.scss'

import React, { Component } from 'react'
import Field from '../../shared/Field/Field'
import SubmitButton from '../../shared/SubmitButton/SubmitButton'
import Form from '../../shared/Form/Form'
import { handleError } from '../../../util/error-util'
import axios from 'axios'
import Footer from '../../Footer/Footer'

type Props = {}

type State = {
  name: String,
  email: String,
  message: String,
  saving: Boolean,
  messageSent: Boolean
}

export default class Contact extends Component<Props, State> {
  state = {
    name: '',
    email: '',
    message: '',
    saving: false,
    messageSent: false
  }

  onSubmit = async () => {
    try {
      this.setState({ saving: true })
      const { name, email, message } = this.state
      await axios.post('/contact', { name, email, message })
      this.setState({ messageSent: true })
    } catch (err) {
      handleError(err)
    } finally {
      this.setState({ saving: false })
    }
  }

  render() {
    const { name, email, message, saving, messageSent } = this.state

    return (
      <div className="LandingPageBase Contact">
        <div className="heading">
          <div className="container">
            <h1>Contact us</h1>
          </div>
        </div>
        <div className="content container">
          <div className="contact-form">
            {messageSent ? (
              <div className="response">Thanks! We'll be in touch soon.</div>
            ) : (
              <Form onSubmit={this.onSubmit}>
                <h2>WE'RE READY, LET'S TALK.</h2>
                <Field
                  type="text"
                  label="Your Name:"
                  value={name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
                <Field
                  type="email"
                  label="Email Address:"
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
                <Field
                  type="textarea"
                  label="Message:"
                  value={message}
                  onChange={e => this.setState({ message: e.target.value })}
                />
                <SubmitButton disabled={saving} onClick={this.onSubmit} />
              </Form>
            )}
          </div>
          <div className="contact-info">
            <h2>CONTACT INFO</h2>
            <div className="bold">EMAIL US</div>
            <div>
              <a href="mailto:info@shineriteco.com">info@shineriteco.com</a>
            </div>
            <div className="bold">CALL US</div>
            <div>512-230-0988</div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
