import BaseModel from './BaseModel'
import Product from './Product'
import Shift from './Shift'

export default class ShiftProduct extends BaseModel {
  shift: Shift
  product: Product
  count: Number

  constructor(data: Object = {}) {
    super(data)
    this.product = data.product ? new Product(data.product) : null
  }
}
