import './style.scss'

import React from 'react'
import Button from '../Button/Button'

type Props = {
  disabled?: Boolean,
  onClick: Function
}

export default function SubmitButton(props: Props) {
  return (
    <Button submit className="SubmitButton" {...props}>
      Submit
    </Button>
  )
}
