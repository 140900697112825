import './style.scss'

import React from 'react'
import Button from '../Button/Button'

type Props = {
  className?: string,
  onClick: Function,
  disabled: boolean,
  children: any
}

export default function DeleteButton(props: Props) {
  const { className, disabled, children } = props

  const onClick = (e: Event) => {
    e.preventDefault()
    props.onClick()
  }

  return (
    <Button
      className={`DeleteButton ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  )
}

DeleteButton.defaultProps = {
  children: 'Delete'
}
