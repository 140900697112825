import './style.scss'

import React, { Component, Fragment } from 'react'
import _debug from 'debug'
import axios from 'axios'
import Form from '../../shared/Form/Form'
import Field from '../../shared/Field/Field'
import SubmitButton from '../../shared/SubmitButton/SubmitButton'
import { handleError } from '../../../util/error-util'

const debug = _debug('carwash:components:ForgotPassword')

type Props = {}

type State = {
  email: String,
  message: String,
  saving: Boolean
}

export default class ForgotPassword extends Component<Props, State> {
  state = {
    email: '',
    message: '',
    saving: false
  }

  onSubmit = async () => {
    try {
      this.setState({ saving: true })
      const { email } = this.state
      const res = await axios.post('/forgot-password', { email })
      this.setState({ message: res.data.msg, saving: false })
    } catch (err) {
      debug('[onSubmit] error: ', err)
      handleError(err, true)
      this.setState({ saving: false })
    }
  }

  onChange = (prop: String, value: any) => {
    this.setState({ [prop]: value })
  }

  render() {
    const { email, message, saving } = this.state

    return (
      <div className="ForgotPassword form-centered">
        {message ? (
          <Fragment>
            <h3>Password Reset</h3>
            <p>{message}</p>
          </Fragment>
        ) : (
          <Fragment>
            <h3>Password Reset</h3>
            <p>Enter your email to reset your password.</p>
            <Form id="login-form" name="login-form" onSubmit={this.onSubmit}>
              <Field
                label="Email"
                type="email"
                autoComplete="email"
                value={email}
                onChange={e => this.onChange('email', e.target.value)}
                usePlaceholders
                autoFocus
              />
              <SubmitButton disabled={saving} onClick={this.onSubmit} />
            </Form>
          </Fragment>
        )}
      </div>
    )
  }
}
