import axios from 'axios'
import _debug from 'debug'
import { store } from '../store'
import User from '../../models/User'
import Car from '../../models/Car'
import Address from '../../models/Address'

const debug = _debug('carwash:store:actions:app')

let _timer = null

export async function loadSession() {
  try {
    const res = await axios.get('/session')
    let { user, org, settings, csrf } = res.data
    if (user) {
      user = new User(user)
    }

    return initSession(user, org, settings, csrf)
  } catch (err) {
    debug('[loadSession] error: ', err)
    store.dispatch({ type: 'SET_SESSION', user: null })
    return {}
  }
}

export function toast(message: string, timeout: Number) {
  clearTimeout(_timer)

  store.dispatch({ type: 'SET_TOAST', message })

  if (!timeout) {
    timeout = 3000
    if (message.length > 30) {
      timeout = 8000
    }
  }

  _timer = setTimeout(() => {
    store.dispatch({ type: 'SET_TOAST', message: null })
  }, timeout)
}

export function setCsrfToken(csrfToken: string) {
  if (!csrfToken) {
    return
  }

  axios.defaults.headers.common['X-CSRFToken'] = csrfToken
}

export function initSession(user: User, org: ?Object, settings: Object, csrfToken: ?string) {
  setCsrfToken(csrfToken)
  store.dispatch({ type: 'SET_SESSION', user, org, settings })
  return { user, org }
}

export function updateCurrentUser(user: User) {
  store.dispatch({ type: 'UPDATE_CURRENT_USER', user })
}

export function continueSignup(user: User, car: Car, address: Address) {
  store.dispatch({ type: 'SET_SIGNUP_INFO', signupInfo: { user, car, address } })
}

export function setAppData(key: String, value: any) {
  store.dispatch({ type: 'SET_APP_DATA', key, value })
}

export function hideCovidBar() {
  store.dispatch({ type: 'SHOW_COVID_BAR', showCovidBar: false })
}

export function enableNavUrls() {
  store.dispatch({ type: 'ENABLE_NAVIGATION' })
}

export function disableNavUrls() {
  store.dispatch({ type: 'DISABLE_NAVIGATION' })
}

export async function logout() {
  await axios.post('/logout')
  window.location = '/'
}
