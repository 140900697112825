import './style.scss'

import React, { Component, Fragment } from 'react'
import classNames from 'classnames'

type Props = {
  current: string,
  steps: string[] | string[][]
}

type State = {}

export default class DiscreteProgessBar extends Component<Props, State> {
  state = {}

  renderStepWithLabel = (step: String[], i: Number) => {
    const { current } = this.props
    const [index, label] = step

    return (
      <Fragment key={i}>
        {i > 0 ? <div className="bar" /> : null}
        <div key={index} className={classNames('step', { active: step === current })}>
          <div>{index}</div>
          <div>{label}</div>
        </div>
      </Fragment>
    )
  }

  renderStep = (step: String, i: Number) => {
    const { current } = this.props

    return (
      <Fragment key={i}>
        {i > 0 ? <div className="bar" /> : null}
        <div key={step} className={classNames('step', { active: step === current })}>
          {step}
        </div>
      </Fragment>
    )
  }

  render() {
    const { steps } = this.props

    return (
      <div className="DiscreteProgessBar">
        {steps.map((step, i) =>
          Array.isArray(step) ? this.renderStepWithLabel(step, i) : this.renderStep(step, i)
        )}
      </div>
    )
  }
}
