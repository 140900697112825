import './style.scss'

import React from 'react'
import Button from '../Button/Button'

type Props = {
  onClick: Function
}

export default function AddButton(props: Props) {
  return (
    <Button icon="plus-white" className="AddButton" onClick={props.onClick} />
  )
}
