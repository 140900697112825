import { store } from './store'
import User from '../models/User'

const ADMINS = ['ORG_ADMIN', 'ORG_STAFF', 'SUPER_ADMIN']
const CUSTOMERS = ['ACTIVE', 'GUEST']

export function getUser() {
  return store.getState().app.user
}

export function isAdmin(user: User) {
  user = user || store.getState().app.user
  return !!user && ADMINS.includes(user.type)
}

export function isCustomer(user: User) {
  user = user || store.getState().app.user
  return !!user && CUSTOMERS.includes(user.type)
}

export function getAppData(key: String) {
  return store.getState().app.appData[key]
}

const initialSearch = window.location.search

export function getStripeKey() {
  let useLiveKey = /shineriteco\.com/.test(window.location.hostname)

  if (!useLiveKey) {
    try {
      const params = new URLSearchParams(initialSearch)
      if (params.get('skey') === 'live') {
        console.log('using live key')
        useLiveKey = true
      }
    } catch (err) {
      // no op
    }
  }

  const prop = useLiveKey ? 'paymentKeyPublicLive' : 'paymentKeyPublicTest'
  const { org } = store.getState().app
  return org[prop]
}
