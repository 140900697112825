import './style.scss'

import React, { Component } from 'react'
import SaveButton from '../shared/SaveButton/SaveButton'
import axios from 'axios'
import { toast, logout } from '../../store/actions/app'
import CollapsibleSection from '../shared/CollapsibleSection/CollapsibleSection'
import Field from '../shared/Field/Field'
import TitleBar from '../shared/TitleBar/TitleBar'
import { handleError } from '../../util/error-util'
import Form from '../shared/Form/Form'
import ActionBar from '../shared/ActionBar/ActionBar'
import Button from '../shared/Button/Button'

type State = {
  password: String,
  saving: Boolean
}

export default class AdminAccount extends Component<Props, State> {
  state = {
    password: '',
    saving: false
  }

  onSavePassword = async () => {
    try {
      this.setState({ saving: true })
      const { password } = this.state
      toast('Saving...')
      await axios.post(`/change_password`, { password })
      this.setState({ password: '' })
      toast('Saved')
    } catch (err) {
      handleError(err)
    } finally {
      this.setState({ saving: false })
    }
  }

  isValid = () => {
    const { password, saving } = this.state
    return !saving && password.length > 6
  }

  render() {
    const { password } = this.state

    return (
      <div className="AdminAccount">
        <TitleBar label="Account" />
        <CollapsibleSection label="Change Password">
          <Form>
            <Field
              type="password"
              label="New password:"
              value={password}
              onChange={e => this.setState({ password: e.target.value })}
            />
            <ActionBar>
              <SaveButton
                onClick={this.onSavePassword}
                disabled={!this.isValid()}
              />
            </ActionBar>
          </Form>
        </CollapsibleSection>
        <ActionBar>
          <Button type="secondary" icon="logout" onClick={logout}>
            Logout
          </Button>
        </ActionBar>
      </div>
    )
  }
}
