import './style.scss'

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import UserFriendlyImage from '../../../../assets/image-userfriendly-website.png'
import LogoBanner from '../../../../assets/logo-banner.png'
import Phone from '../../../../assets/phone.jpg'
import { isMobile } from '../../../../util/dom-util'

type Props = {
  noButtons: Boolean
}

export class MobileFriendly extends Component<Props> {
  render() {
    const { noButtons = false } = this.props

    return isMobile() ? (
      <section className="MobileFriendly_Mobile">
        <img className="car" src={UserFriendlyImage} alt="UserFriendlyImage" />
        <div className="phone" style={{ backgroundImage: `url(${Phone})` }}>
          <img src={LogoBanner} alt="LogoBanner" className="logo" />
          {noButtons ? null : (
            <Link id="book-now-8" to="/signup">
              Book Now
            </Link>
          )}
        </div>
      </section>
    ) : (
      <div className="MobileFriendly">
        <h4>
          Our mobile friendly website is super easy to use. You can enter multiple vehicles and
          addresses.
        </h4>

        <div className="car-img">
          <img src={UserFriendlyImage} alt="UserFriendlyImage" />
        </div>

        <div style={{ backgroundImage: `url(${Phone})` }} className="mobile-img">
          <img src={LogoBanner} alt="LogoBanner" className="logo-banner-img" />
          <div className="signup-wrapper">
            {noButtons ? null : (
              <Link id="book-now-9" to="/signup" className="button primary signup">
                Book Now
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  }
}
