import React, { Component, Fragment } from 'react'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import EmployeeForm from '../Form/EmployeeForm'
import TitleBar from '../../shared/TitleBar/TitleBar'
import Event from '../../../models/Event'
import Form from '../../shared/Form/Form'
import Field from '../../shared/Field/Field'
import WorkSchedule from '../../../models/WorkSchedule'
import ActionBar from '../../shared/ActionBar/ActionBar'
import SaveButton from '../../shared/SaveButton/SaveButton'
import { toast } from '../../../store/actions/app'
import { handleError } from '../../../util/error-util'
import User from '../../../models/User'

type Props = {
  employee: User,
  appointments: Object[],
  urlPrefix: string,
  match: Object,
  history: Object
}

type State = {
  workSchedule: WorkSchedule,
  customHours: Boolean,
  saving: Boolean
}

export default class BasicInfo extends Component<Props, State> {
  state = {
    workSchedule: null,
    customHours: false,
    saving: false
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    const { employee = {} } = this.props
    const workschedule = employee.workschedule || {
      start_time: '08:00',
      end_time: '18:00',
      mo: true,
      tu: true,
      we: true,
      th: true,
      fr: true
    }
    const workSchedule = new WorkSchedule(workschedule)

    const nextState = { workSchedule }

    if (
      workSchedule.su_start_time ||
      workSchedule.su_end_time ||
      workSchedule.mo_start_time ||
      workSchedule.mo_end_time ||
      workSchedule.tu_start_time ||
      workSchedule.tu_end_time ||
      workSchedule.we_start_time ||
      workSchedule.we_end_time ||
      workSchedule.th_start_time ||
      workSchedule.th_end_time ||
      workSchedule.fr_start_time ||
      workSchedule.fr_end_time ||
      workSchedule.sa_start_time ||
      workSchedule.sa_end_time
    ) {
      nextState.customHours = true
    }

    this.setState(nextState)
  }

  onChange = (prop: String, value: any) => {
    const workSchedule = _.cloneDeep(this.state.workSchedule)
    workSchedule[prop] = value
    this.setState({ workSchedule })
  }

  onCustomHoursChange = (e: any) => {
    const nextState = { customHours: e.target.checked }
    if (!e.target.checked) {
      const workSchedule: WorkSchedule = _.cloneDeep(this.state.workSchedule)
      workSchedule.su_start_time = null
      workSchedule.su_end_time = null
      workSchedule.mo_start_time = null
      workSchedule.mo_end_time = null
      workSchedule.tu_start_time = null
      workSchedule.tu_end_time = null
      workSchedule.we_start_time = null
      workSchedule.we_end_time = null
      workSchedule.th_start_time = null
      workSchedule.th_end_time = null
      workSchedule.fr_start_time = null
      workSchedule.fr_end_time = null
      workSchedule.sa_start_time = null
      workSchedule.sa_end_time = null

      nextState.workSchedule = workSchedule
    }

    this.setState(nextState)
  }

  onSaveWorkScheduleClick = () => {
    this.setState({ saving: true }, async () => {
      try {
        const { urlPrefix, employee } = this.props
        const { workSchedule } = this.state
        const data = {
          ...workSchedule,
          employee: employee.id
        }

        toast('Saving...')
        await axios.put(`${urlPrefix}/employees/${employee.id}/workschedule`, data)
        toast('Saved')
      } catch (err) {
        handleError(err)
      } finally {
        this.setState({ saving: false })
      }
    })
  }

  isSaveDisabled = () => {
    const { employee } = this.props
    const { workSchedule, saving } = this.state
    if (saving) {
      return true
    }

    // TODO: workSchedule validation?

    if (!employee.workschedule) {
      return false
    }

    return _.isEqual(workSchedule, employee.workschedule)
  }

  renderAppointments = () => {
    const now = new Date()
    const appointments = _.sortBy(this.props.appointments, 'start').filter(
      a => new Date(a.start) > now
    )

    return (
      <Fragment>
        <div className="header">Upcoming Appointments</div>
        {appointments.length ? (
          appointments.map((appt: Event, i) => {
            return (
              <div key={i} className="appointment-info">
                <div>
                  <span>Date: </span>
                  <span>{moment(appt.start).format('M/D/Y h:mmA')}</span>
                </div>
                <div>
                  <span>Customer: </span>
                  <span>{appt.customer ? appt.customer.toString() : ''}</span>
                </div>
              </div>
            )
          })
        ) : (
          <p>None.</p>
        )}
      </Fragment>
    )
  }

  renderSchedule = () => {
    const { customHours } = this.state
    const workSchedule: WorkSchedule = this.state.workSchedule

    return (
      <div className="work-schedule">
        <div className="header">Work Schedule</div>
        {workSchedule ? (
          <Form>
            <Field
              type="time"
              label="Start time:"
              value={workSchedule.start_time}
              onChange={e => this.onChange('start_time', e.target.value)}
            />
            <Field
              type="time"
              label="End time:"
              value={workSchedule.end_time}
              onChange={e => this.onChange('end_time', e.target.value)}
            />
            <Field
              type="checkbox"
              label="Sunday:"
              checked={workSchedule.su}
              onChange={e => this.onChange('su', e.target.checked)}
            />
            <Field
              type="checkbox"
              label="Monday:"
              checked={workSchedule.mo}
              onChange={e => this.onChange('mo', e.target.checked)}
            />
            <Field
              type="checkbox"
              label="Tuesday:"
              checked={workSchedule.tu}
              onChange={e => this.onChange('tu', e.target.checked)}
            />
            <Field
              type="checkbox"
              label="Wednesday:"
              checked={workSchedule.we}
              onChange={e => this.onChange('we', e.target.checked)}
            />
            <Field
              type="checkbox"
              label="Thursday:"
              checked={workSchedule.th}
              onChange={e => this.onChange('th', e.target.checked)}
            />
            <Field
              type="checkbox"
              label="Friday:"
              checked={workSchedule.fr}
              onChange={e => this.onChange('fr', e.target.checked)}
            />
            <Field
              type="checkbox"
              label="Saturday:"
              checked={workSchedule.sa}
              onChange={e => this.onChange('sa', e.target.checked)}
            />
            <Field
              type="checkbox"
              label="Custom hours each day:"
              checked={customHours}
              onChange={this.onCustomHoursChange}
            />
            {customHours ? (
              <div>
                <h4>Sunday</h4>
                <Field
                  type="time"
                  label="Start time:"
                  value={workSchedule.su_start_time}
                  onChange={e => this.onChange('su_start_time', e.target.value)}
                />
                <Field
                  type="time"
                  label="End time:"
                  value={workSchedule.su_end_time}
                  onChange={e => this.onChange('su_end_time', e.target.value)}
                />
                <h4>Monday</h4>
                <Field
                  type="time"
                  label="Start time:"
                  value={workSchedule.mo_start_time}
                  onChange={e => this.onChange('mo_start_time', e.target.value)}
                />
                <Field
                  type="time"
                  label="End time:"
                  value={workSchedule.mo_end_time}
                  onChange={e => this.onChange('mo_end_time', e.target.value)}
                />
                <h4>Tuesday</h4>
                <Field
                  type="time"
                  label="Start time:"
                  value={workSchedule.tu_start_time}
                  onChange={e => this.onChange('tu_start_time', e.target.value)}
                />
                <Field
                  type="time"
                  label="End time:"
                  value={workSchedule.tu_end_time}
                  onChange={e => this.onChange('tu_end_time', e.target.value)}
                />
                <h4>Wednesday</h4>
                <Field
                  type="time"
                  label="Start time:"
                  value={workSchedule.we_start_time}
                  onChange={e => this.onChange('we_start_time', e.target.value)}
                />
                <Field
                  type="time"
                  label="End time:"
                  value={workSchedule.we_end_time}
                  onChange={e => this.onChange('we_end_time', e.target.value)}
                />
                <h4>Thursday</h4>
                <Field
                  type="time"
                  label="Start time:"
                  value={workSchedule.th_start_time}
                  onChange={e => this.onChange('th_start_time', e.target.value)}
                />
                <Field
                  type="time"
                  label="End time:"
                  value={workSchedule.th_end_time}
                  onChange={e => this.onChange('th_end_time', e.target.value)}
                />
                <h4>Friday</h4>
                <Field
                  type="time"
                  label="Start time:"
                  value={workSchedule.fr_start_time}
                  onChange={e => this.onChange('fr_start_time', e.target.value)}
                />
                <Field
                  type="time"
                  label="End time:"
                  value={workSchedule.fr_end_time}
                  onChange={e => this.onChange('fr_end_time', e.target.value)}
                />
                <h4>Saturday</h4>
                <Field
                  type="time"
                  label="Start time:"
                  value={workSchedule.sa_start_time}
                  onChange={e => this.onChange('sa_start_time', e.target.value)}
                />
                <Field
                  type="time"
                  label="End time:"
                  value={workSchedule.sa_end_time}
                  onChange={e => this.onChange('sa_end_time', e.target.value)}
                />
              </div>
            ) : null}
            <ActionBar>
              <SaveButton disabled={this.isSaveDisabled()} onClick={this.onSaveWorkScheduleClick}>
                Save Work Schedule
              </SaveButton>
            </ActionBar>
          </Form>
        ) : (
          'Loading...'
        )}
      </div>
    )
  }

  render() {
    const { employee, urlPrefix } = this.props
    if (!employee) {
      return 'Loading...'
    }

    return (
      <div className="BasicInfo">
        <TitleBar backTo="/technicians" label="Technician Details" />
        <EmployeeForm employee={employee} urlPrefix={urlPrefix} />
        {this.renderAppointments()}
        {this.renderSchedule()}
      </div>
    )
  }
}
