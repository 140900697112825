import BaseModel from './BaseModel'
import User from './User'
import ShiftProduct from './ShiftProduct'

export default class Shift extends BaseModel {
  start_time: String
  end_time: String
  technicians: User[]
  shift_products: ShiftProduct[]

  constructor(data: Object = {}) {
    super(data)
    this.shift_products = (data.shift_products || []).map(sp => new ShiftProduct(sp))
    if (this.corporate_account) {
      console.log('[Shift] deleting this.corporate_account')
      delete this.corporate_account
    }
  }
}
