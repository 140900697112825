import React, { Component, Fragment } from 'react'
import { formatMoney } from 'accounting'
import moment from 'moment'
import CustomerForm from '../Form/CustomerForm'
import { icons } from '../../../images'
import SaveButton from '../../shared/SaveButton/SaveButton'
import TitleBar from '../../shared/TitleBar/TitleBar'
import AppointmentList from './AppointmentList'
import CarList from './CarList'
import AddressList from './AddressList'

type Props = {
  customer: Object,
  invoices: Object[],
  subscriptions: Object[],
  urlPrefix: string,
  match: Object,
  history: Object
}

export default class BasicInfo extends Component<Props> {
  onChangePlanClick = () => {
    const { match, history } = this.props
    const url = match.url.replace('/info', '/change-plan')
    history.push(url)
  }

  renderBilling = () => {
    const { invoices } = this.props

    return (
      <Fragment>
        <div className="header">Billing</div>
        {invoices.map((inv, i) => (
          <div key={i} className="invoice">
            <span>{moment(inv.created * 1000).format('M/D/Y')}</span>
            <span>{formatMoney(inv.amount_paid / 100)}</span>
            <a
              href={inv.invoice_pdf}
              className="plain download"
              target="_blank"
              rel="noopener noreferrer">
              <img src={icons.download} alt="download" />
            </a>
          </div>
        ))}
      </Fragment>
    )
  }

  renderSubscriptions = () => {
    const { subscriptions } = this.props

    return (
      <Fragment>
        <div className="header">Subscription</div>
        {subscriptions.map((sub, i) => {
          const plans = sub.items.data.map(item => item.plan)
          const planNames = plans.map(plan => plan.nickname)
          const planAmountsTotal = plans
            .map(plan => plan.amount)
            .reduce((acc, amount) => acc + amount, 0)

          return (
            <div key={i} className="subscription-info">
              <div>
                <span>Plan:</span>
                <span>{planNames.join(', ')}</span>
              </div>
              <div>
                <span>Amount:</span>
                <span>
                  {formatMoney(planAmountsTotal / 100)} per {plans[0].interval}
                </span>
              </div>
              <div>
                <span>Paid through:</span>
                <span>{moment(new Date(sub.current_period_end * 1000)).format('M/D/YYYY')}</span>
              </div>
              <div>
                <span>
                  <SaveButton className="sm change-plan" onClick={this.onChangePlanClick}>
                    Change
                  </SaveButton>
                </span>
              </div>
            </div>
          )
        })}
      </Fragment>
    )
  }

  render() {
    const { customer, urlPrefix } = this.props
    if (!customer) {
      return 'Loading...'
    }

    return (
      <div className="BasicInfo">
        <TitleBar backTo="/customers" label="Customer Details" />
        <CustomerForm customer={customer} urlPrefix={urlPrefix} />
        <AppointmentList customer={customer} urlPrefix={urlPrefix} />
        <CarList customer={customer} urlPrefix={urlPrefix} />
        <AddressList customer={customer} urlPrefix={urlPrefix} />
        {/* {this.renderBilling()} */}
      </div>
    )
  }
}
