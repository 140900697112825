import 'react-big-calendar/lib/css/react-big-calendar.css'
import './overrides.scss'
import './style.scss'

import React, { Component } from 'react'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import classNames from 'classnames'
import moment from 'moment'
import Event from '../../../models/Event'
import { ScreenWithClose } from '../Screen/Screen'
import ReservationForm from '../../Reservations/Form/ReservationForm'
import { removeZeros } from '../../../util/date-util'

const localizer = momentLocalizer(moment)

const TIME_FORMAT = 'h:mmA'

type BasicEventInfoProps = {
  event: Event
}

const calendarComponents = {
  event: function BasicEventInfo(props: BasicEventInfoProps) {
    const { event = {} } = props
    let {
      start,
      end,
      cancelled_date,
      title,
      type,
      customer = {},
      employee = {},
      unavailable
    } = event
    const { first_name, last_name } = customer
    type = type || ''

    return (
      <div>
        <span
          className={classNames('BasicEventInfo', type.toLowerCase(), {
            cancelled: !cancelled_date
          })}>
          <span className="time">
            {unavailable ? (
              'Full'
            ) : (
              <>
                {removeZeros(moment(start).format(TIME_FORMAT))}-
                {removeZeros(moment(end).format(TIME_FORMAT))}
              </>
            )}
          </span>
          <strong className="title">
            {first_name && last_name
              ? `${first_name} ${last_name}`
              : title
              ? title
              : type === 'AVAILABLE_TIME' && employee.first_name
              ? `Available: ${employee.first_name}`
              : type === 'TIMEOFF' && employee.first_name
              ? `Time Off: ${employee.first_name}`
              : type !== 'WASH'
              ? type
              : 'Select'}
          </strong>
        </span>
      </div>
    )
  }
}

type Props = {
  events: Object[],
  renderForm?: Function,
  onSelectSlot?: Function,
  onSelectEvent?: Function,
  onRangeChange?: Function,
  onSave?: Function,
  readonly?: Boolean,
  views?: any
}

type State = {
  dialog: 'NEW_EVENT' | 'EDIT_EVENT',
  event: Event
}

export default class Calendar extends Component<Props, State> {
  state = {
    dialog: null,
    event: null
  }

  onSelectSlot = async (slotInfo: Object) => {
    const event = new Event({
      type: 'WASH',
      title: '',
      description: '',
      date: moment(slotInfo.start).format('YYYY-MM-DD'),
      startTime: '08:00',
      endTime: '10:00',

      address: '',
      employee_id: '',
      customer_id: '',
      allDay: false,
      send_email: false
    })

    this.setState({
      dialog: 'NEW_EVENT',
      event
    })
  }

  onSelectEvent = async (event: Event) => {
    this.setState({ dialog: 'EDIT_EVENT', event })
  }

  hideDialog = () => {
    this.setState({ dialog: null, event: null })
  }

  onSave = () => {
    this.hideDialog()
  }

  renderForm = event => {
    return <ReservationForm reservation={event} />
  }

  getEvent = () => {
    const { events } = this.props
    let { event } = this.state

    if (event && event.id) {
      event = events.find(e => e.id === event.id)
    }

    return event
  }

  render() {
    const {
      events,
      readonly,
      views,
      onRangeChange,
      onSelectEvent = this.onSelectEvent,
      onSelectSlot = this.onSelectSlot,
      renderForm = this.renderForm
    } = this.props

    const { dialog } = this.state

    const props = {
      onSelectEvent
    }

    if (!readonly) {
      props.onSelectSlot = onSelectSlot
    }

    return (
      <div className={classNames('Calendar', { readonly })}>
        <BigCalendar
          localizer={localizer}
          events={events}
          components={calendarComponents}
          startAccessor={e => new Date(e.start)}
          endAccessor={e => new Date(e.end)}
          views={views}
          eventPropGetter={(e: Event) => ({
            className: classNames((e.type || '').toLowerCase(), {
              cancelled: !!e.cancelled_date,
              unavailable: !!e.unavailable
            })
          })}
          onRangeChange={onRangeChange}
          selectable
          popup
          {...props}
        />
        {dialog === 'NEW_EVENT' || dialog === 'EDIT_EVENT' ? (
          <ScreenWithClose onClose={this.hideDialog}>
            {React.cloneElement(renderForm(this.getEvent()), {
              onSave: this.onSave,
              onClose: this.hideDialog
            })}
          </ScreenWithClose>
        ) : null}
      </div>
    )
  }
}
