import React from 'react'
import Footer from '../Footer/Footer'

export default function Services() {
  return (
    <div className="LandingPageBase Services">
      <div className="section">
        <div className="container">Services</div>
      </div>
      <Footer />
    </div>
  )
}
