import { combineReducers } from 'redux'
import addresses from './addresses'
import app from './app'
import appointments from './appointments'
import billing from './billing'
import cars from './cars'
import corporateAccounts from './corporateAccounts'
import customers from './customers'
import emailusers from './emailusers'
import employees from './employees'
import events from './events'
import plans from './plans'
import products from './products'
import reservations from './reservations'
import shoppingCart from './shoppingCart'
import subscriptions from './subscriptions'
import packageDeals from './packageDeal'
import userActivities from './userActivities'

export default combineReducers({
  addresses,
  app,
  appointments,
  billing,
  cars,
  corporateAccounts,
  customers,
  employees,
  emailusers,
  events,
  plans,
  products,
  reservations,
  shoppingCart,
  subscriptions,
  packageDeals,
  userActivities
})
