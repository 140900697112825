import axios from 'axios'
import _debug from 'debug'
import { store } from '../store'
import UserActivity from '../../models/UserActivity'

const debug = _debug('carwash:store:actions:userActivities')

export async function loadUserActivities(urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/user_activity`)
    const userActivities = res.data.results.map(data => new UserActivity(data))
    store.dispatch({ type: 'SET_USER_ACTIVITIES', userActivities })
    return userActivities
  } catch (err) {
    debug('[loadUserActivities] error: ', err)
    throw err
  }
}

export async function loadUserActivity(id: number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/user_activity/${id}`)
    const userActivity = new UserActivity(res.data)
    store.dispatch({ type: 'SET_USER_ACTIVITY', userActivity })
    return userActivity
  } catch (err) {
    debug('[loadUserActivity] error: ', err)
    throw err
  }
}

export async function saveUserActivity(data: Object, urlPrefix: string = '') {
  return data.id ? updateUserActivity(data, urlPrefix) : createUserActivity(data, urlPrefix)
}

export async function createUserActivity(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/user_activity`, data)
    const userActivity = new UserActivity(res.data)
    store.dispatch({ type: 'ADD_USER_ACTIVITY', userActivity })
    return userActivity
  } catch (err) {
    debug('[createUserActivity] error: ', err)
    throw err
  }
}

export async function updateUserActivity(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.put(`${urlPrefix}/user_activity/${data.id}`, data)
    const userActivity = new UserActivity(res.data)
    store.dispatch({ type: 'UPDATE_USER_ACTIVITY', userActivity })
    return userActivity
  } catch (err) {
    debug('[updateUserActivity] error: ', err)
    throw err
  }
}

export async function deleteUserActivity(data: Object, urlPrefix: string = '') {
  try {
    await axios.delete(`${urlPrefix}/user_activity/${data.id}`)
    store.dispatch({ type: 'DELETE_USER_ACTIVITY', userActivity: data })
  } catch (err) {
    debug('[deleteUserActivity] error: ', err)
    throw err
  }
}
