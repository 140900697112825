const initialState = {
  invoices: [],
  subscriptions: []
}

export default function billing(state = initialState, action) {
  switch (action.type) {
    case 'SET_BILLING_INFO':
      return { ...state, ...action.billing }
    case 'UPDATE_BILLING_INFO':
      return { ...state, ...action.billing }
    default:
      return state
  }
}
