import _ from 'lodash'

const initialState = []

export default function products(state = initialState, action) {
  let products, index
  switch (action.type) {
    case 'SET_PRODUCTS':
      products = [...state, ...action.products]
      return _.uniqBy(products, m => m.id)
    case 'SET_PRODUCT':
      products = [...state]
      index = _.findIndex(products, m => m.id === action.product.id)
      if (index === -1) {
        products.push(action.product)
      } else {
        products.splice(index, 1, action.product)
      }

      return products
    case 'ADD_PRODUCT':
      return [action.product].concat(state)
    case 'UPDATE_PRODUCT':
      products = [...state]
      index = _.findIndex(products, m => m.id === action.product.id)
      products.splice(index, 1, action.product)
      return products
    case 'DELETE_PRODUCT':
      products = state.filter(m => m.id !== action.product.id)
      return products
    default:
      return state
  }
}
