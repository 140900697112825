/* eslint-disable jsx-a11y/alt-text */
import './style.scss'

import React, { Component } from 'react'
import classNames from 'classnames'
import Footer from '../../Footer/Footer'
import data from './faq-data.json'
import { icons } from '../../../images'

type Props = {}

type State = {}

export default class Faq extends Component<Props, State> {
  render() {
    return (
      <div className="LandingPageBase Faq">
        <div className="heading">
          <div className="container">
            <h1>Frequently asked questions</h1>
          </div>
        </div>
        <div className="section" style={{ background: '#fff' }}>
          <div className="container faq-container">
            {/* <ReactMarkdown source={source} escapeHtml={false} /> */}
            {data.map((section, i) => (
              <FaqSection key={i} title={section.title} icon={section.icon} body={section.body} />
            ))}
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

type FaqSectionProps = {
  title: String,
  icon: String,
  body: String
}

type FaqSectionState = {
  expanded: Boolean
}

class FaqSection extends Component<FaqSectionProps, FaqSectionState> {
  state = {
    expanded: false
  }

  render() {
    const { title, icon, body } = this.props
    const { expanded } = this.state

    return (
      <div className={classNames('FaqSection', { expanded })}>
        <div className="header" onClick={() => this.setState({ expanded: !expanded })}>
          <h2>{title}</h2>
          <img className="icon" src={icons[icon]} />
          <img className="toggle" src={icons.arrowDown} />
        </div>
        {expanded ? (
          <div
            className={classNames('body', { expanded })}
            dangerouslySetInnerHTML={{ __html: body }}
          />
        ) : null}
      </div>
    )
  }
}
