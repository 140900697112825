import './style.scss'

import React, { Component, useState } from 'react'
import classNames from 'classnames'
import {
  injectStripe,
  Elements,
  StripeProvider,
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement
} from 'react-stripe-elements'

import { addStripeScript, removeStripeScript } from '../../util/stripe-util'
import { getStripeKey } from '../../store/util'
import Field from '../shared/Field/Field'
import { handleError } from '../../util/error-util'

type Props = {
  onSuccess: Function,
  customerName?: String,
  address?: Object,
  children: any
}

type State = {
  address: String,
  city: String,
  state: String,
  zip: String,

  card: Object,
  exp: Object,
  cvv: Object,

  useSameAddress: Boolean,

  loading: Boolean,
  saving: Boolean
}

export default class PaymentForm extends Component<Props, State> {
  tag: HTMLScriptElement

  state = {
    address: '',
    city: '',
    state: '',
    zip: '',

    card: {},
    exp: {},
    cvv: {},

    useSameAddress: false,

    loading: true,
    saving: false
  }

  componentDidMount() {
    addStripeScript()
    const tag = (this.tag = document.querySelector('#stripe-script'))
    tag.addEventListener('load', this.onLoad)
  }

  componentWillUnmount() {
    removeStripeScript()
  }

  onLoad = () => {
    this.tag.removeEventListener('load', this.onLoad)
    this.setState({ loading: false })
  }

  onChange = (prop: val, value: any) => {
    this.setState({ [prop]: value })
  }

  onSubmit = (e: Event) => {
    e.preventDefault()
    this.setState({ saving: true }, async () => {
      const { customerName, onSuccess } = this.props
      const { address, city, state, zip } = this.state

      try {
        const data = {
          name: customerName,
          address_line1: address,
          address_city: city,
          address_state: state,
          address_zip: zip
        }

        const result = await this.stripe.createToken(data)

        if (!result) {
          throw new Error(
            'An error occurred while processing your payment. Please try again later.'
          )
        }

        const { token, error } = result

        if (error) {
          throw new Error(error.message)
        }

        this.setState({ saving: false })
        onSuccess(token)
      } catch (err) {
        this.setState({ saving: false })
        handleError(err, true)
      }
    })
  }

  isValid = () => {
    const { address, city, state, zip, card, exp, cvv } = this.state
    return address && city && state && zip && card.complete && exp.complete && cvv.complete
  }

  onUseSameAddressChange = (e: Event) => {
    const { address } = this.props
    const useSameAddress = e.target.checked
    const nextState = { useSameAddress }

    if (useSameAddress) {
      nextState.address = address.street
      nextState.city = address.city
      nextState.state = address.state
      nextState.zip = address.zip
    } else {
      nextState.address = ''
      nextState.city = ''
      nextState.state = ''
      nextState.zip = ''
    }

    this.setState(nextState)
  }

  render() {
    const { children, address: otherAddress } = this.props
    const {
      useSameAddress,
      address,
      city,
      state,
      zip,
      card,
      exp,
      cvv,
      loading,
      saving
    } = this.state

    if (loading) {
      return null
    }

    return (
      <form
        id="payment-form"
        name="payment-form"
        className="PaymentForm Form"
        onSubmit={this.onSubmit}>
        <StripeProvider apiKey={getStripeKey()}>
          <Elements
            fonts={[
              {
                cssSrc: 'https://fonts.googleapis.com/css?family=DM+Sans'
              }
            ]}>
            <WrappedPaymentForm
              cardError={!!card.error}
              expError={!!exp.error}
              cvvError={!!cvv.error}
              onChange={this.onChange}
              onStripe={stripe => (this.stripe = stripe)}>
              {children}
            </WrappedPaymentForm>
          </Elements>
        </StripeProvider>

        {otherAddress ? (
          <div className="same-address">
            <Field
              type="checkbox"
              label="Same as vehicle wash location address"
              checked={useSameAddress}
              onChange={this.onUseSameAddressChange}
              checkboxFirst
            />
          </div>
        ) : null}

        <div className="row">
          <Field
            label="Address"
            value={address}
            usePlaceholders
            useStaticPlaceholders
            type="text"
            name="address"
            autoComplete="address-line1"
            placeholder="185 Berry St"
            onChange={e => this.onChange('address', e.target.value)}
          />
        </div>
        <div className="row">
          <div className="field half-width">
            <Field
              label="City"
              value={city}
              usePlaceholders
              useStaticPlaceholders
              type="text"
              name="city"
              autoComplete="address-level2"
              placeholder="Austin"
              onChange={e => this.onChange('city', e.target.value)}
            />
          </div>
          <div className="field quarter-width">
            <Field
              label="State"
              value={state}
              usePlaceholders
              useStaticPlaceholders
              type="text"
              name="state"
              autoComplete="address-level1"
              placeholder="TX"
              onChange={e => this.onChange('state', e.target.value)}
            />
          </div>
          <div className="field quarter-width">
            <Field
              label="Zip"
              className="postal-code"
              value={zip}
              usePlaceholders
              useStaticPlaceholders
              type="text"
              name="zip"
              autoComplete="postal-code"
              placeholder="73301"
              onChange={e => this.onChange('zip', e.target.value)}
            />
          </div>
        </div>

        {children({ valid: this.isValid(), saving })}
      </form>
    )
  }
}

function InnerPaymentForm(props: Object) {
  const { stripe, cardError, expError, cvvError, onChange, onStripe } = props

  onStripe(stripe)

  const [state, setState] = useState({
    cardFocus: false,
    expFocus: false,
    cvvFocus: false
  })

  const { cardFocus, expFocus, cvvFocus } = state

  const style = {
    base: {
      lineHeight: '30px',
      color: '#555',
      fontWeight: 500,
      fontFamily:
        "'DM Sans', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      fontSize: window.innerWidth > 674 ? '16px' : '18px',
      fontSmoothing: 'antialiased',

      '::placeholder': {
        color: '#bbb'
      }
    },
    invalid: {
      color: '#E25950',

      '::placeholder': {
        color: '#FFCCA5'
      }
    }
  }

  return (
    <div className="InnerPaymentForm">
      <div className="row">
        <div className={classNames('field', 'element', { focus: cardFocus, error: cardError })}>
          <div className="input-wrapper">
            <CardNumberElement
              style={style}
              onChange={o => onChange('card', o)}
              onFocus={() => setState({ ...state, cardFocus: true })}
              onBlur={() => setState({ ...state, cardFocus: false })}
            />
          </div>
          <label>Card number</label>
          <div className="baseline" />
        </div>
      </div>
      <div className="row">
        <div
          className={classNames('field', 'element', 'half-width', {
            focus: expFocus,
            error: expError
          })}>
          <div className="input-wrapper">
            <CardExpiryElement
              style={style}
              onFocus={() => setState({ ...state, expFocus: true })}
              onBlur={() => setState({ ...state, expFocus: false })}
              onChange={o => onChange('exp', o)}
            />
          </div>
          <label>Expiration</label>
          <div className="baseline" />
        </div>
        <div
          className={classNames('field', 'element', 'half-width', {
            focus: cvvFocus,
            error: cvvError
          })}>
          <div className="input-wrapper">
            <CardCVCElement
              style={style}
              onFocus={() => setState({ ...state, cvvFocus: true })}
              onBlur={() => setState({ ...state, cvvFocus: false })}
              onChange={o => onChange('cvv', o)}
            />
          </div>
          <label>CVC</label>
          <div className="baseline" />
        </div>
      </div>
    </div>
  )
}

const WrappedPaymentForm = injectStripe(InnerPaymentForm)
