import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import Form from '../../shared/Form/Form'
import Field from '../../shared/Field/Field'
import { handleError } from '../../../util/error-util'
import { toast } from '../../../store/actions/app'
import Event from '../../../models/Event'
import { toDateString, toTimeString } from '../../../util/date-util'
import { saveReservation } from '../../../store/actions/reservations'

const defaultReservation = {
  title: '',
  description: '',
  date: '',
  startTime: '',
  endTime: '',
  allDay: false,
  location_id: ''
}

const dateFields = ['location_id', 'date', 'startTime', 'endTime', 'allDay']

type Props = {
  reservation?: Object,
  locationId: number,
  urlPrefix: string,
  onSave: Function
}

type State = {
  reservation: ?Object,
  saving: boolean,
  isAvailable: boolean,
  priceInfo: Object
}

export default class ReservationForm extends Component<Props, State> {
  state = {
    reservation: null,
    saving: false,
    isAvailable: true,
    priceInfo: null
  }

  static defaultProps = {
    onSave: _.noop
  }

  componentDidMount() {
    this.setReservation()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.reservation !== prevProps.reservation) {
      this.setReservation()
    }
  }

  setReservation = () => {
    const reservation = _.cloneDeep(
      this.props.reservation || defaultReservation
    )

    if (reservation.start) {
      reservation.date = toDateString(reservation.start)
      reservation.startTime = toTimeString(reservation.start)
    }

    if (reservation.end) {
      reservation.endTime = toTimeString(reservation.end)
    }

    this.setState({ reservation }, () => {
      const event = Event.build(reservation)
      this.checkAvailability(event)
      this.checkPrice(event)
    })
  }

  checkAvailability = _.debounce(async (event: Event) => {
    // try {
    //   const isAvailable = await checkAvailability(event)
    //   this.setState({ isAvailable })
    // } catch (err) {
    //   console.error(err)
    // }
  }, 500)

  checkPrice = _.debounce(async (event: Event) => {
    // try {
    //   const priceInfo = await checkPrice(event)
    //   this.setState({ priceInfo })
    // } catch (err) {
    //   console.error(err)
    // }
  }, 500)

  onChange = (prop: string, value: any) => {
    const reservation = { ...this.state.reservation }
    reservation[prop] = value
    this.setState({ reservation }, () => {
      if (dateFields.includes(prop)) {
        const e = Event.build(reservation)
        this.checkAvailability(e)
        this.checkPrice(e)
      }
    })
  }

  onSubmit = async () => {
    try {
      const { urlPrefix, onSave } = this.props
      const reservation = Event.build(this.state.reservation)
      this.setState({ saving: true })

      toast('Saving...')
      const result = await saveReservation(reservation, urlPrefix)
      toast('Saved')

      this.setState({ saving: false })
      onSave(result)
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  isValid = () => {
    const { reservation, isAvailable } = this.state
    if (!isAvailable) {
      return false
    }

    const e = Event.build(reservation)
    if (!e.allDay) {
      if (e.start >= e.end) {
        return false
      }
    }

    return (
      e.title.trim() &&
      e.start &&
      new Date(e.start).getTime() > Date.now() &&
      (e.end || e.allDay)
    )
  }

  render() {
    const { reservation } = this.state

    if (!reservation) {
      return null
    }

    return (
      <div className="ReservationForm">
        <Form onSubmit={this.onSubmit}>
          <Field
            type="date"
            label="Date:"
            value={reservation.date}
            onChange={e => this.onChange('date', e.target.value)}
            disabled
          />
          <Field
            type="time"
            label="Time:"
            value={reservation.startTime}
            onChange={e => this.onChange('startTime', e.target.value)}
            disabled
          />
          <Field
            type="textarea"
            label="Extra info:"
            value={reservation.description}
            onChange={e => this.onChange('description', e.target.value)}
            disabled
          />
          {/* <ActionBar>
            <SaveButton
              onClick={this.onSubmit}
              disabled={saving || !this.isValid()}
            />
          </ActionBar> */}
        </Form>
      </div>
    )
  }
}

export function ReservationFormWrapper(props: any) {
  return (
    <div className="ReservationFormWrapper">
      <div className="header">Appointment Details</div>
      <ReservationForm {...props} />
    </div>
  )
}
