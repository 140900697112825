import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Form from '../../shared/Form/Form'
import Field from '../../shared/Field/Field'
import SaveButton from '../../shared/SaveButton/SaveButton'
import { saveEvent, deleteEvent } from '../../../store/actions/events'
import { handleError } from '../../../util/error-util'
import { toast } from '../../../store/actions/app'
import { getFullName } from '../../../util/string-util'
import { toTimeString, toDateString } from '../../../util/date-util'
import Event from '../../../models/Event'
import ActionBar from '../../shared/ActionBar/ActionBar'
import DeleteButton from '../../shared/DeleteButton/DeleteButton'
import confirm from '../../shared/confirm/confirm'

type Props = {
  event?: Object,
  employees: Object[],
  customers: Object[],
  urlPrefix: string,
  onSave: Function
}

type State = {
  event: ?Object,
  saving: boolean
}

export class GenericEventForm extends Component<Props, State> {
  state = {
    event: null,
    saving: false
  }

  static defaultProps = {
    onSave: _.noop,
    customers: [],
    employees: []
  }

  componentDidMount() {
    this.setEvent()
  }

  componentDidUpdate(prevProps) {
    if (this.props.event !== prevProps.event) {
      this.setEvent()
    }
  }

  /**
   * Clones props.event for form editing.
   */
  setEvent = () => {
    let event = new Event(this.props.event)

    if (event.start) {
      event.date = toDateString(event.start)
      event.startTime = toTimeString(event.start)
    }
    if (event.end) {
      event.endTime = toTimeString(event.end)
    }

    this.setState({ event })
  }

  onChange = (prop: string, value: any) => {
    const event = { ...this.state.event }
    event[prop] = value
    this.setState({ event })
  }

  onSubmit = async () => {
    try {
      const { urlPrefix, onSave } = this.props
      const event = Event.build(this.state.event)

      this.setState({ saving: true })

      toast('Saving...')
      const result = await saveEvent(event, urlPrefix)
      toast('Saved')

      this.setState({ saving: false })
      onSave(result)
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  onDeleteClick = async () => {
    try {
      if (!(await confirm())) {
        return
      }

      const { urlPrefix } = this.props
      const { event } = this.state

      this.setState({ saving: true })

      toast('Deleting...')
      await deleteEvent(event, urlPrefix)
      toast('Deleted')

      setTimeout(() => {
        window.location.reload()
      }, 250)
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  isValid = () => {
    const { event } = this.state
    if (!event) {
      return false
    }

    const e = Event.build(event)
    if (!e.allDay) {
      if (e.start >= e.end) {
        return false
      }
    }

    return e.start && (e.end || e.allDay)
  }

  render() {
    const { employees, customers } = this.props
    const { event, saving } = this.state

    if (!event) {
      return null
    }

    return (
      <div className="EventForm GenericEventForm">
        <div className="wrapper">
          <Form onSubmit={this.onSubmit}>
            <Field
              type="select"
              label="Type:"
              value={event.type}
              onChange={e => this.onChange('type', e.target.value)}
              options={[
                <option key="-1" value=""></option>,
                <option key="WASH" value="WASH">
                  Wash
                </option>,
                <option key="CORPORATE_WASH" value="CORPORATE_WASH">
                  Corporate Wash
                </option>,
                <option key="TIMEOFF" value="TIMEOFF">
                  Employee Time Off
                </option>,
                <option key="HOLIDAY" value="HOLIDAY">
                  Holiday
                </option>,
                <option key="AVAILABLE_TIME" value="AVAILABLE_TIME">
                  Available Time
                </option>,
                <option key="OTHER" value="OTHER">
                  Other
                </option>
              ]}
            />
            <Field
              type="select"
              label="Technician:"
              value={event.employee_id}
              onChange={e => this.onChange('employee_id', Number(e.target.value))}
              options={[
                <option key="-1" value=""></option>,
                ..._.sortBy(employees, 'last_name').map(m => (
                  <option key={m.id} value={m.id}>
                    {getFullName(m)}
                  </option>
                ))
              ]}
            />
            <Field
              type="select"
              label="Customer:"
              value={event.customer_id}
              onChange={e => this.onChange('customer_id', Number(e.target.value))}
              options={[
                <option key="-1" value="" />,
                ..._.sortBy(customers, 'last_name').map(m => (
                  <option key={m.id} value={m.id}>
                    {getFullName(m)} ({m.email})
                  </option>
                ))
              ]}
            />
            <Field
              type="text"
              label="Location:"
              value={event.address}
              onChange={e => this.onChange('address', e.target.value)}
            />
            <Field
              type="date"
              label="Date:"
              value={event.date}
              onChange={e => this.onChange('date', e.target.value)}
            />
            <Field
              type="time"
              label="Start Time:"
              value={event.startTime}
              onChange={e => this.onChange('startTime', e.target.value)}
            />
            <Field
              type="time"
              label="End Time:"
              value={event.endTime}
              onChange={e => this.onChange('endTime', e.target.value)}
            />
            <Field
              type="textarea"
              label="Extra info:"
              value={event.description}
              onChange={e => this.onChange('description', e.target.value)}
            />
          </Form>
        </div>
        <ActionBar>
          <SaveButton onClick={this.onSubmit} disabled={saving || !this.isValid()} />
          {event.id ? <DeleteButton onClick={this.onDeleteClick} disabled={saving} /> : null}
        </ActionBar>
      </div>
    )
  }
}

const mapState = state => ({
  events: state.events,
  customers: state.customers,
  employees: state.employees,
  products: state.products,
  corporateAccounts: state.corporateAccounts
})

export default connect(mapState)(GenericEventForm)
