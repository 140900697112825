export default class Plan {
  active: Boolean
  aggregate_usage: Boolean
  amount: Number
  billing_scheme: String
  created: Number
  currency: String
  id: String
  interval: String
  interval_count: Number
  livemode: Boolean
  metadata: Object
  nickname: String
  object: String
  product: String
  tiers: any
  tiers_mode: any
  transform_usage: any
  trial_period_days: ?Number
  usage_type: String
  description: String

  constructor({
    active = true,
    aggregate_usage = null,
    amount = 0,
    billing_scheme = 'per_unit',
    created = null,
    currency = 'usd',
    id = null,
    interval = 'month',
    interval_count = 1,
    livemode = null,
    metadata = { type: 'PLAN' },
    nickname = '',
    object = 'plan',
    product = null,
    tiers = null,
    tiers_mode = null,
    transform_usage = null,
    trial_period_days = null,
    usage_type = 'licensed',
    description = ''
  } = {}) {
    this.active = active
    this.aggregate_usage = aggregate_usage
    this.amount = amount / 100
    this.billing_scheme = billing_scheme
    this.created = created
    this.currency = currency
    this.id = id
    this.interval = interval
    this.interval_count = interval_count
    this.livemode = livemode
    this.metadata = metadata
    this.nickname = nickname
    this.object = object
    this.product = product
    this.tiers = tiers
    this.tiers_mode = tiers_mode
    this.transform_usage = transform_usage
    this.trial_period_days = trial_period_days
    this.usage_type = usage_type
    this.description = description
  }
}
