import axios from 'axios'
import _debug from 'debug'
import { store } from '../store'

const debug = _debug('carwash:store:actions:products')

export async function loadProducts(urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/products`)
    const products = res.data.results
    store.dispatch({ type: 'SET_PRODUCTS', products })
    return products
  } catch (err) {
    debug('[loadProducts] error: ', err)
    throw err
  }
}

export async function loadProduct(id: number, urlPrefix: string = '') {
  try {
    const res = await axios.get(`${urlPrefix}/products/${id}`)
    store.dispatch({ type: 'SET_PRODUCT', product: res.data })
  } catch (err) {
    debug('[loadProduct] error: ', err)
    throw err
  }
}

export async function saveProduct(data: Object, urlPrefix: string = '') {
  return data.id ? updateProduct(data, urlPrefix) : createProduct(data, urlPrefix)
}

export async function createProduct(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.post(`${urlPrefix}/products`, data)
    const product = res.data
    store.dispatch({ type: 'ADD_PRODUCT', product })
    return product
  } catch (err) {
    debug('[createProduct] error: ', err)
    throw err
  }
}

export async function updateProduct(data: Object, urlPrefix: string = '') {
  try {
    const res = await axios.put(`${urlPrefix}/products/${data.id}`, data)
    const product = res.data
    store.dispatch({ type: 'UPDATE_PRODUCT', product })
    return product
  } catch (err) {
    debug('[updateProduct] error: ', err)
    throw err
  }
}

export async function deleteProduct(data: Object, urlPrefix: string = '') {
  try {
    await axios.delete(`${urlPrefix}/products/${data.id}`)
    store.dispatch({ type: 'DELETE_PRODUCT', product: data })
  } catch (err) {
    debug('[deleteProduct] error: ', err)
    throw err
  }
}
