import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { loadAppointmentsForCustomer } from '../../../store/actions/appointments'
import Event from '../../../models/Event'
import User from '../../../models/User'

type Props = {
  customer: User,
  appointments: Event[],
  urlPrefix: String
}

export class AppointmentList extends Component<Props> {
  componentDidMount() {
    const { customer, urlPrefix } = this.props
    loadAppointmentsForCustomer(customer.id, urlPrefix)
  }

  render() {
    const { appointments } = this.props

    return (
      <Fragment>
        <div className="header">Appointments</div>
        <div className="list list-primary">
          {appointments.map((appt, i) => (
            <div key={i} className="appointment">
              <span>{moment(appt.start).format('M/D/Y h:mmA')}</span>
              <span>{appt.address.toString()}</span>
              <span>{appt.car.toString()}</span>
            </div>
          ))}
        </div>
      </Fragment>
    )
  }
}

const mapState = state => ({
  appointments: state.appointments
})

export default connect(mapState)(AppointmentList)
