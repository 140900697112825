import './style.scss'

import React, { Component, Fragment } from 'react'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadAppointments } from '../../../store/actions/appointments'
import AuthRoute from '../../shared/AuthRoute/AuthRoute'
import AppointmentList from '../List/AppointmentList'
import AppointmentDetail from '../Detail/AppointmentDetail'
import AppointmentForm from '../Form/AppointmentForm'
import TitleBar from '../../shared/TitleBar/TitleBar'
import Appointment from '../../../models/Appointment'
import { getUrlPrefix } from '../../../util/string-util'

type Props = {
  appointments: Appointment[],
  urlPrefix: string,
  backTo?: String,
  match: Object
}

export class Appointments extends Component<Props> {
  componentDidMount() {
    const { appointments, urlPrefix } = this.props
    if (!appointments.length) {
      loadAppointments(urlPrefix)
    }
  }

  onSave = (appointment: Object) => {
    const prefix = getUrlPrefix('appointments')
    this.props.history.push(`/${prefix}/${appointment.id}`)
  }

  render() {
    const { appointments, urlPrefix, backTo, match } = this.props
    const { path } = match
    return (
      <div className="Appointments">
        <Switch>
          <AuthRoute
            exact
            path={path}
            render={props => (
              <AppointmentList {...props} appointments={appointments} backTo={backTo} />
            )}
          />
          <AuthRoute
            path={`${path}/new`}
            render={props => (
              <Fragment>
                <TitleBar backTo={path} label="New Appointment" />
                <AppointmentForm {...props} onSave={this.onSave} urlPrefix={urlPrefix} />
              </Fragment>
            )}
          />
          <AuthRoute
            path={`${path}/:appointmentId`}
            render={props => <AppointmentDetail {...props} urlPrefix={urlPrefix} />}
          />
        </Switch>
      </div>
    )
  }
}

const mapState = state => ({
  appointments: state.appointments
})

export default connect(mapState)(Appointments)
