export function scrollToTop() {
  window.scrollY = 0
  document.documentElement.scrollTop = 0
  document.body.scrollTop = 0
}

export function getScrollY() {
  return window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
}

export function isMobile() {
  // TODO is 940 a good choice?
  return window.innerWidth <= 940
}
