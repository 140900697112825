import './style.scss'

import React, { Component } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { MaybeActionMenu } from '../shared/ActionMenu/ActionMenu'
import { isAdmin, isCustomer } from '../../store/util'
import { scrollToTop } from '../../util/dom-util'
import logo from '../../images/logo.png'
import User from '../../models/User'

type Props = {
  user: User,
  org: Object,
  navigationEnabled: Boolean
}

export class Nav extends Component<Props> {
  onLogoClick = () => {
    scrollToTop()
  }

  renderLogo = () => {
    return <img className="logo-img" src={logo} alt="logo" />
  }

  render() {
    const { user, navigationEnabled } = this.props

    return (
      <div className="Nav">
        <div className="left">
          {navigationEnabled ? (
            <Link to="/">{this.renderLogo()}</Link>
          ) : (
            <span className="pseudo-link">{this.renderLogo()}</span>
          )}
          {isAdmin(user) ? <span>Admin</span> : null}
        </div>
        <div className="right">
          {user ? (
            <span className="greeting">Hi {user.first_name || user.email}</span>
          ) : (
            <NavLink to="/login">Login</NavLink>
          )}

          {isAdmin(user) ? null : (
            <MaybeActionMenu className="NavActionMenu" anchor="right">
              {isCustomer(user) ? <Link to="/myaccount">Account</Link> : null}

              <NavLink to="/our-process">Our Process</NavLink>
              <NavLink to="/faq">FAQ</NavLink>
              <NavLink to="/contact">Contact</NavLink>

              {navigationEnabled ? (
                <NavLink id="book-now-1" className="button primary" to={user ? '/book' : '/signup'}>
                  Book Now
                </NavLink>
              ) : null}
            </MaybeActionMenu>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(Nav)
