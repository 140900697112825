import {
  FETCH_PACKAGE_DEALS,
  FETCH_PACKAGE_DEALS_SUCCESS,
  REMOVE_PACKAGE_DEAL_FROM_CART
} from '../actionTypes'

const initialState = {
  packageDeals: []
}

export default function packegDealReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PACKAGE_DEALS:
      return {
        packageDeals: []
      }
    case FETCH_PACKAGE_DEALS_SUCCESS:
      return {
        packageDeals: action.payload.packageDeals || state.packageDeals
      }
    case REMOVE_PACKAGE_DEAL_FROM_CART:
      return {
        packageDeals: []
      }
    default:
      return state
  }
}
