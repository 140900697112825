import './style.scss'

import React from 'react'
import BackButton from '../BackButton/BackButton'

type Props = {
  backTo: string,
  label: string,
  children: any
}

export default function TitleBar(props: Props) {
  const { backTo, label, children } = props

  return (
    <div className="TitleBar">
      {backTo ? <BackButton to={backTo} noLabel /> : null}
      <h2>{label}</h2>
      {children}
    </div>
  )
}
