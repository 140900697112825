import './style.scss'

import React, { Component, Fragment } from 'react'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadCorporateAccounts } from '../../../store/actions/corporateAccounts'
import AuthRoute from '../../shared/AuthRoute/AuthRoute'
import CorporateAccountList from '../List/CorporateAccountList'
import CorporateAccountDetail from '../Detail/CorporateAccountDetail'
import CorporateAccountForm from '../Form/CorporateAccountForm'
import TitleBar from '../../shared/TitleBar/TitleBar'
import CorporateAccount from '../../../models/CorporateAccount'

type Props = {
  corporateAccounts: CorporateAccount[],
  urlPrefix: string,
  match: Object
}

export class CorporateAccounts extends Component<Props> {
  componentDidMount() {
    const { corporateAccounts, urlPrefix } = this.props
    if (!corporateAccounts.length) {
      loadCorporateAccounts(urlPrefix)
    }
  }

  onSave = (corporateAccount: Object) => {
    this.props.history.push(`/corporate-accounts/${corporateAccount.id}`)
  }

  render() {
    const { corporateAccounts, urlPrefix, match } = this.props
    const { path } = match
    return (
      <div className="CorporateAccounts">
        <Switch>
          <AuthRoute
            exact
            path={path}
            render={props => (
              <CorporateAccountList {...props} corporateAccounts={corporateAccounts} />
            )}
          />
          <AuthRoute
            path={`${path}/new`}
            render={props => (
              <Fragment>
                <TitleBar backTo={path} label="New Account" />
                <CorporateAccountForm {...props} onSave={this.onSave} urlPrefix={urlPrefix} />
              </Fragment>
            )}
          />
          <AuthRoute
            path={`${path}/:corporateAccountId`}
            render={props => <CorporateAccountDetail {...props} urlPrefix={urlPrefix} />}
          />
        </Switch>
      </div>
    )
  }
}

const mapState = state => ({
  corporateAccounts: state.corporateAccounts
})

export default connect(mapState)(CorporateAccounts)
