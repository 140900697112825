import './style.scss'

import React, { Component } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { formatMoney } from 'accounting'
import { isAdmin } from '../../../store/util'
import AddButton from '../../shared/AddButton/AddButton'
import TitleBar from '../../shared/TitleBar/TitleBar'
import Plan from '../../../models/Plan'
import Field from '../../shared/Field/Field'

type Props = {
  plans: Plan[],
  location: Object,
  history: Object,
  match: Object
}

type State = {
  filter: any
}

export default class PlanList extends Component<Props, State> {
  state = {
    filter: ''
  }

  onAddClick = () => {
    const { location, history } = this.props
    history.push(`${location.pathname}/new`)
  }

  render() {
    const { plans, match } = this.props
    const { filter } = this.state
    const { url } = match

    let list = filter ? plans.filter(p => p.metadata.type === filter) : plans
    list = _.sortBy(list, p => Number(p.metadata.sort_order))

    return (
      <div className="PlanList">
        <TitleBar label="Plans">
          {isAdmin() ? <AddButton onClick={this.onAddClick} /> : null}
        </TitleBar>
        <div className="filter-bar">
          <Field
            type="select"
            label="Filter by type:"
            value={filter}
            onChange={e => this.setState({ filter: e.target.value })}
            options={[
              <option key="" value="">
                Show All
              </option>,
              <option key="PLAN" value="PLAN">
                Plan
              </option>,
              <option key="CHILD_PLAN" value="CHILD_PLAN">
                Sub-plan
              </option>,
              <option key="ADD_ON" value="ADD_ON">
                Add-on
              </option>
            ]}
          />
        </div>
        <div className="list list-primary">
          {list.map(plan => (
            <Link key={plan.id} className="plain" to={`${url}/${plan.id}`}>
              <span>{plan.nickname}</span>
              <span>{plan.metadata.type}</span>
              <span>
                {formatMoney(plan.amount / 100)} / {plan.interval}
              </span>
            </Link>
          ))}
        </div>
      </div>
    )
  }
}
