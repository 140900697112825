import './style.scss'

import React, { Component, Fragment } from 'react'
import { Route, Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import Event from '../../models/Event'
import LandingPage from '../LandingPage/LandingPage'
import Account from '../Account/Account'
import { DATETIME_FORMAT_SHORT_YEAR } from '../../contants'
import { ResizableText } from '../shared'
import BookingPage from '../BookingPage/BookingPage'
import OurProcess from '../LandingPage/OurProcess'
import Services from '../LandingPage/Services'
import Faq from '../LandingPage/Faq/Faq'
import Contact from '../LandingPage/Contact/Contact'
import PaymentPage from '../PaymentPage/PaymentPage'
import CovidPage from '../covid/CovidPage/CovidPage'
import CorporateBookingPage from '../CorporateBookingPage/CorporateBookingPage'

type Props = {
  location: Location
}

type State = {
  loading: Boolean,
  customer: Object,
  nextAppointment: Event,
  nextInvoice: Object,
  lastInvoice: Object
}

export class CustomerHome extends Component<Props, State> {
  state = {
    loading: true,
    customer: null,
    nextAppointment: null,
    nextInvoice: null,
    lastInvoice: null
  }

  componentDidMount() {
    this.init()
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location.pathname === '/' && prevProps.location.pathname !== '/') {
      this.init()
    }
  }

  init = async () => {
    try {
      const res = await axios.get('/home')
      this.setState({ ...res.data, loading: false })
    } catch (err) {
      this.setState({ loading: false })
    }
  }

  renderSummary = () => {
    const { nextAppointment, loading } = this.state
    if (loading) {
      return <div className="summary">Loading...</div>
    }

    const s = nextAppointment
      ? `Your next wash: ${moment(nextAppointment.start).format(DATETIME_FORMAT_SHORT_YEAR)}`
      : 'No upcoming appointments.'

    return (
      <div key="resizetext" className="summary">
        <ResizableText>{s}</ResizableText>
        {nextAppointment ? (
          <Link to={`/myaccount/appointments/${nextAppointment.id}`}>Change</Link>
        ) : (
          <Link id="book-now-6" to="/book">
            Book now
          </Link>
        )}
      </div>
    )
  }

  render() {
    return (
      <div className="CustomerHome">
        <Route
          exact
          path="/"
          render={props => (
            <Fragment>
              {this.renderSummary()}
              <LandingPage {...props} ctaUrl="/book" />
            </Fragment>
          )}
        />
        <Route
          path="/myaccount"
          render={props => (
            <div className="customer-content">
              <Account {...props} />
            </div>
          )}
        />
        <Route
          path="/book"
          render={props => (
            <div className="customer-content">
              <BookingPage {...props} />
            </div>
          )}
        />
        <Route path="/our-process" component={OurProcess} />
        <Route path="/services" component={Services} />
        <Route path="/faq" component={Faq} />
        <Route path="/contact" component={Contact} />
        <Route path="/covid-19" component={CovidPage} />
        <Route path="/wash/:washSlug" component={PaymentPage} />
        <Route path="/account/:slug" component={CorporateBookingPage} />
      </div>
    )
  }
}

export default withRouter(CustomerHome)
