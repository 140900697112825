import './style.scss'

import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { formatMoney } from 'accounting'
import Form from '../../shared/Form/Form'
import Field from '../../shared/Field/Field'
import SaveButton from '../../shared/SaveButton/SaveButton'
import { saveProduct, deleteProduct } from '../../../store/actions/products'
import { handleError } from '../../../util/error-util'
import { toast } from '../../../store/actions/app'
import confirm from '../../shared/confirm/confirm'
import history from '../../../history'
import ActionBar from '../../shared/ActionBar/ActionBar'
import DeleteButton from '../../shared/DeleteButton/DeleteButton'
import Plan from '../../../models/Plan'
import Product from '../../../models/Product'

type Props = {
  product?: Product,
  products: Product[],
  plans: Plan[],
  urlPrefix: string,
  onSave: Function
}

type State = {
  product: Product,
  saving: boolean
}

export default class ProductForm extends Component<Props, State> {
  state = {
    product: null,
    saving: false
  }

  static defaultProps = {
    onSave: _.noop
  }

  componentDidMount() {
    this.setProduct()
  }

  componentDidUpdate(prevProps) {
    if (this.props.product !== prevProps.product) {
      this.setProduct()
    }
  }

  setProduct = () => {
    const product = new Product(this.props.product)
    product.amount /= 100
    if (product.trialAmount) {
      product.trialAmount /= 100
    }

    if (product.add_ons && product.add_ons.length && typeof product.add_ons[0] === 'object') {
      product.add_ons = product.add_ons.map(p => p.id)
    }

    this.setState({ product })
  }

  onChange = (prop: string, value: any) => {
    const product = { ...this.state.product }
    product[prop] = value
    this.setState({ product })
  }

  onTrialDurationChange = (prop: string, value: any) => {
    const product = { ...this.state.product }
    if (prop === 'unit') {
      product.trialDurationUnit = value
    } else {
      product.trialDurationValue = value
    }

    this.setState({ product })
  }

  onSubmit = async () => {
    try {
      const { urlPrefix, onSave } = this.props
      const product: Product = _.cloneDeep(this.state.product)

      product.amount *= 100
      if (product.trialAmount) {
        product.trialAmount *= 100
      }
      if (product.currency) {
        product.currency = product.currency.toUpperCase()
      }

      this.setState({ saving: true })

      toast('Saving...')
      const result = await saveProduct(product, urlPrefix)
      toast('Saved')

      this.setState({ saving: false })
      onSave(result)
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  onDeleteClick = async () => {
    try {
      if (!(await confirm())) {
        return
      }

      const { urlPrefix } = this.props
      const { product } = this.state

      this.setState({ saving: true })

      toast('Saving...')
      await deleteProduct(product, urlPrefix)
      toast('Saved')

      history.goToListView()
    } catch (err) {
      this.setState({ saving: false })
      handleError(err)
    }
  }

  render() {
    const { products } = this.props
    const { product, saving } = this.state

    if (!product) {
      return null
    }

    return (
      <div className="ProductForm">
        <Form onSubmit={this.onSubmit}>
          <Field
            type="text"
            label="Name:"
            value={product.name}
            onChange={e => this.onChange('name', e.target.value)}
          />
          <Field
            type="select"
            label="Type:"
            value={product.type}
            onChange={e => this.onChange('type', e.target.value)}
            options={[
              <option key="" value=""></option>,
              <option key="WASH" value="WASH">
                Wash
              </option>,
              <option key="CORPORATE_WASH" value="CORPORATE_WASH">
                Corporate Wash
              </option>,
              <option key="DETAIL" value="DETAIL">
                Detail
              </option>,
              <option key="SPECIAL" value="SPECIAL">
                Special
              </option>,
              <option key="CORPORATE_SPECIAL" value="CORPORATE_SPECIAL">
                Corporate Special
              </option>,
              <option key="ADD_ON" value="ADD_ON">
                Add-on
              </option>
            ]}
          />
          {product.type !== 'ADD_ON' ? (
            <Field
              className="add_ons"
              type="checkboxgroup"
              label="Add-ons:"
              value={product.add_ons}
              onChange={e => this.onChange('add_ons', e.target.value)}
              options={_.sortBy(products, 'sequence')
                .filter(p => p.type === 'ADD_ON')
                .map(p => ({ value: p.id, label: `${p.name} - ${formatMoney(p.amount / 100)}` }))}
            />
          ) : null}
          <Field
            type="textarea"
            label="Description:"
            value={product.description}
            onChange={e => this.onChange('description', e.target.value)}
          />
          <Field
            type="currency"
            label="Amount:"
            value={product.amount}
            onChange={e => this.onChange('amount', e.target.value)}
          />
          <Field
            type="number"
            label="Duration (minutes):"
            value={product.duration}
            onChange={e => this.onChange('duration', e.target.value)}
          />
          {/* <Field
            type="select"
            label="Interval:"
            value={product.interval}
            onChange={e => this.onChange('interval', e.target.value)}
            options={[
              <option key="" value="" />,
              <option key="HOURLY" value="HOURLY">
                Hourly
              </option>,
              <option key="DAILY" value="DAILY">
                Daily
              </option>,
              <option key="WEEKLY" value="WEEKLY">
                Weekly
              </option>,
              <option key="MONTHLY" value="MONTHLY">
                Monthly
              </option>
            ]}
          /> */}
          <Field
            type="number"
            label="Sort order:"
            value={product.sort_order}
            onChange={e => this.onChange('sort_order', Number(e.target.value))}
          />
          <Field
            type="checkbox"
            label="Active:"
            checked={product.active}
            onChange={e => this.onChange('active', e.target.checked)}
          />
          {/* <Field
            type="checkbox"
            label="Public:"
            checked={product.public}
            onChange={e => this.onChange('public', e.target.checked)}
          /> */}
          {/* <Field
            type="checkbox"
            label="Include trial:"
            checked={product.includeTrial}
            onChange={e => this.onChange('includeTrial', e.target.checked)}
          /> */}
          {product.includeTrial ? (
            <Fragment>
              <Field
                type="currency"
                label="Trial Amount:"
                value={product.trialAmount}
                onChange={e => this.onChange('trialAmount', e.target.value)}
              />
              <Field
                type="duration"
                label="Trial Duration:"
                value={[product.trialDurationValue, product.trialDurationUnit]}
                onChange={this.onTrialDurationChange}
              />
            </Fragment>
          ) : null}
          <Field
            type="text"
            label="Icon:"
            value={product.icon_position}
            onChange={e => this.onChange('icon_position', e.target.value)}
          />
          <ActionBar>
            <SaveButton onClick={this.onSubmit} disabled={saving} />
            {product.id ? <DeleteButton onClick={this.onDeleteClick} disabled={saving} /> : null}
          </ActionBar>
        </Form>
      </div>
    )
  }
}
