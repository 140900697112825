import './AddCardCancel.scss'

import React, { Component } from 'react'

type Props = {
  location: Location
}

type State = {}

export default class AddCardCancel extends Component<Props, State> {
  render() {
    return <div className="AddCardCancel">AddCardCancel</div>
  }
}
