import './style.scss'

import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import BasicInfo from './BasicInfo'
// import ChangePlan from './ChangePlan'
import { loadEmployee } from '../../../store/actions/employees'
import Event from '../../../models/Event'

type Props = {
  employee: ?Object,
  urlPrefix: string,
  match: Object
}

type State = {
  appointments: Object[]
}

export class EmployeeDetail extends Component<Props, State> {
  state = {
    appointments: []
  }

  async componentDidMount() {
    const { urlPrefix, match } = this.props
    const id = match.params.employeeId
    loadEmployee(id, urlPrefix)

    const res = await axios.get(`${urlPrefix}/employees/${id}/appointments`)
    const appointments = res.data.map(appt => new Event(appt))
    this.setState({ appointments })
  }

  render() {
    const { employee, urlPrefix, match } = this.props
    const { url, path } = match
    const { appointments } = this.state

    if (!employee) {
      return 'Loading...'
    }

    return (
      <div className="EmployeeDetail">
        <Switch>
          <Route
            path={`${path}/info`}
            render={props => (
              <BasicInfo
                {...props}
                employee={employee}
                urlPrefix={urlPrefix}
                appointments={appointments}
              />
            )}
          />
          <Route exact path={path} render={props => <Redirect to={`${url}/info`} />} />
        </Switch>
      </div>
    )
  }
}

const mapState = (state, props) => {
  const id = Number(props.match.params.employeeId)
  const employee = state.employees.find(m => m.id === id)

  return {
    employee
  }
}

export default connect(mapState)(EmployeeDetail)
