import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadProduct } from '../../../store/actions/products'
import ProductForm from '../Form/ProductForm'
import TitleBar from '../../shared/TitleBar/TitleBar'
import Plan from '../../../models/Plan'

type Props = {
  product: ?Object,
  products: Product[],
  plans: Plan[],
  urlPrefix: string,
  match: Object
}

type State = {}

export class ProductDetail extends Component<Props, State> {
  state = {}

  async componentDidMount() {
    const { urlPrefix, match } = this.props
    const id = match.params.productId
    loadProduct(id, urlPrefix)
  }

  render() {
    const { product, products, plans, urlPrefix } = this.props

    if (!product) {
      return 'Loading...'
    }

    return (
      <div className="ProductDetail">
        <TitleBar backTo="/products" label="Product Detail" />
        <ProductForm product={product} products={products} plans={plans} urlPrefix={urlPrefix} />
      </div>
    )
  }
}

const mapState = (state, props) => {
  const id = Number(props.match.params.productId)
  const product = state.products.find(m => m.id === id)

  return {
    product
  }
}

export default connect(mapState)(ProductDetail)
